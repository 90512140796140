(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@brightcove/studio-components"), require("react-autosuggest"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@brightcove/studio-components", "react-autosuggest"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("@brightcove/studio-components"), require("react-autosuggest")) : factory(root["react"], root["@brightcove/studio-components"], root["react-autosuggest"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__13__) {
return 