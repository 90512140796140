import React, { FC } from 'react';

type Props = {
  label?: string | null;
  url?: string | null | false;
  title: string;
};

const PanelRowUrl: FC<Props> = ({ label, url, title }) => {
  if (!url || !label) {
    return (
      <div className="ml-4 mt-6">
        <p className="title ">{title}</p>
        <p className="description">{label || '-'}</p>
      </div>
    );
  }

  return (
    <div className="ml-4 mt-6">
      <p className="title ">{title}</p>
      <p className="link">
        <a href={url}>{label}</a>
      </p>
    </div>
  );
};

export default PanelRowUrl;
