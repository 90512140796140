import React, { useState, useEffect, FC } from 'react';

import EditPanel from '../../../components/Panel/EditPanel';
import TextInput from '../../../components/ControlledComponents/TextInput';

interface LocaleInformationProps {
  panelId: string;
  data: any;
  disableEditing: boolean;
  customSection?: boolean;
  toggleDisableEditing: () => void;
  onCancel: () => void;
  onSave: (localeInfo: any) => Promise<any>;
}

const LocaleInformation: FC<LocaleInformationProps> = ({
  panelId,
  data,
  disableEditing,
  customSection,
  toggleDisableEditing,
  onCancel,
  onSave,
}) => {
  const [localeInfo, setLocaleInfo] = useState(data || {});

  useEffect(() => setLocaleInfo(data), [data]);

  const cancelChanges = () => {
    setLocaleInfo(data);
    onCancel();
  };

  const onChange = (value, property) => {
    setLocaleInfo({ ...localeInfo, [property]: value });
  };

  return (
    <EditPanel
      id={panelId}
      title="Locale Information"
      disableEditing={disableEditing}
      disableSave={!(localeInfo?.ctaLabel || localeInfo?.name)}
      onEnableEditing={toggleDisableEditing}
      onCancel={cancelChanges}
      save={() => onSave(localeInfo)}
    >
      {(isEditing) => (
        <div className="px-5 pt-4 pb-6">
          <div className="panel-label">
            {customSection && <p>CTA Label</p>}
            {!customSection && <p>Section Name</p>}
          </div>
          {isEditing ? (
            <TextInput className="mt-1" property="name" value={localeInfo?.name || ''} onChange={onChange} />
          ) : (
            <div className="panel-text">
              <p>{localeInfo?.name || '-'}</p>
            </div>
          )}
        </div>
      )}
    </EditPanel>
  );
};

export default LocaleInformation;
