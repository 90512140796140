import { GenericAction, StoreState } from '../types';
import {
  SET_CONTENT_FILTER,
  SET_CONTENT_SEARCH,
  SET_USER,
  SET_EVENTS_FILTER,
  SET_EVENTS_SEARCH,
  TOGGLE_EVENT_SAVING,
  TOGGLE_LANGUAGE_DISABLED,
} from '../actions';

export const initialState: StoreState = {
  user: null,
  contentFilter: '',
  contentSearch: '',
  eventsFilter: '',
  eventsSearch: '',
  isEventSaving: false,
  isLanguageDisabled: false,
};

export function reducer(state: StoreState = initialState, action: GenericAction) {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.data.user };
    case SET_CONTENT_FILTER:
      return { ...state, contentFilter: action.data.filter };
    case SET_CONTENT_SEARCH:
      return { ...state, contentSearch: action.data.search };
    case SET_EVENTS_FILTER:
      return { ...state, eventsFilter: action.data.filter };
    case SET_EVENTS_SEARCH:
      return { ...state, eventsSearch: action.data.search };
    case TOGGLE_EVENT_SAVING:
      return { ...state, isEventSaving: !state.isEventSaving };
    case TOGGLE_LANGUAGE_DISABLED:
      return {
        ...state,
        isLanguageDisabled: action?.data?.value ?? !state.isLanguageDisabled,
      };
    default:
      return state;
  }
}
