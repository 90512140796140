import React, { FC } from 'react';
import { RadioGroup as BrightcoveRadioGroup } from '@brightcove/studio-components';

import './RadioGroup.scss';

interface RadioGroupProps {
  value: string;
  onChange: (val: string) => void;
  options: Option[];
}
interface Option {
  label: string | JSX.Element;
  value: string;
}
export const RadioGroup: FC<RadioGroupProps> = ({ value, onChange, options }) => {
  return (
    <>
      <BrightcoveRadioGroup
        className="Radio-group"
        name="states"
        value={value}
        options={options}
        onChange={onChange}
      />
    </>
  );
};
