import React, { FC } from 'react';
import classNames from 'classnames';
import './SubHeader.scss';

interface SubHeaderProps {
  icon?: JSX.Element;
  actions?: JSX.Element;
  title: string;
  className?: string;
  subtitle: string;
  quantitySubtitle?: string;
  detailMode?: boolean;
  onBack?: () => void;
}

const SubHeader: FC<SubHeaderProps> = ({
  icon,
  actions,
  title,
  subtitle,
  detailMode,
  quantitySubtitle,
  onBack,
  className,
}) => {
  return (
    <span className={classNames('Sub-header', className)}>
      <div className="Sub-header-info">
        {detailMode ? (
          <div className="Sub-header-info-detail">
            <div className="Sub-header-info-detail-subtitle pl-6" onClick={() => (onBack ? onBack() : null)}>
              {icon ? icon : null}
              <div className="ml-2">{subtitle}</div>
            </div>
            <div className="Sub-header-info-detail-title pl-6">{title}</div>
          </div>
        ) : (
          <div className="Sub-header-info-wrapper">
            <div className="Sub-header-info-icon pl-7">{icon ? icon : null}</div>
            <div className={icon ? 'pl-5' : ''}>
              <div className="Sub-header-info-subtitle">{subtitle}</div>
              <div className="Sub-header-info-title mt-1">{title}</div>
            </div>
            <div className="Sub-header-info-quantity mt-6 ml-5">{quantitySubtitle}</div>
          </div>
        )}
      </div>
      <div className="Sub-header-actions">{actions}</div>
    </span>
  );
};

export default SubHeader;
