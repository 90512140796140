import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { getUrlWithOptions, Toaster } from '../../utils';
import { useApi } from '../../hooks/useApi';
import SubHeader from '../../components/SubHeader/SubHeader';
import Panel from '../../components/Panel/Panel';
import TextInput from '../../components/ControlledComponents/TextInput';
import Checkbox from '../../components/ControlledComponents/Checkbox';
import Button from '../../components/Button/Button';
import './AccessControls.scss';

const defaultOptionsAccessControls = [
  { name: 'Allow content viewing', value: false, id: 'allowContentPageViewing' },
  { name: 'Allow non-content viewing', value: false, id: 'allowNonContentPageViewing' },
  { name: 'Allow interactivity', value: true, id: 'allowInteraction' },
  { name: 'Allow playback', value: false, id: 'allowPlayback' },
  { name: 'Allow free user content page viewing', value: false, id: 'allowFreeUserContentPageViewing' },
  {
    name: 'Allow free user non content page viewing',
    value: false,
    id: 'allowFreeUserNonContentPageViewing',
  },
];

interface AccessControlData {
  name: string;
  externalIds: string[];
  allowContentPageViewing: boolean;
  allowNonContentPageViewing: boolean;
  allowInteraction: boolean;
  allowPlayback: boolean;
  allowFreeUserContentPageViewing: boolean;
  allowFreeUserNonContentPageViewing: boolean;
}

const getOptionValueById = (options, id) => {
  return options.find((option) => option.id === id).value;
};

const AccessControlsDetail = () => {
  const { apiGet, apiPost, apiPatch, isLoading } = useApi();
  const { id } = useParams();
  const navigate = useNavigate();

  const [optionsControllers, setOptionsAccessControllers] = useState(defaultOptionsAccessControls);
  const [currentId, setCurrentId] = useState<string>(id ? id : '');
  const [currentNameTitle, setCurrentNameTitle] = useState<string>('');
  const [currentName, setCurrentName] = useState<string>('');
  const [currentExternalId, setCurrentExternalId] = useState<string>('');
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [canCreate, setCanCreate] = useState<boolean>(false);
  const editing = !!currentId.length;

  useEffect(() => {
    if (editing) {
      const getData = async () => {
        const { data } = await apiGet(getUrlWithOptions(`/access_controls/${currentId}`));

        if (data) {
          const currentOptions = [
            { id: 'allowContentPageViewing', value: data.allowContentPageViewing },
            { id: 'allowNonContentPageViewing', value: data.allowNonContentPageViewing },
            { id: 'allowInteraction', value: data.allowInteraction },
            { id: 'allowPlayback', value: data.allowPlayback },
            { id: 'allowFreeUserContentPageViewing', value: data.allowFreeUserContentPageViewing },
            { id: 'allowFreeUserNonContentPageViewing', value: data.allowFreeUserNonContentPageViewing },
          ];

          const updatedCheckboxes = optionsControllers.map((option) => ({
            ...option,
            value: currentOptions.find((currentOption) => currentOption.id === option.id)?.value,
          }));

          setOptionsAccessControllers(updatedCheckboxes);
          setCurrentName(data.name);
          setCurrentNameTitle(data.name);
          setCurrentExternalId(data.externalIds[0]);
        }
      };

      getData();
    }
  }, [currentId]);

  const onChangeAccessControllers = (e) => {
    const updatedOptions = optionsControllers.map((option) => {
      return option.id === e.target.id ? { ...option, value: e.target.checked } : option;
    });
    const allOptionsChecked = updatedOptions.every(({ value }) => value);

    setOptionsAccessControllers(updatedOptions);
    setSelectAll(allOptionsChecked);
  };

  const onSelectAll = () => {
    const updatedOptions = optionsControllers.map((option) => ({
      ...option,
      value: !selectAll,
    }));

    setOptionsAccessControllers(updatedOptions);
    setSelectAll(!selectAll);
  };

  const onCreate = () => {
    const body: AccessControlData = {
      name: currentName,
      externalIds: [currentExternalId],
      allowContentPageViewing: getOptionValueById(optionsControllers, 'allowContentPageViewing'),
      allowNonContentPageViewing: getOptionValueById(optionsControllers, 'allowNonContentPageViewing'),
      allowInteraction: getOptionValueById(optionsControllers, 'allowInteraction'),
      allowPlayback: getOptionValueById(optionsControllers, 'allowPlayback'),
      allowFreeUserContentPageViewing: getOptionValueById(
        optionsControllers,
        'allowFreeUserContentPageViewing'
      ),
      allowFreeUserNonContentPageViewing: getOptionValueById(
        optionsControllers,
        'allowFreeUserNonContentPageViewing'
      ),
    };

    const saveData = async () => {
      if (editing) {
        const response = await apiPatch(getUrlWithOptions(`/access_controls/${currentId}`), { body });

        if (response?.data?.id?.length) {
          setCurrentNameTitle(response.data?.name);
          Toaster.success({ message: 'Access Control updated' });
        }
      } else {
        const response = await apiPost(getUrlWithOptions('/access_controls'), {
          body,
        });
        const createId = response?.data?.id;

        if (createId?.length) {
          setCurrentId(createId);
          navigate(`../${ROUTES.ACCESS_CONTROLS}/edit/${createId}`);
        }
      }
    };

    saveData();
  };

  useEffect(() => {
    setCanCreate(!!currentName?.length && !!currentExternalId?.length);
  }, [currentName, currentExternalId]);

  const onChangeName = (name: string) => {
    setCurrentName(name);
  };

  const onChangeExternalId = (externalIds: string) => {
    setCurrentExternalId(externalIds);
  };

  return (
    <>
      <SubHeader
        icon={<Icon name="ARROW_RIGHT" rotate="180" />}
        title={!!currentNameTitle.length && editing ? currentNameTitle : 'New Access Control'}
        subtitle={'Back to All Videos'}
        detailMode
        onBack={() => navigate(`../${ROUTES.ACCESS_CONTROLS}`)}
        className="pl-5"
      />

      <div className="ml-11 mr-11 mt-12">
        {isLoading ? (
          <Flex justifyContent="center" alignContent="center" className="mt-8">
            <Icon name={ICONS.SPINNER} spin className="spinner-large" />
          </Flex>
        ) : (
          <Panel title={<p>{editing ? 'Edit' : 'Create'} Access Control</p>} actions={<></>}>
            <>
              <Flex>
                <div className="ml-4">
                  <p className="AccessControlsDetail-title">Name *</p>
                  <TextInput value={currentName} onChange={onChangeName} />
                </div>
                <div className="ml-5">
                  <p className="AccessControlsDetail-title ">External ID *</p>
                  <TextInput value={currentExternalId} onChange={onChangeExternalId} />
                </div>
              </Flex>

              <Flex className="ml-4 mt-4">
                <p className="AccessControlsDetail-title mt-4" style={{ lineHeight: 0.3 }}>
                  Controls
                </p>
                <Button
                  className="ml-2"
                  variant="link"
                  text={`${selectAll ? 'Deselect All' : 'Select All'}`}
                  onClick={onSelectAll}
                />
              </Flex>
              <div className="ml-4 mt-2">
                {optionsControllers.map(({ name, value, id: optionId }) => {
                  return (
                    <Flex key={name}>
                      <Checkbox
                        className="mt-1"
                        label={name}
                        checked={value}
                        id={optionId}
                        onChange={onChangeAccessControllers}
                      />
                    </Flex>
                  );
                })}
              </div>
              <Flex justifyContent="flex-end" className="ml-4 mt-12">
                <Button
                  variant="secondary"
                  text="Cancel"
                  onClick={() => {
                    navigate(`../${ROUTES.ACCESS_CONTROLS}`);
                  }}
                />
                <Button
                  className="ml-4"
                  variant="danger"
                  text={editing ? 'Save' : 'Create'}
                  onClick={onCreate}
                  disabled={!canCreate}
                />
              </Flex>
            </>
          </Panel>
        )}
      </div>
    </>
  );
};

export default AccessControlsDetail;
