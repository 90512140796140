import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import {
  getUrlWithOptions,
  isPermissionExcluded,
  parsePermissions,
  permissionsLabelsAndValues,
} from '../../utils';
import { useApi } from '../../hooks/useApi';
import SubHeader from '../../components/SubHeader/SubHeader';
import Panel from '../../components/Panel/Panel';
import Loading from '../../components/Loading/Loading';
import TextInput from '../../components/ControlledComponents/TextInput';
import Checkbox from '../../components/ControlledComponents/Checkbox';
import Button from '../../components/Button/Button';
import './RolesDetail.scss';

const RolesDetail = () => {
  const { id: roleId } = useParams();
  const navigate = useNavigate();
  const { apiGet, apiPatch, apiPost, isLoading } = useApi();
  const [role, setRole] = useState<any>({ permissions: parsePermissions([]) });
  const [roleName, setRoleName] = useState<string>('');

  useEffect(() => {
    if (roleId) {
      (async () => {
        const { data: roleData } = await apiGet(getUrlWithOptions(`/roles/${roleId}`));

        if (roleData) {
          setRole({ ...roleData, permissions: parsePermissions(roleData.permissions) });
          setRoleName(roleData.name);
        }
      })();
    }
  }, []);

  const formatPermissions = (permissions) => {
    return Object.entries(permissions).reduce((newArr: any[], entry: any[]) => {
      const [key, arr] = entry;

      return newArr.concat(arr.map((module) => `${key.toUpperCase()}_${module}`));
    }, []);
  };

  const onSetRoleAsDefault = async () => {
    const { data } = await apiPatch(getUrlWithOptions(`/roles/${roleId}`), { body: { isDefault: true } });

    if (data) {
      setRole({ ...role, isDefault: data.isDefault });
    }
  };

  const onSaveChanges = async () => {
    if (roleName !== '') {
      const { data } = await apiPatch(getUrlWithOptions(`/roles/${roleId}`), {
        body: {
          name: roleName,
          permissions: formatPermissions(role.permissions),
        },
      });

      if (data) {
        goBack();
      }
    }
  };

  const onAddRole = async () => {
    if (roleName !== '') {
      const { data } = await apiPost(getUrlWithOptions(`/roles`), {
        body: {
          name: roleName,
          permissions: formatPermissions(role.permissions),
        },
      });

      if (data) {
        goBack();
      }
    }
  };

  const onChangePermissions = (accessType, module, isChecked) => {
    const currentPermissions = role.permissions[accessType];
    const updatedPermissions = isChecked
      ? currentPermissions.concat(module)
      : currentPermissions.filter((item) => item !== module);

    setRole({
      ...role,
      permissions: {
        ...role.permissions,
        [accessType]: updatedPermissions,
      },
    });
  };

  const onSelectAll = (permissionType) => {
    setRole({
      ...role,
      permissions: {
        ...role.permissions,
        [permissionType]: permissionsLabelsAndValues.reduce((acc: string[], permission) => {
          if (!isPermissionExcluded(permissionType, permission.value)) {
            acc.push(permission.value);
          }

          return acc;
        }, []),
      },
    });
  };

  const onDeselectAll = (permissionType) => {
    setRole({
      ...role,
      permissions: {
        ...role.permissions,
        [permissionType]: [],
      },
    });
  };

  const goBack = () => navigate(`../${ROUTES.ROLES}`);

  const { isDefault, name, permissions } = role || {};

  return isLoading ? (
    <Loading />
  ) : (
    <div className="Roles-detail">
      <SubHeader
        title={roleId ? 'Edit Role' : 'Create Role'}
        subtitle="Back to Roles"
        detailMode={true}
        onBack={goBack}
        icon={<Icon name={ICONS.ARROW_RIGHT} flip="horizontal" />}
        actions={
          roleId ? (
            <Button
              className="set-as-default-btn"
              variant="primary"
              text="Set as default"
              disabled={isDefault}
              onClick={onSetRoleAsDefault}
            />
          ) : (
            <></>
          )
        }
      />

      <div className="main-content">
        <Panel title={roleId ? name : 'New role'}>
          <div className="panel-content">
            <div>
              <div className="required label">Role name</div>
              <TextInput
                className="mt-2"
                value={roleName}
                placeholder="Role name"
                onChange={(updatedName) => setRoleName(updatedName)}
              />
            </div>
            {Object.entries(permissions).map(([permissionType, permissionValue]: any, index) => {
              return (
                <div key={index} className="mt-7 pb-1">
                  <Flex alignItems="center">
                    <div className="required label">Module Access: {_.capitalize(permissionType)}</div>
                    <Button
                      className="ml-2 select-all-btn"
                      variant="link"
                      text="Select All"
                      onClick={() => onSelectAll(permissionType)}
                    />
                    <Button
                      className="ml-2 select-all-btn"
                      variant="link"
                      text="Deselect All"
                      onClick={() => onDeselectAll(permissionType)}
                    />
                  </Flex>
                  <div className="checkbox-grid">
                    {permissionsLabelsAndValues.map(
                      ({ label, value }, index) =>
                        !isPermissionExcluded(permissionType, value) && (
                          <Checkbox
                            key={index}
                            label={label}
                            checked={permissionValue.includes(value)}
                            onChange={(e) => onChangePermissions(permissionType, value, e.target.checked)}
                          />
                        )
                    )}
                  </div>
                </div>
              );
            })}
            <Flex className="mt-12 pt-2">
              <Button variant="secondary" text="Cancel" onClick={goBack} />
              <Button
                className="ml-3"
                variant="primary"
                text={roleId ? 'Save changes' : 'Add role'}
                onClick={roleId ? onSaveChanges : onAddRole}
                disabled={!roleName}
              />
            </Flex>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default RolesDetail;
