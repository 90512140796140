import { useSelector } from 'react-redux';

import { Permission } from '../utils/types';
import { selectUser } from '../store/selectors';

export const usePermissions = () => {
  const user = useSelector(selectUser);

  const hasPermission = (permission?: Permission) => {
    if (!permission) return true;

    if (user?.role === 'Super Admin') return true;

    return !!user?.permissions?.includes(permission);
  };

  return { hasPermission };
};
