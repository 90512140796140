import React, { useState } from 'react';

import InteractionSummaryModal from './InteractionSummaryModal';
import GenericModal from './GenericModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import CtaModal from './CtaModal/index';
import AddPageModal from './AddPageModal';
import AddItemModal from './AddItemModal';
import AddContentItemModal from './AddContentItemModal';

const withModal = (WrappedComponent, Modal) => {
  const ModalOpener = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const { modalProps, onModalConfirm } = props;

    const closeModal = () => {
      setIsOpen(false);
    };

    const openModal = () => {
      setIsOpen(true);
    };

    const confirmModal = (data) => {
      setIsOpen(false);
      onModalConfirm?.(data);
    };

    return (
      <>
        <WrappedComponent {...props} onClick={openModal} />
        <Modal
          {...modalProps}
          appElement={document.getElementById('root')}
          show={isOpen}
          onConfirm={confirmModal}
          onClose={closeModal}
        />
      </>
    );
  };

  return ModalOpener;
};

export {
  withModal,
  DeleteConfirmationModal,
  GenericModal,
  AddItemModal,
  AddPageModal,
  InteractionSummaryModal,
  CtaModal,
  AddContentItemModal
};
