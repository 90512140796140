import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { DEFAULT_LANGUAGE, getLocaleByLanguage, getNativeLanguage, getUrlWithOptions } from '../../utils';
import useGoBack from '../../hooks/useGoBack';
import { useApi } from '../../hooks/useApi';
import AddBodyClasses from '../../helpers/AddBodyClasses';
import TableOfContents from '../../components/TableOfContents/TableOfContents';
import SubHeader from '../../components/SubHeader/SubHeader';
import PublicationStateButtons from '../../components/PublicationStateButtons/PublicationStateButtons';
import Images from '../../components/Panel/Images';
import Availability from '../../components/Panel/Availability';
import { DeleteConfirmationModal, withModal } from '../../components/Modals';
import Loading from '../../components/Loading/Loading';
import LanguageDropdown from '../../components/LanguageDropdown/LanguageDropdown';
import Button from '../../components/Button/Button';

import Sections from './Panels/Sections';
import PageInformation from './Panels/PageInformation';
import LocaleInformation from './Panels/LocaleInformation';
import Html from './Panels/Html';
import FeaturedContentRules from './Panels/FeaturedContentRules/FeaturedContentRules';

import './PageDetail.scss';

const pagePanels = [
  {
    title: 'Page Information',
    id: 'page-information',
    offset: -250,
  },
  {
    title: 'Locale Information',
    id: 'locale-information',
    offset: -200,
  },
  {
    title: 'Additional HTML',
    id: 'additional-html',
    offset: -200,
  },
  {
    title: 'Page content',
    id: 'page-content',
    offset: -200,
  },
  {
    title: 'No Results Content',
    id: 'no-results-content',
    offset: -200,
  },
  {
    title: 'Featured Content Rules',
    id: 'featured-content-rules',
    offset: -200,
  },
  {
    title: 'Availability',
    id: 'availability',
    offset: -200,
  },
  {
    title: 'Images',
    id: 'images',
    offset: -200,
  },
];

const DeleteButton = withModal(Button, DeleteConfirmationModal);

const PageDetail = () => {
  const { id } = useParams() as { id: string };
  const { goBackLabel, goBack } = useGoBack(ROUTES.PAGES, 'Back to Pages');
  const { apiGet, apiPut, apiPatch, apiDelete } = useApi();
  const [disableEditing, setDisableEditing] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);
  const [pageInfo, setPageInfo] = useState<any>(null);
  const [locales, setLocales] = useState<any[]>([]);
  const [currentLocaleData, setCurrentLocaleData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(true);
  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const PAGES_ENDPOINT = '/pages';
  const pageUrl = getUrlWithOptions(`${PAGES_ENDPOINT}/${id}`);
  const pageType = pageInfo?.type.toLowerCase();

  useEffect(() => {
    if (refetch) {
      (async () => {
        const { data } = await apiGet(pageUrl);

        if (data) {
          const localesData = data?.locales;
          const language = isInitializing
            ? getNativeLanguage(localesData) || DEFAULT_LANGUAGE
            : currentLanguage;
          const currentLocaleInfo = getLocaleByLanguage(localesData, language);

          setPageInfo(data);
          setLocales(localesData);
          setCurrentLocaleData(currentLocaleInfo);

          if (isInitializing) {
            setCurrentLanguage(language);
            setIsInitializing(false);
          }
        }

        setLoading(false);
        setRefetch(false);
      })();
    }
  }, [refetch]);

  const toggleDisableEditing = () => setDisableEditing(!disableEditing);

  const getPagePublishingStatus = async () => {
    return apiGet(`${pageUrl}/status`);
  };

  const updateLocales = (updatedLocale, updatedLocales) => {
    const index = _.findIndex(updatedLocales, (locale: any) => locale.language === updatedLocale.language);

    if (index > -1) {
      updatedLocales[index] = updatedLocale;
    } else {
      updatedLocales.push(updatedLocale);
    }

    setCurrentLocaleData(updatedLocale);
    setLocales(updatedLocales);
  };

  const updatePageInfo = async (updatedPageInfo): Promise<any> => {
    const { data, error } = await apiPatch(pageUrl, { body: updatedPageInfo });

    if (error) {
      return { error };
    }

    if (data) {
      setRefetch(true);
      setPageInfo(data);
    }

    toggleDisableEditing();

    return {};
  };

  const updateLocaleInfo = async (localeInfo: any): Promise<any> => {
    const updatedLocaleInfo = { ...currentLocaleData };
    const hasCode = localeInfo.code || localeInfo.code === '';

    if (hasCode) {
      updatedLocaleInfo.content = localeInfo.code;
    }

    const requestBody = hasCode ? updatedLocaleInfo : localeInfo;
    const { data, error } = await apiPut(`${pageUrl}/locales/${currentLanguage}`, {
      body: _.omit(requestBody, ['language', 'images']),
    });

    if (error) {
      return { error };
    }

    if (data) {
      setRefetch(true);
    }

    const { data: publication } = await getPagePublishingStatus();

    if (publication) {
      setPageInfo({ ...pageInfo, ...publication });
    }

    toggleDisableEditing();

    return {};
  };

  const updatePublicationStatus = async (updateType) => {
    const { data } = await apiPut(`${pageUrl}/${updateType}`);

    if (data) {
      setPageInfo({ ...pageInfo, ...data });

      if (updateType === 'revert') {
        setRefetch((prevRefetch) => !prevRefetch);
      }
    }
  };

  const publishChanges = async (publishDate) => {
    const { data } = await apiPut(`${pageUrl}/publish`, {
      body: {
        scheduledPublishAt: publishDate?.length > 0 ? new Date(publishDate) : undefined,
      },
    });

    if (data) {
      setPageInfo({ ...pageInfo, ...data });
    }
  };

  const onDeletePage = async () => {
    const { error } = await apiDelete(
      getUrlWithOptions(`${PAGES_ENDPOINT}?ids=${encodeURIComponent(pageInfo.id)}`)
    );

    if (!error) {
      goBack();
    }
  };

  const onEditModeChange = async (editing, isSavedData) => {
    setDisableEditing(editing);

    if (isSavedData) {
      setRefetch((prevRefetch) => !prevRefetch);
    }
  };

  const filteredTableOfContents = () => {
    return pagePanels.filter((panel) => {
      switch (pageType) {
        case 'searchpage':
          return true;
        case 'eventpage':
          return (
            panel.id === 'page-information' || panel.id === 'page-content' || panel.id === 'availability'
          );
        case 'custompage':
          return (
            panel.id !== 'additional-html' &&
            panel.id !== 'no-results-content' &&
            panel.id !== 'featured-content-rules'
          );
        default:
          return panel.id !== 'no-results-content' && panel.id !== 'featured-content-rules';
      }
    });
  };

  const onLanguageChange = (value) => {
    const localeInfo = getLocaleByLanguage(locales, value);

    setCurrentLanguage(value);
    setCurrentLocaleData(localeInfo);
  };

  const onNativeLanguageChange = (updatedLocale) => {
    const updatedLocales = locales.map((locale) => {
      if (locale.language === updatedLocale.language) {
        return { ...locale, isNativeLanguage: true };
      } else {
        return { ...locale, isNativeLanguage: false };
      }
    });

    updateLocales(updatedLocale, updatedLocales);
  };

  const defaultPanelProps = {
    onCancel: toggleDisableEditing,
    onSave: updatePageInfo,
    disableEditing,
    onEnableEditing: toggleDisableEditing,
  };

  return (
    <>
      <AddBodyClasses classes={['disabled-overflow']} />
      {loading ? (
        <Loading />
      ) : (
        <div className="Page-detail">
          <SubHeader
            title={pageInfo?.name}
            subtitle={goBackLabel}
            className="pl-5 pr-4"
            detailMode={true}
            onBack={goBack}
            icon={<Icon name={ICONS.ARROW_RIGHT} flip="horizontal" />}
            actions={
              <>
                <PublicationStateButtons
                  disabled={disableEditing}
                  publication={pageInfo?.publication}
                  allowRevert
                  allowPreview
                  id={id}
                  onRevert={() => updatePublicationStatus('revert')}
                  onPublish={(val) => publishChanges(val)}
                  onUnschedule={() => updatePublicationStatus('unschedule')}
                />
                {!pageInfo?.internal && (
                  <DeleteButton
                    className="ml-1 mr-1"
                    variant="secondary"
                    disabled={disableEditing}
                    modalProps={{
                      title: 'Delete Page',
                      children: (
                        <>
                          You will be deleting {pageInfo?.name} from the system. This action cannot be undone.
                        </>
                      ),
                    }}
                    onModalConfirm={onDeletePage}
                  >
                    Delete
                  </DeleteButton>
                )}
              </>
            }
          />
          {pageInfo && (
            <Flex>
              <div style={{ flex: 1 }} className="main-content py-8 px-11">
                <PageInformation {...defaultPanelProps} panelId="page-information" data={pageInfo} />
                {pageType !== 'eventpage' && (
                  <LocaleInformation
                    {...defaultPanelProps}
                    panelId="locale-information"
                    data={currentLocaleData}
                    onSave={updateLocaleInfo}
                  />
                )}
                {pageType === 'custompage' ? (
                  <Html
                    {...defaultPanelProps}
                    panelId="page-content"
                    title="Page Content"
                    panelGroupTitle="Page HTML"
                    code={currentLocaleData?.content || ''}
                    onSave={updateLocaleInfo}
                  />
                ) : (
                  <>
                    {pageType !== 'eventpage' && (
                      <Html
                        {...defaultPanelProps}
                        panelId="additional-html"
                        title="Additional HTML"
                        code={currentLocaleData?.content || ''}
                        onSave={updateLocaleInfo}
                      />
                    )}
                    <Sections
                      {...defaultPanelProps}
                      panelId="page-content"
                      title="Page Content"
                      sections={pageInfo.sections}
                      sectionType="sections"
                    />
                  </>
                )}
                {pageType === 'searchpage' && (
                  <>
                    <Sections
                      {...defaultPanelProps}
                      panelId="no-results-content"
                      title="No Results Content"
                      sections={pageInfo.noResultsSections}
                      sectionType="noResultsSections"
                    />
                    <FeaturedContentRules
                      {...defaultPanelProps}
                      panelId="featuted-content-rules"
                      title="Featured Content Rules"
                      featuredRules={pageInfo.featuredRules}
                    />
                  </>
                )}
                <Availability
                  idPanel="availability"
                  data={_.pick(pageInfo, ['startDate', 'endDate', 'locations', 'accessControls'])}
                  endpointRootPath={PAGES_ENDPOINT}
                  onEditModeChange={onEditModeChange}
                  disableEditing={disableEditing}
                />
                {pageType !== 'eventpage' && (
                  <Images
                    idPanel="images"
                    data={{
                      id,
                      language: currentLanguage,
                      images: currentLocaleData?.images || [],
                    }}
                    endpointRootPath={PAGES_ENDPOINT}
                    onEditModeChange={onEditModeChange}
                    disableEditing={disableEditing}
                  />
                )}
              </div>
              <div className="side-menu pt-8 pr-11">
                {pageType !== 'eventpage' && (
                  <LanguageDropdown
                    title="Language"
                    currentLanguage={currentLanguage}
                    nativeLanguage={getNativeLanguage(locales)}
                    endpointRootPath={PAGES_ENDPOINT}
                    onNativeLanguageChange={onNativeLanguageChange}
                    onLanguageChange={onLanguageChange}
                  />
                )}
                <TableOfContents data={filteredTableOfContents()} hasBorder />
              </div>
            </Flex>
          )}
        </div>
      )}
    </>
  );
};

export default PageDetail;
