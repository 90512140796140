import React, { FC, useState } from 'react';

import Button from '../Button/Button';
import Collections from '../../routes/Collections/Collections';

import GenericModal, { GenericModalProps } from './GenericModal';
import './AddCollectionModal.scss';

interface AddCollectionModalProps extends GenericModalProps {
  title: string;
  confirmBtnLabel?: string;
  filter?: string;
  singleSelection?: boolean;
  onAddCollection?: (param: any[]) => void;
  onCancel?: () => void;
}

const AddCollectionModal: FC<AddCollectionModalProps> = ({
  title,
  confirmBtnLabel,
  filter,
  singleSelection,
  onAddCollection,
  show,
  onClose,
  onCancel,
}) => {
  const [selections, setSelections] = useState<any[]>([]);

  const handleCloseModal = () => {
    onCancel?.();
    onClose?.();
  };

  const onRowSelect = (items: any[]) => {
    setSelections(items);
  };

  return (
    <GenericModal
      className="Add-collection-modal"
      show={show}
      title={title}
      onClose={handleCloseModal}
      buttons={
        <>
          <Button variant="secondary" text="Cancel" onClick={handleCloseModal} />,
          <Button
            className="ml-3"
            variant="primary"
            text={confirmBtnLabel || 'Add Collection'}
            disabled={selections.length === 0}
            onClick={() => {
              onAddCollection?.(selections);
              handleCloseModal();
            }}
          />
        </>
      }
    >
      <Collections embedded filter={filter} singleSelection={singleSelection} onRowSelect={onRowSelect} />
    </GenericModal>
  );
};

export default AddCollectionModal;
