import { useNavigate, useParams } from 'react-router';
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import * as ROUTES from '../../routes';
import { pageSections } from '../../../utils';
import TitleDescription from '../../../components/TitleDescription/TitleDescription';
import Table from '../../../components/Table/Table';
import EditPanel from '../../../components/Panel/EditPanel';
import AddSectionModal from '../../../components/Modals/AddSectionModal';
import { withModal } from '../../../components/Modals';
import Button from '../../../components/Button/Button';
import './Sections.scss';

const allSections = _.values(pageSections).flat();
const defaultSections = pageSections.default_sections.map((section) =>
  section.replace(/ /g, '').toLowerCase()
);
const AddSectionButton = withModal(Button, AddSectionModal);

const Sections = ({
  panelId,
  sections,
  sectionType,
  title,
  disableEditing,
  onEnableEditing,
  onCancel,
  onSave,
}) => {
  const navigate = useNavigate();
  const { id: pageId } = useParams();
  const [sectionsInfo, setSectionsInfo] = useState<any[]>([]);

  useEffect(() => {
    setSectionsInfo(sections);
  }, [sections]);

  const cancelChanges = () => {
    setSectionsInfo(sections);
    onCancel();
  };

  const onClickDeleteRow = (id: string) => {
    setSectionsInfo((prev) => prev.filter((item: any) => item.id !== id));
  };

  const onDragRow = (reorderedRows: any[]) => setSectionsInfo(reorderedRows);

  const isDefaultSection = (type) => {
    return defaultSections.indexOf(type.toLowerCase()) > -1;
  };

  const getSectionType = (type) => {
    return allSections.filter(
      (sectionType) => sectionType.replace(/ /g, '').toLowerCase() === type.toLowerCase()
    );
  };

  const parseSections = (sections) => {
    return sections.map((section) => ({
      ...section,
      disableDragging: isDefaultSection(section.type),
      disableDeletion: isDefaultSection(section.type),
    }));
  };

  const addSection = useCallback(
    (data) => {
      setSectionsInfo(() => {
        return [...sectionsInfo, { ...data, id: `temp_id_${_.uniqueId()}` }];
      });
    },
    [sectionsInfo]
  );

  const saveChanges = () => {
    const updatedSectionInfo = sectionsInfo.map((section) =>
      section.id.indexOf('temp_id') > -1 ? _.omit(section, ['id']) : section
    );

    return onSave({ [sectionType]: updatedSectionInfo });
  };

  const columns = [
    {
      Header: 'Sections',
      colWidth: '20%',
      accessor: (row) => [row.name, row.id],
      Cell: ({ value }) => {
        const [name, sectionId] = value;

        return (
          <>
            {sectionId.indexOf('temp_id') > -1 ? (
              name
            ) : (
              <TitleDescription
                title={name}
                description={sectionId}
                id={sectionId}
                onClick={() => navigate(`../${ROUTES.PAGES}/edit/${pageId}/sections/${sectionId}`)}
              />
            )}
          </>
        );
      },
    },
    {
      Header: 'Type',
      colWidth: '10%',
      accessor: 'type',
      Cell: ({ value }) => getSectionType(value),
    },
  ];

  return (
    <EditPanel
      id={panelId}
      title={title}
      disableEditing={disableEditing}
      onEnableEditing={onEnableEditing}
      onCancel={cancelChanges}
      save={saveChanges}
    >
      {(isEditing) => (
        <div className="Page-detail-sections">
          {sectionsInfo && (
            <Table
              className={classNames({ editing: isEditing })}
              data={parseSections(sectionsInfo) || []}
              columns={columns}
              onClickDeleteRow={onClickDeleteRow}
              onDragRow={onDragRow}
              hasDeletion
              hasDragging
            />
          )}
          {isEditing && (
            <AddSectionButton
              className="mb-5 mt-5 ml-6 add-menu-item-button"
              variant="primary"
              modalProps={{
                confirmBtnLabel: 'Add section',
                singleSelection: true,
                onAddSection: addSection,
              }}
            >
              + Add section
            </AddSectionButton>
          )}
        </div>
      )}
    </EditPanel>
  );
};

export default Sections;
