import { useNavigate } from 'react-router';
import React, { useState } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { getUrlWithOptions, pageFilters } from '../../utils';
import { useApi } from '../../hooks/useApi';
import SubHeader from '../../components/SubHeader/SubHeader';
import Panel from '../../components/Panel/Panel';
import TextInput from '../../components/ControlledComponents/TextInput';
import SimpleSelect from '../../components/ControlledComponents/SimpleSelect';
import Button from '../../components/Button/Button';
import './CreatePage.scss';

const typeOptions = pageFilters
  .filter((filterInfo) => filterInfo.id && !filterInfo.internal && filterInfo.id !== 'EventLandingPage')
  .map((filter) => ({
    label: filter.name.substring(0, filter.name.length - 1),
    value: filter.id,
  }));

const CreatePage = () => {
  const navigate = useNavigate();
  const { apiPost } = useApi();
  const [pageType, setPageType] = useState<string>('HomePage');
  const [pageName, setPageName] = useState<string>('');

  const onCreate = async () => {
    if (pageName && pageType) {
      const { data } = await apiPost(getUrlWithOptions('/pages'), {
        body: {
          name: pageName,
          type: pageType,
        },
      });

      if (data) {
        navigate(`../${ROUTES.PAGES}/edit/${data.id}`);
      }
    }
  };

  const goBack = () => navigate(`../${ROUTES.PAGES}`);

  return (
    <div className="Create-page">
      <SubHeader
        title="New Page"
        subtitle="Back to Pages"
        className="pl-5 pr-4"
        detailMode={true}
        onBack={goBack}
        icon={<Icon name={ICONS.ARROW_RIGHT} flip="horizontal" />}
      />
      <div className="main-content">
        <Panel
          title="Page Information"
          actions={
            <>
              <Button className="mr-1" variant="secondary" text="Cancel" onClick={goBack} />
              <Button variant="primary" text="Save" onClick={onCreate} disabled={!pageName || !pageType} />
            </>
          }
        >
          <div className="content ml-4 mb-12">
            <Flex>
              <div className="mr-5 page-name-wrapper">
                <div className="label required">Page Name</div>
                <TextInput
                  className="mt-2"
                  value={pageName}
                  onChange={(updatedName) => setPageName(updatedName)}
                />
              </div>
              <div className="mb-8">
                <div className="label required">Page type</div>
                <SimpleSelect
                  className="select-input-lg mt-2"
                  value={pageType}
                  options={typeOptions}
                  onChange={(type) => setPageType(type)}
                />
              </div>
            </Flex>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default CreatePage;
