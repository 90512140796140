import React, { useEffect, useState } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import { getUrlWithOptions, UPDATE, ADD, DELETE, updateItems } from '../../utils';
import { ScheduledTextPanelData } from '../../interfaces/ScheduledTextPanel';
import { useApi } from '../../hooks/useApi';
import SubHeader from '../../components/SubHeader/SubHeader';
import GenericModal from '../../components/Modals/GenericModal';
import DeleteConfirmationModal from '../../components/Modals/DeleteConfirmationModal';
import Button from '../../components/Button/Button';

import ServiceMessagesView from './ServiceMessagesView';
import ServiceMessagesCreate from './ServiceMessagesCreate';
import './ServiceMessages.scss';

const resource = '/service_messages';

const ServiceMessages = () => {
  const { apiGet, apiDelete, apiPut, apiPost, isLoading, currentMethod } = useApi();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showShowCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [editingId, setEditingId] = useState<string>('');
  const [currentDeletion, setCurrentDeletion] = useState<string>('');
  const [messages, setMessages] = useState<ScheduledTextPanelData[]>();
  const [languages, setLanguages] = useState<any[]>();

  useEffect(() => {
    getLanguages();
  }, []);

  const getData = async () => {
    const { data } = await apiGet(getUrlWithOptions(resource));

    setMessages(data);
  };
  const getLanguages = async () => {
    const { data } = await apiGet(getUrlWithOptions(`/languages`));

    setLanguages(data);
    getData();
  };

  const onCreateServiceMessage = async (message: ScheduledTextPanelData, id?: string) => {
    if (id?.length) {
      const { data } = await apiPut(getUrlWithOptions(`${resource}/${id}`), {
        body: message,
      });

      if (data?.id.length) {
        setEditingId('');
        setMessages(updateItems(messages, [data], UPDATE));
      }
    } else {
      const { data } = await apiPost(getUrlWithOptions(`${resource}`), {
        body: message,
      });

      if (data?.id.length) {
        setEditingId('');
        setShowCreateModal(false);
        setMessages(updateItems(messages, [data], ADD));
      }
    }
  };

  const onDeleteServiceMessage = async () => {
    const response = await apiDelete(getUrlWithOptions(`${resource}/${currentDeletion}`));

    if (response.status === 204) {
      setMessages(updateItems(messages, [{ id: currentDeletion }], DELETE));
    }

    setEditingId('');
    setShowDeleteModal(false);
  };

  return (
    <>
      <SubHeader
        icon={<Icon name="CHAT" className="mt-5" />}
        title={'Service Messages'}
        subtitle={'NetApp TV'}
        quantitySubtitle={`${messages?.length ? messages.length : 0} Messages`}
        className="pl-4"
      />
      <div className="ml-11 mr-11">
        <DeleteConfirmationModal
          title="Delete Message"
          show={showDeleteModal}
          onDelete={onDeleteServiceMessage}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
        >
          <>You will be deleting 1 message permanently from the system. This action cannot be undone.</>
        </DeleteConfirmationModal>
        <GenericModal className="Service-Message-Modal" show={showShowCreateModal}>
          <ServiceMessagesCreate
            setEditingId={setEditingId}
            id={''}
            setShowCreateModal={setShowCreateModal}
            onCreateServiceMessage={onCreateServiceMessage}
            status={'Inactive'}
            startTime={''}
            endTime={''}
            messages={[]}
            languages={languages?.length ? languages : []}
          />
        </GenericModal>
        <div className="mt-5">
          <Button
            variant="primary"
            text="Create Service Message"
            onClick={() => {
              setShowCreateModal(true);
            }}
            disabled={isLoading || editingId?.length > 0}
          />
        </div>
        {isLoading && currentMethod === 'GET' ? (
          <Flex justifyContent="center" alignContent="center" className="mt-8">
            <Icon name={ICONS.SPINNER} spin className="spinner-large" />
          </Flex>
        ) : (
          <div className="mt-4">
            {messages?.map((message: ScheduledTextPanelData) =>
              editingId === message.id ? (
                <ServiceMessagesCreate
                  key={message.id}
                  setEditingId={setEditingId}
                  showDeleteModal={(value: boolean, id: string) => {
                    setCurrentDeletion(id);
                    setShowDeleteModal(value);
                  }}
                  onCreateServiceMessage={onCreateServiceMessage}
                  id={message.id}
                  status={message.status}
                  startTime={message.startTime}
                  endTime={message.endTime}
                  messages={message.messages}
                  languages={languages?.length ? languages : []}
                />
              ) : (
                <ServiceMessagesView
                  key={message.id}
                  setEditingId={setEditingId}
                  id={message.id}
                  status={message.status}
                  startTime={message.startTime}
                  endTime={message.endTime}
                  messages={message.messages}
                  showDeleteModal={(value: boolean, id: string) => {
                    setCurrentDeletion(id);
                    setShowDeleteModal(value);
                  }}
                  disabled={!!editingId?.length}
                  languages={languages?.length ? languages : []}
                />
              )
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ServiceMessages;
