import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Autosuggest from 'react-autosuggest';
import { provideTheme, keycode, MenuItem } from '@brightcove/studio-components';
import PlainTagListInput from './PlainTagListInput';
import css from 'styles/autosuggest.sass';

const autosuggestTheme = {
  container: {
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: '11000',
    marginTop: '2px',
    left: '0',
    right: '0',
    border: '0.5px solid #8e9093',
    borderRadius: '1px',
    boxShadow: '0 10px 16px 0px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#fff',
    maxHeight: '11rem',
    overflowY: 'auto'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  }
};

export const getTagFromDataSet = ({ inputString, dataSet }) => {
  const matchingTag = dataSet.find(dataSetItem => {
    return (
      dataSetItem.label.toLocaleLowerCase() ===
      inputString.toLocaleLowerCase().trim()
    );
  });
  return matchingTag ? matchingTag : null;
};

// Match case-insensitive against all suggestions.
// Don't suggest items that have already been chosen.
export const getSuggestions = (
  inputValue,
  suggestionsList,
  minChars,
  maxSuggestions,
  currentSuggestions
) => {
  const searchString = inputValue.trim().toLocaleLowerCase();
  const filteredSuggestions = suggestionsList
    .filter(
      suggestion =>
        suggestion.label.toLocaleLowerCase().includes(searchString) &&
        !currentSuggestions.includes(suggestion.label)
    )
    .slice(0, maxSuggestions);

  return inputValue.length < minChars ? [] : filteredSuggestions;
};

const highlightLabelMatch = (suggestion, value) => {
  const { label } = suggestion;
  const matchIndex = label.toLowerCase().indexOf(value.toLowerCase());
  const beforeMatch = label.substr(0, matchIndex);
  const match = label.substr(matchIndex, value.length);
  const afterMatch = label.substr(matchIndex + value.length);

  const highlightedLabel = (
    <React.Fragment>
      {beforeMatch}
      <span className={css.match}>{match}</span>
      {afterMatch}
    </React.Fragment>
  );

  return { ...suggestion, highlightedLabel };
};

class AutosuggestTagListInput extends React.Component {
  constructor(props) {
    super(props);
    const { suggestions } = props;

    this.state = {
      value: '',
      suggestions,
      highlightedSuggestion: null,
      isValid: true
    };

    this.getTagLabels = () => {
      return this.props.value.map(tag => {
        return tag[this.props.labelKey];
      });
    };

    this.onPaste = evt => {
      evt.preventDefault();
      const inputTextArray = evt.clipboardData.getData('Text').split(',');
      const validTagObjects = [];
      const onValidTagCallback = tag =>
        tag ? validTagObjects.push(tag) : null;
      inputTextArray.forEach(inputString => {
        if (inputString.trim() === '') {
          return;
        }
        this.addTag(
          evt,
          this.generateTagObject(inputString),
          onValidTagCallback
        );
      });
      this.props.onChange([...this.props.value, ...validTagObjects]);
    };

    this.handleKeyDown = (evt, onKeyDown) => {
      // Remove last tag on backspace if the input is empty
      if (keycode(evt).is('backspace') && this.state.value === '') {
        evt.preventDefault();
        const { onChange, value } = this.props;
        if (!value.length) {
          // No tags to remove
          return;
        }
        onChange(value.slice(0, value.length - 1));
      }

      if (
        keycode(evt).is('comma') ||
        keycode(evt).is('tab') ||
        keycode(evt).is('enter')
      ) {
        if (this.state.value === '') {
          return;
        }
        const tag =
          this.state.highlightedSuggestion !== null
            ? this.generateTagObject(
                this.state.highlightedSuggestion[this.props.labelKey]
              )
            : this.generateTagObject(this.state.value);
        this.addTag(evt, tag);
      } else {
        onKeyDown(evt);
      }
    };

    this.renderInput = this.renderInput.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.addTag = this.addTag.bind(this);
    this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(
      this
    );
    this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(
      this
    );
    this.onChange = this.onChange.bind(this);
    this.onPaste = this.onPaste.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  handleError(err) {
    this.setState({ isValid: !err });
    if (this.props.onError) {
      this.props.onError(err);
    }
  }

  renderInput({ onKeyDown, ...props }) {
    return (
      <PlainTagListInput
        value={this.props.value}
        onChange={values => this.props.onChange(values)}
        inputProps={{
          onPaste: evt => this.onPaste(evt),
          onKeyDown: evt => {
            this.handleKeyDown(evt, onKeyDown);
          },
          ...props
        }}
        placeholder={this.props.placeholder}
        hasError={!this.state.isValid}
      />
    );
  }

  generateTagObject(inputString) {
    const tag = getTagFromDataSet({
      inputString,
      dataSet: this.props.suggestions
    });
    let obj = {};
    if (tag === null) {
      obj[this.props.labelKey] = inputString;
      obj.isValid = false;
    } else {
      obj = tag;
      obj.isValid = true;
    }
    return obj;
  }

  // adds the given tag to list of current tags. Tag should be supplied as an
  // object with at least a label
  addTag(evt, tag, onValidTagCallback) {
    const tagLabel = tag[this.props.labelKey];
    if (
      tagLabel.trim() === '' ||
      this.getTagLabels().indexOf(tagLabel) !== -1
    ) {
      evt.preventDefault();
      return;
    }

    if (this.props.requireSuggestionMatch && !tag.isValid) {
      this.handleError(tag);
      if (!keycode(evt).is('tab')) {
        evt.preventDefault();
      }
      return;
    }

    evt.preventDefault();

    this.setState({ value: '' });
    this.handleError(null);
    this.handleSuggestionsClearRequested();

    if (onValidTagCallback) {
      onValidTagCallback(tag);
      return;
    }

    const tags = this.props.value;
    this.props.onChange([...tags, tag]);
  }

  renderSuggestion(suggestion, { isHighlighted }) {
    return (
      <MenuItem
        className={cx(css[this.props.theme], css.menuItem, {
          [css.selected]: isHighlighted
        })}
        onMouseDown={evt =>
          this.addTag(
            evt,
            this.generateTagObject(this.state.highlightedSuggestion.label)
          )
        }
        selected={false} // Override default selected highlight style
        Component="div"
      >
        {suggestion.highlightedLabel}
      </MenuItem>
    );
  }

  handleSuggestionsFetchRequested({ value }) {
    const suggestions = getSuggestions(
      value,
      this.props.suggestions,
      this.props.suggestMinChars,
      this.props.maxSuggestions,
      this.getTagLabels()
    );
    this.setState({
      suggestions: suggestions.map(suggestion => {
        return highlightLabelMatch(suggestion, value);
      })
    });
  }

  handleSuggestionsClearRequested() {
    this.setState({ suggestions: [] });
  }

  onChange(evt, { newValue }) {
    if (!newValue.length) {
      this.handleError(null);
    }
    this.setState({ value: newValue });
  }

  onBlur(evt, { highlightedSuggestion }) {
    const tag =
      highlightedSuggestion !== null
        ? this.generateTagObject(
            this.state.highlightedSuggestion[this.props.labelKey]
          )
        : this.generateTagObject(this.state.value);
    this.addTag(evt, tag);
  }

  render() {
    return (
      <Autosuggest
        highlightFirstSuggestion={
          this.props.highlightFirstSuggestion && this.state.value.length > 0
        }
        renderInputComponent={this.renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        getSuggestionValue={suggestion => suggestion.label}
        onSuggestionSelected={(evt, { suggestion }) =>
          this.addTag(evt, this.generateTagObject(suggestion.label))
        }
        onSuggestionHighlighted={({ suggestion }) => {
          if (suggestion !== this.state.highlightedSuggestion) {
            this.setState({ highlightedSuggestion: suggestion });
          }
        }}
        renderSuggestion={this.renderSuggestion}
        inputProps={Object.assign({}, this.props.inputProps, {
          value: this.state.value,
          onChange: this.onChange,
          onBlur: this.props.onBlur,
          onFocus: this.props.onFocus
        })}
        theme={autosuggestTheme}
        shouldRenderSuggestions={() => true}
      />
    );
  }
}

AutosuggestTagListInput.defaultProps = {
  maxSuggestions: 50,
  suggestMinChars: 0,
  labelKey: 'label',
  highlightFirstSuggestion: false,
  requireSuggestionMatch: false
};

AutosuggestTagListInput.propTypes = {
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  highlightFirstSuggestion: PropTypes.bool,
  requireSuggestionMatch: PropTypes.bool,
  inputProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  /** Callback fired whenever an invalid tag is attempted */
  onError: PropTypes.func,
  value: PropTypes.array.isRequired,
  maxSuggestions: PropTypes.number,
  suggestMinChars: PropTypes.number,
  labelKey: PropTypes.string,
  placeholder: PropTypes.node,
  theme: PropTypes.string
};

export default provideTheme(css)(AutosuggestTagListInput);
