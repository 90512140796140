import React, { FC } from 'react';

import TextInput from '../../ControlledComponents/TextInput';

interface LinkCtaProps {
  ctaTemplate: any;
  onUpdateField: (property: string, val: any) => void;
}

const LinkCta: FC<LinkCtaProps> = ({ ctaTemplate, onUpdateField }) => {
  const onTemplateFieldsUpdate = (value, property) => {
    onUpdateField(`template.${property}`, value);
  };

  return (
    <>
      <div className="mb-5">
        <div className="label required mb-2">CTA</div>
        <TextInput
          property="clickLabel"
          value={ctaTemplate?.clickLabel || ''}
          onChange={onTemplateFieldsUpdate}
        />
      </div>
      <div>
        <div className="label required mb-2">Description</div>
        <TextInput
          property="description"
          value={ctaTemplate?.description || ''}
          onChange={onTemplateFieldsUpdate}
        />
      </div>
      <div className="mb-5">
        <div className="label required mb-2">Image URL</div>
        <TextInput
          property="imageUrl"
          value={ctaTemplate?.imageUrl || ''}
          onChange={onTemplateFieldsUpdate}
        />
      </div>
      <div>
        <div className="label required mb-2">CTA URL</div>
        <TextInput
          property="clickUrl"
          value={ctaTemplate?.clickUrl || ''}
          onChange={onTemplateFieldsUpdate}
        />
      </div>
    </>
  );
};

export default LinkCta;
