import React, { FC, useState, KeyboardEvent, useCallback, useEffect, ChangeEventHandler } from 'react';
import { debounce } from 'lodash';
import { Icon } from '@brightcove/studio-components';
import './InputSearch.scss';

interface InputSearchProps {
  value?: string;
  placeholder: string;
  onSearch: (val: string) => void;
}

const InputSearch: FC<InputSearchProps> = ({ placeholder, onSearch, value = '' }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const minCharCount = 1;
  const debouncedTime = 600;

  useEffect(() => setCurrentValue(value), [value]);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCurrentValue(e.target.value);
    debouncedOnSearch(e.target.value);
  };

  const search = (text = '') => {
    onSearch?.(text);
  };

  const debouncedOnSearch = useCallback(debounce(search, debouncedTime), []);

  const onClickSearch = () => {
    if (currentValue.length >= minCharCount) {
      search(currentValue);
    } else {
      search('');
    }
  };

  const handleKeyUp = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      search(currentValue);
    }
  };

  return (
    <div className="Input-search">
      <input
        type="text"
        className="search-input"
        placeholder={placeholder}
        value={currentValue}
        onChange={handleInputChange}
        onKeyUp={handleKeyUp}
      />
      <span onClick={onClickSearch}>
        <Icon name="SEARCH" className="Input-search-icon" />
      </span>
    </div>
  );
};

export default InputSearch;
