import { v4 as uuidv4 } from 'uuid';
import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ToolTip } from '@brightcove/studio-components';

import Table from '../Table/Table';
import Panel from '../Panel/Panel';
import Button from '../Button/Button';
import { getUrlWithOptions } from '../../utils';
import { useApi } from '../../hooks/useApi';

import GenericModal from './GenericModal';
import './InteractionSummaryModal.scss';

interface InteractionSummaryModalProps {
  show: boolean;
  onClose?: () => void;
  className?: string;
  eventId: string;
  ctaInfo: any;
}

const tooltipContent = {
  ctaDismissedTooltip: 'Users who manually dismissed the CTA without further interaction',
  ctaInteractionsTooltip: 'Users who interacted with the CTA in any way',
  ctaClicksTooltip: 'Users who clicked the CTA link',
};

const InteractionSummaryModal: FC<InteractionSummaryModalProps> = ({
  show,
  onClose,
  className,
  eventId,
  ctaInfo: {
    id: ctaId,
    template: { name, clickLabel, type },
  },
}) => {
  const [summaryData, setSummaryData] = useState<any>({});
  const ctaType = type?.toLowerCase();
  const { apiGet } = useApi();

  const summaryColumns = [
    {
      Header: ctaType === 'poll' ? 'Poll options' : '',
      accessor: 'category',
      Cell: ({ value }) => (
        <>
          {value}
          <ToolTip
            className="tooltip light ml-2"
            tipClassName="tooltip-box"
            placement="top"
            dark={false}
            content={<>{tooltipContent.ctaDismissedTooltip}</>}
          />
        </>
      ),
      className: `category ${ctaType}`,
    },
    {
      Header: () => (
        <>
          Total interactions
          <ToolTip
            className="tooltip dark ml-1"
            tipClassName="tooltip-box"
            placement="top"
            dark={false}
            content={<>{tooltipContent.ctaInteractionsTooltip}</>}
          />
        </>
      ),
      accessor: 'totalInteractions',
    },
    {
      Header: () => (
        <>
          # Total {ctaType === 'survey' || ctaType === 'quiz' ? 'responses' : 'clicks'}
          <ToolTip
            className="tooltip dark ml-1"
            tipClassName="tooltip-box"
            placement="top"
            dark={false}
            content={<>{tooltipContent.ctaClicksTooltip}</>}
          />
        </>
      ),
      accessor: 'totalClicks',
    },
  ];

  useEffect(() => {
    if (!show) {
      setSummaryData({});

      return;
    }

    (async () => {
      const { data } = await apiGet(getUrlWithOptions(`/events/${eventId}/ctas/${ctaId}/analytics`));

      data && setSummaryData(data);
    })();
  }, [show]);

  const getInteractionPercentage = (interactionCount, totalInteractions) => {
    if (interactionCount && totalInteractions) {
      return `${Math.round((interactionCount / totalInteractions) * 100)}%`;
    }

    return '-';
  };

  const getSummaryData = () => {
    const data: any = [];
    const { totalInteractions, dismissals = 0, clicks = 0, totalResponses, answers } = summaryData;

    switch (ctaType) {
      case 'link':
        data.push({
          id: uuidv4(),
          category: clickLabel,
          totalInteractions: getInteractionPercentage(clicks, totalInteractions),
          totalClicks: clicks || '-',
        });
        break;
      case 'poll': {
        if (answers) {
          Object.entries(answers).forEach(([answer, count]) => {
            data.push({
              id: uuidv4(),
              category: answer,
              totalInteractions: getInteractionPercentage(count, totalInteractions),
              totalClicks: count || '-',
            });
          });
        }
        break;
      }
      case 'survey':
      case 'quiz':
        data.push({
          id: uuidv4(),
          category: ctaType,
          totalInteractions: getInteractionPercentage(totalResponses, totalInteractions),
          totalClicks: totalResponses || '-',
        });
    }

    data.push({
      id: uuidv4(),
      category: '# dismissed',
      totalInteractions: getInteractionPercentage(dismissals, totalInteractions),
      totalClicks: dismissals || '-',
    });

    return data;
  };

  const closeHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <GenericModal show={show} className={classNames('Interaction-summary-modal', className)}>
      <Panel title="Interaction Summary">
        <div className="px-4">
          <div className="title mb-6">{name}</div>
          <Table data={getSummaryData()} columns={summaryColumns} />
          <Button className="action mt-4 mb-2" variant="danger" text="Back to Event" onClick={closeHandler} />
        </div>
      </Panel>
    </GenericModal>
  );
};

export default InteractionSummaryModal;
