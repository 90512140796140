import React, { useEffect, useState } from 'react';
import _, { flowRight as compose } from 'lodash';
import { Flex, ICONS, Icon } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { DEFAULT_LANGUAGE, getLocaleByLanguage, getNativeLanguage, getUrlWithOptions } from '../../utils';
import { useApi } from '../../hooks/useApi';
import AddBodyClasses from '../../helpers/AddBodyClasses';
import config from '../../config';
import { withRouter } from '../../components/withRouter/withRouter';
import TableOfContents from '../../components/TableOfContents/TableOfContents';
import SubHeader from '../../components/SubHeader/SubHeader';
import ReactPlayer from '../../components/ReactPlayer/ReactPlayer';
import PublicationStateButtons from '../../components/PublicationStateButtons/PublicationStateButtons';
import Panel from '../../components/Panel/Panel';
import Images from '../../components/Panel/Images';
import Availability from '../../components/Panel/Availability';
import { DeleteConfirmationModal, withModal } from '../../components/Modals';
import Loading from '../../components/Loading/Loading';
import LanguageDropdown from '../../components/LanguageDropdown/LanguageDropdown';
import Button from '../../components/Button/Button';

import VideoPanel from './Panels/VideoPanel';
import LocaleInformationPanel from './Panels/LocaleInformationPanel';
import InteractivityPanel from './Panels/InteractivityPanel';
import EventPanel from './Panels/EventPanel';
import './EventsDetail.scss';

const DeleteButton = withModal(Button, DeleteConfirmationModal);
export const EVENTS_PATH = '/events';
export const CTAS_PATH = '/ctas';

const EventsDetail = ({ navigate, params: { id: eventId } }) => {
  const { apiGet, apiDelete, apiPatch, apiPut } = useApi();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState<any>({});
  const [ctas, setCtas] = useState<any[]>([]);
  const [disableEditing, setDisableEditing] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);
  const [locales, setLocales] = useState<any[]>([]);
  const [currentLocale, setCurrentLocale] = useState<any>({});
  const [eventManagement, setEventManagement] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [reloadPlayer, setReloadPlayer] = useState(false);
  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const eventUrl = getUrlWithOptions(`${EVENTS_PATH}/${eventId}`);

  useEffect(() => {
    (async () => {
      const [eventResponse, ctaResponse] = await Promise.all([fetchEvent(), fetchCtas()]);

      if (eventResponse.data) {
        const data = eventResponse.data;
        const localesData = data?.locales;
        const language = isInitializing
          ? getNativeLanguage(localesData) || DEFAULT_LANGUAGE
          : currentLanguage;
        const currentLocaleInfo = getLocaleByLanguage(localesData, language);

        setEvent(eventResponse.data);
        setLocales(localesData);
        setCurrentLocale(currentLocaleInfo);

        if (isInitializing) {
          setCurrentLanguage(language);
          setIsInitializing(false);
        }
      }

      ctaResponse.data && setCtas(ctaResponse.data);
      setLoading(false);
    })();
  }, [refetch]);

  const fetchEvent = () => {
    return apiGet(getUrlWithOptions(`${EVENTS_PATH}/${eventId}`));
  };

  const fetchCtas = () => {
    return apiGet(getUrlWithOptions(`${EVENTS_PATH}/${eventId}${CTAS_PATH}`));
  };

  const toggleDisableEditing = () => setDisableEditing(!disableEditing);

  const onDeleteEvent = async () => {
    const { error } = await apiDelete(getUrlWithOptions(`${EVENTS_PATH}?ids=${encodeURIComponent(eventId)}`));

    if (!error) {
      goBack();
    }
  };

  const onUpdateEvent = async (updatedEvent): Promise<any> => {
    const patchResult = await apiPatch(eventUrl, { body: updatedEvent });

    if (patchResult.error) {
      return { error: patchResult.error };
    } else {
      const { data, error } = await fetchEvent();

      if (error) return { error };

      if (data) {
        setEvent(data);
        setReloadPlayer((prevReloadPlayer) => !prevReloadPlayer);
      }
    }

    toggleDisableEditing();

    return {};
  };

  const onUpdateLocale = async (updatedLocale): Promise<any> => {
    const { error } = await apiPut(`${eventUrl}/locales/${currentLanguage}`, {
      body: _.omit(updatedLocale, ['language', 'images']),
    });

    if (error) {
      return { error };
    }

    onEditModeChange(false, true);

    const publication = await getEventPublishingStatus();

    if (publication) {
      setEvent({ ...event, ...publication });
    }

    toggleDisableEditing();

    return {};
  };

  const updateLocales = (updatedLocale, updatedLocales) => {
    const index = _.findIndex(updatedLocales, (locale: any) => locale.language === updatedLocale.language);

    if (index > -1) {
      updatedLocales[index] = updatedLocale;
    } else {
      updatedLocales.push(updatedLocale);
    }

    setCurrentLocale(updatedLocale);
    setLocales(updatedLocales);
  };

  const onNativeLanguageChange = (updatedLocale) => {
    const updatedLocales = locales.map((locale) => {
      if (locale.language === updatedLocale.language) {
        return { ...locale, isNativeLanguage: true };
      } else {
        return { ...locale, isNativeLanguage: false };
      }
    });

    updateLocales(updatedLocale, updatedLocales);
  };

  const onLanguageChange = (value) => {
    const localeInfo = getLocaleByLanguage(locales, value);

    setCurrentLanguage(value);
    setCurrentLocale(localeInfo);
  };

  const getEventPublishingStatus = async () => {
    const { data: publication } = await apiGet(`${eventUrl}/status`);

    return publication;
  };

  const updatePublicationStatus = async (updateType) => {
    const { data } = await apiPut(`${eventUrl}/${updateType}`);

    if (data) {
      setEvent({ ...event, ...data });

      if (updateType === 'revert') {
        setRefetch((prevRefetch) => !prevRefetch);
      }
    }
  };

  const publishChanges = async (publishDate) => {
    const { data } = await apiPut(`${eventUrl}/publish`, {
      body: {
        scheduledPublishAt: publishDate?.length > 0 ? new Date(publishDate) : undefined,
      },
    });

    if (data) {
      setEvent({ ...event, ...data });
    }
  };

  const onEditModeChange = async (editing, isSavedData) => {
    setDisableEditing(editing);

    if (isSavedData) {
      setRefetch((prevRefetch) => !prevRefetch);
    }
  };

  const onInteractivityPanelSave = async () => {
    const publication = await getEventPublishingStatus();

    if (publication) {
      setEvent({ ...event, ...publication });
    }
  };

  const goBack = () => navigate(`../${ROUTES.EVENTS}`);

  const defaultPanelProps = {
    disableEditing,
    toggleDisableEditing,
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      !_.isEmpty(event) && (
        <>
          <AddBodyClasses classes={['disabled-overflow', 'Events-detail-page']} />
          <div className="Events-detail">
            <SubHeader
              title={event.name}
              subtitle="Back to All Events"
              className="pl-5 pr-3"
              actions={
                <>
                  {!eventManagement && (
                    <>
                      <PublicationStateButtons
                        disabled={disableEditing}
                        publication={event?.publication}
                        allowRevert={true}
                        allowPreview
                        id={eventId}
                        onRevert={() => updatePublicationStatus('revert')}
                        onPublish={(val) => publishChanges(val)}
                        onUnschedule={() => updatePublicationStatus('unschedule')}
                      />
                      {!event?.publication?.unpublishedChanges && (
                        <Button
                          variant="secondary-invert"
                          text="Manage Event"
                          onClick={() => setEventManagement(true)}
                        />
                      )}
                      <DeleteButton
                        className="ml-1"
                        disabled={disableEditing}
                        variant="secondary"
                        modalProps={{
                          title: 'Delete Event',
                          children: (
                            <>
                              You will be deleting {event.name} from the system. This action cannot be undone.
                            </>
                          ),
                        }}
                        onModalConfirm={onDeleteEvent}
                      >
                        Delete
                      </DeleteButton>
                    </>
                  )}
                  {eventManagement && (
                    <Button
                      variant="secondary-invert"
                      text="Edit Event"
                      onClick={() => setEventManagement(false)}
                    />
                  )}
                </>
              }
              detailMode
              onBack={goBack}
              icon={<Icon name={ICONS.ARROW_RIGHT} flip="horizontal" />}
            />
            {(event.videos?.length > 0 || event.finalVideo) && (
              <div className="event-player-wrapper">
                <ReactPlayer
                  accountId={config.account_id || ''}
                  playerId={event.brightcovePlayerId}
                  eventId={event.id}
                  reloadPlayer={reloadPlayer}
                  startDate={event.startDate}
                />
              </div>
            )}
            <Flex>
              <div className="page-content">
                {!eventManagement && (
                  <>
                    <EventPanel id="event" event={event} onSave={onUpdateEvent} {...defaultPanelProps} />
                    <div id="page">
                      <Panel
                        className="empty-panel"
                        title="Page"
                        actions={
                          <Button
                            text="Edit"
                            variant="primary"
                            disabled={disableEditing}
                            onClick={() => {
                              navigate(`../${ROUTES.PAGES}/edit/${event.pageId}`);
                            }}
                          />
                        }
                      />
                    </div>
                    <LocaleInformationPanel
                      id="locale"
                      locale={currentLocale}
                      onSave={onUpdateLocale}
                      {...defaultPanelProps}
                    />
                    <VideoPanel
                      id="videos"
                      title="Videos"
                      addButtonText="+ Add Event Video"
                      videos={event.videos || []}
                      availability={_.pick(event, ['startDate', 'endDate'])}
                      onSave={(updatedVideos) => {
                        return onUpdateEvent({
                          videos: updatedVideos.map((video) => _.pick(video, ['id', 'startDate', 'endDate'])),
                        });
                      }}
                      onEnableEditing={() => {
                        toggleDisableEditing();
                      }}
                      modalProps={{
                        title: 'Add Videos',
                        addButtonText: 'Add Videos',
                        includeFilters: ['ItemSubType:MovieContent', 'ItemSubType:AdContent'],
                      }}
                      {...defaultPanelProps}
                    />
                    <VideoPanel
                      id="trailer"
                      title="Trailer"
                      addButtonText="+ Set Video"
                      videos={[event.trailer]}
                      availability={_.pick(event, ['startDate', 'endDate'])}
                      onSave={(updatedVideos) => {
                        const selectedVideo = updatedVideos[0];

                        return selectedVideo
                          ? onUpdateEvent({
                              trailer: selectedVideo.id,
                            })
                          : onUpdateEvent({
                              trailer: null,
                            });
                      }}
                      onEnableEditing={() => {
                        toggleDisableEditing();
                      }}
                      modalProps={{
                        title: 'Add Trailer',
                        addButtonText: 'Add Trailer',
                        includeFilters: ['ItemSubType:MovieContent', 'ItemSubType:TrailerContent'],
                      }}
                      singleVideo={true}
                      {...defaultPanelProps}
                    />
                    <VideoPanel
                      id="final-video"
                      title="Final Video"
                      addButtonText="+ Set Video"
                      videos={[event.finalVideo]}
                      availability={_.pick(event, ['startDate', 'endDate'])}
                      onSave={(updatedVideos) => {
                        const selectedVideo = updatedVideos[0];

                        return selectedVideo
                          ? onUpdateEvent({
                              finalVideo: selectedVideo.id,
                            })
                          : onUpdateEvent({
                              finalVideo: null,
                            });
                      }}
                      onEnableEditing={() => {
                        toggleDisableEditing();
                      }}
                      modalProps={{
                        title: 'Add Final Video',
                        addButtonText: 'Add Final Video',
                        includeFilters: ['ItemSubType:MovieContent'],
                      }}
                      singleVideo={true}
                      {...defaultPanelProps}
                    />
                    <Availability
                      idPanel="availability"
                      data={_.pick(event, ['startDate', 'endDate', 'locations', 'accessControls'])}
                      endpointRootPath={EVENTS_PATH}
                      onEditModeChange={onEditModeChange}
                      disableEditing={disableEditing}
                    />
                    <Images
                      idPanel="images"
                      data={{
                        id: eventId,
                        language: currentLanguage,
                        images: currentLocale?.images || [],
                      }}
                      endpointRootPath={EVENTS_PATH}
                      onEditModeChange={onEditModeChange}
                      disableEditing={disableEditing}
                    />
                  </>
                )}
                <InteractivityPanel
                  id="interactivity"
                  ctasData={ctas}
                  eventId={eventId}
                  disabled={disableEditing}
                  onSave={onInteractivityPanelSave}
                />
              </div>
              {!eventManagement && (
                <div className="side-menu">
                  <LanguageDropdown
                    title="Language"
                    currentLanguage={currentLanguage}
                    nativeLanguage={getNativeLanguage(locales)}
                    endpointRootPath={EVENTS_PATH}
                    onNativeLanguageChange={onNativeLanguageChange}
                    onLanguageChange={onLanguageChange}
                  />
                  <TableOfContents
                    data={[
                      { id: 'event', title: 'Event', offset: -400 },
                      { id: 'page', title: 'Page', offset: -300 },
                      { id: 'locale', title: 'Locale Information', offset: -300 },
                      { id: 'videos', title: 'Videos', offset: -300 },
                      { id: 'trailer', title: 'Trailer', offset: -250 },
                      { id: 'final-video', title: 'Final Video', offset: -250 },
                      { id: 'availability', title: 'Availability', offset: -280 },
                      { id: 'images', title: 'Images', offset: -250 },
                      { id: 'interactivity', title: 'User Interactivity', offset: -250 },
                    ]}
                    hasBorder
                  />
                </div>
              )}
            </Flex>
          </div>
        </>
      )
    );
  }
};

export default compose(withRouter)(EventsDetail);
