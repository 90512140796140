const config = {
  version: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENVIRONMENT,
  aws_region: process.env.REACT_APP_AWS_REGION,
  account_id: process.env.REACT_APP_BC_ACCOUNT_ID,
  player_id: process.env.REACT_APP_BC_PLAYER_ID,
  api_url: process.env.REACT_APP_API_URL,
  api_token: process.env.REACT_APP_API_TOKEN,
};

export default config;
