import { useNavigate } from 'react-router';
import React, { FC, useState, useEffect, useCallback } from 'react';
import { Flex } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { formatDate } from '../../utils/format';
import { DEFAULT_PAGE } from '../../utils/constants';
import { getUrlWithOptions, getPageCount, pageFilters } from '../../utils';
import { useApi } from '../../hooks/useApi';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import SideNavigationItem from '../../components/SideNavigation/SideNavigationItem';
import SideNavigation from '../../components/SideNavigation/SideNavigation';
import { DeleteConfirmationModal, withModal } from '../../components/Modals';
import InputSearch from '../../components/ControlledComponents/InputSearch';
import Button from '../../components/Button/Button';
import { SVGImage } from '../../assets/images';
import './Pages.scss';

interface PagesProps {
  embedded?: boolean;
  pageChangeModal?: boolean;
  selectedFilter?: string;
  onRowSelect?: (param: any[]) => void;
}

const Pages: FC<PagesProps> = ({ embedded, pageChangeModal, selectedFilter, onRowSelect }) => {
  const { apiGet, apiPost, apiDelete, refetchGet } = useApi();
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [filter, setFilter] = useState<string>(selectedFilter || '');
  const [currentSideMenuFilter, setCurrentSideMenuFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sort, setSort] = useState<string>('updatedAt:desc');
  const [pagesData, setPagesData] = useState<any>();
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_PAGE);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const navigate = useNavigate();
  const pagesUrl = getUrlWithOptions('/pages');
  const DeleteButton = withModal(Button, DeleteConfirmationModal);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setPagesData([]);
      const { data } = await apiGet(
        getUrlWithOptions(
          '/pages',
          searchQuery,
          sort,
          filter ? getFilters(filter) : undefined,
          currentPageIndex,
          currentPageSize
        )
      );

      setPagesData(data);
      setTotalCount(data?.totalCount);
      setLoading(false);
    })();
  }, [searchQuery, sort, filter, currentPageIndex, currentPageSize]);

  const getFilters = (filters) => {
    return filters
      .split(',')
      .map((type) => (type ? `type:${type}` : ''))
      .join(',');
  };

  const sectionTitle = (id: string) => {
    const selected = pageFilters.find((item) => item.id === id)?.name;

    return selected?.length ? selected : 'All Pages';
  };

  const onChangeFilter = (id: string) => {
    const filter = !id && embedded && selectedFilter ? selectedFilter : id;

    setSearchQuery('');
    setCurrentPageIndex(DEFAULT_PAGE);
    setFilter(filter);
    setCurrentSideMenuFilter(id);
  };

  const onChangeSelection = (items: any[]) => {
    setSelectedItems(items);
    onRowSelect?.(items);
  };

  const onDeletePages = async () => {
    const pagesToDelete = selectedItems.map((item) => item.id).toString();
    const { error } = await apiDelete(`${pagesUrl}?ids=${encodeURIComponent(pagesToDelete)}`);

    if (!error) {
      const { data } = await refetchGet();

      setPagesData(data);
      setTotalCount(data.totalCount);
    }
  };

  const onDuplicatePages = async () => {
    const pageToDuplicate = selectedItems?.[0];

    if (!pageToDuplicate) {
      return;
    }

    setIsDuplicating(true);

    const { error } = await apiPost(`${pagesUrl}/${pageToDuplicate.id}/duplicate`);

    if (!error) {
      const { data } = await refetchGet();

      setPagesData(data);
      setTotalCount(data.totalCount);
    }

    setIsDuplicating(false);
  };

  const onClickSort = (field: string, order: string) => {
    if (field && order) {
      setSort(`${field}:${order}`);
    }
  };

  const getPageTypeLabel = (type) => {
    const pageFilter = pageFilters.filter((pageFilter) => pageFilter.id.toLowerCase() === type.toLowerCase());
    const pageFilterLabel = pageFilter.length > 0 ? pageFilter[0]?.name : '';

    return pageFilterLabel.substring(0, pageFilterLabel.length - 1);
  };

  const onPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
  }, []);

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      sortable: true,
      Cell: ({ value, row }) => (
        <TitleDescription
          title={value}
          description={row.original.id}
          id={row.original.id}
          onClick={(id) => navigate(`../${ROUTES.PAGES}/edit/${id}`)}
        />
      ),
    },
    {
      Header: 'Type',
      accessor: 'type',
      className: 'type',
      Cell: ({ value }) => getPageTypeLabel(value),
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      sortable: true,
      Cell: ({ value, row }) => (
        <>
          <p>{formatDate(value)}</p>
          <p className="subtitle">{row.original.createdBy}</p>
        </>
      ),
      className: 'created-date',
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      sortable: true,
      Cell: ({ value, row }) => (
        <>
          <p>{formatDate(value)}</p>
          <p className="subtitle">{row.original.updatedBy}</p>
        </>
      ),
      className: 'updated-date',
    },
  ];

  const getSideNavFilters = () => {
    const filters = pageFilters.filter((filter) =>
      !embedded ? !filter.internal : !filter.internal && selectedFilter?.includes(filter.id)
    );

    return filters;
  };

  return (
    <Flex className="Pages">
      {!pageChangeModal && (
        <SideNavigation
          onChange={onChangeFilter}
          item={SideNavigationItem}
          filters={getSideNavFilters()}
          defaultValue={currentSideMenuFilter}
        />
      )}
      <Flex className="Pages-content" flexDirection="column">
        <SubHeader
          icon={<img src={SVGImage.PagesHeaderIcon} alt="Pages Header Icon" />}
          title={sectionTitle(currentSideMenuFilter)}
          subtitle={'NetApp TV'}
          quantitySubtitle={`${pagesData?.totalCount || 0} Item${pagesData?.totalCount === 1 ? '' : 's'}`}
          actions={
            filter !== 'EventLandingPage' ? (
              <InputSearch placeholder="Search by name or ID" onSearch={(val) => setSearchQuery(val)} />
            ) : (
              <></>
            )
          }
        />

        {!embedded && filter !== 'EventLandingPage' && (
          <Flex className="mt-4 mb-4 mx-10" alignItems="center">
            <DeleteButton
              className="mr-1"
              variant="danger"
              disabled={selectedItems.length === 0}
              modalProps={{
                title: `Delete Page${selectedItems.length > 1 ? 's' : ''}`,
                children: (
                  <>
                    You will be deleting {selectedItems.length} page
                    {selectedItems.length > 1 && 's'} from the system. This action cannot be undone.
                  </>
                ),
              }}
              onModalConfirm={onDeletePages}
            >
              Delete
            </DeleteButton>
            <Button
              variant="secondary"
              text={isDuplicating ? 'Duplicating' : 'Duplicate'}
              disabled={selectedItems.length !== 1 || isDuplicating}
              onClick={onDuplicatePages}
            />
            <Button
              className="create-page-button"
              variant="primary"
              text="Create Page"
              onClick={() => navigate(`../${ROUTES.PAGES}/create`)}
            />
          </Flex>
        )}
        <Table
          className="Pages-content-table"
          data={pagesData?.items || []}
          columns={columns}
          onChangeSelection={onChangeSelection}
          onClickSort={onClickSort}
          hasSelection
          hasPagination
          defaultSortingValues={[{ id: 'updatedAt', desc: true }]}
          pageCount={getPageCount(currentTotalCount, currentPageSize)}
          onPaginationChange={onPaginationChange}
          pageIndex={currentPageIndex}
          pageSize={currentPageSize}
          singleSelection={pageChangeModal}
          width={1000}
          loading={loading}
        />
      </Flex>
    </Flex>
  );
};

export default Pages;
