import React, { FC } from 'react';
import { Flex } from '@brightcove/studio-components';

import { ContentData } from '../../../interfaces/Content';
import Panel from '../../../components/Panel/Panel';

interface ContentProps {
  idPanel: string;
  data: ContentData;
  isSerie?: boolean;
}

const Content: FC<ContentProps> = ({ idPanel, data, isSerie = false }) => {
  const { id, publicUrl } = data;

  const url = isSerie ? `${publicUrl}series-detail/${id}` : (publicUrl as string);

  return (
    <div id={idPanel} className="Content">
      <Panel title="Content" actions={<></>}>
        <>
          <Flex>
            <div>
              <div className="ml-4">
                <p className="title">ID</p>
                <p className="description">{id}</p>
              </div>
              <div className="ml-4 mt-6">
                <p className="title">Public URL</p>
                {publicUrl?.length ? (
                  <p className="link">
                    <a href={url} target="_blank" rel="noreferrer">
                      {url}
                    </a>
                  </p>
                ) : (
                  '-'
                )}
              </div>
            </div>
          </Flex>
        </>
      </Panel>
    </div>
  );
};

export default Content;
