import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import TextArea from '../ControlledComponents/TextArea';
import Button from '../Button/Button';

import EditPanel from './EditPanel';
import './TemplatePanel.scss';

const TemplatePanel = ({
  propertyName = '',
  title,
  data,
  disableEditing,
  onEnableEditing,
  onCancel,
  onSave,
  id: panelId = '',
}) => {
  const [templateString, setTemplateString] = useState(data || '');
  const [templateMode, setTemplateMode] = useState(data && data.length > 0 ? 'custom' : 'default');

  useEffect(() => {
    setTemplateString(data || '');
    setTemplateMode(data && data.length > 0 ? 'custom' : 'default');
  }, [data]);

  const toggleTemplateMode = () => {
    const mode = templateMode === 'custom' ? 'default' : 'custom';

    setTemplateMode(mode);

    if (mode === 'default') {
      setTemplateString('');
    }
  };

  const cancelChanges = () => {
    setTemplateMode(data && data.length > 0 ? 'custom' : 'default');
    setTemplateString(data);
    onCancel();
  };

  return (
    <EditPanel
      id={panelId}
      title={<p>{title}</p>}
      editActions={
        <Button
          variant="secondary-invert"
          text={`Use ${templateMode === 'custom' ? 'default' : 'custom'}`}
          onClick={toggleTemplateMode}
        />
      }
      disableEditing={disableEditing}
      onEnableEditing={onEnableEditing}
      onCancel={cancelChanges}
      save={() => {
        return onSave(propertyName, templateString);
      }}
    >
      {(isEditing) => (
        <div className={classNames('Template', templateMode, { editing: isEditing })}>
          <TextArea
            className="Template-text-area"
            value={templateString}
            placeholder={!isEditing || templateMode === 'default' ? `Using ${templateMode} template` : ''}
            readOnly={!isEditing || templateMode === 'default'}
            autoFocus={isEditing && templateMode === 'custom'}
            disableResizing={true}
            onChange={(value) => setTemplateString(value)}
          ></TextArea>
        </div>
      )}
    </EditPanel>
  );
};

export default TemplatePanel;
