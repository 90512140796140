import { useNavigate, useParams } from 'react-router';
import React, { FC, useEffect, useState } from 'react';
import { Flex, Icon, ICONS, Thumbnail } from '@brightcove/studio-components';

import * as ROUTES from '../../routes';
import { formatDate } from '../../../utils/format';
import { DEFAULT_PAGE } from '../../../utils/constants';
import { getUrlWithOptions } from '../../../utils';
import COLORS from '../../../styles/_colors.export.scss';
import { useApi } from '../../../hooks/useApi';
import TitleDescription from '../../../components/TitleDescription/TitleDescription';
import Table from '../../../components/Table/Table';
import Panel from '../../../components/Panel/Panel';
import GenericModal from '../../../components/Modals/GenericModal';
import InputSearch from '../../../components/ControlledComponents/InputSearch';
import Button from '../../../components/Button/Button';
import './Episodes.scss';
import './CollectionsContained.scss';

interface EpisodesProps {
  idPanel: string;
  data: any[];
  disabledEditing?: boolean;
  onEditModeChange?: (val: boolean, isSavedData?: boolean) => void;
}
const orderData = (data) => {
  const withoutNull = data.map((item) => {
    return {
      ...item,
      number: item.number ? item.number : data.length,
    };
  });

  return withoutNull.sort((a, b) => a.number - b.number);
};

const Episodes: FC<EpisodesProps> = ({ idPanel, data, disabledEditing, onEditModeChange }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { apiGet, apiPut, isLoading } = useApi();
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentData, setCurrentData] = useState(orderData(data));
  const [defaultData] = useState(data);
  const [editing, setEditing] = useState<boolean>(false);
  const [contentSort, setContentSort] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_PAGE);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [selectedEpisodes, setSelectedEpisodes] = useState([]);
  const [allEpisodes, setAllEpisodes] = useState<any>([]);

  const columns = [
    {
      Header: '',
      accessor: 'image',
      className: 'image',
      Cell: ({ value }) => <Thumbnail className="Content-management-thumbnail" src={value} />,
    },
    {
      Header: 'Name',
      accessor: (row) => [row.name, row.id], // accessor is the "key" in the data
      className: 'name',
      Cell: ({ value }) => {
        const [name, id] = value;

        return (
          <TitleDescription
            title={name}
            description={id}
            id={id}
            onClick={(itemId) => navigate(`../${ROUTES.CONTENT_MANAGEMENT}/edit/${itemId}`)}
          />
        );
      },
    },
    {
      Header: 'Reference ID',
      accessor: 'extId',
      className: 'extId',
      sortable: true,
    },
    {
      Header: 'Type',
      accessor: 'type',
      className: 'type',
    },
    {
      Header: 'Published',
      accessor: 'published', // accessor is the "key" in the data
      Cell: ({ value }) => {
        return (
          <Flex justifyContent="center">
            {value ? (
              <Icon name={ICONS.CHECK2} color={COLORS.cyan} />
            ) : (
              <Icon name={ICONS.WARNING} color={COLORS.burgundy} />
            )}
          </Flex>
        );
      },
      className: 'Content-management-published',
    },
    {
      Header: 'Created',
      sortable: true,
      className: 'date',
      accessor: (row) => [row.createdAt, row.createdBy], // accessor is the "key" in the data
      Cell: ({ value }) => {
        const [createdAt, email] = value;

        return (
          <TitleDescription
            classNameTitle="Content-management-column-updated-item-title"
            classNameDescription="Content-management-column-updated-item-description"
            title={formatDate(createdAt)}
            description={email}
          />
        );
      },
    },
    {
      Header: 'Updated',
      sortable: true,
      className: 'date',
      accessor: (row) => [row.updatedAt, row.email], // accessor is the "key" in the data
      Cell: ({ value }) => {
        const [updated, email] = value;

        return (
          <TitleDescription
            classNameTitle="Content-management-column-updated-item-title"
            classNameDescription="Content-management-column-updated-item-description"
            title={formatDate(updated)}
            description={email}
          />
        );
      },
    },
  ];

  const addEpisodes = () => {
    let nextNumber = currentData.length + 1;

    const filteredEpisodesWithNumber = selectedEpisodes.reduce((acc: any, episode: any) => {
      if (!currentData.find((existingEpisode) => existingEpisode.id === episode.id)) {
        acc.push({
          ...episode,
          number: nextNumber++,
        });
      }

      return acc;
    }, []);

    setCurrentData([...currentData, ...filteredEpisodesWithNumber]);
    setShowAddModal(false);
  };

  useEffect(() => {
    (async () => {
      const { data } = await apiGet(
        getUrlWithOptions(
          '/content',
          searchQuery,
          contentSort,
          'ItemSubType:MovieContent',
          currentPageIndex,
          currentPageSize
        )
      );

      setAllEpisodes(data?.items?.length ? data?.items : []);
      setTotalCount(data.totalCount);
    })();
  }, [searchQuery, contentSort, '', currentPageIndex, currentPageSize]);

  const onDragRow = (reorderedRows: any[]) => {
    const updatedRows = reorderedRows.map((row, index) => {
      return {
        ...row,
        number: index + 1,
      };
    });

    setCurrentData(orderData(updatedRows));
  };

  const onClickDeleteRow = (id: string) => {
    setCurrentData((prev) => prev.filter((item: any) => item.id !== id));
  };

  const onSearch = (query) => setSearchQuery(query);

  const updateEpisodes = async () => {
    const response = await apiPut(getUrlWithOptions(`/content/${id}/episodes`), {
      body: currentData.map((data) => ({ id: data.id, number: data.number })),
    });

    if (response?.data) {
      setEditing(false);
      onEditModeChange?.(!disabledEditing, true);
      setCurrentData(response.data);
    }
  };

  const resetItems = () => {
    setSearchQuery('');
    setContentSort('');
    setCurrentPageIndex(DEFAULT_PAGE);
  };

  const onClickSort = (field, order) => {
    if (field && order) {
      setContentSort(`${field}:${order}`);
    }
  };

  const onPaginationChange = ({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
  };

  useEffect(() => {
    setCurrentData(data);
  }, [data]);

  return (
    <div className="Episodes w-100">
      {
        <GenericModal show={showAddModal} className="Episodes-confirmation-modal ">
          <>
            <Panel title="Add Episode" actions={<></>} className="Episodes-Panel">
              <>
                <div className="Episodes-table-actions">
                  <InputSearch placeholder="Search by name or ID" onSearch={onSearch} />
                </div>

                <Table
                  className={`Episodes-Table`}
                  data={allEpisodes}
                  columns={columns}
                  hasPagination
                  onPaginationChange={onPaginationChange}
                  pageCount={Math.ceil(currentTotalCount / currentPageSize)}
                  pageIndex={currentPageIndex}
                  pageSize={currentPageSize}
                  onClickSort={onClickSort}
                  hasSelection
                  onChangeSelection={setSelectedEpisodes}
                />

                <Flex justifyContent="center" className="Episodes-table-actions">
                  <Button
                    variant="secondary"
                    text="Cancel"
                    onClick={() => {
                      setShowAddModal(false);
                    }}
                  />
                  <Button variant="primary" text="Add Episode" onClick={addEpisodes} />
                </Flex>
              </>
            </Panel>
          </>
        </GenericModal>
      }
      <div id={idPanel}>
        <Panel
          title="Episodes"
          actions={
            <>
              {editing ? (
                <>
                  <Button
                    variant="secondary"
                    text="Cancel"
                    onClick={() => {
                      setEditing(false);
                      onEditModeChange?.(!disabledEditing);
                      setCurrentData(defaultData);
                    }}
                  />
                  <Button variant="primary" text="Save" onClick={updateEpisodes} disabled={isLoading} />
                </>
              ) : (
                <Button
                  variant="primary"
                  text="Edit"
                  onClick={() => {
                    setEditing(!editing);
                    onEditModeChange?.(!disabledEditing);
                  }}
                  disabled={disabledEditing}
                />
              )}
            </>
          }
        >
          <>
            {currentData?.length ? (
              <Table
                data={currentData.length ? currentData : []}
                columns={columns}
                className="Episodes-Panel-Table mt-4"
                onClickDeleteRow={onClickDeleteRow}
                onDragRow={onDragRow}
                hasDeletion={editing}
                hasDragging={editing}
                width={1000}
              />
            ) : (
              <div className="ml-4">-</div>
            )}
            {editing ? (
              <>
                {
                  <Button
                    variant="primary"
                    text="+ Add Episode"
                    onClick={() => {
                      resetItems();
                      setShowAddModal(true);
                    }}
                    disabled={isLoading}
                    className="mt-4"
                  />
                }
              </>
            ) : null}
          </>
        </Panel>
      </div>
    </div>
  );
};

export default Episodes;
