import { compose } from 'redux';
import React from 'react';
import { Flex } from '@brightcove/studio-components';

import { withStore } from '../../store';
import SubHeader from '../../components/SubHeader/SubHeader';
import './MyProfile.scss';

const MyProfile = ({ store: { user } }) => {
  return (
    <>
      <SubHeader title={'Profile Information'} subtitle={'NetApp TV'} className="mb-4" />
      {user?.id?.length ? (
        <div className="ml-11 mr-11 mt-8">
          <div className="My-profile-bg">
            <Flex>
              <div className="My-profile">
                <p className="My-profile-title">First Name</p>
                <p className="My-profile-content">{user.firstName}</p>
              </div>
              <div className="My-profile ml-5">
                <p className="My-profile-title">Last Name</p>
                <p className="My-profile-content">{user.lastName}</p>
              </div>
              <div className="My-profile ml-6">
                <p className="My-profile-title">Role</p>
                <p className="My-profile-content">{user.role}</p>
              </div>
            </Flex>
            <Flex>
              <div className="My-profile">
                <p className="My-profile-title">Email Address</p>
                <p className="My-profile-content">{user.email}</p>
              </div>
            </Flex>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default compose(withStore)(MyProfile);
