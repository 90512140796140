import { useNavigate } from 'react-router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _, { flowRight as compose } from 'lodash';
import { Flex, Icon, ICONS, Thumbnail } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { formatDate } from '../../utils/format';
import { DEFAULT_PAGE } from '../../utils/constants';
import { getPageCount, getUrlWithOptions, getItemCollectionPath } from '../../utils';
import COLORS from '../../styles/_colors.export.scss';
import useGoBack from '../../hooks/useGoBack';
import { useApi } from '../../hooks/useApi';
import AddBodyClasses from '../../helpers/AddBodyClasses';
import { withRouter } from '../../components/withRouter/withRouter';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import TableOfContents from '../../components/TableOfContents/TableOfContents';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import PublicationStateButtons from '../../components/PublicationStateButtons/PublicationStateButtons';
import EditPanel from '../../components/Panel/EditPanel';
import AddItemModal from '../../components/Modals/AddItemModal';
import { DeleteConfirmationModal, withModal } from '../../components/Modals';
import Loading from '../../components/Loading/Loading';
import Counter from '../../components/Counter/Counter';
import TextInput from '../../components/ControlledComponents/TextInput';
import TagList from '../../components/ControlledComponents/TagList';
import SimpleSelect from '../../components/ControlledComponents/SimpleSelect';
import { RadioGroup } from '../../components/ControlledComponents/RadioGroup';
import Checkbox from '../../components/ControlledComponents/Checkbox';
import Button from '../../components/Button/Button';
import './CollectionsDetail.scss';

const DeleteButton = withModal(Button, DeleteConfirmationModal);
const AddCollectionItemButton = withModal(Button, AddItemModal);
const collectionItemsColumns = [
  {
    Header: 'Start Date',
    accessor: 'startDate',
    Cell: ({ value }) => <>{formatDate(value)}</>,
    className: 'date',
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    Cell: ({ value }) => <>{formatDate(value)}</>,
    className: 'date',
  },
];

const CollectionsDetail = ({ params: { id: collectionId }, withHeader = true }) => {
  const { goBackLabel, goBack } = useGoBack(ROUTES.COLLECTIONS, 'Back to Collections');
  const contentFilters = [
    { name: 'All Content', id: '', iconName: ICONS.PLAY_OUTLINE },
    { name: 'Series', id: 'SeriesContent', iconName: ICONS.FOLDER },
    { name: 'Seasons', id: 'SeasonContent', iconName: ICONS.FOLDER },
    { name: 'Episodes', id: 'EpisodeContent', iconName: ICONS.FOLDER },
    { name: 'Movies', id: 'MovieContent', iconName: ICONS.FOLDER },
    { name: 'Pages', id: 'CustomPage,StandardPage', iconName: ICONS.FOLDER },
    { name: 'Events', id: 'StandardEvent', iconName: ICONS.FOLDER },
  ];
  const sortOptions = [
    { label: 'Name (A-Z)', value: 'AZ' },
    { label: 'Name (Z-A)', value: 'ZA' },
    { label: 'Recent to oldest', value: 'Recent' },
    { label: 'Oldest to recent', value: 'Oldest' },
    { label: 'Most views', value: 'MostViews' },
  ];
  const fieldOptions = [
    { label: 'Series', value: 'SeriesContent' },
    { label: 'Season', value: 'SeasonContent' },
    { label: 'Episode', value: 'EpisodeContent' },
    { label: 'Movie', value: 'MovieContent' },
  ];
  const includeOptions = [
    { label: 'Type', value: 'type' },
    { label: 'Keywords', value: 'keywords' },
    { label: 'Functional Tags', value: 'tags' },
  ];
  const radioOptions = [
    { label: 'Match any', value: 'match_any' },
    { label: 'Match all', value: 'match_all' },
  ];
  const { apiGet, apiPatch, apiDelete, apiPut } = useApi();
  const [collection, setCollection] = useState<any>({});
  const [collectionItemsData, setCollectionItemsData] = useState<any>({});
  const [collectionName, setCollectionName] = useState<string>('');
  const [collectionItems, setCollectionItems] = useState<any[]>([]);
  const [collectionRules, setCollectionRules] = useState<any[]>([]);
  const [disableEditing, setDisableEditing] = useState(false);
  const [contentItemsData, setContentItemsData] = useState<any>({});
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<string>('');
  const [contentSort, setContentSort] = useState<string>('');
  const [collectionSort, setCollectionSort] = useState<string>(sortOptions[0].value);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [maxNumItems, setMaxNumItems] = useState(50);
  const [contentPageSize, setContentPageSize] = useState(50);
  const [contentPageIndex, setContentPageIndex] = useState(DEFAULT_PAGE);
  const [contentTotalCount, setContentTotalCount] = useState(0);
  const [collectionPageSize, setCollectionPageSize] = useState(50);
  const [collectionPageIndex, setCollectionPageIndex] = useState(DEFAULT_PAGE);
  const [collectionTotalCount, setCollectionTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const COLLECTIONS_ENDPOINT = '/collections';
  const collectionUrl = getUrlWithOptions(`${COLLECTIONS_ENDPOINT}/${collectionId}`);
  const navigate = useNavigate();

  const url = useMemo(() => {
    return collection?.publicUrl ? `${collection.publicUrl}collection/${collection.id}` : null;
  }, [collection]);

  const commonColumns = useMemo(
    () => [
      {
        accessor: 'image',
        Cell: ({ value }) => <Thumbnail className="Collections-detail-thumbnail" src={value} />,
        className: 'thumbnail-cell',
      },
      {
        Header: 'Name',
        id: 'name',
        accessor: (row) => ({ name: row.name, id: row.id, type: row.type }),
        Cell: ({ value }) => {
          return (
            <TitleDescription
              className="pb-3"
              title={value.name}
              description={value.id}
              id={value.id}
              onClick={() => navigate(`../${getItemCollectionPath(value.type)}/edit/${value.id}`)}
            />
          );
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        className: 'type',
      },
      {
        Header: 'Published',
        accessor: 'published',
        Cell: ({ value }) => (
          <Icon name={value ? ICONS.CHECK2 : ICONS.WARNING} color={value ? COLORS.cyan : COLORS.burgundy} />
        ),
        className: 'published',
      },
    ],
    []
  );

  const contentItemsColumns = useMemo(
    () =>
      commonColumns
        .map((column) => ({ ...column, sortable: true }))
        .concat([
          {
            Header: 'Created',
            accessor: 'createdAt',
            Cell: (obj: any) => (
              <>
                <div>{formatDate(obj.value)}</div>
                <div className="subtitle">{obj.row.original.updatedBy}</div>
              </>
            ),
            sortable: true,
            className: 'date',
          },
          {
            Header: 'Updated',
            accessor: 'updatedAt',
            Cell: (obj: any) => (
              <>
                <div>{formatDate(obj.value)}</div>
                <div className="subtitle">{obj.row.original.updatedBy}</div>
              </>
            ),
            sortable: true,
            className: 'date',
          },
        ]),
    []
  );

  useEffect(() => {
    (async () => {
      const [collectionResponse, collectionItemsResponse] = await Promise.all([
        fetchCollection(),
        fetchCollectionItems(),
      ]);

      if (collectionResponse.data) {
        const { name, rules } = collectionResponse.data;

        setCollection(collectionResponse.data);
        setCollectionName(name);
        setCollectionRules(formatRules(rules));

        if (collectionResponse.data.maxNumItems) {
          setMaxNumItems(collectionResponse.data.maxNumItems);
        }

        if (collectionResponse.data.sort) {
          setCollectionSort(collectionResponse.data.sort);
        }
      }

      if (collectionItemsResponse.data) {
        const { items, totalCount } = collectionItemsResponse.data;

        setCollectionItemsData(collectionItemsResponse.data);
        setCollectionItems(items);

        if (isDynamicCollection(collectionResponse?.data?.type)) {
          setCollectionTotalCount(totalCount);
        }
      }

      setLoading(false);
    })();
  }, [refetch]);

  useEffect(() => {
    (async () => {
      const { data } = await apiGet(
        getUrlWithOptions(
          '/items',
          searchQuery,
          contentSort,
          selectedFilter,
          contentPageIndex,
          contentPageSize
        )
      );

      setContentItemsData(data);
      setContentTotalCount(data.totalCount);
    })();
  }, [searchQuery, contentSort, selectedFilter, contentPageIndex, contentPageSize]);

  const fetchCollection = async () => {
    return apiGet(collectionUrl);
  };

  const fetchCollectionItems = async () => {
    return apiGet(`${collectionUrl}/items`);
  };

  const updateCollection = async (requestBody): Promise<any> => {
    return apiPatch(collectionUrl, { body: requestBody });
  };

  const getCollectionPublishingStatus = async () => {
    return apiGet(`${collectionUrl}/status`);
  };

  const onUpdateCollectionInformation = async (): Promise<any> => {
    if (collectionName) {
      const { data, error } = await updateCollection({ name: collectionName });

      if (error) {
        return { error };
      }

      if (data) {
        setCollection(data);
      }

      toggleDisableEditing();
    }

    return {};
  };

  const onUpdateCollectionItems = async (): Promise<any> => {
    if (isDynamicCollection()) {
      const { data, error } = await updateCollection({
        rules: collectionRules.map((rule) => {
          delete rule.id;

          return rule;
        }),
        maxNumItems,
        sort: collectionSort,
      });

      if (error) {
        return { error };
      }

      if (data) {
        setCollection(data);

        // once collection rules are updated, get new list of collection items
        const { data: itemsData } = await fetchCollectionItems();

        if (itemsData) {
          const { items, totalCount } = itemsData;

          setCollectionItemsData(itemsData);
          setCollectionItems(items);
          setCollectionTotalCount(totalCount);
        }
      }
    } else {
      const { data, error } = await apiPut(`${collectionUrl}/items`, {
        body: collectionItems.map((item) => item.id),
      });

      if (error) {
        return { error };
      }

      if (data) {
        setCollectionItemsData(data);
      }
    }

    const { data } = await getCollectionPublishingStatus();

    if (data) {
      setCollection({ ...collection, ...data });
    }

    toggleDisableEditing();

    return {};
  };

  const onDeleteCollection = async () => {
    const { error } = await apiDelete(
      getUrlWithOptions(`${COLLECTIONS_ENDPOINT}?ids=${encodeURIComponent(collectionId)}`)
    );

    if (!error) {
      goBack();
    }
  };

  const onClickDeleteRow = (id) => {
    setCollectionItems((prev) => prev.filter((item: any) => item.id !== id));
  };

  const onDragRow = (reorderedRows) => setCollectionItems(reorderedRows);

  const onSearch = (query) => setSearchQuery(query);

  const onChangeFilter = (filter: string) => {
    const filters = filter.split(',').map((type) => (type ? `ItemSubType:${type}` : ''));

    setSelectedFilter(filters.join(','));
  };

  const onClickSort = (field, order) => {
    if (field && order) {
      setContentSort(`${field}:${order}`);
    }
  };

  const onChangeSelection = (items) => setSelectedItems(items);

  const onContentPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setContentPageIndex(pageIndex);
    setContentPageSize(pageSize);
  }, []);

  const onCollectionPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setCollectionPageIndex(pageIndex);
    setCollectionPageSize(pageSize);
  }, []);

  const onAddCollectionItem = () => {
    const existingIds = new Set(collectionItems.map((item) => item.id));
    const uniqueSelectedItems = selectedItems.filter((item) => !existingIds.has(item.id));
    const newCollectionItems = collectionItems.concat(uniqueSelectedItems);

    setCollectionItems(newCollectionItems);
  };

  const toggleDisableEditing = () => setDisableEditing(!disableEditing);

  const isDynamicCollection = (type = '') => (type || collection?.type)?.toUpperCase() === 'DYNAMIC';

  const isManualCollection = (type = '') => (type || collection?.type)?.toUpperCase() === 'MANUAL';

  const isIncludeType = (value) => value.toUpperCase() === 'TYPE';

  const addNewRule = () => {
    setCollectionRules(
      collectionRules.concat({
        id: _.uniqueId(),
        values: [],
        relation: radioOptions[0].value,
        include: includeOptions[0].value,
      })
    );
  };

  const formatRules = (rules) => {
    return rules.map((rule) => ({
      ...rule,
      id: _.uniqueId(),
    }));
  };

  const updatePublicationStatus = async (updateType) => {
    const url = `${collectionUrl}/${updateType}`;
    const { data } = await apiPut(url);

    if (data) {
      setCollection({ ...collection, ...data });

      if (updateType === 'revert') {
        setRefetch((prevRefetch) => !prevRefetch);
      }
    }
  };

  const publishChanges = async (publishDate) => {
    const { data } = await apiPut(`${collectionUrl}/publish`, {
      body: {
        scheduledPublishAt: publishDate?.length > 0 ? new Date(publishDate) : undefined,
      },
    });

    if (data) {
      setCollection({ ...collection, ...data });
    }
  };

  const onCancel = () => {
    setSelectedFilter('');
  };

  const filteredCollectionItems = useMemo(() => {
    if (collectionItems.length && contentItemsData.items) {
      const existingIds = new Set(collectionItems.map((item) => item.id));

      return contentItemsData.items.filter((item) => !existingIds.has(item.id));
    } else {
      return contentItemsData.items;
    }
  }, [collectionItems, contentItemsData.items]);

  return loading ? (
    <Loading />
  ) : (
    !_.isEmpty(collection) && (
      <>
        <AddBodyClasses classes={['disabled-overflow']} />
        <div className="Collections-detail">
          {withHeader && (
            <SubHeader
              title={collection.name}
              subtitle={goBackLabel}
              className="pl-5 pr-4"
              actions={
                <>
                  <PublicationStateButtons
                    disabled={disableEditing}
                    publication={collection?.publication}
                    allowRevert={true}
                    onRevert={() => updatePublicationStatus('revert')}
                    onPublish={(val) => publishChanges(val)}
                    onUnschedule={() => updatePublicationStatus('unschedule')}
                  />
                  <DeleteButton
                    variant="secondary"
                    disabled={disableEditing}
                    modalProps={{
                      title: 'Delete Collection',
                      children: (
                        <>
                          You will be deleting {collection.name} from the system. This action cannot be
                          undone.
                        </>
                      ),
                    }}
                    onModalConfirm={onDeleteCollection}
                  >
                    Delete
                  </DeleteButton>
                </>
              }
              detailMode={true}
              onBack={goBack}
              icon={<Icon name={ICONS.ARROW_RIGHT} flip="horizontal" />}
            />
          )}

          <Flex>
            <div style={{ flex: 1 }} className="main-content">
              <EditPanel
                id="information"
                title="Collection Information"
                disableEditing={disableEditing}
                disableSave={collectionName === ''}
                onEnableEditing={toggleDisableEditing}
                onCancel={() => {
                  setCollectionName(collection.name);
                  toggleDisableEditing();
                }}
                save={onUpdateCollectionInformation}
              >
                {(isEditing) => (
                  <div className="py-5 pl-6">
                    <div>
                      <div className={`panel-label mb-1${isEditing ? ' required' : ''}`}>Collection name</div>
                      {isEditing ? (
                        <TextInput
                          value={collectionName}
                          onChange={(updatedName) => setCollectionName(updatedName)}
                        />
                      ) : (
                        <div>{collection.name}</div>
                      )}
                    </div>
                    <div className="my-4">
                      <div className="panel-label mb-1">ID</div>
                      <div>{collection.id}</div>
                    </div>
                    <div>
                      <div className="panel-label mb-1">Type</div>
                      <div>{collection.type}</div>
                    </div>
                    <div className="mt-4">
                      <div className="panel-label mb-1">Public URL</div>
                      {url?.length ? (
                        <p className="link mt-1">
                          <a href={url} target="_blank" rel="noreferrer">
                            {url}
                          </a>
                        </p>
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                )}
              </EditPanel>
              <EditPanel
                id="items"
                title="Collection Items"
                disableEditing={disableEditing}
                onEnableEditing={toggleDisableEditing}
                onCancel={() => {
                  setCollectionItems(collectionItemsData.items);
                  setCollectionRules(formatRules(collection.rules));
                  toggleDisableEditing();
                  setSelectedFilter('');
                }}
                save={onUpdateCollectionItems}
              >
                {(isEditing) => (
                  <>
                    {isDynamicCollection() && isEditing && (
                      <div className="pt-1 pb-6 px-4">
                        {collectionRules.map(({ id, values, relation, include }, index) => (
                          <div key={id} className="mt-4">
                            <Flex>
                              <div className="mr-7">
                                <div className="panel-label mb-1">Include</div>
                                <SimpleSelect
                                  className="select-input-lg"
                                  value={include}
                                  options={includeOptions}
                                  onChange={(value) => {
                                    const updatedRules = [...collectionRules];

                                    updatedRules[index] = {
                                      include: value,
                                      values: [],
                                      relation: radioOptions[0].value,
                                    };
                                    setCollectionRules(updatedRules);
                                  }}
                                />
                              </div>
                              <div className="mr-12">
                                <div className="panel-label mb-1">Relation</div>
                                <RadioGroup
                                  value={relation}
                                  options={isIncludeType(include) ? [radioOptions[0]] : radioOptions}
                                  onChange={(relationValue) => {
                                    const updatedRules = [...collectionRules];

                                    updatedRules[index].relation = relationValue;
                                    setCollectionRules(updatedRules);
                                  }}
                                />
                              </div>
                              <div className="ml-6">
                                <div className="panel-label mb-1">Values</div>
                                {isIncludeType(include) ? (
                                  fieldOptions.map(({ label, value }, fieldIndex) => (
                                    <Checkbox
                                      key={fieldIndex}
                                      label={label}
                                      checked={values.includes(value)}
                                      onChange={(e) => {
                                        const updatedRules = _.cloneDeep(collectionRules);

                                        if (e.target.checked) {
                                          updatedRules[index].values.push(value);
                                        } else {
                                          _.pull(updatedRules[index].values, value);
                                        }

                                        setCollectionRules(updatedRules);
                                      }}
                                    />
                                  ))
                                ) : (
                                  <TagList
                                    value={values}
                                    onChange={(arr) => {
                                      const updatedRules = [...collectionRules];

                                      updatedRules[index].values = arr;
                                      setCollectionRules(updatedRules);
                                    }}
                                  />
                                )}
                              </div>
                            </Flex>
                            <Button
                              className="delete-rule-btn"
                              variant="link"
                              onClick={() =>
                                setCollectionRules(collectionRules.filter((rule) => rule.id !== id))
                              }
                            >
                              - Clear
                            </Button>
                            <hr />
                          </div>
                        ))}
                        <Button variant="link" onClick={addNewRule}>
                          + And include
                        </Button>
                        <div className="mt-10">
                          <div className="panel-label mb-1">Max # of items</div>
                          <Counter initialCount={maxNumItems} onChange={(count) => setMaxNumItems(count)} />
                        </div>
                        <div className="mt-5">
                          <div className="panel-label mb-1">Default Sort</div>
                          <SimpleSelect
                            className="select-input-lg"
                            value={collectionSort}
                            options={sortOptions}
                            onChange={(optionValue) => setCollectionSort(optionValue)}
                          />
                        </div>
                      </div>
                    )}
                    <Table
                      className={isEditing ? 'isEditing' : ''}
                      data={collectionItems || []}
                      columns={commonColumns.concat(collectionItemsColumns)}
                      onClickDeleteRow={onClickDeleteRow}
                      onDragRow={onDragRow}
                      pageCount={getPageCount(collectionTotalCount, collectionPageSize)}
                      pageIndex={collectionPageIndex}
                      pageSize={collectionPageSize}
                      onPaginationChange={onCollectionPaginationChange}
                      hasPagination={isDynamicCollection()}
                      hasDeletion={isManualCollection()}
                      hasDragging={isManualCollection()}
                    />
                    {isManualCollection() && isEditing && (
                      <AddCollectionItemButton
                        className="ml-5 mb-5 mt-2"
                        variant="primary"
                        modalProps={{
                          title: 'Add a collection item',
                          addButtonText: 'Add to collection',
                          searchPlaceholder: 'Search by name or ID',
                          filters: contentFilters,
                          data: filteredCollectionItems,
                          columns: contentItemsColumns,
                          pageCount: getPageCount(contentTotalCount, contentPageSize),
                          pageIndex: contentPageIndex,
                          pageSize: contentPageSize,
                          hasPagination: true,
                          onChangeFilter,
                          onClickSort,
                          onSearch,
                          onChangeSelection,
                          onPaginationChange: onContentPaginationChange,
                          onAddItem: onAddCollectionItem,
                          onCancel,
                        }}
                      >
                        + Add Collection Item
                      </AddCollectionItemButton>
                    )}
                  </>
                )}
              </EditPanel>
            </div>
            <TableOfContents
              className="pt-12 mr-11"
              data={[
                {
                  id: 'information',
                  title: 'Collection Information',
                  offset: -250,
                },
                { id: 'items', title: 'Collection Items', offset: -300 },
              ]}
              isSticky
              hasBorder
            />
          </Flex>
        </div>
      </>
    )
  );
};

export default compose(withRouter)(CollectionsDetail);
