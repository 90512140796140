import React, { FC } from 'react';
import { Flex } from '@brightcove/studio-components';

import Button from '../Button/Button';
import './SimplePager.scss';

interface SimplePagerProps {
  length: number;
  index: number;
  onChange: (val: number) => void;
  nextText: string;
  previousText: string;
}

const SimplePager: FC<SimplePagerProps> = ({ length, index, onChange, nextText, previousText }) => {
  const prevDisabled = () => index === 0;
  const nextDisabled = () => index === length - 1 || !length;

  return (
    <Flex className="ml-4 mt-2">
      <Button
        variant="link"
        onClick={() => {
          if (index > 0) {
            onChange(index - 1);
          }
        }}
        text={previousText}
        className="SimplePager-buttons"
        disabled={prevDisabled()}
      />
      <Button
        variant="link"
        onClick={() => {
          if (index < length) {
            onChange(index + 1);
          }
        }}
        text={nextText}
        className="SimplePager-buttons ml-4"
        disabled={nextDisabled()}
      />
    </Flex>
  );
};

export default SimplePager;
