import { Role } from '../../types/roles';

export const sortRoles = (roles: Role[]): Role[] => {
  roles.sort((a, b) => {
    // sort by default admins in descending order (true comes first)
    if (b.isDefault !== a.isDefault) {
      return b.isDefault ? 1 : -1;
    }

    // sort by super admins in descending order (true comes first)
    if (b.isSuper !== a.isSuper) {
      return b.isSuper ? 1 : -1;
    }

    // sort by name in ascending order
    return a.name.localeCompare(b.name);
  });

  return roles;
};
