import { useNavigate } from 'react-router';
import React, { FC } from 'react';

import * as ROUTES from '../../routes';
import { GenericNameId } from '../../../interfaces';
import TitleDescription from '../../../components/TitleDescription/TitleDescription';
import Table from '../../../components/Table/Table';
import Panel from '../../../components/Panel/Panel';

import './CollectionsContained.scss';

interface CollectionsContainedProps {
  idPanel: string;
  data: GenericNameId[];
}
const CollectionsContained: FC<CollectionsContainedProps> = ({ idPanel, data }) => {
  const navigate = useNavigate();
  const columns = [
    {
      Header: 'Name',
      sortable: true,
      accessor: (row) => [row.name, row.id], // accessor is the "key" in the data
      Cell: ({ value }) => {
        const [name, collectionId] = value;

        return (
          <TitleDescription
            title={name}
            description={collectionId}
            id={collectionId}
            onClick={(itemId) => {
              navigate(`../${ROUTES.COLLECTIONS}/edit/${itemId}`);
            }}
          />
        );
      },
    },
  ];

  return (
    <div id={idPanel} className="CollectionsContained">
      <Panel title="Collections Contained In" actions={<></>}>
        <div className="ml-4">
          {data?.length ? <Table className="" data={data.length ? data : []} columns={columns} /> : '-'}
        </div>
      </Panel>
    </div>
  );
};

export default CollectionsContained;
