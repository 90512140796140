import React, { FC, useState } from 'react';

import Button from '../Button/Button';
import ContentManagement from '../../routes/ContentManagement/ContentManagement';

import GenericModal, { GenericModalProps } from './GenericModal';
import './AddContentItemModal.scss';

interface AddContentItemModalProps extends GenericModalProps {
  title: string;
  addItemBtnLabel?: string;
  excludeFilters?: any[];
  includeFilters?: any[];
  singleSelection?: boolean;
  onAddItems?: (param: any[]) => void;
  onCancel?: () => void;
}

const AddContentItemModal: FC<AddContentItemModalProps> = ({
  title,
  addItemBtnLabel,
  excludeFilters,
  includeFilters,
  show,
  onClose,
  onCancel,
  singleSelection,
  onAddItems,
}) => {
  const [selections, setSelections] = useState<any[]>([]);

  const handleCloseModal = () => {
    onCancel?.();
    onClose?.();
  };

  const onRowSelect = (items: any[]) => {
    setSelections(items);
  };

  return (
    <GenericModal
      className="Add-content-item-modal"
      show={show}
      title={title}
      onClose={handleCloseModal}
      buttons={
        <>
          <Button variant="secondary" text="Cancel" onClick={handleCloseModal} />,
          <Button
            className="ml-3"
            variant="primary"
            text={`Add ${addItemBtnLabel || "Item"}`}
            disabled={selections.length === 0}
            onClick={() => {
              onAddItems?.(selections);
              handleCloseModal();
            }}
          />
        </>
      }
    >
      <ContentManagement
        embedded
        excludeFilters={excludeFilters}
        includeFilters={includeFilters}
        singleSelection={singleSelection}
        onRowSelect={onRowSelect}
      />
    </GenericModal>
  );
};

export default AddContentItemModal;
