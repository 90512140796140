import { compose } from 'redux';
import { NavLink, useNavigate } from 'react-router-dom';
import React from 'react';
import { DropdownMenu, DropdownMenuItem } from '@brightcove/studio-components';

import Navigation from '../Navigation/Navigation';
import { Permission } from '../../utils/types';
import { getUrlWithOptions } from '../../utils';
import { withStore } from '../../store';
import * as ROUTES from '../../routes/routes';
import { SVGImage } from '../../assets/images';

import './Header.scss';

const Header: React.FunctionComponent<any> = ({ store: { user } }) => {
  const navigate = useNavigate();

  const logout = () => {
    const location = window.location;
    const authBaseUrl = getUrlWithOptions('/auth');

    location.replace(`${authBaseUrl}/logout`);
  };

  const hasAccess = (permissionType: Permission) => {
    if (user.role === 'Super Admin') return true;

    return !!user.permissions?.includes(permissionType);
  };

  return (
    <div className="Header">
      <div className="Header-content">
        <NavLink to={user ? '/' : '/error'}>
          <img src={SVGImage.NetAppLogo} className="Header-logo" alt="NetApp Logo" />
        </NavLink>
        {user && (
          <>
            <Navigation
              items={[
                ...(hasAccess('READ_CONTENT') ? [{ title: 'Content Management', path: ROUTES.HOME }] : []),
                ...(hasAccess('READ_EVENT') ? [{ title: 'Events', path: ROUTES.EVENTS }] : []),
                ...(hasAccess('READ_COLLECTION') ? [{ title: 'Collections', path: ROUTES.COLLECTIONS }] : []),
                ...(hasAccess('READ_PAGE') ? [{ title: 'Pages', path: ROUTES.PAGES }] : []),
              ]}
            />
            <div className="menu-dropdown">
              <DropdownMenu
                label="More modules"
                buttonProps={{
                  look: 'tertiary',
                  className: 'menu-dropdown-button',
                }}
                testName="menu-modules"
              >
                <>
                  {hasAccess('READ_USER') && (
                    <DropdownMenuItem onClick={() => navigate(ROUTES.USER_MANAGEMENT)}>
                      User Management
                    </DropdownMenuItem>
                  )}
                  {hasAccess('READ_ROLE') && (
                    <DropdownMenuItem onClick={() => navigate(ROUTES.ROLES)}>Roles</DropdownMenuItem>
                  )}
                  {hasAccess('READ_ACTIVITY_LOG') && (
                    <DropdownMenuItem onClick={() => navigate(ROUTES.ACTIVITY_LOG)}>
                      Activity Log
                    </DropdownMenuItem>
                  )}
                  {hasAccess('READ_LOCALIZED_LABEL') && (
                    <DropdownMenuItem onClick={() => navigate(ROUTES.LOCALIZED_LABELS)}>
                      Localized Labels
                    </DropdownMenuItem>
                  )}
                  {hasAccess('READ_SERVICE_MESSAGE') && (
                    <DropdownMenuItem onClick={() => navigate(ROUTES.SERVICE_MESSAGES)}>
                      Service Messages
                    </DropdownMenuItem>
                  )}
                  {hasAccess('READ_MAINTENANCE_MODE') && (
                    <DropdownMenuItem onClick={() => navigate(ROUTES.MAINTENANCE_MODE)}>
                      Maintenance Mode
                    </DropdownMenuItem>
                  )}
                  {hasAccess('READ_SITE_CONFIGURATION') && (
                    <DropdownMenuItem onClick={() => navigate(ROUTES.SITE_CONFIGURATION)}>
                      Site Configuration
                    </DropdownMenuItem>
                  )}
                  {hasAccess('READ_DRAFT') && (
                    <DropdownMenuItem onClick={() => navigate(ROUTES.DRAFT_CHANGES)}>
                      Draft Changes
                    </DropdownMenuItem>
                  )}
                  {hasAccess('READ_LOCATION') && (
                    <DropdownMenuItem onClick={() => navigate(ROUTES.LOCATIONS)}>Locations</DropdownMenuItem>
                  )}
                  {hasAccess('READ_ACCESS_CONTROL') && (
                    <DropdownMenuItem onClick={() => navigate(ROUTES.ACCESS_CONTROLS)}>
                      Access Controls
                    </DropdownMenuItem>
                  )}
                  <DropdownMenuItem onClick={() => window.open(ROUTES.BRIGHTCOVE_VIDEO_CLOUD, '_blank')}>
                    Brightcove Video Cloud
                  </DropdownMenuItem>
                </>
              </DropdownMenu>
              <DropdownMenu
                label={user?.email}
                buttonProps={{
                  look: 'tertiary',
                  className: 'menu-dropdown-button',
                }}
                testName="menu-user"
              >
                <>
                  <DropdownMenuItem onClick={() => navigate(ROUTES.MY_PROFILE)}>My Profile</DropdownMenuItem>
                  <DropdownMenuItem onClick={logout}>Log Out</DropdownMenuItem>
                </>
              </DropdownMenu>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default compose(withStore)(Header);
