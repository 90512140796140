import { useParams } from 'react-router';
import React, { FC, useEffect, useState } from 'react';
import { AutosaveTagList, Flex } from '@brightcove/studio-components';

import { renderArrayOfStrings } from '../../../utils/format';
import { getUrlWithOptions } from '../../../utils';
import { LocaleInformationEpisodesData } from '../../../interfaces/Content';
import { useApi } from '../../../hooks/useApi';
import Panel from '../../../components/Panel/Panel';
import TextInput from '../../../components/ControlledComponents/TextInput';
import TextArea from '../../../components/ControlledComponents/TextArea';
import Button from '../../../components/Button/Button';
import './LocaleInformationEpisodes.scss';

interface TextProps {
  idPanel?: string;
  data: LocaleInformationEpisodesData;
  disabledEdit?: boolean;
  onEditChange?: (val: boolean, isSaving?: boolean) => void;
}

const LocaleInformationEpisode: FC<TextProps> = ({ idPanel, data, disabledEdit, onEditChange }) => {
  const { id } = useParams();
  const { apiPut, isLoading } = useApi();
  const {
    name,
    description,
    cta,
    language,
    keywords,
    industry,
    product,
    service,
    sessionTechLevel,
    sessionTheme,
    sessionType,
    topic,
  } = data;
  const [editing, setEditing] = useState<boolean>(false);
  const [currentName, setCurrentName] = useState<string>('');
  const [currentDescription, setCurrentDescription] = useState<string>('');
  const [currentCtaTex, setCurrentCtaTex] = useState<string>('');
  const [currentCtaUrl, setCurrentCtaUrl] = useState<string>('');
  const [currentKeywords, setCurrentKeywords] = useState<string[]>([]);
  const [keywordsState, setKeywordsState] = useState<any>({});
  const [defaultData, setDefaultData] = useState<any>({});

  useEffect(() => {
    setDefaultData({
      cta,
      keywords,
    });
    setCurrentName(name?.length ? name : '');
    setCurrentDescription(description?.length ? description : '');
    setCurrentCtaTex(cta?.text?.length ? cta.text : '');
    setCurrentCtaUrl(cta?.url?.length ? cta.url : '');
    setCurrentKeywords(keywords?.length ? keywords : []);
  }, [data]);

  const onSaveText = async () => {
    const body = {
      name: currentName,
      description: currentDescription,
      cta: { text: currentCtaTex, url: currentCtaUrl },
      keywords: currentKeywords,
    };
    const response = await apiPut(getUrlWithOptions(`/content/${id}/locales/${language}`), {
      body,
    });

    if (response?.data) {
      setDefaultData({
        cta: response?.data?.cta,
        keywords: response?.data?.keywords,
      });
      setEditing(false);

      if (onEditChange) {
        onEditChange(!editing, true);
      }
    }
  };

  const restoreData = () => {
    setCurrentCtaTex(defaultData?.cta?.text?.length ? defaultData.cta.text : '');
    setCurrentCtaUrl(defaultData?.cta?.url?.length ? defaultData.cta.url : '');
    setCurrentKeywords(defaultData?.keywords?.length ? defaultData.keywords : []);
  };

  return (
    <div id={idPanel} className="LocaleInformationEpisodes w-100">
      <Panel
        title="Locale Information"
        actions={
          <>
            {editing ? (
              <>
                <Button
                  variant="secondary"
                  text="Cancel"
                  onClick={() => {
                    setEditing(false);
                    restoreData();

                    if (onEditChange) {
                      onEditChange(!editing);
                    }
                  }}
                />
                <Button variant="primary" text="Save" onClick={onSaveText} disabled={isLoading} />
              </>
            ) : (
              <Button
                variant="primary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);

                  if (onEditChange) {
                    onEditChange(!editing);
                  }
                }}
                disabled={disabledEdit}
              />
            )}
          </>
        }
      >
        <>
          {editing ? (
            <div className="w-50">
              <div className="ml-4 name">
                <p className="title">Name</p>
                <TextInput value={currentName} onChange={setCurrentName} />
              </div>
              <div className="ml-4 mt-6 short-description">
                <p className="title">Description</p>
                <TextArea value={currentDescription} onChange={setCurrentDescription} />
              </div>
              <Flex className="ml-4 mt-3">
                <div>
                  <p className="title">CTA Text</p>
                  <TextInput value={currentCtaTex} onChange={setCurrentCtaTex} />
                </div>
                <div className="ml-4">
                  <p className="title">CTA Url</p>
                  <TextInput value={currentCtaUrl} onChange={setCurrentCtaUrl} />
                </div>
              </Flex>
              <div className="ml-4 mt-4 keywords">
                <AutosaveTagList
                  className="AutoCompleteTags"
                  fieldId="locationControlsField"
                  label="Keywords"
                  value={currentKeywords}
                  editing={keywordsState.editing || false}
                  saving={keywordsState.saving || false}
                  saved={keywordsState.saved || false}
                  onStartEditing={() => {
                    setKeywordsState({ editing: true });
                  }}
                  onCancel={() => setKeywordsState({ editing: false })}
                  onSave={(value) => {
                    setKeywordsState({ saved: true });
                    setCurrentKeywords(value);
                  }}
                />
              </div>
            </div>
          ) : (
            <Flex>
              <div>
                <div className="ml-4 name">
                  <p className="title">Name</p>
                  <p className="description">{currentName.length ? currentName : '-'}</p>
                </div>
                <div className="ml-4 mt-6 short-description">
                  <p className="title">Description</p>
                  <p className="description">{currentDescription.length ? currentDescription : '-'}</p>
                </div>
                <Flex className="ml-4 mt-3">
                  <div className="cta-text">
                    <p className="title">CTA Text</p>
                    <p className="description">{currentCtaTex ? currentCtaTex : '-'}</p>
                  </div>
                  <div className="ml-4">
                    <p className="title">CTA Url</p>
                    <p className="description">{currentCtaUrl ? currentCtaUrl : '-'}</p>
                  </div>
                </Flex>
                <div className="ml-4 mt-2">
                  <p className="title">Keywords</p>
                  <p className="description">{renderArrayOfStrings(currentKeywords)}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Industry</p>
                  <p className="description">{renderArrayOfStrings(industry)}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Product</p>
                  <p className="description">{renderArrayOfStrings(product)}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Service</p>
                  <p className="description">{renderArrayOfStrings(service)}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Session Tech Level</p>
                  <p className="description">{renderArrayOfStrings(sessionTechLevel)}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Session Theme</p>
                  <p className="description">{renderArrayOfStrings(sessionTheme)}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Session Type</p>
                  <p className="description">{renderArrayOfStrings(sessionType)}</p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Topic</p>
                  <p className="description">{renderArrayOfStrings(topic)}</p>
                </div>
              </div>
            </Flex>
          )}
        </>
      </Panel>
    </div>
  );
};

export default LocaleInformationEpisode;
