import React, { FC } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';
import './ErrorScreen.scss';

interface ErrorScreenProps {
  description?: string;
}
const ErrorScreen: FC<ErrorScreenProps> = ({ description }) => {
  return (
    <>
      <span className="ErrorScreen ml-11 mr-12 mt-12">
        <Flex alignItems="center" flexDirection="column">
          <Icon name={ICONS.WARNING} />
          <p className="title">
            {description?.length ? description : 'You do not have permission to access this page'}
          </p>
        </Flex>
      </span>
    </>
  );
};

export default ErrorScreen;
