import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Flex } from '@brightcove/studio-components';

import EditPanel from '../../../../components/Panel/EditPanel';
import Button from '../../../../components/Button/Button';

import Rule from './Rule';
import './FeaturedContentRules.scss';

const FeaturedContentRules = ({
  panelId,
  title,
  featuredRules,
  disableEditing,
  onEnableEditing,
  onCancel,
  onSave,
}) => {
  const [rules, setRules] = useState<any[]>([]);
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    setRules(parseRules(featuredRules));
  }, [featuredRules]);

  useEffect(() => {
    validateRules();
  }, [rules]);

  const cancelChanges = () => {
    setRules(parseRules(featuredRules));
    onCancel();
  };

  const parseRules = (rules: any[]) => {
    return rules.map((rule) => ({ ...rule, id: _.uniqueId() }));
  };

  const getUpdatedRules = () => {
    return rules.map((rule) => {
      const updatedRule = _.omit(rule, ['id', 'item']);

      if (rule.item) {
        return { ...updatedRule, itemId: rule.item.id };
      }

      return updatedRule;
    });
  };

  const validateRules = () => {
    const inValidRules = rules.filter((rule) => !rule.filter);

    setDisableSave(inValidRules.length > 0);
  };

  const addRule = () => {
    setRules([...rules, { id: _.uniqueId(), filter: '', value: '' }]);
  };

  const deleteRule = (id) => {
    setRules((prevRules) => {
      return prevRules.filter((rule) => rule.id !== id);
    });
  };

  const updateRule = (updatedRule) => {
    const updatedRules = rules.map((rule) => (rule.id === updatedRule.id ? updatedRule : rule));

    setRules(updatedRules);
  };

  return (
    <EditPanel
      id={panelId}
      title={title}
      disableEditing={disableEditing}
      onEnableEditing={onEnableEditing}
      disableSave={disableSave}
      onCancel={cancelChanges}
      save={() => onSave({ featuredRules: getUpdatedRules() })}
    >
      {(isEditing) => (
        <div
          className={classNames('Page-detail-featured-content-rules px-4 pt-4 pb-6', { editing: isEditing })}
        >
          {rules.length > 0 && (
            <Flex flexDirection="column">
              {rules.map((rule, index) => (
                <Rule
                  key={index}
                  index={index}
                  ruleData={rule}
                  isEditing={isEditing}
                  onRuleUpdate={updateRule}
                  onDeleteRule={deleteRule}
                />
              ))}
            </Flex>
          )}
          {isEditing && <Button className="mt-3" variant="primary" text="+ Add Rule" onClick={addRule} />}
        </div>
      )}
    </EditPanel>
  );
};

export default FeaturedContentRules;
