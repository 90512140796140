import React from 'react';
import './Footer.scss';

const Footer: React.FunctionComponent<any> = () => {
  return (
    <div className="Footer">
      <p>Copyright © {new Date().getFullYear()} NetApp, Inc. All Rights Reserved</p>
    </div>
  );
};

export default Footer;
