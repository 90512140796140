import React, { FC } from 'react';
import { Flex } from '@brightcove/studio-components';

import { ContentData } from '../../../interfaces/Content';
import Panel from '../../../components/Panel/Panel';

import PanelRowUrl from './components/PanelRowUrl';

interface ContentProps {
  idPanel: string;
  data: Partial<ContentData>;
}

const ContentAdTrailer: FC<ContentProps> = ({ idPanel, data }) => {
  const { id, brightcoveVideoId, extId, source, name } = data;

  const videocloudUrl =
    !!brightcoveVideoId &&
    `https://studio.brightcove.com/products/videocloud/media/video-details/${brightcoveVideoId}/overview`;

  return (
    <div id={idPanel} className="Content">
      <Panel title="Content" actions={<></>}>
        <>
          <Flex>
            <div>
              <div className="ml-4">
                <p className="title">ID</p>
                <p className="description">{id}</p>
              </div>

              <PanelRowUrl title="Video ID" label={brightcoveVideoId} url={videocloudUrl} />

              <div className="ml-4 mt-6">
                <p className="title">Source</p>
                <p className="description">{source || '-'}</p>
              </div>

              <div className="ml-4 mt-6">
                <p className="title">Name</p>
                <p className="description">{name || '-'}</p>
              </div>

              <PanelRowUrl title="Reference ID" label={extId} url={videocloudUrl} />
            </div>
          </Flex>
        </>
      </Panel>
    </div>
  );
};

export default ContentAdTrailer;
