export const SET_USER = 'SET_USER';
export const SET_CONTENT_FILTER = 'SET_CONTENT_FILTER';
export const SET_CONTENT_SEARCH = 'SET_CONTENT_SEARCH';
export const SET_EVENTS_FILTER = 'SET_EVENTS_FILTER';
export const SET_EVENTS_SEARCH = 'SET_EVENTS_SEARCH';
export const TOGGLE_EVENT_SAVING = 'TOGGLE_EVENT_SAVING';
export const TOGGLE_LANGUAGE_DISABLED = 'TOGGLE_LANGUAGE_DISABLED';

export const setUser = (user) => ({
  type: SET_USER,
  data: { user },
});

export const setContentFilter = (filter) => ({
  type: SET_CONTENT_FILTER,
  data: { filter },
});

export const setContentSearch = (search) => ({
  type: SET_CONTENT_SEARCH,
  data: { search },
});

export const setEventsFilter = (filter) => ({
  type: SET_EVENTS_FILTER,
  data: { filter },
});

export const setEventsSearch = (search) => ({
  type: SET_EVENTS_SEARCH,
  data: { search },
});

export const setEventSaving = () => ({
  type: TOGGLE_EVENT_SAVING,
});

export const toggleLanguageDisabled = (value?: boolean) => ({
  type: TOGGLE_LANGUAGE_DISABLED,
  data: { value },
});
