export const HOME = '/';
export const CONTENT_MANAGEMENT = '/content-management';
export const EVENTS = '/events';
export const COLLECTIONS = '/collections';
export const PAGES = '/pages';
export const USER_MANAGEMENT = '/user-management';
export const ROLES = '/roles';
export const ACTIVITY_LOG = '/activity-log';
export const LOCALIZED_LABELS = '/localized-labels';
export const SERVICE_MESSAGES = '/service-messages';
export const MAINTENANCE_MODE = '/maintenance-mode';
export const SITE_CONFIGURATION = '/site-configuration';
export const DRAFT_CHANGES = '/draft-changes';
export const LOCATIONS = '/locations';
export const ACCESS_CONTROLS = '/access-controls';
export const BRIGHTCOVE_VIDEO_CLOUD = 'https://studio.brightcove.com';
export const MY_PROFILE = '/my-profile';
export const ERROR = '/error';
