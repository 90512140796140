import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { getUrlWithOptions, DEFAULT_LANGUAGE } from '../../utils';
import { useApi } from '../../hooks/useApi';
import SubHeader from '../../components/SubHeader/SubHeader';
import Panel from '../../components/Panel/Panel';
import TextInput from '../../components/ControlledComponents/TextInput';
import './ContentManagementCreate.scss';
import SimpleSelect from '../../components/ControlledComponents/SimpleSelect';
import Button from '../../components/Button/Button';

interface ContentManagementCreateData {
  type: 'SeriesContent';
  language: string;
  name: string;
}

const ContentManagementCreate = () => {
  const { apiGet, apiPost, isLoading } = useApi();
  const navigate = useNavigate();
  const [currentName, setCurrentName] = useState<string>('');
  const [currentLanguage, setCurrentLanguage] = useState<string>(DEFAULT_LANGUAGE);
  const [languages, setLanguages] = useState<any[]>([]);
  const [canCreate, setCanCreate] = useState<boolean>(false);

  useEffect(() => {
    getLanguages();
  }, []);

  const getLanguages = async () => {
    const { data } = await apiGet(getUrlWithOptions(`/languages`));

    setLanguages(data);
  };

  const onCreate = () => {
    const saveData = async () => {
      const body: ContentManagementCreateData = {
        type: 'SeriesContent',
        language: currentLanguage,
        name: currentName,
      };
      const response = await apiPost(getUrlWithOptions('/content'), { body });
      const createId = response?.data?.id;

      if (createId?.length) {
        navigate(`../${ROUTES.CONTENT_MANAGEMENT}`);
      }
    };

    saveData();
  };

  useEffect(() => {
    setCanCreate(!!currentName.length);
  }, [currentName]);

  return (
    <>
      <SubHeader
        icon={<Icon name="ARROW_RIGHT" rotate="180" />}
        title={'Create Series'}
        subtitle={'Back to All Videos'}
        detailMode
        onBack={() => navigate(`../${ROUTES.CONTENT_MANAGEMENT}`)}
        className="pl-5"
      />

      <div className="ml-11 mr-11 mt-12">
        {isLoading ? (
          <Flex justifyContent="center" alignContent="center" className="mt-8">
            <Icon name={ICONS.SPINNER} spin className="spinner-large" />
          </Flex>
        ) : (
          <Panel
            title={<p>Create Series</p>}
            actions={
              <>
                <SimpleSelect
                  className="select-input-lg mr-2"
                  value={currentLanguage}
                  options={languages as any}
                  onChange={(value) => setCurrentLanguage(value)}
                />
                <Button
                  variant="secondary"
                  text="Cancel"
                  onClick={() => {
                    navigate(`../${ROUTES.CONTENT_MANAGEMENT}`);
                  }}
                />
                <Button
                  className="ml-4"
                  variant="primary"
                  text={'Create'}
                  onClick={onCreate}
                  disabled={!canCreate}
                />
              </>
            }
          >
            <>
              <Flex>
                <div className="ml-4">
                  <p className="ContentManagementCreate-title">Name *</p>
                  <TextInput value={currentName} onChange={setCurrentName} />
                </div>
              </Flex>
            </>
          </Panel>
        )}
      </div>
    </>
  );
};

export default ContentManagementCreate;
