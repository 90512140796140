import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { DEFAULT_PAGE } from '../../utils/constants';
import { getUrlWithOptions } from '../../utils';
import { useApi } from '../../hooks/useApi';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import DeleteConfirmationModal from '../../components/Modals/DeleteConfirmationModal';
import InputSearch from '../../components/ControlledComponents/InputSearch';
import Button from '../../components/Button/Button';
import './LocalizedLabels.scss';

const resource = '/localized_labels';
const SORT_DEFAULT = 'updatedAt:desc';
const VALID_SORT_FIELDS = {
  Name: 'name',
  Created: 'createdAt',
  Updated: 'updatedAt',
};
const LocalizedLabels = () => {
  const { apiGet, apiDelete, isLoading } = useApi();
  const [selectedItems, setSelectedItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const [items, setItems] = useState<any[]>([]);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [currentSort, setCurrentSort] = useState<string>(SORT_DEFAULT);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getData = async () => {
    setItems([]);
    const { data } = await apiGet(
      getUrlWithOptions(resource, currentSearch, currentSort, undefined, DEFAULT_PAGE)
    );

    setItems(data.items?.length ? data.items : []);
    setTotalCount(data.totalCount);
  };

  useEffect(() => {
    getData();
  }, [currentSearch, currentSort]);

  const onDeleteItems = () => {
    const ids = selectedItems.map((item: any) => item.key).join(',');
    const deleteData = async () => {
      const response = await apiDelete(getUrlWithOptions(`${resource}?keys=${encodeURIComponent(ids)}`));

      setShowDeleteModal(false);

      if (!response?.error) {
        getData();
      }
    };

    deleteData();
  };

  const onSearch = (val: string) => setCurrentSearch(val);

  const onClickSort = (field, order) => {
    if (field && order) {
      setCurrentSort(`${VALID_SORT_FIELDS[field]}:${order.toLocaleLowerCase()}`);
    }
  };

  const columns = [
    {
      Header: 'Key',
      accessor: (row) => [row.key], // The return must be an array if you need that the search (global filter) apply
      Cell: ({ value }) => {
        const [key] = value;

        return (
          <TitleDescription
            title={key}
            description={''}
            id={key}
            onClick={(selectedId) => navigate(`../${ROUTES.LOCALIZED_LABELS}/edit/${selectedId}`)}
          />
        );
      },
    },
    {
      Header: 'Value',
      accessor: (row) => [row.values[0]?.value],
    },
  ];

  return (
    <>
      <SubHeader
        icon={<Icon name={ICONS.CARD} className="mt-3" />}
        title={'Localized Labels'}
        subtitle={'NetApp TV'}
        quantitySubtitle={`${currentTotalCount} Labels`}
        className="pl-4"
        actions={<InputSearch placeholder="Search by name or ID" onSearch={onSearch} />}
      />
      <div className="ml-11 mr-12 flex-no-grow">
        <DeleteConfirmationModal
          title="Delete Localized Label"
          show={showDeleteModal}
          onDelete={onDeleteItems}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          actionDisabled={isLoading}
        >
          <>
            You will be deleting {selectedItems.length} Localized Label permanently from the system. This
            action cannot be undone.
          </>
        </DeleteConfirmationModal>
        <Flex className="mt-6">
          <Button
            variant="primary"
            onClick={() => {
              navigate(`../${ROUTES.LOCALIZED_LABELS}/create`);
            }}
          >
            Create
          </Button>
          <Button
            className="ml-3"
            variant="danger"
            disabled={!selectedItems.length}
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            Delete
          </Button>
        </Flex>
      </div>

      <Table
        className="LocalizedLabels-table my-4 "
        data={items}
        columns={columns}
        onChangeSelection={setSelectedItems}
        hasSelection
        onClickSort={onClickSort}
        width={700}
        loading={isLoading}
      />
    </>
  );
};

export default LocalizedLabels;
