import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { CountryTagList, getCountryDataForLocale } from '@brightcove/studio-components-countrytaglist';
import { Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { getUrlWithOptions } from '../../utils';
import { useApi } from '../../hooks/useApi';
import SubHeader from '../../components/SubHeader/SubHeader';
import Panel from '../../components/Panel/Panel';
import { DeleteConfirmationModal, withModal } from '../../components/Modals';
import Loading from '../../components/Loading/Loading';
import TextInput from '../../components/ControlledComponents/TextInput';
import Button from '../../components/Button/Button';
import './LocationsDetail.scss';

const DeleteButton = withModal(Button, DeleteConfirmationModal);

type Country = {
  label: string;
  code: string;
  isValid?: boolean;
};

const LocationsDetail = () => {
  const { id: locationId } = useParams();
  const navigate = useNavigate();
  const { apiGet, apiPost, apiPatch, apiDelete, isLoading } = useApi();
  const [locationName, setLocationName] = useState<string>('');
  const [countries, setCountries] = useState<Country[]>([]);
  const [editing, setEditing] = useState<boolean>(!locationId);
  const [location, setLocation] = useState<any>({});

  const LOCALE = 'en';
  const COUNTRIES_LIST: Country[] = getCountryDataForLocale(LOCALE);

  const getCountriesList = (codes: string[]): Country[] => {
    return COUNTRIES_LIST.filter((item) => codes.includes(item.code));
  };

  const countryLabels = countries?.map((item) => item?.label);
  const countryCodes = countries?.map((item) => item?.code);

  useEffect(() => {
    if (locationId) {
      (async () => {
        const { data: locationData } = await apiGet(getUrlWithOptions(`/locations/${locationId}`));

        if (locationData) {
          setLocation(locationData);
          setLocationName(locationData.name);
          setCountries(getCountriesList(locationData.locations));
        }
      })();
    }
  }, []);

  const goBack = () => navigate(`../${ROUTES.LOCATIONS}`);

  const toggleEditing = () => setEditing(!editing);

  const onCreate = async () => {
    if (locationName !== '') {
      const { data } = await apiPost(getUrlWithOptions(`/locations`), {
        body: {
          name: locationName,
          locations: countryCodes,
        },
      });

      if (data) {
        navigate(`../${ROUTES.LOCATIONS}/edit/${data.id}`);
        toggleEditing();
      }
    }
  };

  const onDelete = async () => {
    const { error } = await apiDelete(
      getUrlWithOptions(`/locations/?ids=${encodeURIComponent(locationId as string)}`)
    );

    if (!error) {
      goBack();
    }
  };

  const onSave = async () => {
    if (locationName !== '') {
      const { data } = await apiPatch(getUrlWithOptions(`/locations/${locationId}`), {
        body: {
          name: locationName,
          locations: countryCodes,
        },
      });

      if (data) {
        setLocation(data);
        setLocationName(data.name);
        toggleEditing();
      }
    }
  };

  const cancelChanges = () => {
    const { name, locations } = location;

    setLocationName(name);
    setCountries(getCountriesList(locations));
    toggleEditing();
  };

  return isLoading ? (
    <Loading />
  ) : (locationId && location) || !locationId ? (
    <div className="Locations-detail">
      <SubHeader
        title={locationId ? 'Edit Location' : 'New Location'}
        subtitle="Back to Locations"
        detailMode
        onBack={goBack}
        icon={<Icon name={ICONS.ARROW_RIGHT} flip="horizontal" />}
        actions={
          locationId ? (
            <DeleteButton
              variant="secondary"
              modalProps={{
                title: 'Delete User',
                children: (
                  <>You will be deleting this location from the system. This action cannot be undone.</>
                ),
              }}
              onModalConfirm={onDelete}
            >
              Delete
            </DeleteButton>
          ) : (
            <></>
          )
        }
      />
      <div className="main-content">
        <Panel
          title={locationId ? 'Edit location' : 'Create location'}
          actions={
            editing || !locationId ? (
              <>
                <Button
                  className="mr-1"
                  variant="secondary"
                  text="Cancel"
                  onClick={locationId ? () => cancelChanges() : goBack}
                />
                {locationId ? (
                  <Button variant="primary" text="Save" onClick={onSave} disabled={!locationName} />
                ) : (
                  <Button variant="primary" text="Create" onClick={onCreate} disabled={!locationName} />
                )}
              </>
            ) : (
              <Button variant="primary" text="Edit" onClick={toggleEditing} />
            )
          }
        >
          <div className="body">
            <div>
              <div className="required label mb-2">Name</div>
              {editing ? (
                <TextInput
                  className="input-field"
                  value={locationName}
                  onChange={(updatedName) => setLocationName(updatedName)}
                />
              ) : (
                <div>{locationName}</div>
              )}
            </div>
            <div className="my-6">
              <div className="label mb-2">Select Countries</div>
              {editing ? (
                <CountryTagList
                  className="input-field countries-input"
                  locale={LOCALE}
                  value={countries}
                  onChange={setCountries}
                />
              ) : (
                <div>{countryLabels.join(', ')}</div>
              )}
            </div>
          </div>
        </Panel>
      </div>
    </div>
  ) : null;
};

export default LocationsDetail;
