import React, { FC } from 'react';

import Button from '../../Button/Button';

import QuestionAnswer from './QuestionAnswer';

interface QuizCtaProps {
  ctaTemplate: any;
  onUpdateField: (property: string, val: any) => void;
}

const QuizCta: FC<QuizCtaProps> = ({ ctaTemplate, onUpdateField }) => {
  const addAnswer = (index) => {
    const currentQuestionAnswers = [...ctaTemplate.questionAnswers];
    const currentAnswers = currentQuestionAnswers[index].answers || [];

    currentQuestionAnswers[index] = {
      ...currentQuestionAnswers[index],
      answers: currentAnswers.concat(''),
    };
    onUpdateField('template.questionAnswers', currentQuestionAnswers);
  };

  const updateQuestion = (index, value) => {
    const currentQuestionAnswers = [...ctaTemplate.questionAnswers];

    currentQuestionAnswers[index].question = value;
    onUpdateField('template.questionAnswers', currentQuestionAnswers);
  };

  const updateAnswers = (index, updatedAnswers) => {
    const currentQuestionAnswers = [...ctaTemplate.questionAnswers];

    currentQuestionAnswers[index].answers = updatedAnswers;
    onUpdateField('template.questionAnswers', currentQuestionAnswers);
  };

  const deleteQuestion = (index) => {
    const currentQuestionAnswers = [...ctaTemplate.questionAnswers];

    currentQuestionAnswers.splice(index, 1);
    onUpdateField('template.questionAnswers', currentQuestionAnswers);
  };

  const correctAnswerChange = (correctAnswerIndex, questionIndex) => {
    const currentQuestionAnswers = [...ctaTemplate.questionAnswers];

    if (Number.isInteger(correctAnswerIndex) || !!correctAnswerIndex) {
      currentQuestionAnswers[questionIndex].correctAnswer = correctAnswerIndex;
    } else {
      delete currentQuestionAnswers[questionIndex].correctAnswer;
    }

    onUpdateField('template.questionAnswers', currentQuestionAnswers);
  };

  return (
    <>
      {ctaTemplate?.questionAnswers?.map(({ question, answers, correctAnswer }, index) => (
        <QuestionAnswer
          className="mb-5"
          key={index}
          showNoAnswerBlurb={false}
          question={question || ''}
          answers={answers || []}
          correctAnswer={correctAnswer || null}
          ctaType={ctaTemplate?.type?.toUpperCase()}
          onAddAnswer={() => addAnswer(index)}
          onUpdateQuestion={(value) => updateQuestion(index, value)}
          onUpdateAnswers={(updatedAnswers) => updateAnswers(index, updatedAnswers)}
          onDeleteAnswer={(updatedAnswers) => updateAnswers(index, updatedAnswers)}
          onDeleteQuestion={() => deleteQuestion(index)}
          onCorrectAnswerChange={(correctAnswerIndex) => correctAnswerChange(correctAnswerIndex, index)}
        />
      ))}
      <div className="full-width-grid-item mb-5">
        <Button
          variant="primary"
          text="+ Add Question"
          onClick={() => {
            const currentQuestionAnswers = ctaTemplate?.questionAnswers || [];

            onUpdateField('template.questionAnswers', currentQuestionAnswers.concat({ question: '' }));
          }}
        />
      </div>
    </>
  );
};

export default QuizCta;
