import React, { FC } from 'react';
import classnames from 'classnames';
import { Flex } from '@brightcove/studio-components';

import Table, { TableProps } from '../Table/Table';
import SideNavigationItem from '../SideNavigation/SideNavigationItem';
import SideNavigation from '../SideNavigation/SideNavigation';
import InputSearch from '../ControlledComponents/InputSearch';
import Button from '../Button/Button';

import GenericModal, { GenericModalProps } from './GenericModal';
import './AddItemModal.scss';

interface AddItemModalProps extends GenericModalProps, TableProps {
  onChangeFilter?: (val: string) => void;
  filters?: any[];
  searchPlaceholder?: string;
  onSearch: (val: string) => void;
  onAddItem?: () => void;
  addButtonText?: string;
  onCancel?: () => void;
}

const AddItemModal: FC<AddItemModalProps> = ({
  show,
  className,
  onClose,
  onCancel,
  onChangeFilter,
  onChangeSelection,
  onPaginationChange,
  onAddItem,
  filters,
  data,
  columns,
  searchPlaceholder,
  onSearch,
  onClickSort,
  addButtonText,
  title,
  pageCount,
  pageIndex,
  pageSize,
  hasPagination,
  singleSelection,
}) => {
  const handleCloseModal = () => {
    onCancel?.();
    onClose?.();
  };
  const handleChangeFilter = (param) => {
    if (onChangeFilter) {
      onChangeFilter(param);
    }
  };

  return (
    <GenericModal
      className={classnames('Add-item-modal', className)}
      show={show}
      title={title}
      onClose={handleCloseModal}
      buttons={
        <>
          <Button variant="secondary" text="Cancel" onClick={handleCloseModal} />
          <Button
            className="ml-3"
            variant="primary"
            text={addButtonText}
            onClick={() => {
              if (onAddItem) {
                onAddItem();
              }

              handleCloseModal();
            }}
          />
        </>
      }
    >
      <Flex className="Add-item-modal-content">
        {filters && (
          <SideNavigation
            onChange={handleChangeFilter}
            item={SideNavigationItem}
            filters={filters}
            defaultValue={filters.length > 0 ? filters[0].id : ''}
          />
        )}
        <Flex flexDirection="column" style={{ flex: '1' }}>
          <div className="sub-header">
            <InputSearch placeholder={searchPlaceholder || ''} onSearch={onSearch} />
          </div>
          <Table
            data={data}
            columns={columns}
            onClickSort={onClickSort}
            onChangeSelection={onChangeSelection}
            onPaginationChange={onPaginationChange}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            hasPagination={hasPagination}
            hasSelection
            singleSelection={singleSelection}
          />
        </Flex>
      </Flex>
    </GenericModal>
  );
};

export default AddItemModal;
