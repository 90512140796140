import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Flex, Icon, ICONS, Thumbnail } from '@brightcove/studio-components';

import AddContentItemModal from '../../../../components/Modals/AddContentItemModal';
import { withModal } from '../../../../components/Modals';
import TextInput from '../../../../components/ControlledComponents/TextInput';
import SimpleSelect from '../../../../components/ControlledComponents/SimpleSelect';
import Button from '../../../../components/Button/Button';

const filters = [
  { label: 'Filter', value: '' },
  { label: 'is exact match', value: 'exact' },
  { label: 'is not exact match', value: 'not_exact' },
  { label: 'contains', value: 'contains' },
  { label: 'doesn’t contain', value: 'not_contains' },
];

const AddContentItemButton = withModal(Button, AddContentItemModal);

const Rule = ({ index, isEditing, ruleData, onRuleUpdate, onDeleteRule }) => {
  const [rule, setRule] = useState(ruleData || {});
  const { id, filter, value, item } = rule;

  useEffect(() => {
    setRule(ruleData);
  }, [ruleData]);

  const getRuleFilterLabel = (value) => {
    return filters.filter((filter) => filter.value === value)[0]?.label;
  };

  const addItemToRule = (items: any[]) => {
    const itemInfo = _.pick(items[0], ['id', 'name', 'image', 'type']);
    const updatedRule = { ...rule, item: itemInfo };

    setRule(updatedRule);
    onRuleUpdate(updatedRule);
  };

  const onChange = (value, property) => {
    const updatedRule = { ...rule, [property]: value };

    setRule(updatedRule);
    onRuleUpdate(updatedRule);
  };

  return (
    <Flex className="rule" flexDirection="column">
      {!isEditing ? (
        <>
          <h4 className="rule-index">Rule {index + 1}</h4>
          <p className="rule-filter">{getRuleFilterLabel(filter)}</p>
        </>
      ) : (
        <>
          <Flex className="rule-header" alignItems="center">
            <h4 className="rule-index">Rule {index + 1}</h4>
            <Button className="rule-delete-btn" variant="link" onClick={() => onDeleteRule(id)}>
              <>
                <Icon className="minus-icon" name={ICONS.MINUS} />
                <span>Delete Rule</span>
              </>
            </Button>
          </Flex>
          <Flex className="rule-content">
            <div className="mr-8">
              <div className="panel-group">
                <h4 className="panel-label">Filter *</h4>
                <SimpleSelect
                  className="select-input-lg mr-2 mt-2"
                  property="filter"
                  value={filter}
                  options={filters as any}
                  onChange={onChange}
                />
              </div>
              <div className="panel-group">
                <h4 className="panel-label">Value</h4>
                <TextInput className="panel-text-input" property="value" value={value} onChange={onChange} />
              </div>
            </div>
            <Flex className="rule-item">
              {item ? (
                <>
                  <div className="panel-group">
                    <h4 className="panel-label">Content</h4>
                    <Thumbnail className="rule-item-thumbnail" src={item.image} />
                  </div>
                  <div className="panel-group">
                    <h4 className="panel-label">Name</h4>
                    <ul className="panel-rule-item-info">
                      <li className="rule-item-name mb-1">{item.name}</li>
                      <li className="rule-item-id mb-1">{item.id}</li>
                      <li className="rule-item-type">{item.type}</li>
                    </ul>
                  </div>
                </>
              ) : (
                <AddContentItemButton
                  className="mb-5 mt-2 add-menu-item-button"
                  variant="primary"
                  modalProps={{
                    title: 'Add content item',
                    excludeFilters: ['ItemSubType:AdContent'],
                    singleSelection: true,
                    onAddItems: addItemToRule,
                  }}
                >
                  + Add Content Item
                </AddContentItemButton>
              )}
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default Rule;
