import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import EditPanel from '../../../components/Panel/EditPanel';
import TextInput from '../../../components/ControlledComponents/TextInput';
import './SectionInformation.scss';

const SectionInformation = ({
  panelId,
  section: sectionProp,
  disableEditing,
  toggleDisableEditing,
  onCancel,
  onSave,
  includePlayerCustomization = false,
}) => {
  const [section, setSection] = useState(sectionProp || {});

  useEffect(() => {
    setSection(sectionProp);
  }, [sectionProp]);

  const onUpdateField = (field, value) => {
    setSection({
      ...section,
      [field]: value,
    });
  };

  const cancelChanges = () => {
    setSection(sectionProp);
    onCancel();
  };

  return (
    <EditPanel
      id={panelId}
      className="Section-information"
      title="Section Information"
      disableEditing={disableEditing}
      onEnableEditing={toggleDisableEditing}
      onCancel={cancelChanges}
      save={() => onSave(_.pick(section, ['name', 'playerId']))}
    >
      {(isEditing) => (
        <div className="pl-5 pt-4 pb-8 panel-body">
          <div className="mr-4">
            <div className="panel-label">Name</div>
            {isEditing ? (
              <TextInput
                className="mt-1"
                value={section.name || ''}
                onChange={(value) => onUpdateField('name', value)}
              />
            ) : (
              <div className="panel-text">{section.name}</div>
            )}
          </div>
          <div className="mr-4">
            <div className="panel-label">ID</div>
            <div className="panel-text">{section.id}</div>
          </div>
          <div>
            <div className="panel-label">Type</div>
            <div className="panel-text">{section.type.match(/[A-Z][a-z]+/g).join(' ')}</div>
          </div>
          {includePlayerCustomization && (
            <div className="mt-4">
              <div className="panel-label">Player ID</div>
              {isEditing ? (
                <TextInput
                  className="mt-1"
                  value={section?.playerId}
                  onChange={(value) => onUpdateField('playerId', value)}
                />
              ) : (
                <div className="panel-text">{section?.playerId}</div>
              )}
            </div>
          )}
        </div>
      )}
    </EditPanel>
  );
};

export default SectionInformation;
