import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import { Flex } from '@brightcove/studio-components';
import ReactPlayerLoader from '@brightcove/react-player-loader';

import { formatDate } from '../../utils/format';
import config from '../../config';
import './ReactPlayer.scss';

interface ReactPlayerProps {
  accountId: string | undefined;
  playerId?: string;
  contentId?: string | undefined;
  eventId?: string | undefined;
  videoId?: string | undefined;
  playlistId?: string;
  onSuccess?: (val: any) => void;
  reloadPlayer?: boolean;
  small?: boolean;
  startDate?: string;
}

const ReactPlayer: FC<ReactPlayerProps> = ({
  accountId,
  playerId,
  contentId,
  videoId,
  playlistId,
  eventId,
  onSuccess,
  reloadPlayer,
  small,
  startDate,
}) => {
  const user = useSelector((store: any) => store.user);
  const containerClassName = `ReactPlayer-container ${small ? 'small' : 'standard'}`;
  const isAvailable = !startDate || startDate < new Date().toISOString();

  if (!isAvailable) {
    return (
      <Flex justifyContent="center" className={containerClassName}>
        <div className="ReactPlayer-unavailable">
          Video scheduled to be published on {formatDate(startDate)}. Please review the video in Bynder if
          necessary.
        </div>
      </Flex>
    );
  }

  return (
    <Flex justifyContent="center" className={containerClassName}>
      <ReactPlayerLoader
        attrs={{
          className: 'ReactPlayer',
        }}
        accountId={accountId || config.account_id}
        playerId={playerId || config.player_id}
        playlistId={playlistId}
        videoId={!playlistId ? videoId : ''}
        reloadPlayer={reloadPlayer}
        options={{
          autoplay: false,
        }}
        onEmbedCreated={(embed) => {
          embed.setAttribute('data-is-admin', 'true');

          if (eventId) {
            embed.setAttribute('data-event-id', eventId);
          }

          if (contentId) {
            embed.setAttribute('data-content-id', contentId);
          }

          user?.id && embed.setAttribute('data-user-id', user?.id);
        }}
        onSuccess={onSuccess}
        onFailure={(e) => {
          // eslint-disable-next-line no-console
          console.log('Player Error: ', e);
        }}
      />
    </Flex>
  );
};

export default ReactPlayer;
