import React, { FC } from 'react';
import { Flex } from '@brightcove/studio-components';

import { ContentData } from '../../../interfaces/Content';
import Panel from '../../../components/Panel/Panel';

import PanelRowUrl from './components/PanelRowUrl';

interface ContentMoviesProps {
  idPanel: string;
  data: ContentData;
  isSeries?: boolean;
}

const ContentMovie: FC<ContentMoviesProps> = ({ idPanel, data }) => {
  const { id, extId, brightcoveVideoId, source, publicUrl } = data;

  const netAppUrl = publicUrl ? `${publicUrl}video-detail/${id}` : null;
  const videocloudUrl =
    !!brightcoveVideoId &&
    `https://studio.brightcove.com/products/videocloud/media/video-details/${brightcoveVideoId}/overview`;

  return (
    <div id={idPanel} className="Content">
      <Panel title="Content" actions={<></>}>
        <>
          <Flex>
            <div>
              <div className="ml-4">
                <p className="title">ID</p>
                <p className="description">{id}</p>
              </div>

              <PanelRowUrl title="Video ID" label={brightcoveVideoId} url={videocloudUrl} />

              <div className="ml-4 mt-6">
                <p className="title">Source</p>
                <p className="description">{source ? source : '-'}</p>
              </div>

              <PanelRowUrl title="Reference ID" label={extId} url={videocloudUrl} />

              <PanelRowUrl title="Public URL" label={netAppUrl} url={netAppUrl} />
            </div>
          </Flex>
        </>
      </Panel>
    </div>
  );
};

export default ContentMovie;
