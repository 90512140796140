import { createStore as createReduxStore } from 'redux';

import { GenericAction, StoreState } from './types';
import { initialState as defaultState, reducer } from './reducers';
import withStore from './mappers';

export default function createStore(initialState) {
  const state = { ...defaultState, ...initialState };

  return createReduxStore<StoreState, GenericAction, any, any>(
    reducer,
    state,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
}

export { withStore };
