import { useEffect, useState, useRef } from 'react';
import moment from 'moment';

import { getCtaStatus } from '../utils';

export const useGetCtaStatus = (startTime: string, endTime: string) => {
  const interval = useRef<any>();
  const [status, setStatus] = useState(getCtaStatus(startTime, endTime));

  useEffect(() => {
    interval.current = setInterval(() => setStatus(getCtaStatus(startTime, endTime)), 1000);

    return () => {
      clearInterval(interval.current);
    };
  }, [startTime, endTime]);
  useEffect(() => {
    if (!status || moment().isAfter(endTime)) {
      clearInterval(interval.current);
    }
  }, [status]);

  return status;
};
