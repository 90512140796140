import React, { FC } from 'react';
import classnames from 'classnames';
import { Modal } from '@brightcove/studio-components';
import './GenericModal.scss';

export interface GenericModalProps {
  show: boolean;
  className?: string;
  children: JSX.Element;
  buttons?: JSX.Element;
  onClose?: () => void;
  title?: string;
  appElement?: string | HTMLElement;
}
const GenericModal: FC<GenericModalProps> = ({
  children,
  show,
  className,
  buttons,
  onClose,
  title,
  appElement,
}) => {
  const closeHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Modal
        appElement={appElement || document.getElementById('root') || undefined}
        className={classnames(`Generic-modal-create-modal`, className)}
        titleClassName={classnames(`Generic-modal-create-title`, `${className}-title`)}
        headerClassName={classnames(`Generic-modal-create-header`, `${className}-header`)}
        isOpen={show}
        shrink={true}
        buttons={buttons}
        title={title ? title : undefined}
        onClose={closeHandler}
      >
        {children}
      </Modal>
    </>
  );
};

export default GenericModal;
