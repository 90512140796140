import React, { FC, useEffect, useState } from 'react';
import { Flex } from '@brightcove/studio-components';

import { getMessageByLanguage, fillMissingLanguages, hasValue } from '../../utils';
import { LanguageData, ScheduledTextPanelData, StatusData } from '../../interfaces/ScheduledTextPanel';
import Panel from '../../components/Panel/Panel';
import SimpleSelect from '../../components/ControlledComponents/SimpleSelect';
import { RadioGroupDatePicker } from '../../components/ControlledComponents/RadioGroupDatePicker';
import { RadioGroup } from '../../components/ControlledComponents/RadioGroup';
import Button from '../../components/Button/Button';
import 'react-widgets/scss/styles.scss';

interface MaintenanceModeCreateProps extends ScheduledTextPanelData {
  setShowCreateModal?: (val: boolean) => void;
  showDeleteModal?: (val: boolean, id: string) => void;
  onCreate: (val: ScheduledTextPanelData, id?: string) => void;
  setEditingId: (val: string) => void;
  languages: LanguageData[];
}

const MaintenanceModeCreate: FC<MaintenanceModeCreateProps> = ({
  id = '',
  status,
  startTime,
  endTime,
  messages,
  setEditingId,
  onCreate,
  setShowCreateModal,
  showDeleteModal,
  languages,
}) => {
  const editing = !!id.length;
  const [currentStartTime, setCurrentStartTime] = useState<string | null>(startTime);
  const [currentEndTime, setCurrentEndTime] = useState<string | null>(endTime);
  const [currentStatus, setCurrentStatus] = useState<'Active' | 'Inactive'>(editing ? status : 'Inactive');
  const [currentMessages, setCurrentMessages] = useState(
    fillMissingLanguages(editing ? messages : [], languages)
  );
  const [currentLanguage, setCurrentLanguage] = useState(editing ? messages[0]?.language : 'en-us');
  const [selectedMessageValue, setSelectedMessageValue] = useState('');

  useEffect(() => {
    const messageValue = getMessageByLanguage(currentLanguage, currentMessages)?.value;

    setSelectedMessageValue(messageValue?.length ? messageValue : '');
  }, [currentLanguage, currentMessages]);

  const updateMessagesByLanguage = (value) => {
    return currentMessages.map((message) => {
      if (message.language === currentLanguage) {
        message.value = value;
      }

      return message;
    });
  };

  const createMaintenanceMode = () => {
    const startTime = currentStartTime && new Date(currentStartTime);
    const endTime = currentEndTime && new Date(currentEndTime);
    onCreate(
      {
        status: currentStatus,
        startTime: startTime ? new Date(startTime).toISOString() : null,
        endTime: endTime ? new Date(endTime).toISOString() : null,
        messages: currentMessages.filter((message) => message.value),
      },
      id
    );
  };

  return (
    <Panel
      title={<p>Maintenance Mode</p>}
      actions={
        <>
          {editing ? (
            <>
              <SimpleSelect
                className="select-input-lg mr-2"
                value={currentLanguage}
                options={languages as any}
                onChange={(value) => setCurrentLanguage(value)}
              />
              <Button
                variant="secondary"
                text="Cancel"
                onClick={() => {
                  setEditingId('');
                }}
              />
              <Button
                variant="primary"
                text="Save"
                onClick={createMaintenanceMode}
                disabled={!hasValue(currentMessages)}
              />
              <Button
                variant="secondary"
                text="Delete"
                onClick={() => {
                  if (editing && showDeleteModal) {
                    showDeleteModal(true, id);
                  }
                }}
              />
            </>
          ) : (
            <>
              <SimpleSelect
                className="select-input-lg mr-2"
                value={currentLanguage}
                options={languages as any}
                onChange={(value) => setCurrentLanguage(value)}
              />
              <Button
                variant="secondary"
                text="Cancel"
                onClick={() => {
                  if (setShowCreateModal) {
                    setShowCreateModal(false);
                  }

                  setEditingId('');
                }}
              />
              <Button
                variant="primary"
                text="Create"
                disabled={!hasValue(currentMessages)}
                onClick={createMaintenanceMode}
              />
            </>
          )}
        </>
      }
    >
      <>
        <Flex style={{ justifyContent: 'space-between' }}>
          <Flex style={{ flexDirection: 'column', flex: 3 }}>
            <Flex>
              {editing ? (
                <div className="Maintenance-mode-create">
                  <p className="Maintenance-mode-create-title">Resource ID</p>
                  <p className="Maintenance-mode-create-content">{id}</p>
                </div>
              ) : null}
              <div className="Maintenance-mode-create">
                <p className="Maintenance-mode-create-title">Status</p>
                <RadioGroup
                  value={currentStatus}
                  options={[
                    { label: 'Active', value: 'Active' },
                    { label: 'Inactive', value: 'Inactive' },
                  ]}
                  onChange={(value) => {
                    setCurrentStatus(value as StatusData);
                  }}
                />
              </div>
            </Flex>
            <Flex>
              <div className="Maintenance-mode-create">
                <p className="Maintenance-mode-create-title">Message</p>
                <textarea
                  className="Maintenance-mode-create-body"
                  value={selectedMessageValue}
                  onChange={(e) => {
                    setSelectedMessageValue(e.target.value);
                    setCurrentMessages(updateMessagesByLanguage(e.target.value));
                  }}
                ></textarea>
              </div>
            </Flex>
          </Flex>
          <Flex style={{ flexDirection: 'column', flex: 1 }}>
            <p className="Maintenance-mode-create-title">Time Frame</p>
            <p className="Maintenance-mode-create-subtitle">Schedule Start time</p>
            <RadioGroupDatePicker
              date={currentStartTime || ''}
              maxDate={currentEndTime || ''}
              label="Available Immediately"
              editing={editing}
              onChangeDatePicker={(val) => {
                setCurrentStartTime(val);
              }}
            />
            <p className="Maintenance-mode-create-subtitle pt-3">Schedule End time</p>
            <RadioGroupDatePicker
              date={currentEndTime || ''}
              minDate={currentStartTime || ''}
              label="Available Indefinitely"
              editing={editing}
              onChangeDatePicker={(val) => {
                setCurrentEndTime(val);
              }}
            />
          </Flex>
        </Flex>
      </>
    </Panel>
  );
};
export default MaintenanceModeCreate;
