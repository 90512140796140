import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import './UserManagementDetail.scss';
import { getUrlWithOptions, isSuperAdmin } from '../../utils';
import { useApi } from '../../hooks/useApi';
import SubHeader from '../../components/SubHeader/SubHeader';
import Panel from '../../components/Panel/Panel';
import { DeleteConfirmationModal, withModal } from '../../components/Modals';
import Loading from '../../components/Loading/Loading';
import SimpleSelect from '../../components/ControlledComponents/SimpleSelect';
import Button from '../../components/Button/Button';

const DeleteButton = withModal(Button, DeleteConfirmationModal);

const UserManagementView = () => {
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const { apiGet, apiPatch, apiDelete, isLoading } = useApi();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [roleOptions, setRoleOptions] = useState<any[]>([]);
  const [user, setUser] = useState<any>({});
  const [isSuperAdminUser, setIsSuperAdminUser] = useState(false);

  useEffect(() => {
    (async () => {
      const [{ data: rolesData }, { data: userData }] = await Promise.all([
        apiGet(getUrlWithOptions('/basic/roles')),
        apiGet(getUrlWithOptions(`/users/${userId}`)),
      ]);

      if (userData) {
        setUser(userData);
        setIsSuperAdminUser(userData.role && isSuperAdmin({ name: userData.role }));
      }

      if (rolesData) {
        setRoleOptions(
          rolesData.reduce((newArr, role) => {
            return !isSuperAdmin(role) ? [...newArr, { label: role.name, value: role.id }] : newArr;
          }, [])
        );
      }
    })();
  }, []);

  const onUpdateSelectedRole = (role) => setSelectedRole(role);

  const onSaveRole = async () => {
    if (selectedRole) {
      const { data } = await apiPatch(getUrlWithOptions(`/users/${userId}`), {
        body: { roleId: selectedRole },
      });

      if (data) {
        setIsEditing(false);
        setSelectedRole('');
        setUser({ ...user, role: data.role });
      }
    }
  };

  const onDeleteUser = async () => {
    const { error } = await apiDelete(
      getUrlWithOptions(`/users/?ids=${encodeURIComponent(userId as string)}`)
    );

    if (!error) {
      goBack();
    }
  };

  const goBack = () => navigate(`../${ROUTES.USER_MANAGEMENT}`);

  const getRoleValue = () => roleOptions.find((role) => role.label === user.role)?.value;

  return isLoading ? (
    <Loading />
  ) : (
    <div className="User-management-view">
      <SubHeader
        title={`${user.firstName} ${user.lastName}`}
        subtitle="Back to User Management"
        className="pl-5 pr-4"
        actions={
          !isSuperAdminUser ? (
            <DeleteButton
              disabled={user.deleted}
              variant="primary"
              modalProps={{
                title: 'Delete User',
                children: (
                  <>
                    You will be deleting {user.firstName} {user.lastName} from the system. This action cannot
                    be undone.
                  </>
                ),
              }}
              onModalConfirm={onDeleteUser}
            >
              Delete
            </DeleteButton>
          ) : (
            <></>
          )
        }
        detailMode={true}
        onBack={goBack}
        icon={<Icon name={ICONS.ARROW_RIGHT} flip="horizontal" />}
      />

      <div className="main-content">
        <Panel
          title="Personal Information"
          actions={
            !isSuperAdminUser ? (
              isEditing ? (
                <>
                  <Button variant="secondary" text="Cancel" onClick={() => setIsEditing(false)} />
                  <Button variant="primary" text="Save" onClick={onSaveRole} />
                </>
              ) : (
                <Button variant="primary" text="Edit" onClick={() => setIsEditing(true)} />
              )
            ) : (
              <></>
            )
          }
        >
          <div className="body">
            <Flex>
              <div className="mr-12">
                <p className="title">First Name</p>
                <p className="content">{user.firstName}</p>
              </div>
              <div className="ml-12">
                <p className="title">Last Name</p>
                <p className="content">{user.lastName}</p>
              </div>
            </Flex>
            <div>
              <p className="title">Email Address</p>
              <p className="content">{user.email}</p>
            </div>
            <Flex>
              <div>
                <p className="title">Role</p>
                {isEditing ? (
                  <SimpleSelect
                    className="select-input-lg"
                    value={getRoleValue()}
                    options={roleOptions}
                    onChange={onUpdateSelectedRole}
                  />
                ) : (
                  <p>{user.role}</p>
                )}
              </div>
            </Flex>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default UserManagementView;
