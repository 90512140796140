import React, { FC } from 'react';
import './SideNavigationItem.scss';
import classnames from 'classnames';

interface SideNavigationItemProps {
  selected?: boolean;
  icon?: JSX.Element;
  name: string;
  id: string;
  onClick?: (e: any) => void;
}

const SideNavigationItem: FC<SideNavigationItemProps> = ({ id, selected, icon, name, onClick }) => {
  return (
    <div
      onClick={onClick}
      id={id}
      className={classnames('Side-navigation-item', selected ? 'Side-navigation-item-selected' : '')}
    >
      {icon ? (
        <span className={selected ? 'Side-navigation-item-icon-selected' : 'Side-navigation-item-icon'}>
          {icon}
        </span>
      ) : null}
      <p>{name}</p>
    </div>
  );
};

export default SideNavigationItem;
