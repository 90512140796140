import { Link } from 'react-router-dom';
import React, { FC } from 'react';
import { Flex } from '@brightcove/studio-components';

import * as ROUTES from '../../routes';
import { ContentData } from '../../../interfaces/Content';
import Panel from '../../../components/Panel/Panel';

import PanelRowUrl from './components/PanelRowUrl';

interface ContentEpisodesProps {
  idPanel: string;
  data: ContentData;
  isSeries?: boolean;
}

const ContentEpisode: FC<ContentEpisodesProps> = ({ idPanel, data }) => {
  const { id, extId, brightcoveVideoId, source, publicUrl, series, season } = data;

  const netAppUrl = `${publicUrl}video-detail/${id}`;
  const videocloudUrl =
    !!brightcoveVideoId &&
    `https://studio.brightcove.com/products/videocloud/media/video-details/${brightcoveVideoId}/overview`;

  return (
    <div id={idPanel} className="Content">
      <Panel title="Content" actions={<></>}>
        <>
          <Flex>
            <div>
              <div className="ml-4">
                <p className="title">ID</p>
                <p className="description">{id}</p>
              </div>

              <PanelRowUrl title="Video ID" label={brightcoveVideoId} url={videocloudUrl} />

              <div className="ml-4 mt-6">
                <p className="title">Source</p>
                <p className="description">{source ? source : '-'}</p>
              </div>

              <div className="ml-4 mt-6">
                <p className="title">Series</p>
                {series?.name?.length ? (
                  <p className="link">
                    <Link to={`${ROUTES.CONTENT_MANAGEMENT}/edit/series/${series.id}`}>{series.name}</Link>
                  </p>
                ) : (
                  '-'
                )}
              </div>

              <PanelRowUrl title="Reference ID" label={extId} url={videocloudUrl} />

              <PanelRowUrl title="Public URL" label={netAppUrl} url={netAppUrl} />

              <div className="ml-4 mt-6">
                <p className="title">Season</p>
                {season?.name?.length ? (
                  <p className="link">
                    <Link to={`${ROUTES.CONTENT_MANAGEMENT}/edit/season/${season.id}`}>{season.name}</Link>
                  </p>
                ) : (
                  '-'
                )}
              </div>
            </div>
          </Flex>
        </>
      </Panel>
    </div>
  );
};

export default ContentEpisode;
