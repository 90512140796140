import React, { FC, useEffect, useState } from 'react';
import { AutosaveTagList } from '@brightcove/studio-components';

import { renderArrayOfStrings } from '../../../utils/format';
import { getUrlWithOptions } from '../../../utils';
import { InformationData } from '../../../interfaces/Content';
import { useApi } from '../../../hooks/useApi';
import Panel from '../../../components/Panel/Panel';
import TextInput from '../../../components/ControlledComponents/TextInput';
import TextArea from '../../../components/ControlledComponents/TextArea';
import SimpleSelect from '../../../components/ControlledComponents/SimpleSelect';
import Button from '../../../components/Button/Button';
import './Information.scss';

interface InformationProps {
  idPanel?: string;
  data: InformationData;
  disabledEditing?: boolean;
  onEditModeChange?: (val: boolean, isSavedData?: boolean) => void;
}

const youtubePrivacyOptions = ['private', 'public', 'unlisted'];

const Information: FC<InformationProps> = ({ idPanel, data, disabledEditing, onEditModeChange }) => {
  const { apiPatch, isLoading } = useApi();

  const {
    id,
    syndicationDescription,
    campaignCode,
    sessionCode,
    sessionId,
    sessionJourney,
    sessionTimeId,
    translationStatus,
    genre,
    tags,
    industrySector,
    youtubePrivacy,
    ticket,
    productionYear,
  } = data;
  const [editing, setEditing] = useState<boolean>(false);
  const [currentTicket, setCurrentTicket] = useState('');
  const [description, setDescription] = useState<string>('');
  const [currentGenre, setCurrentGenre] = useState<string[]>([]);
  const [currentTags, setCurrentTags] = useState<string[]>([]);
  const [tagsState, setTagsState] = useState<any>({});
  const [youtubePrivacyOption, setYoutubePrivacyOption] = useState(youtubePrivacy || 'public');
  const [productionYearOption, setProductionYearOption] = useState(productionYear || undefined);

  useEffect(() => {
    if (data) {
      setCurrentTicket(ticket);
      setDescription(syndicationDescription);
      setCurrentGenre(Array.isArray(genre) ? genre : [genre]);
      setCurrentTags(tags?.length ? tags : []);
    }
  }, [data]);

  const onSaveInformation = async () => {
    const body = {
      genre: currentGenre,
      syndicationDescription: description,
      ticket: currentTicket,
      tags: currentTags,
      youtubePrivacy: youtubePrivacyOption,
      productionYear: productionYearOption,
    };
    const response = await apiPatch(getUrlWithOptions(`/content/${id}`), {
      body,
    });

    if (response?.data) {
      setEditing(false);
      onEditModeChange?.(!disabledEditing, true);
    }
  };

  const restoreData = () => {
    const { syndicationDescription, genre, tags, ticket } = data;

    setCurrentTicket(ticket);
    setDescription(syndicationDescription);
    setCurrentGenre([genre]);
    setCurrentTags(tags?.length ? tags : []);
    setYoutubePrivacyOption(youtubePrivacy || 'public');
    setProductionYearOption(productionYear);
  };

  const productionYearHandler = (year: string): void => {
    setProductionYearOption(Number(year));
  };

  return (
    <div id={idPanel} className="Information w-100">
      <Panel
        title="Information"
        actions={
          <>
            {editing ? (
              <>
                <Button
                  variant="secondary"
                  text="Cancel"
                  onClick={() => {
                    restoreData();
                    setEditing(false);
                    onEditModeChange?.(!disabledEditing);
                  }}
                />
                <Button variant="primary" text="Save" onClick={onSaveInformation} disabled={isLoading} />
              </>
            ) : (
              <Button
                variant="primary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);
                  onEditModeChange?.(!disabledEditing);
                }}
                disabled={disabledEditing}
              />
            )}
          </>
        }
      >
        <div className="Information-panel-content">
          {editing ? (
            <div>
              <div className="ml-4">
                <p className="title">Youtube Privacy</p>
                <SimpleSelect
                  className="select-input-sm mr-2 mt-2"
                  property="type"
                  value={youtubePrivacyOption}
                  options={youtubePrivacyOptions}
                  onChange={(value) => setYoutubePrivacyOption(value)}
                />
              </div>
              <div className="ml-4 mt-6">
                <p className="title">Production Year</p>
                <TextInput
                  value={productionYearOption || ''}
                  type="number"
                  onChange={productionYearHandler}
                />
              </div>
              <div className="ml-4 mt-6">
                <p className="title">Ticket</p>
                <TextInput value={currentTicket?.length ? currentTicket : ''} onChange={setCurrentTicket} />
              </div>
              <div className="ml-4 mt-6">
                <p className="title">Syndication Description</p>
                <TextArea value={description?.length ? description : ''} onChange={setDescription} />
              </div>
              <div className="ml-4 mt-6 genre">
                <p className="title">Genre</p>
                <TextInput value={currentGenre[0]} onChange={(value) => setCurrentGenre([value])} />
              </div>
              <div className="ml-4 mt-4 tags">
                <AutosaveTagList
                  className="AutoCompleteTags"
                  fieldId="locationControlsField"
                  label="Tags"
                  value={currentTags}
                  editing={tagsState.editing || false}
                  saving={tagsState.saving || false}
                  saved={tagsState.saved || false}
                  onStartEditing={() => {
                    setTagsState({ editing: true });
                  }}
                  onCancel={() => setTagsState({ editing: false })}
                  onSave={(value) => {
                    setTagsState({ saved: true });
                    setCurrentTags(value);
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="ml-4">
              <div>
                <p className="title">Youtube privacy</p>
                <p className="description">{youtubePrivacyOption}</p>
              </div>
              <div className="mt-6">
                <p className="title">Ticket</p>
                <p className="description">{currentTicket?.length ? currentTicket : '-'}</p>
              </div>
              <div className="mt-6">
                <p className="title">Production Year</p>
                <p className="description">{productionYearOption || '-'}</p>
              </div>
              <div className="mt-6">
                <p className="title">Session Code</p>
                <p className="description">{sessionCode?.length ? sessionCode : '-'}</p>
              </div>
              <div className="mt-6">
                <p className="title">Session ID </p>
                <p className="description">{sessionId?.length ? sessionId : '-'}</p>
              </div>
              <div className="mt-6">
                <p className="title">Session Journey </p>
                <p className="description">{sessionJourney?.length ? sessionJourney : '-'}</p>
              </div>
              <div className="mt-6">
                <p className="title">Session Time ID </p>
                <p className="description">{sessionTimeId?.length ? sessionTimeId : '-'}</p>
              </div>
              <div className="mt-6">
                <p className="title">Syndication description</p>
                <p className="description">{description?.length ? description : '-'}</p>
              </div>
              <div className="mt-6">
                <p className="title">Translation Status</p>
                <p className="description">{translationStatus?.length ? translationStatus : '-'}</p>
              </div>
              <div className="mt-6">
                <p className="title">Industry sector</p>
                <p className="description">{renderArrayOfStrings(industrySector)}</p>
              </div>
              <div className="mt-6">
                <p className="title">Genre</p>
                <p className="description">{renderArrayOfStrings(currentGenre)}</p>
              </div>
              <div className="mt-6">
                <p className="title">Tags</p>
                <p className="description">{renderArrayOfStrings(currentTags)}</p>
              </div>
              <div className="mt-6">
                <p className="title">Campaign code</p>
                <p className="description">{campaignCode?.length ? campaignCode : '-'}</p>
              </div>
            </div>
          )}
        </div>
      </Panel>
    </div>
  );
};

export default Information;
