import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';

import { secondsToTimeString } from '../../utils/format';
import { CuePointData } from '../../interfaces/Content';

import CuePoint from './CuePoint';
import './CuePointsBar.scss';

interface CuePointsBarProps {
  cuePoints: CuePointData[];
  duration: number;
  className: string;
  selectedTime: number;
  onCuePointSelected: (val: any) => void;
  onChangeTime: (val: number) => void;
}

const CuePointsBar: FC<CuePointsBarProps> = ({
  cuePoints,
  duration,
  className,
  selectedTime,
  onCuePointSelected,
  onChangeTime,
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [currentCuePoints, setCurrentCuePoints] = useState<CuePointData[] | null>(null);

  const handleProgressBarClick = (event) => {
    const progressBar = event.currentTarget;
    const clickedTime =
      ((event.clientX - progressBar.getBoundingClientRect().left) / progressBar.offsetWidth) * duration;

    setCurrentTime(Math.round(clickedTime));
    // This selected time is needs to change the currentTime of player
    onChangeTime(Math.round(clickedTime));
  };

  useEffect(() => {
    if (cuePoints?.length) {
      setCurrentCuePoints(cuePoints);
    }
  }, [cuePoints]);
  // Set the time from the CuePoints Panel (onChange hh:mm:ss)
  useEffect(() => {
    if (selectedTime <= duration) {
      setCurrentTime(selectedTime);
    }
  }, [selectedTime]);

  return (
    <div className={classnames(`CuePointsBar`, className)}>
      <div className="CuePointsBar-container">
        <div onClick={handleProgressBarClick} className="progressBar">
          <div style={{ width: `${(currentTime / duration) * 100}%` }} className="progress"></div>
        </div>
        <div style={{ position: 'relative' }}>
          {currentCuePoints?.map((cuePoint) => (
            <CuePoint
              key={cuePoint.time}
              time={cuePoint.time}
              duration={duration}
              name={cuePoint.name}
              onCuePointSelected={onCuePointSelected}
            />
          ))}
        </div>
        <div className="time-indicator">
          <p>{secondsToTimeString(0)}</p>
          <p>{secondsToTimeString(duration)}</p>
        </div>
      </div>
    </div>
  );
};

export default CuePointsBar;
