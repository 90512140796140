import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import { DeleteConfirmationModal, withModal } from '../Modals';
import { RadioGroupDatePicker } from '../ControlledComponents/RadioGroupDatePicker';
import Button from '../Button/Button';
import { formatDate } from '../../utils/format';
import { getUrlWithOptions } from '../../utils';
import { usePrevious } from '../../hooks/usePrevious';
import { useApi } from '../../hooks/useApi';
import './PublicationStateButtons.scss';

interface Publication {
  publishing?: boolean;
  unpublishedChanges?: boolean;
  canRevert?: boolean;
  scheduledPublishAt?: string | undefined | null;
}

interface PublicationStateButtonsProps {
  id?: string;
  disabled?: boolean;
  publication?: Publication | undefined | null;
  isPublishing?: boolean;
  showUnpublishedChangesWarning?: boolean;
  unscheduleBySelection?: boolean;
  allowPreview?: boolean;
  allowRevert?: boolean;
  allowDelete?: boolean;
  showPublishButton?: boolean;
  showUnscheduleButton?: boolean;
  onPublish?: (val: any) => void;
  onRevert?: () => void;
  onUnschedule?: () => void;
  onDelete?: () => void;
}

const RevertButton = withModal(Button, DeleteConfirmationModal);
const PublishButton = withModal(Button, DeleteConfirmationModal);
const DeleteButton = withModal(Button, DeleteConfirmationModal);

const PublicationStateButtons: FC<PublicationStateButtonsProps> = ({
  disabled,
  publication = {},
  isPublishing,
  showUnpublishedChangesWarning = true,
  onPublish,
  onRevert,
  onUnschedule,
  onDelete,
  allowPreview,
  allowRevert,
  allowDelete = false,
  showPublishButton = false,
  showUnscheduleButton = false,
  unscheduleBySelection = false,
  id = null,
}) => {
  const { apiPut } = useApi();
  const [publicationInfo, setPublicationInfo] = useState<any>(publication || {});
  const { unpublishedChanges, canRevert, scheduledPublishAt } = publicationInfo || {};
  const [publishDate, setPublishDate] = useState<string | null | undefined>(scheduledPublishAt);
  const prevPublicationInfo = usePrevious(publicationInfo);

  useEffect(() => {
    if (prevPublicationInfo && !_.isEqual(prevPublicationInfo, publication)) {
      setPublicationInfo(publication);
    }
  }, [publication, prevPublicationInfo]);

  const onPreview = async () => {
    const { data } = await apiPut(getUrlWithOptions('/items/preview_link'), {
      body: { id },
    });

    window.open(data.url);
  };

  return _.isEmpty(publicationInfo) && !showPublishButton ? (
    <></>
  ) : (
    <div className="Publication-state-buttons">
      {(unpublishedChanges || showPublishButton) && (
        <>
          {(scheduledPublishAt && !isPublishing) || showUnscheduleButton ? (
            <>
              {!unscheduleBySelection && (
                <span className="scheduled-date-time">Scheduled for {formatDate(scheduledPublishAt)}</span>
              )}

              {allowRevert && canRevert && (
                <Button
                  className="mr-1"
                  disabled={disabled}
                  variant="danger"
                  text="Revert"
                  onClick={() => onRevert?.()}
                />
              )}
              <Button
                className="mr-1"
                disabled={disabled}
                variant="secondary"
                text="Unschedule"
                onClick={() => onUnschedule?.()}
              />
              {allowPreview && (
                <Button
                  className="mr-1"
                  disabled={disabled}
                  variant="secondary-invert"
                  text="Preview"
                  onClick={() => onPreview?.()}
                />
              )}
            </>
          ) : (
            <Flex alignItems="center">
              {showUnpublishedChangesWarning && (
                <Flex className="unpublished-changes-warning" alignItems="center">
                  <Icon name={ICONS.WARNING} />
                  <span>Unpublished changes</span>
                </Flex>
              )}
              {allowRevert && canRevert && (
                <RevertButton
                  className="mr-1"
                  disabled={disabled}
                  variant="danger"
                  modalProps={{
                    title: 'Revert',
                    confirmBtnLabel: 'Revert',
                    children: (
                      <>You will be reverting all the unpublished changes. This action cannot be undone.</>
                    ),
                  }}
                  onModalConfirm={() => onRevert?.()}
                >
                  Revert
                </RevertButton>
              )}
              {isPublishing && <span className="mr-6">Publishing...</span>}
              <PublishButton
                className="mr-1"
                variant="primary"
                disabled={isPublishing || disabled}
                modalProps={{
                  title: 'Publish',
                  confirmBtnLabel: 'Publish',
                  children: (
                    <div className="publish-modal-content">
                      <p>You can publish right now or schedule it for later.</p>
                      <RadioGroupDatePicker
                        placeholder="Select date and time"
                        date={publishDate || ''}
                        editing={true}
                        label="Publish Now"
                        onChangeDatePicker={(val) => {
                          setPublishDate(val);
                        }}
                      />
                    </div>
                  ),
                }}
                onModalConfirm={() => onPublish?.(publishDate)}
              >
                Publish
              </PublishButton>

              {allowPreview && (
                <Button
                  className="mr-1"
                  disabled={disabled}
                  variant="secondary-invert"
                  text="Preview"
                  onClick={() => onPreview?.()}
                />
              )}
            </Flex>
          )}
        </>
      )}

      {allowDelete && (
        <DeleteButton
          variant="danger"
          text="Delete"
          modalProps={{
            title: 'Important',
            children: <>You will be deleting this content. This action cannot be undone.</>,
          }}
          onModalConfirm={onDelete}
        />
      )}
    </div>
  );
};

export default PublicationStateButtons;
