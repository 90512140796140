import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState } from 'react';

import { renameObjectKey } from '../../../utils';
import Table from '../../../components/Table/Table';
import EditPanel from '../../../components/Panel/EditPanel';
import CustomizeModal from '../../../components/Modals/CustomizeModal';
import { withModal } from '../../../components/Modals';
import TextInput from '../../../components/ControlledComponents/TextInput';
import Button from '../../../components/Button/Button';

import './LocaleInformationPanel.scss';

const CustomizeButton = withModal(Button, CustomizeModal);

const LocaleInformationPanel = ({
  locale: localeProp,
  onSave,
  disableEditing,
  toggleDisableEditing,
  id: panelId = '',
}) => {
  const [locale, setLocale] = useState(localeProp || {});
  const tableData = [
    {
      banner: 'Pre Event',
      data: locale?.preEventBanner,
      key: 'preEventBanner',
      id: uuidv4(),
    },
    {
      banner: 'Post Event',
      data: locale?.postEventBanner,
      key: 'postEventBanner',
      id: uuidv4(),
    },
  ];
  const tableColumns = [
    {
      Header: 'Banner',
      accessor: 'banner',
    },
    {
      Header: 'Action',
      accessor: 'data',
      Cell: ({ value, row }) => (
        <>
          <div className="disabled-customize-btn">Customize</div>
          <CustomizeButton
            className="customize-btn"
            variant="link"
            modalProps={{
              data: renameObjectKey(value, 'eyebrowText', 'eyebrow'),
              includesContentDetails: false,
              onCustomize: (data) => {
                setLocale((prevLocale) => {
                  return { ...prevLocale, [row.original.key]: renameObjectKey(data, 'eyebrow', 'eyebrowText') };
                });
              },
            }}
          >
            Customize
          </CustomizeButton>
        </>
      ),
      colWidth: '154px',
    },
  ];

  useEffect(() => setLocale(localeProp), [localeProp]);

  const onChange = (value, property) => {
    setLocale({ ...locale, [property]: value });
  };

  return (
    <EditPanel
      id={panelId}
      className="Locale-information-panel"
      title="Locale Information"
      save={() => onSave(locale)}
      disableEditing={disableEditing}
      onEnableEditing={toggleDisableEditing}
      onCancel={() => {
        setLocale(localeProp);
        toggleDisableEditing();
      }}
    >
      {(isEditing) => (
        <>
          <div className="ml-4 my-5">
            <div className="panel-label mb-1">Name</div>
            {isEditing ? (
              <TextInput
                className="panel-text-input"
                property="name"
                value={locale?.name || ''}
                onChange={onChange}
              />
            ) : (
              <p className="panel-text">{locale?.name || '-'}</p>
            )}
          </div>
          <Table className={`pb-5${isEditing ? ' isEditing' : ''}`} data={tableData} columns={tableColumns} />
        </>
      )}
    </EditPanel>
  );
};

export default LocaleInformationPanel;
