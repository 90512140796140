import React, { FC } from 'react';
import classnames from 'classnames';
import { PlainTagListInput } from '@brightcove/studio-components';
import './TagList.scss';

interface TagListProps {
  className?: string;
  value: any[];
  onChange: (val: string[]) => void;
}

const TagList: FC<TagListProps> = ({ className, value, onChange }) => {
  return (
    <PlainTagListInput className={classnames('Tag-list', className)} value={value} onChange={onChange} />
  );
};

export default TagList;
