import React, { CSSProperties, FC } from 'react';
import classnames from 'classnames';
import './Loading.scss';

interface LoadingProps {
  className?: string;
  style?: CSSProperties;
}

const Loading: FC<LoadingProps> = ({ className, style }) => {
  const loadingClassName = classnames('Loading-indicator', className);

  return (
    <div className={loadingClassName} style={style}>
      <svg className="circular">
        <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10" />
      </svg>
    </div>
  );
};

export default Loading;
