import { useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { Icon } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import CollectionsDetail from '../Collections/CollectionsDetail';
import {
  COLLECTION_ITEM,
  CONTENT_AD,
  CONTENT_EPISODE,
  CONTENT_MOVIE,
  CONTENT_SEASONS,
  CONTENT_SERIES,
  CONTENT_TRAILER,
  DEFAULT_LANGUAGE,
  getLocaleByLanguage,
  getNativeLanguage,
  getUrlWithOptions,
} from '../../utils';
import { ContentDetailData, ContentLocalesData } from '../../interfaces/Content';
import useGoBack from '../../hooks/useGoBack';
import { useApi } from '../../hooks/useApi';
import SubHeader from '../../components/SubHeader/SubHeader';
import PublicationStateButtons from '../../components/PublicationStateButtons/PublicationStateButtons';
import Loading from '../../components/Loading/Loading';

import DetailSeriesSeasons from './DetailSeriesSeasons';
import DetailEpisode from './DetailEpisodeMovie';
import DetailAdTrailer from './DetailAdTrailer';

const ContentManagementDetail = () => {
  const { id } = useParams();
  const { apiGet, apiPut, apiDelete } = useApi();
  const { goBackLabel, goBack } = useGoBack(ROUTES.CONTENT_MANAGEMENT, 'Back to All Videos');
  const [loading, setLoading] = useState(false);
  const [currentData, setCurrentData] = useState<ContentDetailData>();
  const [currentLocalesData, setCurrentLocalesData] = useState<ContentLocalesData[]>([]);
  const [currentTitle, setCurrentTitle] = useState('');
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);
  const [currentId, setCurrentId] = useState<string>();
  const [isSavedData, setIsSavedData] = useState(false);
  const CONTENT_ENDPOINT = '/content';
  const contentUrl = getUrlWithOptions(`${CONTENT_ENDPOINT}/${id}`);

  const allowDelete = useMemo(() => {
    if (!currentData?.type) return false;

    return [CONTENT_SERIES].includes(currentData.type);
  }, [currentData]);

  useEffect(() => {
    if (id?.length) {
      setCurrentId(id);
      getData();
      setLoading(true);
    }
  }, [id]);

  useEffect(() => {
    if (isSavedData && id?.length) {
      getData();
    }
  }, [isSavedData]);

  const getData = async () => {
    const { data } = await apiGet(contentUrl);
    const nativeLanguage = getNativeLanguage(data?.locales) || DEFAULT_LANGUAGE;
    const { name } = getLocaleByLanguage(data?.locales, nativeLanguage) || {};

    setCurrentTitle(name);
    setCurrentData(data);
    setCurrentLocalesData(data?.locales);
    setLoading(false);
    setIsSavedData(false);
  };

  useEffect(() => {
    const { name } = getLocaleByLanguage(currentLocalesData, currentLanguage || DEFAULT_LANGUAGE) || {};
    setCurrentTitle(name);
  }, [currentLocalesData, currentLanguage]);

  const getTypeOfContent = (contentData, localesData, contentId) => {
    const type = contentData.type;

    if (type === CONTENT_SERIES || type === CONTENT_SEASONS) {
      return (
        <DetailSeriesSeasons
          data={contentData}
          localesData={localesData}
          id={contentId}
          onSavedData={() => setIsSavedData(true)}
          onLanguageChange={setCurrentLanguage}
          onLocaleInformationSave={() => getData()}
        />
      );
    } else if (type === CONTENT_EPISODE || type === CONTENT_MOVIE) {
      return (
        <DetailEpisode
          data={contentData}
          localesData={localesData}
          id={contentId}
          onLanguageChange={setCurrentLanguage}
          onSavedData={() => setIsSavedData(true)}
        />
      );
    } else if (type === CONTENT_TRAILER || type === CONTENT_AD) {
      return (
        <DetailAdTrailer
          data={contentData}
          id={contentId}
          onSavedData={() => setIsSavedData(true)}
          localesData={localesData}
        />
      );
    } else if (type === COLLECTION_ITEM) {
      return <CollectionsDetail params={{ id }} withHeader={false} />;
    }
  };

  const updatePublicationStatus = async (updateType) => {
    const url = `${contentUrl}/${updateType}`;
    const { data } = await apiPut(url);

    if (data) {
      setCurrentData({ ...(currentData as ContentDetailData), ...data });

      if (updateType === 'revert') {
        setIsSavedData(true);
      }
    }
  };

  const publishChanges = async (publishDate) => {
    const { data } = await apiPut(`${contentUrl}/publish`, {
      body: {
        scheduledPublishAt: publishDate?.length > 0 ? new Date(publishDate) : undefined,
      },
    });

    if (data) {
      setCurrentData({ ...(currentData as ContentDetailData), ...data });
    }
  };

  const deleteContent = async () => {
    const { error } = await apiDelete(contentUrl);

    if (!error) {
      goBack();
    }
  };

  return (
    <>
      <SubHeader
        icon={<Icon name="ARROW_RIGHT" rotate="180" />}
        title={currentTitle ? currentTitle : ''}
        subtitle={goBackLabel}
        detailMode
        onBack={goBack}
        className="pl-5"
        actions={
          <PublicationStateButtons
            publication={currentData?.publication}
            id={currentId}
            allowRevert
            allowDelete={allowDelete}
            allowPreview={currentData?.type !== CONTENT_TRAILER}
            onRevert={() => updatePublicationStatus('revert')}
            onPublish={(val) => publishChanges(val)}
            onUnschedule={() => updatePublicationStatus('unschedule')}
            onDelete={deleteContent}
          />
        }
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {currentData &&
            !!currentLocalesData?.length &&
            getTypeOfContent(currentData, currentLocalesData, currentId)}
        </>
      )}
    </>
  );
};

export default ContentManagementDetail;
