import React, { useState } from 'react';
import { flowRight as compose } from 'lodash';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { getUrlWithOptions } from '../../utils';
import { useApi } from '../../hooks/useApi';
import { withRouter } from '../../components/withRouter/withRouter';
import SubHeader from '../../components/SubHeader/SubHeader';
import Panel from '../../components/Panel/Panel';
import './CollectionsCreate.scss';
import TextInput from '../../components/ControlledComponents/TextInput';
import SimpleSelect from '../../components/ControlledComponents/SimpleSelect';
import Button from '../../components/Button/Button';

const typeOptions = ['Manual', 'Dynamic'].map((value) => ({
  label: value,
  value,
}));

const CollectionsCreate = ({ navigate }) => {
  const { apiPost } = useApi();
  const [selectedType, setSelectedType] = useState(typeOptions[0]?.value || '');
  const [collectionName, setCollectionName] = useState<string>('');

  const onUpdateSelectedType = (role) => setSelectedType(role);

  const onCreate = async () => {
    if (collectionName && selectedType) {
      const { data } = await apiPost(getUrlWithOptions('/collections'), {
        body: {
          name: collectionName,
          type: selectedType,
        },
      });

      if (data) {
        navigate(`../${ROUTES.COLLECTIONS}/edit/${data.id}`);
      }
    }
  };

  const goBack = () => navigate(`../${ROUTES.COLLECTIONS}`);

  return (
    <div className="Collections-create">
      <SubHeader
        title="New Collection"
        subtitle="Back to Collections"
        className="pl-5 pr-4"
        detailMode={true}
        onBack={goBack}
        icon={<Icon name={ICONS.ARROW_RIGHT} flip="horizontal" />}
      />

      <div className="main-content">
        <Panel
          title="Collection Info"
          actions={
            <>
              <Button className="mr-1" variant="secondary" text="Cancel" onClick={goBack} />
              <Button
                variant="primary"
                text="Save"
                onClick={onCreate}
                disabled={!collectionName || !selectedType}
              />
            </>
          }
        >
          <div className="content ml-4 mb-12">
            <Flex>
              <div className="mr-5 collection-name-wrapper">
                <div className="label required">Collection name</div>
                <TextInput
                  className="mt-2"
                  value={collectionName}
                  onChange={(updatedName) => setCollectionName(updatedName)}
                />
              </div>
              <div className="mb-8">
                <div className="label required">Collection type</div>
                <SimpleSelect
                  className="select-input-lg mt-2"
                  value={selectedType}
                  options={typeOptions}
                  onChange={onUpdateSelectedType}
                />
              </div>
            </Flex>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default compose(withRouter)(CollectionsCreate);
