import React, { FC } from 'react';
import './CuePointsBar.scss';

interface CuePointsProps {
  time: number;
  duration: number;
  name: string;
  onCuePointSelected: (val: any) => void;
}

const CuePoint: FC<CuePointsProps> = ({ time, duration, name, onCuePointSelected }) => {
  const handleClick = () => {
    onCuePointSelected({ time, name });
  };

  const cuePointTime = Math.floor((time / duration) * 100);
  const cuePointPosition = `calc(${cuePointTime}% - 2px)`;

  return (
    <div
      className="CuePointsBar-cuepoint-container"
      onClick={handleClick}
      style={{
        left: cuePointPosition,
      }}
    >
      <div className="diamond"></div>
    </div>
  );
};

export default CuePoint;
