import React, { FC } from 'react';
import classnames from 'classnames';

import GenericModal, { GenericModalProps } from './GenericModal';
import './StandardModal.scss';

export interface StandardModalProps extends GenericModalProps {
  actions: JSX.Element;
}

const StandardModal: FC<StandardModalProps> = ({ className, show, title, onClose, actions, children }) => {
  return (
    <GenericModal
      className={classnames('Standard-modal', className)}
      show={show}
      title={title}
      onClose={onClose}
      buttons={actions || <></>}
    >
      {children}
    </GenericModal>
  );
};

export default StandardModal;
