import React, { useEffect, useState } from 'react';
import _, { flowRight as compose } from 'lodash';
import classNames from 'classnames';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import {
  getUrlWithOptions,
  isPermissionExcluded,
  isSuperAdmin,
  parsePermissions,
  permissionsLabelsAndValues,
} from '../../utils';
import { Role } from '../../types/roles';
import COLORS from '../../styles/_colors.export.scss';
import { useApi } from '../../hooks/useApi';
import { withRouter } from '../../components/withRouter/withRouter';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import { DeleteConfirmationModal, withModal } from '../../components/Modals';
import Loading from '../../components/Loading/Loading';
import InputSearch from '../../components/ControlledComponents/InputSearch';
import Button from '../../components/Button/Button';

import { sortRoles } from './utils';

import './Roles.scss';

const DeleteButton = withModal(Button, DeleteConfirmationModal);

const Roles = ({ navigate }) => {
  const { apiGet, apiDelete, refetchGet } = useApi();
  const [roles, setRoles] = useState<Role[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRoles, setSelectedRoles] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const rolesUrl = getUrlWithOptions('/roles');

  const parseRoles = (roles) => {
    return (
      roles.map((role) => ({
        ...role,
        disableSelection: isSuperAdmin(role) || role.isDefault,
        permissions: parsePermissions(role.permissions),
      })) || []
    );
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      const { data } = await apiGet(getUrlWithOptions('/roles', searchQuery));

      if (data) {
        setRoles(parseRoles(data));
      }

      setLoading(false);
    })();
  }, [searchQuery]);

  const handleRoleClick = (row: { original: { id: string } }) => {
    if (!isSuperAdmin(row.original)) {
      navigate(`../${ROUTES.ROLES}/edit/${row.original.id}`);
    }
  };

  const getColumns = () => {
    const columns: any = [
      {
        Header: 'Roles',
        accessor: 'name',
        Cell: ({ value, row }) => (
          <div
            className={classNames({ 'role-name-container': true, clickable: !isSuperAdmin(row.original) })}
            onClick={() => handleRoleClick(row)}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center">
                <div className="role-name mr-4">{value}</div>
                {row.original.isDefault && <div className="default-marker">Default</div>}
              </Flex>
              <Icon
                name={ICONS.PENCIL}
                color={COLORS.cyan}
                className={isSuperAdmin({ name: value }) ? 'hidden' : ''}
              />
            </Flex>
          </div>
        ),
        className: 'role',
        filter: 'exactText',
      },
    ];

    permissionsLabelsAndValues.forEach(({ label, value }) => {
      columns.push({
        Header: label,
        id: value,
        accessor: ({ permissions }) =>
          Object.entries(permissions).reduce((newObj, [permissionType, permissionValue]: any) => {
            if (!isPermissionExcluded(permissionType, value)) {
              newObj[permissionType] = permissionValue.includes(value);
            }

            return newObj;
          }, {}),
        Cell: ({ value: cellValue, row }) => (
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="table-cell"
          >
            {Object.entries(cellValue).map(([permissionType, hasPermission], index) => {
              const showCheckmark = isSuperAdmin(row.original) || hasPermission;

              return (
                <Flex key={index} alignItems="center">
                  <Icon
                    name={ICONS.CHECK2}
                    color={COLORS.cyan}
                    className={showCheckmark ? '' : 'visibility-hidden'}
                  />
                  <span>{_.capitalize(permissionType)}</span>
                </Flex>
              );
            })}
          </Flex>
        ),
        className: 'module',
      });
    });

    return columns;
  };

  const onChangeSelection = (roles: any[]) => {
    setSelectedRoles(roles);
  };

  const onDeleteRoles = async () => {
    const idsToDelete = selectedRoles.map((role) => role.id).toString();
    const { error } = await apiDelete(`${rolesUrl}?ids=${encodeURIComponent(idsToDelete)}`);

    if (!error) {
      const { data } = await refetchGet();

      setRoles(parseRoles(data));
    }
  };

  const list = sortRoles(roles);

  return (
    <div className="Roles">
      <SubHeader
        title="Roles"
        subtitle="NetApp TV"
        actions={<InputSearch placeholder="Search by name" onSearch={(value) => setSearchQuery(value)} />}
      />
      <div className="main-content py-8 pl-11 pr-1">
        <Flex alignItems="flex-end">
          <Button
            variant="primary"
            text="Create role"
            onClick={() => navigate(`../${ROUTES.ROLES}/create`)}
          />
          <Flex className="actions">
            <DeleteButton
              className="delete-btn mr-11"
              variant="danger"
              disabled={
                selectedRoles.length === 0 || !!selectedRoles.find(({ disableSelection }) => disableSelection)
              }
              modalProps={{
                title: 'Delete Role',
                children: (
                  <>
                    You will be deleting {selectedRoles.length} role{selectedRoles.length !== 1 && 's'} from
                    the system. This action cannot be undone.
                  </>
                ),
              }}
              onModalConfirm={onDeleteRoles}
            >
              Delete
            </DeleteButton>
          </Flex>
        </Flex>
        {loading ? (
          <Loading />
        ) : (
          <Table
            className="mt-11 overflow-auto"
            data={list}
            columns={getColumns()}
            onChangeSelection={onChangeSelection}
            width="2300px"
            hasSelection
            hasSelectAll={false}
          />
        )}
      </div>
    </div>
  );
};

export default compose(withRouter)(Roles);
