import { NavLink, useLocation } from 'react-router-dom';
import React, { FC } from 'react';

import './Navigation.scss';
import { CONTENT_MANAGEMENT } from '../../routes/routes';

interface NavigationProps {
  items: Item[];
}
interface Item {
  title: string;
  path: string;
}

const Navigation: FC<NavigationProps> = ({ items }) => {
  const { pathname } = useLocation();

  const isSelected = (path: string) => {
    if (path === '/') {
      return pathname === path || pathname.includes(CONTENT_MANAGEMENT);
    }

    return pathname.includes(path);
  };

  return (
    <div className="Navigation">
      {!!items.length &&
        items.map((item: Item) => (
          <NavLink
            to={item.path}
            className={isSelected(item.path) ? 'Navigation-item-selected' : 'Navigation-item'}
            key={item.path}
          >
            {item.title}
          </NavLink>
        ))}
    </div>
  );
};

export default Navigation;
