import React, { FC, useEffect, useState } from 'react';
import { pick, cloneDeep, omit, capitalize, isNumber, set } from 'lodash';
import classnames from 'classnames';
import { Flex } from '@brightcove/studio-components';

import StandardModal, { StandardModalProps } from '../StandardModal';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import TextInput from '../../ControlledComponents/TextInput';
import TextArea from '../../ControlledComponents/TextArea';
import SimpleSelect from '../../ControlledComponents/SimpleSelect';
import Button from '../../Button/Button';
import { getUrlWithOptions, getDuration, getEndTimecode } from '../../../utils';
import { CTA, CTAType } from '../../../types/cta';
import { useApi } from '../../../hooks/useApi';

import {
  defaultCTA,
  getCtaJsonByType,
  getMissingParametersMessage,
  isScheduleAvailable,
  placementOptions,
  requiredCtaTemplateFields,
  templateOptions,
} from './helpers';
import SurveyCta from './SurveyCta';
import QuizCta from './QuizCta';
import PollCta from './PollCta';
import LinkCta from './LinkCta';

import './CtaModal.scss';

const malformedJsonMessage = 'Malformed JSON, please verify';

interface CtaModalProps extends StandardModalProps {
  onCreateCta?: (val: any) => void;
  onUpdateCta?: (val: any) => void;
  ctaInfo?: CTA;
  eventId: string;
  ctasList: CTA[];
}

const CtaModal: FC<CtaModalProps> = ({
  className,
  show,
  ctasList,
  onClose,
  onCreateCta,
  onUpdateCta,
  ctaInfo,
  eventId,
}) => {
  const [cta, setCta] = useState<CTA>(ctaInfo || defaultCTA);
  const [ctaAsString, setCtaAsString] = useState<string>('');
  const [ctaAsJson, setCtaAsJson] = useState<CTA>();
  const [ctaJsonError, setCtaJsonError] = useState<string>();
  const [ctaDuration, setCtaDuration] = useState<any>();
  const [isStandard, setIsStandard] = useState<boolean>(true);
  const [canBeSubmitted, setCanBeSubmitted] = useState(false);
  const [saving, setSaving] = useState(false);
  const { apiPost, apiPatch } = useApi();
  const ctaType = cta?.template?.type?.toUpperCase() as CTAType;
  const isGridDisplay = ctaType === 'LINK';

  useEffect(() => {
    const duration =
      ctaInfo?.startTimecode && ctaInfo?.endTimecode
        ? getDuration(ctaInfo.startTimecode, ctaInfo.endTimecode)
        : '';

    setCtaDuration(duration);
  }, [ctaInfo]);

  useEffect(() => {
    setCtaAsString(getJSONFromCta() || '');
  }, [cta, ctaDuration]);

  useEffect(() => {
    requiredFieldsValidationCheck();
  }, [isStandard, cta, ctaAsJson, ctaType]);

  useEffect(() => {
    try {
      setCtaAsJson(JSON.parse(ctaAsString));
      setCtaJsonError(undefined);
    } catch (error) {
      setCtaJsonError(malformedJsonMessage);
    }
  }, [ctaAsString]);

  const getJSONFromCta = () => {
    const initialCta = getCtaJsonByType(ctaType);
    const updatedCta: CTA = {
      ...pick(cloneDeep(cta), ['placement', 'startTimecode', 'duration']),
      template: { ...initialCta.template, ...cloneDeep(cta.template) },
    };

    if (ctaDuration) {
      updatedCta.duration = ctaDuration;
    }

    return JSON.stringify(updatedCta, undefined, 4);
  };

  const getCtaTypeLabel = (type: string) => {
    return templateOptions.filter((option) => option.value === type)[0]?.label;
  };

  const onUpdateType = (type) => {
    setCta({ ...cta, template: { type } });
  };

  const onUpdateField = (field, value) => {
    const updatedCta = JSON.parse(JSON.stringify(cta)); // create deep copy

    set(updatedCta, field, value);
    setCta(updatedCta);
  };

  const resetCta = () => {
    setCta({
      template: {
        type: templateOptions[0].value,
      },
      placement: placementOptions[0].value,
    });
    setCtaAsString('');
    setCtaAsJson(undefined);
    setCtaJsonError(undefined);
    setIsStandard(true);
    setCtaDuration('');
    onClose?.();
  };

  const getCurrentCta = () => {
    if (isStandard) return cta;

    if (ctaAsJson) return ctaAsJson;

    if (ctaAsString) {
      const json = JSON.parse(ctaAsString);
      setCtaAsJson(json);

      return json;
    }

    return defaultCTA;
  };

  const requiredFieldsValidationCheck = () => {
    const subjectCTA = getCurrentCta();
    const subjectType = ctaType;

    if (subjectCTA) {
      const requiredTemplateFields: string[] = requiredCtaTemplateFields[subjectType] ?? [];
      const missingFieldsMessage = getMissingParametersMessage(ctaType, subjectCTA);
      setCtaJsonError(missingFieldsMessage);

      if (missingFieldsMessage.length) {
        return setCanBeSubmitted(false);
      }

      for (const key of requiredTemplateFields) {
        // see if one of the required fields is empty
        if (
          subjectCTA.template?.[key]?.length === 0 ||
          (Array.isArray(subjectCTA.template[key]) &&
            subjectCTA.template?.[key].find(({ question }) => !question)) ||
          (ctaType === 'QUIZ' &&
            Array.isArray(subjectCTA.template?.[key]) &&
            subjectCTA.template?.[key].find(({ answers, correctAnswer }) => {
              return (correctAnswer && isNaN(correctAnswer)) || !answers || answers?.length < 2;
            }))
        ) {
          setCtaJsonError('Wrong format');

          return setCanBeSubmitted(false);
        }
      }

      return setCanBeSubmitted(true);
    }

    setCanBeSubmitted(false);
  };

  const getRequestBody = () => {
    let requestBody = isStandard ? cta : (ctaAsJson as CTA);

    requestBody = {
      ...requestBody,
      startTimecode: requestBody.startTimecode || '',
      endTimecode:
        ctaDuration && ctaDuration > 0 && requestBody.startTimecode
          ? getEndTimecode(requestBody.startTimecode, ctaDuration)
          : '',
    };
    delete requestBody.duration;

    return requestBody;
  };

  const handleNewCta = async () => {
    const body = getRequestBody();

    if (isScheduleAvailable(ctasList, body, ctaInfo?.id)) {
      setSaving(true);

      const { data, error } = await apiPost(getUrlWithOptions(`/events/${eventId}/ctas`), {
        body,
      });

      setSaving(false);

      if (!error && data) {
        onCreateCta?.(data);
        resetCta();
      }
    }
  };

  const handleUpdateCta = async () => {
    const body = omit(getRequestBody(), ['id', 'createdAt', 'updatedAt']) as CTA;

    if (isScheduleAvailable(ctasList, body, ctaInfo?.id)) {
      setSaving(true);

      const { data, error } = await apiPatch(getUrlWithOptions(`/events/${eventId}/ctas/${cta.id}`), {
        body,
      });

      setSaving(false);

      if (!error && data) {
        onUpdateCta?.(data);
        resetCta();
      }
    }
  };

  const ctaProps = {
    ctaTemplate: cta?.template,
    onUpdateField,
  };

  return (
    <StandardModal
      className={classnames('Cta-modal', className)}
      show={show}
      title={ctaInfo ? 'Edit CTA' : 'New CTA'}
      onClose={onClose}
      actions={
        <>
          <Button variant="secondary" text="Cancel" onClick={() => onClose?.()} />
          <Button
            className="ml-3"
            variant="primary"
            text={ctaInfo ? (saving ? 'Saving...' : 'Save') : saving ? 'Creating...' : 'Create'}
            onClick={ctaInfo ? handleUpdateCta : handleNewCta}
            disabled={!canBeSubmitted || saving}
          />
        </>
      }
    >
      <div className="Cta-modal-content py-2">
        <Flex className="mb-6">
          <div
            className={`display-type mr-4${isStandard ? ' selected' : ''}`}
            onClick={() => setIsStandard(true)}
          >
            Standard
          </div>
          <div
            className={`display-type${!isStandard ? ' selected' : ''}`}
            onClick={() => setIsStandard(false)}
          >
            Advanced
          </div>
        </Flex>
        {isStandard ? (
          <div className={isGridDisplay ? 'grid-display' : ''}>
            <div className="mb-5">
              <div className="label required mb-2">{ctaInfo ? 'Template type' : 'Select template'}</div>
              {ctaInfo ? (
                <p className="m-0">{getCtaTypeLabel(ctaType)}</p>
              ) : (
                <SimpleSelect
                  className="select-input-lg"
                  value={ctaType || templateOptions[0].value}
                  options={templateOptions}
                  onChange={(value) => onUpdateType(value)}
                />
              )}
            </div>
            <div className="mb-5">
              <div className="label required mb-2">
                {!isGridDisplay && `${capitalize(ctaType)} `}
                Title
              </div>
              <TextInput
                value={cta?.template?.name || ''}
                onChange={(value) => onUpdateField('template.name', value)}
              />
            </div>
            {ctaType === 'LINK' && <LinkCta {...ctaProps} />}
            {ctaType === 'POLL' && <PollCta {...ctaProps} />}
            {ctaType === 'SURVEY' && <SurveyCta {...ctaProps} />}
            {ctaType === 'QUIZ' && <QuizCta {...ctaProps} />}
            <div className="placement full-width-grid-item mb-5">
              <div className="label required mb-2">Select placement</div>
              <div className="placement-container">
                {placementOptions.map(({ value, style }, index) => (
                  <div
                    key={index}
                    className={`placement-option${value === cta?.placement ? ' selected' : ''}`}
                    onClick={() => onUpdateField('placement', value)}
                    style={style}
                  >
                    <div></div>
                  </div>
                ))}
              </div>
            </div>
            <Flex className="full-width-grid-item">
              <div style={{ flex: 1 }} className="mr-6">
                <div className="label mb-2">Insertion time into stream</div>
                <CustomDatePicker
                  valueFormat={{ seconds: 'short' }}
                  timePrecision={'seconds'}
                  value={cta?.startTimecode ? new Date(cta?.startTimecode) : null}
                  min={new Date()}
                  onChange={(value) => onUpdateField('startTimecode', value?.toISOString() || '')}
                />
              </div>
              <div style={{ flex: 1 }}>
                <div className="label mb-2">Duration</div>
                <Flex alignItems="center" className="duration">
                  <TextInput
                    value={ctaDuration}
                    onChange={(value) => isNumber(Number(value)) && setCtaDuration(value)}
                  />
                  <div>Seconds</div>
                </Flex>
              </div>
            </Flex>
          </div>
        ) : (
          <div className="advanced-display">
            <div className="mb-6">
              <div className="label required mb-2">{ctaInfo ? 'Template type' : 'Select template'}</div>
              {ctaInfo ? (
                <p className="m-0">{getCtaTypeLabel(ctaType)}</p>
              ) : (
                <SimpleSelect
                  className="select-input-lg"
                  value={ctaType || templateOptions[0].value}
                  options={templateOptions}
                  onChange={(value) => onUpdateType(value)}
                />
              )}
            </div>
            {}
            <div className={classnames({ 'json-error-container': !!ctaJsonError })}>
              <div className="label mb-2 required">JSON</div>
              <TextArea
                className="json-content"
                value={ctaAsString}
                onChange={(value) => setCtaAsString(value)}
              />
              {!!ctaJsonError && <div className="json-error-message">{ctaJsonError}</div>}
            </div>
          </div>
        )}
      </div>
    </StandardModal>
  );
};

export default CtaModal;
