import React, { FC } from 'react';

import QuestionAnswer from './QuestionAnswer';

interface PollCtaProps {
  ctaTemplate: any;
  onUpdateField: (property: string, val: any) => void;
}

const PollCta: FC<PollCtaProps> = ({ ctaTemplate, onUpdateField }) => {
  return (
    <QuestionAnswer
      className="mb-5"
      question={ctaTemplate?.question}
      answers={ctaTemplate?.answers}
      ctaType={ctaTemplate?.type?.toUpperCase()}
      onAddAnswer={() => {
        const currentAnswers = ctaTemplate?.answers || [];

        onUpdateField('template.answers', currentAnswers.concat(''));
      }}
      onUpdateQuestion={(value) => {
        onUpdateField('template.question', value);
      }}
      onUpdateAnswers={(answers) => {
        onUpdateField('template.answers', answers);
      }}
      onDeleteAnswer={(answers) => onUpdateField('template.answers', answers)}
    />
  );
};

export default PollCta;
