/* tslint-disable no-unused-expressions */
import { v4 as uuidv4 } from 'uuid';
import React, { FC } from 'react';
import classNames from 'classnames';
import { Modal } from '@brightcove/studio-components';

import Button from '../Button/Button';
import './DeleteConfirmationModal.scss';

interface DeleteConfirmationModalProps {
  title: string;
  confirmBtnLabel?: string;
  show: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  onConfirm?: () => void;
  children: JSX.Element;
  appElement?: string | HTMLElement;
  actionDisabled?: boolean;
}

const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = ({
  show,
  title,
  confirmBtnLabel = 'Delete',
  onClose,
  onCancel,
  onDelete,
  onConfirm,
  children,
  appElement,
  actionDisabled = false,
}) => {
  const closeHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Modal
        appElement={appElement || document.getElementById('root') || undefined}
        className={classNames('Delete-confirmation-modal', {
          [confirmBtnLabel.toLowerCase()]: confirmBtnLabel,
        })}
        titleClassName="Delete-confirmation-modal-title"
        headerClassName="Delete-confirmation-modal-header"
        title={title}
        isOpen={show}
        shrink={true}
        onClose={closeHandler}
        buttons={[
          <Button
            variant="secondary"
            text="Cancel"
            onClick={() => {
              onCancel && onCancel();
              onClose && onClose();
            }}
            key={uuidv4()}
          />,
          <Button
            variant="danger"
            text={confirmBtnLabel}
            onClick={() => {
              onDelete && onDelete();
              onConfirm && onConfirm();
            }}
            key={uuidv4()}
            disabled={actionDisabled}
          />,
        ]}
      >
        <div key={uuidv4()}>{children}</div>
      </Modal>
    </>
  );
};

export default DeleteConfirmationModal;
