import { useNavigate } from 'react-router';
import React, { useState, useEffect, useCallback } from 'react';
import { Flex } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { formatDate } from '../../utils/format';
import { DEFAULT_PAGE } from '../../utils/constants';
import { getUrlWithOptions, getPageCount } from '../../utils';
import { useApi } from '../../hooks/useApi';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import PublicationStateButtons from '../../components/PublicationStateButtons/PublicationStateButtons';
import Loading from '../../components/Loading/Loading';
import { SVGImage } from '../../assets/images';
import './DraftChanges.scss';

type EntityType = 'content' | 'collection' | 'page' | 'event' | 'siteconfiguration' | 'movie' | 'season';

const draftPathByType: Record<EntityType, string> = {
  content: ROUTES.CONTENT_MANAGEMENT,
  movie: ROUTES.CONTENT_MANAGEMENT,
  season: ROUTES.CONTENT_MANAGEMENT,
  collection: ROUTES.COLLECTIONS,
  page: ROUTES.PAGES,
  event: ROUTES.EVENTS,
  siteconfiguration: ROUTES.SITE_CONFIGURATION,
};

const draftsBaseUrl = getUrlWithOptions('/drafts');

type Draft = {
  id: string;
  draftInfoId: string;
  name: string;
  scheduledPublishAt?: string;
  type: string;
  publishing: boolean;
  updatedAt: string;
  updatedBy: string;
};

const DraftChanges = () => {
  const { apiGet, apiPut } = useApi();
  const [loading, setLoading] = useState(true);
  const [drafts, setDrafts] = useState<Draft[]>([]);
  const [selection, setSelection] = useState<Draft[]>([]);
  const [currentPageSize, setCurrentPageSize] = useState(20);
  const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_PAGE);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [sort, setSort] = useState<string>('updatedAt:desc');
  const navigate = useNavigate();

  const scheduledSelection = selection?.filter((item) => !!item.scheduledPublishAt);
  const noPublishingSelection = selection?.filter((item) => !item.publishing);

  const getDrafts = async () => {
    const { data } = await apiGet(
      getUrlWithOptions('/drafts', undefined, sort, undefined, currentPageIndex, currentPageSize)
    );

    if (data) {
      const { items, totalCount } = data;

      setDrafts(items);
      setTotalCount(totalCount);
    }

    setLoading(false);
  };

  useEffect(() => {
    getDrafts();
  }, [currentPageIndex, currentPageSize, sort]);

  const onPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
  }, []);

  const getDraftPath = (type: EntityType) => {
    const path = draftPathByType[type];

    return path || ROUTES.CONTENT_MANAGEMENT;
  };

  const goToEntityDetailPage = (id: string, entityType: EntityType) => {
    const lowerType = entityType.toLowerCase() as EntityType;

    if (lowerType === 'siteconfiguration') {
      return navigate(ROUTES.SITE_CONFIGURATION);
    }

    const route = `${getDraftPath(lowerType)}/edit/${id}`;

    return navigate(route, {
      state: { goBack: { url: ROUTES.DRAFT_CHANGES, label: 'Back to All Drafts' } },
    });
  };

  const onClickSort = (field: string, order: string) => {
    if (field && order) {
      setSort(`${field}:${order}`);
    }
  };

  const columns = [
    {
      Header: 'Entity name',
      accessor: 'name',
      sortable: true,
      className: 'name',
      Cell: ({ value, row }) => (
        <TitleDescription
          title={value}
          id={row.original.id}
          onClick={() => goToEntityDetailPage(row.original.id, row.original.type)}
        />
      ),
    },
    {
      Header: 'Entity Type',
      accessor: 'type',
      sortable: true,
      className: 'type',
    },
    {
      Header: 'Scheduled publish',
      accessor: 'scheduledPublishAt',
      sortable: true,
      Cell: ({ value }) => <p>{value ? formatDate(value) : '-'}</p>,
      className: 'scheduled-publish-date',
    },
    {
      Header: 'Publishing',
      accessor: 'publishing',
      sortable: true,
      className: 'publishing',
      Cell: ({ value }) => {
        return value ? 'Yes' : 'No';
      },
    },
    {
      Header: 'Updated at',
      accessor: 'updatedAt',
      sortable: true,
      Cell: ({ value, row }) => (
        <>
          <p>{formatDate(value)}</p>
          <p className="subtitle">{row.original.updatedBy}</p>
        </>
      ),
      className: 'updated-date',
    },
  ];

  const publishChanges = async (publishDate: string) => {
    const selectedDrafts = noPublishingSelection?.map((item) => item.draftInfoId);

    await apiPut(`${draftsBaseUrl}/publish`, {
      body: {
        selectedDrafts,
        scheduledPublishAt: publishDate?.length > 0 ? new Date(publishDate) : undefined,
      },
    });

    getDrafts();
  };

  const handleUnschedule = async () => {
    await apiPut(`${draftsBaseUrl}/unschedule`, {
      body: {
        selectedDrafts: scheduledSelection.map(({ draftInfoId }) => draftInfoId),
      },
    });

    getDrafts();
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Flex className="Draft-Changes">
          <Flex className="Draft-Changes-content" flexDirection="column">
            <SubHeader
              icon={<img src={SVGImage.ScheduleIcon} alt="Draft Changes Header Icon" />}
              title="Draft changes"
              subtitle={'NetApp TV'}
              quantitySubtitle={`${currentTotalCount || 0} unpublished change${
                currentTotalCount === 1 ? '' : 's'
              }`}
              actions={
                <PublicationStateButtons
                  showPublishButton={!!noPublishingSelection.length}
                  showUnscheduleButton={!!scheduledSelection.length}
                  showUnpublishedChangesWarning={false}
                  onPublish={(val) => publishChanges(val)}
                  onUnschedule={handleUnschedule}
                  unscheduleBySelection
                />
              }
            />
            <Table
              className="Draft-Changes-content-table"
              data={drafts}
              columns={columns}
              hasPagination
              pageCount={getPageCount(currentTotalCount, currentPageSize)}
              onPaginationChange={onPaginationChange}
              defaultSortingValues={[{ id: 'updatedAt', desc: true }]}
              pageIndex={currentPageIndex}
              pageSize={currentPageSize}
              onClickSort={onClickSort}
              onChangeSelection={setSelection}
              hasSelection
              width={1250}
            />
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default DraftChanges;
