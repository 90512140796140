import { useNavigate } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Thumbnail } from '@brightcove/studio-components';

import * as ROUTES from '../../routes';
import { formatDate } from '../../../utils/format';
import { AvailabilityData } from '../../../interfaces';
import config from '../../../config';
import TitleDescription from '../../../components/TitleDescription/TitleDescription';
import Table from '../../../components/Table/Table';
import ReactPlayer from '../../../components/ReactPlayer/ReactPlayer';
import EditPanel from '../../../components/Panel/EditPanel';
import { AddContentItemModal, withModal } from '../../../components/Modals';
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import Button from '../../../components/Button/Button';

import './VideoPanel.scss';

const AddVideoButton = withModal(Button, AddContentItemModal);

const dateNotAvailable = 'Event not available for this date';

type Props = {
  id: string;
  title: string;
  videos: any[];
  modalProps: any;
  disableEditing: boolean;
  className?: string;
  availability?: AvailabilityData;
  addButtonText?: string;
  singleVideo?: boolean;
  onSave: (data: any) => Promise<any>;
  toggleDisableEditing: () => void;
  onEnableEditing: () => void;
};

const VideoPanel: FC<Props> = ({
  availability = {},
  videos: videosProp,
  onSave,
  modalProps,
  disableEditing,
  toggleDisableEditing,
  onEnableEditing,
  className = '',
  title,
  addButtonText,
  singleVideo = false,
  id: panelId = '',
}) => {
  const [, setRefresh] = useState(0);
  const [videos, setVideos] = useState(videosProp || []);
  const [reloadPlayer, setReloadPlayer] = useState(false);
  const navigate = useNavigate();

  const isDateAvailable = (date?: Date | null) => {
    const startDate = (availability as unknown as AvailabilityData)?.startDate;
    const endDate = (availability as unknown as AvailabilityData)?.endDate;

    if (date) {
      if (startDate && date.toISOString() < startDate) return false;

      if (endDate && date.toISOString() > endDate) return false;
    }

    return true;
  };

  const updateVideoDate = (index: number, type: 'startDate' | 'endDate', date?: Date | null) => {
    onUpdateVideoDate(index, type, date?.toISOString?.());
  };

  const commonVideoColumns = [
    {
      accessor: 'image',
      Cell: ({ value }) => <Thumbnail className="Collections-detail-thumbnail" src={value} />,
      className: 'thumbnail-cell',
    },
    {
      Header: 'Name',
      id: 'name',
      accessor: (row) => ({ name: row.name, id: row.id }),
      Cell: ({ value }) => (
        <TitleDescription
          className="pb-3"
          title={value.name}
          description={value.id}
          id={value.id}
          onClick={(id) => navigate(`../${ROUTES.CONTENT_MANAGEMENT}/edit/${id}`)}
        />
      ),
      sortable: true,
    },
  ];

  const panelColumns = [
    commonVideoColumns[0],
    {
      ...commonVideoColumns[1],
      sortable: false,
    },

    {
      Header: 'Simulive/Live Start Date',
      accessor: (row, index) => [row.startDate, row.id, index],
      Cell: ({ value, row }) => {
        const { endDate } = row.original;
        const [startDate] = value;
        const [initDate, setInitDate] = useState<Date | null | undefined>(
          startDate ? new Date(startDate) : undefined
        );
        const isAvailable = isDateAvailable(initDate);
        const max = endDate ? new Date(endDate) : undefined;

        return (
          <div>
            <div className="date">{startDate ? formatDate(startDate, true, '', true) : '-'}</div>

            <div className={classNames({ DateError: !isAvailable })}>
              <CustomDatePicker
                valueFormat={{ seconds: 'short' }}
                timePrecision={'seconds'}
                value={initDate}
                max={max}
                onChange={setInitDate}
                onBlur={() => updateVideoDate(row.index, 'startDate', initDate)}
                onToggle={(isOpen) => {
                  if (!isOpen) updateVideoDate(row.index, 'startDate', initDate);
                }}
              />
              {!isAvailable && <div className="ErrorMessage">{dateNotAvailable}</div>}
            </div>
          </div>
        );
      },
      className: 'date-column',
    },

    {
      Header: 'Simulive/Live End Date',
      accessor: (row, index) => [row.endDate, row.id, index],
      Cell: ({ value, row }) => {
        const { startDate } = row.original;
        const [endDate] = value;
        const [finishDate, setFinishDate] = useState<Date | null | undefined>(
          endDate ? new Date(endDate) : null
        );
        const isAvailable = isDateAvailable(finishDate);
        const min = startDate ? new Date(startDate) : undefined;

        return (
          <div>
            <div className="date">{endDate ? formatDate(endDate, true, '', true) : '-'}</div>

            <div className={classNames({ DateError: !isAvailable })}>
              <CustomDatePicker
                valueFormat={{ seconds: 'short' }}
                timePrecision={'seconds'}
                value={finishDate}
                min={min}
                onChange={setFinishDate}
                onBlur={() => updateVideoDate(row.index, 'endDate', finishDate)}
                onToggle={(isOpen) => {
                  if (!isOpen) updateVideoDate(row.index, 'endDate', finishDate);
                }}
              />
              {!isAvailable && <div className="ErrorMessage">{dateNotAvailable}</div>}
            </div>
          </div>
        );
      },
      className: 'date-column',
    },
  ];

  useEffect(() => setVideos(videosProp), [videosProp]);

  const onDeleteVideo = (id) => {
    if (id) {
      setVideos((prevVideos) => {
        return prevVideos.filter((video: any) => video.id !== id);
      });
    } else {
      setVideos([]);
    }
  };

  const onDragRow = (reorderedRows) => setVideos(reorderedRows);

  const forceUpdate = () => {
    setTimeout(() => setRefresh((current) => current + 1), 10);
  };

  const onUpdateVideoDate = (index, dateField, value) => {
    const videosCopy = JSON.parse(JSON.stringify(videos));
    videosCopy[index][dateField] = value;
    setVideos(videosCopy || []);
    forceUpdate();
  };

  const onAddVideos = (items) => {
    const updatedVideos = singleVideo ? items : videos.concat(items);

    setVideos(updatedVideos);
  };

  const onSaveVideos = () => {
    return onSave(videos);
  };

  const onCancel = () => {
    setVideos(videosProp || []);
    setReloadPlayer((prevReloadPlayer) => !prevReloadPlayer);
    toggleDisableEditing();
  };

  return (
    <EditPanel
      id={panelId}
      className={classNames('Video-panel', className)}
      title={title}
      save={onSaveVideos}
      onCancel={onCancel}
      disableEditing={disableEditing}
      onEnableEditing={onEnableEditing}
    >
      {(isEditing) => (
        <>
          {!singleVideo ? (
            <Table
              className={isEditing ? 'isEditing' : ''}
              data={videos}
              columns={panelColumns}
              onClickDeleteRow={onDeleteVideo}
              onDragRow={onDragRow}
              hasDeletion
              hasDragging
            />
          ) : (
            <div className={`pl-4 pt-5${isEditing ? '' : ' pb-3'}`}>
              <div className="panel-label mb-2">Video</div>
              <div className="video-placeholder">
                {videos.length > 0 && videos[0]?.brightcoveVideoId && (
                  <ReactPlayer
                    accountId={config.account_id || ''}
                    videoId={videos[0]?.brightcoveVideoId}
                    reloadPlayer={reloadPlayer}
                    startDate={videos[0]?.startDate}
                    small
                  />
                )}
              </div>
            </div>
          )}
          {isEditing && (
            <>
              <AddVideoButton
                className="ml-4 mb-5 mt-3"
                variant="primary"
                modalProps={{
                  ...modalProps,
                  addItemBtnLabel: title,
                  singleSelection: singleVideo,
                  onAddItems: onAddVideos,
                }}
              >
                {addButtonText}
              </AddVideoButton>
              {videos.length > 0 && videos[0]?.brightcoveVideoId && (
                <Button
                  className="ml-3"
                  variant="secondary"
                  text="Remove"
                  onClick={() => onDeleteVideo(null)}
                />
              )}
            </>
          )}
        </>
      )}
    </EditPanel>
  );
};

export default VideoPanel;
