import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ICONS, Icon } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { DEFAULT_LANGUAGE, getUrlWithOptions } from '../../utils';
import { useApi } from '../../hooks/useApi';
import SubHeader from '../../components/SubHeader/SubHeader';
import Panel from '../../components/Panel/Panel';
import TextInput from '../../components/ControlledComponents/TextInput';
import './EventsCreate.scss';
import SimpleSelect from '../../components/ControlledComponents/SimpleSelect';
import Button from '../../components/Button/Button';

const EventsCreate = () => {
  const { apiGet, apiPost } = useApi();
  const [eventName, setEventName] = useState<string>('');
  const [languages, setLanguages] = useState<any[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);
  const [isLoadingLanguage, setIsLoadingLanguage] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data } = await apiGet(getUrlWithOptions(`/languages`));

      setIsLoadingLanguage(false);

      if (data && data.length > 0) {
        setLanguages(data);
        setCurrentLanguage(data[0].value);
      }
    })();
  }, []);

  const onCreate = async () => {
    if (eventName) {
      const { data } = await apiPost(getUrlWithOptions('/events'), {
        body: {
          name: eventName,
          language: currentLanguage,
        },
      });

      if (data) {
        navigate(`../${ROUTES.EVENTS}/edit/${data.id}`);
      }
    }
  };

  const goBack = () => navigate(`../${ROUTES.EVENTS}`);

  return !isLoadingLanguage ? (
    <div className="Events-create">
      <SubHeader
        title="Create Event"
        subtitle="Back to Events"
        className="pl-5 pr-4"
        detailMode={true}
        onBack={goBack}
        icon={<Icon name={ICONS.ARROW_RIGHT} flip="horizontal" />}
      />

      <div className="main-content">
        <Panel
          title="Create Event"
          actions={
            <>
              <SimpleSelect
                className="select-input-lg mr-2 language-dropdown"
                value={currentLanguage}
                options={languages}
                onChange={(value) => setCurrentLanguage(value)}
              />
              <Button className="mr-1" variant="secondary" text="Cancel" onClick={goBack} />
              <Button variant="primary" text="Save" onClick={onCreate} disabled={!eventName} />
            </>
          }
        >
          <div className="content ml-4 mb-6">
            <div className="panel-label required">Name</div>
            <TextInput
              className="mt-2"
              value={eventName}
              onChange={(updatedName) => setEventName(updatedName)}
            />
          </div>
        </Panel>
      </div>
    </div>
  ) : null;
};

export default EventsCreate;
