/* eslint-disable no-shadow */
import moment from 'moment';
import { Toaster as StudioToaster } from '@brightcove/studio-components';

import * as ROUTES from '../routes/routes';
import { MessageData } from '../interfaces/ScheduledTextPanel';
import { ContentLocalesData } from '../interfaces/Content';
import config from '../config';
import { SVGImage } from '../assets/images';

import { formatCountdownTimer } from './format';

export const permissionsLabelsAndValues = [
  { value: 'USER', label: 'User Management' },
  { value: 'ROLE', label: 'Roles' },
  { value: 'LOCATION', label: 'Locations' },
  { value: 'ACCESS_CONTROL', label: 'Access Control' },
  { value: 'ACTIVITY_LOG', label: 'Activity Log' },
  { value: 'LOCALIZED_LABEL', label: 'Localized Labels' },
  { value: 'SERVICE_MESSAGE', label: 'Service Messages' },
  { value: 'MAINTENANCE_MODE', label: 'Maintenance Mode' },
  { value: 'SITE_CONFIGURATION', label: 'Site Configuration' },
  { value: 'CONTENT', label: 'Content Management' },
  { value: 'EVENT', label: 'Events' },
  { value: 'COLLECTION', label: 'Collections' },
  { value: 'PAGE', label: 'Pages' },
  { value: 'DRAFT', label: 'Drafts' },
];

export const pageFilters = [
  {
    name: 'All Pages',
    id: '',
    image: SVGImage.PagesHeaderIcon,
  },
  {
    name: 'Home Pages',
    id: 'HomePage',
  },
  {
    name: 'Video Detail Pages',
    id: 'DetailPage',
  },
  {
    name: 'Event Pages',
    id: 'EventPage',
    internal: true,
  },
  {
    name: 'Event Landing Page',
    id: 'EventLandingPage',
  },
  {
    name: 'Search Pages',
    id: 'SearchPage',
  },
  {
    name: 'Error 404 Pages',
    id: 'Error404Page',
  },
  {
    name: 'Series Pages',
    id: 'SeriesPage',
  },
  {
    name: 'Custom Pages',
    id: 'CustomPage',
  },
  {
    name: 'Standard Pages',
    id: 'StandardPage',
  },
];

export const pageSections = {
  default_sections: ['Search Bar', 'Search', 'Player', 'Video Detail', 'Series Detail', 'Event Hero'],
  custom_sections: ['Hero', 'Grid', 'Carousel', 'Custom'],
};

export const Toaster: any = (() => {
  const TOASTER_AUTOHIDE_TIME = 5; // time in seconds

  return {
    error: (options) =>
      StudioToaster.error({
        hideAfter: TOASTER_AUTOHIDE_TIME,
        ...options,
      }),
    success: (options) =>
      StudioToaster.success({
        hideAfter: TOASTER_AUTOHIDE_TIME,
        ...options,
      }),
    warn: (options) =>
      StudioToaster.warn({
        hideAfter: TOASTER_AUTOHIDE_TIME,
        ...options,
      }),
    info: (options) =>
      StudioToaster.info({
        hideAfter: TOASTER_AUTOHIDE_TIME,
        ...options,
      }),
    dismiss: (toastId) => StudioToaster.dismiss(toastId),
  };
})();

export const fetchApiData = (path, options = {}) => {
  return new Promise<any>((resolve, reject) => {
    fetch(`${config.api_url}${path}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      ...options,
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 403) {
            Toaster.error({
              message: 'You do not have permission to access this page',
            });
            reject();
          } else {
            response.json().then((data) => {
              Toaster.error({
                message: data.message,
              });
              reject(data);
            });
          }
        } else {
          resolve(response);
        }
      })
      .catch((err) => {
        Toaster.error({
          message: err.message,
        });
        reject(err);
      });
  });
};

export const isSuperAdmin = (role) => {
  return role.name.toUpperCase() === 'SUPER ADMIN';
};

export const getUrlWithOptions = (
  path,
  query = '',
  sort = '',
  filter = '',
  pageNumber: any = undefined,
  pageSize: any = undefined
) => {
  const baseUrl = `${config.api_url}${path}`;

  if (query || sort || filter || pageNumber || pageSize) {
    const urlQuery = query ? `q=${query}` : '';
    const urlSort = sort ? `sort=${sort}` : '';
    const urlFilter = filter ? `filter=${filter}` : '';
    const urlPageNumber = pageNumber ? `page=${pageNumber}` : '';
    const urlPageSize = pageSize ? `page_size=${pageSize}` : '';

    return [urlQuery, urlSort, urlFilter, urlPageNumber, urlPageSize].reduce((fullUrl, item) => {
      if (item) {
        const [key, value] = item.split('=');
        const result = `${key}=${encodeURIComponent(value)}`;

        if (fullUrl.endsWith('?')) {
          fullUrl = fullUrl = fullUrl.concat(result);
        } else {
          fullUrl = fullUrl.concat(`&${result}`);
        }
      }

      return fullUrl;
    }, `${baseUrl}?`);
  }

  return baseUrl;
};

export const parsePermissions = (permissions) => {
  return permissions.reduce(
    (newObj, item) => {
      const strToArray = item.replace('_', ' ').split(' ');
      const permission = strToArray[0].toLowerCase();
      const module = strToArray[1];

      newObj[permission]?.push(module);

      return newObj;
    },
    { create: [], read: [], update: [], delete: [], publish: [] }
  );
};

export const hasValue = (messages: MessageData[]) => {
  return messages.some((message: MessageData) => !!message.value?.length);
};

export const getMessageByLanguage = (lang, message) => {
  return message.find(({ language }) => language === lang);
};

export const getAvailableLanguages = (allLanguages, available, withNativeLabel = false) => {
  return available.map(({ language, isNativeLanguage }) => {
    const lang = allLanguages.find((lang) => lang.value === language);
    const tpmLang = Object.assign({}, lang);

    if (withNativeLabel && isNativeLanguage) {
      tpmLang.label = `Native Language: ${lang.label}`;
    }

    return tpmLang;
  });
};

export const fillMissingLanguages = (messages, languages) => {
  const allLang = languages.map((language) => {
    return { language: language.value, value: '' };
  });

  return [...allLang, ...messages].reduce((acc, curr) => {
    const langIndex = acc.findIndex((obj) => obj.language === curr.language);

    if (langIndex === -1) {
      acc.push(curr);
    } else if (curr.value !== '') {
      acc[langIndex].value = curr.value;
    }

    return acc;
  }, []);
};

export const UPDATE = 'update';
export const ADD = 'add';
export const DELETE = 'delete';
export const updateItems = (allItems, newItems, operation, prop = 'id') => {
  let filteredItems;
  const idsToDelete = newItems.map((newItem) => newItem[prop]);

  switch (operation) {
    case UPDATE:
      filteredItems = allItems.map((item) => {
        const updatedItem = newItems.find((newItem) => newItem[prop] === item[prop]);

        return updatedItem ? updatedItem : item;
      });
      break;
    case ADD:
      filteredItems = [...allItems, ...newItems];
      break;
    case DELETE:
      filteredItems = allItems.filter((item) => !idsToDelete.includes(item[prop]));
      break;
    default:
      break;
  }

  return filteredItems;
};

export const getPageCount = (totalCount, pageSize) => {
  return Math.ceil(totalCount / pageSize);
};

export const getArrayOfNames = (allItems) => {
  return allItems.map(({ name }) => name);
};
export const getItemsByNames = (currentItems, allItems) => {
  return currentItems.map((name) => {
    return getItemsIdByName(name, allItems);
  });
};
export const getItemsIdByName = (name, allItems) => {
  return allItems.find((items) => items.name === name)!;
};

export const CONTENT_SERIES = 'SeriesContent';
export const CONTENT_SEASONS = 'SeasonContent';
export const CONTENT_EPISODE = 'EpisodeContent';
export const CONTENT_MOVIE = 'MovieContent';
export const CONTENT_TRAILER = 'TrailerContent';
export const CONTENT_AD = 'AdContent';
export const EVENT_STANDARD = 'StandardEvent';
export const PAGE_CUSTOM = 'CustomPage';
export const PAGE_STANDARD = 'StandardPage';
export const COLLECTION_ITEM = 'CollectionItem';
export const DEFAULT_LANGUAGE = 'en-us';

export const getLocaleByLanguage = (
  locales: any[] = [],
  currentLanguage: string = DEFAULT_LANGUAGE
): ContentLocalesData => {
  return locales.find((locale) => locale.language === currentLanguage)!;
};

export const getNativeLanguage = (locales: any[]) => {
  return locales.find((locale) => locale.isNativeLanguage)?.language;
};

export const getCtaStatus = (start, end) => {
  if (!start) {
    return '';
  }

  const currentTime = moment();

  if (currentTime.isBefore(start)) {
    const countdownTimer = formatCountdownTimer(getTimeRemaining(new Date(start)));

    return `Scheduled: ${countdownTimer}`;
  } else if ((end && currentTime.isBetween(start, end)) || !end) {
    return 'Inserted';
  } else {
    return 'Ended';
  }
};

export const getTimeRemaining = (startTime) => {
  const t = Date.parse(startTime) - Date.parse(new Date().toString());
  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  const days = Math.floor(t / (1000 * 60 * 60 * 24));

  return {
    total: t,
    days,
    hours,
    minutes,
    seconds,
  };
};

export const hasScriptInHtml = (html: string) => {
  return new DOMParser().parseFromString(html, 'text/html').querySelector('script');
};

export const isPermissionExcluded = (type: string, value: string) => {
  const excludedPermissions = {
    create: ['USER', 'ACTIVITY_LOG', 'SITE_CONFIGURATION', 'DRAFT'],
    delete: ['ACTIVITY_LOG', 'SITE_CONFIGURATION', 'DRAFT'],
    update: ['ACTIVITY_LOG', 'DRAFT'],
    publish: [
      'ACTIVITY_LOG',
      'LOCALIZED_LABEL',
      'SERVICE_MESSAGE',
      'MAINTENANCE_MODE',
      'USER',
      'ROLE',
      'LOCATION',
      'ACCESS_CONTROL',
    ],
  };

  const excludedValues = excludedPermissions[type] || [];

  return excludedValues.includes(value);
};

export const getDuration = (startDate, endDate) => {
  return moment.duration(moment(endDate).diff(startDate)).asSeconds();
};

export const getEndTimecode = (startDate, duration) => {
  return moment(startDate).add(duration, 'seconds').toISOString();
};

export const isValidDate = (date) => {
  return new Date(date).toString() !== 'Invalid Date';
};

export const itemTypes = [
  CONTENT_SERIES,
  CONTENT_SEASONS,
  CONTENT_EPISODE,
  CONTENT_MOVIE,
  CONTENT_TRAILER,
  CONTENT_AD,
  EVENT_STANDARD,
  PAGE_CUSTOM,
  PAGE_STANDARD,
];
export const getSimplifiedItemType = (type: string): string => {
  let contentType = type?.toLowerCase();

  if (itemTypes.includes(type)) {
    contentType = contentType.replace(/standard|custom|content/g, '');
  }

  return contentType;
};

export const getItemCollectionPath = (type: string) => {
  const simplifiedType = getSimplifiedItemType(type);

  if (simplifiedType === 'event') {
    return ROUTES.EVENTS;
  } else if (simplifiedType === 'page') {
    return ROUTES.PAGES;
  } else {
    return ROUTES.CONTENT_MANAGEMENT;
  }
};

export const renameObjectKey = (object: any, oldKey: string, newKey: string) => {
  if (object && oldKey !== newKey) {
    object = { ...object, [newKey]: object[oldKey] };
    delete object[oldKey];
  }

  return object;
};
