import { useLocation, useNavigate } from 'react-router-dom';

interface LocationState {
  goBack?: {
    url: string;
    label: string;
  };
}

/**
 * Used to create a go back function depending on if it should return to the default page
 * or a custom one.
 *
 * @param defaultRoute
 * @param defaultLabel
 */
const useGoBack = (defaultRoute: string, defaultLabel: string) => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;

  const goBack = () => {
    if (state?.goBack?.url) {
      navigate(state.goBack.url);
    } else {
      navigate(defaultRoute);
    }
  };

  return {
    goBackLabel: state?.goBack?.label || defaultLabel,
    goBack,
  };
};

export default useGoBack;
