import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Flex } from '@brightcove/studio-components';

import { CTAS_PATH, EVENTS_PATH } from '../EventsDetail';
import { getUrlWithOptions, getEndTimecode } from '../../../utils';
import { CTA } from '../../../types/cta';
import { useGetCtaStatus } from '../../../hooks/useGetCtaStatus';
import { useApi } from '../../../hooks/useApi';
import Table from '../../../components/Table/Table';
import Panel from '../../../components/Panel/Panel';
import { isScheduleAvailable } from '../../../components/Modals/CtaModal/helpers';
import { CtaModal, GenericModal, InteractionSummaryModal, withModal } from '../../../components/Modals';
import TextInput from '../../../components/ControlledComponents/TextInput';
import Button from '../../../components/Button/Button';

import './InteractivityPanel.scss';

const CreateCtaButton = withModal(Button, CtaModal);
const SummaryButton = withModal(Button, InteractionSummaryModal);

type Props = {
  ctas: CTA[];
  ctaInfo: CTA;
  showAddDurationModal: boolean;
  onInsertCta: (duration: string) => void;
  onDurationModalClose: () => void;
};

const AddDurationModal: FC<Props> = ({
  ctas,
  ctaInfo,
  showAddDurationModal,
  onInsertCta,
  onDurationModalClose,
}) => {
  const [ctaDuration, setCtaDuration] = useState('');

  const handleInsertClick = (duration: string) => {
    const startTimecode = moment();
    const body: Partial<CTA> = {
      startTimecode: startTimecode.toISOString(),
      endTimecode: startTimecode.add(duration, 'seconds').toISOString(),
    };

    if (isScheduleAvailable(ctas, body, ctaInfo.id)) {
      onInsertCta(duration);
    }
  };

  useEffect(() => {
    setCtaDuration('');
  }, [showAddDurationModal]);

  return (
    <GenericModal show={showAddDurationModal}>
      <div className="Add-duration-modal">
        <Flex className="Add-duration-modal-header" alignItems="center" justifyContent="center">
          <h2>Insert CTA</h2>
        </Flex>
        <Flex
          className="Add-duration-modal-content"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div className="label required mb-4">Add Duration (in seconds)</div>
          <TextInput
            className="cta-duration-input"
            value={ctaDuration}
            property="duration"
            onChange={(value) => setCtaDuration(value)}
          />
        </Flex>
        <Flex justifyContent="center" className="Add-duration-modal-actions">
          <Button
            variant="secondary"
            text="Cancel"
            onClick={() => {
              setCtaDuration('');
              onDurationModalClose();
            }}
          />
          <Button
            variant="primary"
            text="Insert"
            disabled={!ctaDuration}
            onClick={() => handleInsertClick(ctaDuration)}
          />
        </Flex>
      </div>
    </GenericModal>
  );
};

const InteractivityPanel = ({ ctasData, eventId, disabled, onSave, id: panelId = '' }) => {
  const [ctas, setCtas] = useState<any[]>(ctasData || []);
  const [showAddDurationModal, setShowAddDurationModal] = useState(false);
  const [ctaToBeInserted, setCtaToBeInserted] = useState<any>({});
  const { apiDelete, apiPatch } = useApi();

  const interactivityColumns = [
    {
      Header: 'Title',
      id: 'name',
      accessor: (row) => row.template.name,
      Cell: ({ value, row }) => {
        const EditCtaButton = withModal(Button, CtaModal);

        return (
          <EditCtaButton
            variant="link"
            modalProps={{
              ctaInfo: row.original,
              ctasList: ctas,
              eventId,
              onUpdateCta: (updatedCta) => {
                setCtas((prevCtas) => {
                  const updatedCtas = prevCtas.map((cta: any) =>
                    cta.id === updatedCta.id ? updatedCta : cta
                  );

                  return updatedCtas;
                });
                onSave();
              },
            }}
          >
            {value}
          </EditCtaButton>
        );
      },
      className: 'cta-title',
    },
    {
      Header: 'CTA',
      id: 'cta',
      accessor: (row) => row.template.description,
      Cell: ({ value }) => <>{value || '-'}</>,
    },
    {
      Header: 'Type',
      accessor: (row) => row.template.type,
      Cell: ({ value }) => <>{_.capitalize(value)}</>,
    },
    {
      Header: 'Placement',
      accessor: 'placement',
      Cell: ({ value }) => (
        <>
          {value
            .split('_')
            .map((word) => _.capitalize(word))
            .join(' ')}
        </>
      ),
    },
    {
      Header: 'Status',
      id: 'status',
      className: 'cta-status',
      accessor: (row) => ({ startTimecode: row.startTimecode, endTimecode: row.endTimecode }),
      Cell: ({ value, row }) => {
        const status = useGetCtaStatus(value.startTimecode, value.endTimecode);

        return (
          <>
            {status || (
              <Button
                variant="link"
                text="Insert"
                onClick={() => {
                  setShowAddDurationModal(true);
                  setCtaToBeInserted(row.original);
                }}
              />
            )}
          </>
        );
      },
    },
    {
      Header: 'Summary',
      Cell: ({ row: { original } }) => {
        const status = useGetCtaStatus(original.startTimecode, original.endTimecode);

        return (
          <>
            {status === 'Inserted' || status === 'Ended' ? (
              <SummaryButton
                variant="link"
                modalProps={{
                  eventId,
                  ctaInfo: original,
                  enableAction: true,
                }}
              >
                Interaction Summary
              </SummaryButton>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
  ];

  const onDeleteCta = async (rowId) => {
    const { error } = await apiDelete(
      getUrlWithOptions(`${EVENTS_PATH}/${eventId}${CTAS_PATH}?ids=${encodeURIComponent(rowId)}`)
    );

    if (!error) {
      setCtas((prevCtas) => {
        const updatedCtas = prevCtas.filter((cta: any) => cta.id !== rowId);

        return updatedCtas;
      });
      onSave();
    }
  };

  const onInsertCta = async (duration) => {
    const { data } = await apiPatch(
      getUrlWithOptions(`${EVENTS_PATH}/${eventId}${CTAS_PATH}/${ctaToBeInserted.id}`),
      {
        body: {
          startTimecode: new Date().toISOString(),
          endTimecode: getEndTimecode(new Date(), duration),
        },
      }
    );

    if (data) {
      setCtas((prevCtas) => {
        const updatedCtas = prevCtas.map((cta: any) => (cta.id === ctaToBeInserted.id ? data : cta));

        return updatedCtas;
      });
      onDurationModalClose();
      onSave();
    }
  };

  const onDurationModalClose = () => {
    setShowAddDurationModal(false);
    setCtaToBeInserted({});
  };

  return (
    <div id={panelId} style={{ flex: 1 }}>
      <Panel
        className="interactivity-panel pb-4"
        title="User Interactivity"
        actions={
          <CreateCtaButton
            variant="primary"
            disabled={disabled}
            modalProps={{
              eventId,
              ctasList: ctas,
              onCreateCta: (newCta) => {
                setCtas((prevCtas) => {
                  const updatedCtas = prevCtas.concat(newCta);

                  return updatedCtas;
                });
                onSave();
              },
            }}
          >
            Create CTA
          </CreateCtaButton>
        }
      >
        <Table data={ctas} columns={interactivityColumns} onClickDeleteRow={onDeleteCta} hasDeletion />
      </Panel>

      <AddDurationModal
        showAddDurationModal={showAddDurationModal}
        onInsertCta={onInsertCta}
        onDurationModalClose={onDurationModalClose}
        ctas={ctas}
        ctaInfo={ctaToBeInserted}
      />
    </div>
  );
};

export default InteractivityPanel;
