import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { provideTheme, KeyCode, Tag } from '@brightcove/studio-components';
import Placeholder from './PlaceholderTag';
import css from 'styles/plain-taglist-input.sass';

/**
 * A TagList combined with an input for entering new tags.
 * `<InputGroup>` not included.
 *
 * All form inputs in this library must be implemented as [controlled
 * components](https://reactjs.org/docs/forms.html#controlled-components).
 */

class PlainTagListInput extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      inputValue: '',
      isInputFocused: false
    };

    this.focusInput = this.focusInput.bind(this);
    this.setInputRef = this.setInputRef.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
  }

  get tagFormat() {
    return typeof this.props.value[0] === 'object' ? 'object' : 'string';
  }

  get parsedInputTags() {
    return this.state.inputValue
      .split(',')
      .map(v => {
        const text = v.trim();
        if (!text) {
          return null;
        }
        return this.formatTag(text);
      })
      .filter(Boolean); // strip empty values
  }

  addTags() {
    this.setState({ inputValue: '' });
    this.props.onChange(this.generateCombinedTags());
  }

  formatTag(value) {
    if (this.tagFormat === 'string') {
      return value;
    }
    return {
      [this.props.labelKey]: value
    };
  }

  generateCombinedTags() {
    const { value, allowDuplicateTags, labelKey } = this.props;
    const combinedTags = [...value, ...this.parsedInputTags];

    if (allowDuplicateTags) {
      return combinedTags;
    }

    const uniqueTags = new Set();
    const callbackTags = [];
    combinedTags.forEach(tag => {
      const tagValue = this.tagFormat === 'string' ? tag : tag[labelKey];
      if (uniqueTags.has(tagValue)) {
        return;
      }
      uniqueTags.add(tagValue);
      callbackTags.push(tag);
    });

    return callbackTags;
  }

  handleChange(evt) {
    this.setState({
      inputValue: evt.currentTarget.value
    });
  }

  handleKeyUp(evt) {
    if (KeyCode(evt).is('comma')) {
      this.addTags();
    }
  }

  handleKeyDown(evt) {
    const { inputValue } = this.state;
    const { onChange, value, disabled } = this.props;

    if (disabled) return;

    if (KeyCode(evt).is('enter')) {
      evt.preventDefault(); // Prevents submit if in a form
      this.addTags();
    } else if (KeyCode(evt).is('backspace') && inputValue === '') {
      onChange(value.slice(0, value.length - 1));
    }
  }

  setInputRef(element) {
    this.inputRef = element;
    if (this.props.inputProps && this.props.inputProps.ref) {
      this.props.inputProps.ref(element);
    }
  }

  focusInput() {
    setTimeout(() => this.inputRef.focus());
  }

  handleInputFocus(evt) {
    // NOTE: It should be impossible to have any text in the input
    // when this function is called since it's cleared onBlur.
    this.setState({ isInputFocused: true });

    if (this.props.onFocus) {
      this.props.onFocus(this.props.value, evt);
    }

    if (this.props.inputProps.onFocus) {
      return this.props.inputProps.onFocus(evt);
    }
  }

  handleInputBlur(evt) {
    this.setState({ isInputFocused: false });

    const combinedTags = this.generateCombinedTags();

    if (this.state.inputValue.trim().length > 0) {
      this.props.onChange(combinedTags);
      this.setState({ inputValue: '' });
    }

    this.setState({ isInputFocused: false });

    if (this.props.onBlur) {
      this.props.onBlur(combinedTags, evt);
    }

    if (this.props.inputProps.onBlur) {
      return this.props.inputProps.onBlur(evt);
    }
  }

  handleRemoveItem(item, index) {
    const { value } = this.props;
    this.props.onChange([
      ...value.slice(0, index),
      ...value.slice(index + 1, value.length)
    ]);
  }

  render() {
    const {
      disabled,
      hasError,
      value,
      name,
      className,
      testName,
      maxLength,
      id,
      style,
      autoFocus,
      labelKey,
      inputProps,
      theme,
      placeholder
    } = this.props;
    const { isInputFocused } = this.state;

    const classes = classNames(css[theme], css.container, className, {
      [css.hasError]: hasError,
      [css.disabled]: disabled,
      [css.isInputFocused]: isInputFocused
    });

    const VALID_TAG_COLOR = 'primary';
    const INVALID_TAG_COLOR = 'danger';

    const getColor = isValidTag => {
      return isValidTag ? VALID_TAG_COLOR : INVALID_TAG_COLOR;
    };
    const hasPlaceholder =
      Boolean(placeholder) && value.length === 0 && !isInputFocused;

    return (
      <div
        className={classes}
        data-test-name={testName}
        style={style}
        onClick={this.focusInput}
      >
        {hasPlaceholder ? (
          <Placeholder theme={theme}>{placeholder}</Placeholder>
        ) : null}
        {value.map((item, index) => {
          const label = typeof item === 'string' ? item : item[labelKey];

          return (
            <Tag
              key={index}
              className={css.tag}
              onRemove={
                disabled ? null : () => this.handleRemoveItem(item, index)
              }
              color={getColor(item.isValid)}
              theme={theme}
            >
              {label}
            </Tag>
          );
        })}

        <input
          id={id}
          name={name}
          disabled={disabled}
          className={css.input}
          value={this.state.inputValue}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          maxLength={maxLength}
          autoFocus={autoFocus}
          {...inputProps}
          onBlur={this.handleInputBlur}
          onFocus={this.handleInputFocus}
          ref={this.setInputRef}
        />
      </div>
    );
  }
}

PlainTagListInput.defaultProps = {
  allowDuplicateTags: false,
  maxLength: 128,
  labelKey: 'label',
  inputProps: {}
};

PlainTagListInput.propTypes = {
  /** Whether or not to allow duplicate tags. */
  allowDuplicateTags: PropTypes.bool,
  /** Container class name */
  className: PropTypes.string,
  /** Disables the input and removes x links from all `Tag` components. */
  disabled: PropTypes.bool,
  /** Whether or not to outline this field in magenta. */
  hasError: PropTypes.bool,
  /** Sets the id attribute on the inner `<input>` */
  id: PropTypes.string,
  /** Additional props to set on the inner `<input>`. These will take precedence over other props on `<input>`. */
  inputProps: PropTypes.any,
  /** If provided this attribute will be set on the inner `<input>` which will make it impossible to type more than maxLength characters. */
  maxLength: PropTypes.number,
  /** Sets the name attribute on the inner `<input>` */
  name: PropTypes.string,
  /**
   * Your blur handler. Note that if the input has text in it an
   * `onChange` event will be triggered immediately before `onBlur`.
   * If you intend to validate the field at this point you should use
   * the value returned by this function
   * @param {string[]} value - An array of your tags.
   * @param {SyntheticEvent} event - The event.
   */
  onBlur: PropTypes.func,
  /**
   * Change handler for your tag list. Called whenever a tag is added to the
   * list. This can happen when the user types a comma, ENTER, DELETE, or blurs
   * the input field while text is in it. (Update your state here)
   * @param {value} string[] - An array of strings no longer than 128 chars.
   */

  onChange: PropTypes.func.isRequired,
  /**
   * Your focus handler.
   * @param {string[]} value - An array of your tags.
   * @param {SyntheticEvent} event - The event.
   */
  onFocus: PropTypes.func,
  /** Container style attribute */
  style: PropTypes.object,
  /** Whether the input is initially focused when mounted */
  autoFocus: PropTypes.bool,
  /** Test name for automation */
  testName: PropTypes.string,
  /** Theme to apply to the component */
  theme: PropTypes.string,
  /**
   * A list of tags as strings or objects that can be passed into a Tag as props.
   * You should update this value onChange.
   */
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object)
  ]).isRequired,
  /**
   * When a tag is specified in the value as an object, this tells which key in
   * the item object to use for the `Tag` text.
   */
  labelKey: PropTypes.string,
  /**
   * Placeholder tag content
   */
  placeholder: PropTypes.node
};

export default provideTheme(css)(PlainTagListInput);
