import React from 'react';
import PropTypes from 'prop-types';
import isRequiredIf from 'react-proptype-conditional-require';
import AutosuggestTagListInput from './components/AutosuggestTagListInput';
import compact from 'lodash.compact';

import EN_COUNTRY_DATA from '../data/countries-en.json';
import ES_COUNTRY_DATA from '../data/countries-es.json';
import JA_COUNTRY_DATA from '../data/countries-ja.json';
import FR_COUNTRY_DATA from '../data/countries-fr.json';
import ZH_COUNTRY_DATA from '../data/countries-zh.json';
import KO_COUNTRY_DATA from '../data/countries-ko.json';

const FALLBACK_LOCALE = 'en';

export const getCountryDataForLocale = locale => {
  const ALL_COUNTRY_DATA = {
    en: EN_COUNTRY_DATA,
    es: ES_COUNTRY_DATA,
    ja: JA_COUNTRY_DATA,
    fr: FR_COUNTRY_DATA,
    zh: ZH_COUNTRY_DATA,
    ko: KO_COUNTRY_DATA
  };
  return ALL_COUNTRY_DATA[locale]
    ? ALL_COUNTRY_DATA[locale]
    : ALL_COUNTRY_DATA[FALLBACK_LOCALE];
};

export const sanitize = input => {
  return compact(input);
};

export const getCountriesFromCodes = ({
  countries,
  locale = FALLBACK_LOCALE
}) => {
  return sanitize(countries).map(code => {
    const countryData = getCountryDataForLocale(locale);
    return countryData.find(country => country.code.toLowerCase() === code);
  });
};

export const makeAllCountriesValid = countries => {
  return sanitize(countries).map(country => {
    return { isValid: true, ...country };
  });
};

export const hasInvalidCountry = countryList => {
  for (const country of countryList) {
    if (!country.isValid) {
      return true;
    }
  }
  return false;
};

export const getValidCodesFromCountryList = countryList => {
  return sanitize(countryList).reduce((countryCodes, country) => {
    if (country.isValid) {
      countryCodes.push(country.code.toLowerCase());
    }
    return countryCodes;
  }, []);
};

export const sortCountries = ({ countries, key }) => {
  countries.sort(function(a, b) {
    if (
      typeof a === 'object' &&
      typeof b === 'object' &&
      a.hasOwnProperty(key) &&
      b.hasOwnProperty(key)
    ) {
      return String(a[key]).localeCompare(String(b[key]));
    }
    return 0;
  });
};

export const countriesToString = ({ countries, key }) => {
  return sanitize(countries)
    .map(country => country[key])
    .join(', ');
};

class CountryTagList extends React.Component {
  render() {
    const {
      locale,
      maxSuggestions,
      suggestMinChars,
      labelKey,
      value,
      onChange,
      onBlur,
      onFocus,
      inputProps,
      placeholder,
      onError,
      suggestions
    } = this.props;

    return (
      <AutosuggestTagListInput
        suggestions={suggestions || getCountryDataForLocale(locale)}
        maxSuggestions={maxSuggestions}
        suggestMinChars={suggestMinChars}
        labelKey={labelKey}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        inputProps={inputProps}
        placeholder={placeholder}
        onError={onError}
        highlightFirstSuggestion
        requireSuggestionMatch
      />
    );
  }
}

CountryTagList.defaultProps = {
  maxSuggestions: 10,
  suggestMinChars: 0,
  labelKey: 'label',
  inputProps: {}
};

CountryTagList.propTypes = {
  locale: isRequiredIf(PropTypes.string, props => !props.suggestions),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.array.isRequired,
  maxSuggestions: PropTypes.number,
  suggestMinChars: PropTypes.number,
  labelKey: PropTypes.string,
  inputProps: PropTypes.object,
  placeholder: PropTypes.node,
  onError: PropTypes.func,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  )
};

export default CountryTagList;
