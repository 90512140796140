import { useParams } from 'react-router';
import React, { FC, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { AutosaveTagListSuggestions, Flex } from '@brightcove/studio-components';

import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import Checkbox from '../ControlledComponents/Checkbox';
import Button from '../Button/Button';
import { formatDate } from '../../utils/format';
import { getArrayOfNames, getItemsByNames, getUrlWithOptions } from '../../utils';
import { CONTENT } from '../../routes/ContentManagement/DetailAdTrailer';
import { AvailabilityData } from '../../interfaces';
import { useApi } from '../../hooks/useApi';

import Panel from './Panel';

import './Availability.scss';

interface AvailabilityProps {
  idPanel: string;
  data: AvailabilityData;
  endpointRootPath: string;
  onEditModeChange?: (val: boolean, isSavedData?: boolean) => void;
  disableEditing?: boolean;
}

const Availability: FC<AvailabilityProps> = ({
  idPanel,
  data,
  endpointRootPath,
  onEditModeChange,
  disableEditing,
}) => {
  const { id } = useParams();
  const [availabilityPanelData, setAvailabilityPanelData] = useState<any>(data || {});
  const { apiGet, apiPatch, isLoading } = useApi();
  const [ntv, setNtv] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [allAccessControls, setAllAccessControls] = useState<any[]>([]);
  const [currentAccessControls, setCurrentAccessControls] = useState<any[]>([]);
  const [accessState, setAccessState] = useState<any>({});
  const [allLocations, setAllLocations] = useState<any[]>([]);
  const [currentLocations, setCurrentLocations] = useState<any[]>([]);
  const [locationsState, setLocationsState] = useState<any>({});
  const [currentStartDateString, setCurrentStartDateString] = useState<string>('');
  const [currentEndDateString, setCurrentEndDateString] = useState<string>('');
  const [savedData, setSavedData] = useState(false);
  const [, setRefresh] = useState(0);
  const isContentPage = useMemo(() => endpointRootPath === CONTENT, [endpointRootPath]);

  const onSaveAvailability = async () => {
    const body = {
      startDate: currentStartDateString || null,
      endDate: currentEndDateString || null,
      accessControls: getItemsByNames(currentAccessControls, allAccessControls).map(
        (location) => location.id
      ),
      locations: getItemsByNames(currentLocations, allLocations).map((location) => location.id),
      ...(isContentPage && { ntv }),
    };

    const response = await apiPatch(getUrlWithOptions(`${endpointRootPath}/${id}`), {
      body,
    });

    if (response?.data) {
      setEditing(false);
      setSavedData(true);
    }
  };
  useEffect(() => {
    (async () => {
      const [accessControlsResponse, locationsResponse] = await Promise.all([
        fetchAccessControls(),
        fetchLocations(),
      ]);

      if (accessControlsResponse.data) {
        setAllAccessControls(accessControlsResponse.data);
      }

      if (locationsResponse.data) {
        setAllLocations(locationsResponse.data);
      }
    })();
    setSavedData(false);
  }, []);

  useEffect(() => {
    if (!_.isEqual(availabilityPanelData, data)) {
      setAvailabilityPanelData(data);
    }
  }, [data]);

  useEffect(() => {
    const { startDate, endDate, accessControls, locations, ntv: ntvData } = availabilityPanelData;

    if (isContentPage) setNtv(ntvData);

    setCurrentStartDateString(startDate || '');
    setCurrentEndDateString(endDate || '');
    setCurrentAccessControls(accessControls ? getArrayOfNames(accessControls) : []);
    setCurrentLocations(locations?.length ? getArrayOfNames(locations) : []);
  }, [availabilityPanelData]);

  useEffect(() => {
    onEditModeChange && onEditModeChange(editing, savedData);
  }, [editing]);

  const fetchAccessControls = async () => {
    return apiGet(getUrlWithOptions(`/basic/access_controls`));
  };
  const fetchLocations = async () => {
    return apiGet(getUrlWithOptions(`/basic/locations`));
  };

  const forceUpdate = () => {
    setTimeout(() => setRefresh((current) => current + 1), 10);
  };

  const restoreData = () => {
    setCurrentAccessControls(data?.accessControls?.length ? getArrayOfNames(data.accessControls) : []);
    setCurrentLocations(data?.locations?.length ? getArrayOfNames(data.locations) : []);
    setCurrentStartDateString(data?.startDate?.length ? data.startDate : '');
    setCurrentEndDateString(data?.endDate?.length ? data.endDate : '');
  };

  const onStartDateChange = (value?: Date | null) => {
    if (!value) {
      setCurrentStartDateString('');
    } else {
      setCurrentStartDateString(value.toISOString());
    }

    forceUpdate();
  };

  const onEndDateChange = (value?: Date | null) => {
    if (!value) {
      setCurrentEndDateString('');
    } else {
      setCurrentEndDateString(value.toISOString());
    }

    forceUpdate();
  };

  const currentStartDate = currentStartDateString ? new Date(currentStartDateString) : null;
  const currentEndDate = currentEndDateString ? new Date(currentEndDateString) : null;

  return (
    <div id={idPanel} className="Availability">
      <Panel
        title="Availability"
        actions={
          <>
            {editing ? (
              <>
                <Button
                  variant="secondary"
                  text="Cancel"
                  onClick={() => {
                    setEditing(false);
                    restoreData();
                  }}
                />
                <Button variant="primary" text="Save" onClick={onSaveAvailability} disabled={isLoading} />
              </>
            ) : (
              <Button
                variant="primary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);
                }}
                disabled={disableEditing}
              />
            )}
          </>
        }
      >
        <>
          {editing ? (
            <>
              <Flex flexWrap="wrap">
                <div className="ml-4">
                  <p className="title">Start date</p>
                  <CustomDatePicker
                    value={currentStartDate}
                    max={currentEndDate || undefined}
                    onChange={onStartDateChange}
                  />
                </div>
                <Flex flexDirection="column" justifyContent="space-between" className="ml-12">
                  <div className="ml-5">
                    <p className="title ">End date</p>
                    <CustomDatePicker
                      value={currentEndDate}
                      min={currentStartDate || undefined}
                      onChange={onEndDateChange}
                    />
                  </div>
                </Flex>
              </Flex>
              <Flex flexDirection="column" className="mt-4 Autocomplete-container">
                <div className="ml-4 mb-4">
                  <AutosaveTagListSuggestions
                    suggestions={allLocations ? getArrayOfNames(allLocations) : []}
                    requireSuggestionMatch
                    className="AutoCompleteTags"
                    fieldId="locationControlsField"
                    label="Select Locations"
                    value={currentLocations}
                    editing={locationsState.editing || false}
                    saving={locationsState.saving || false}
                    saved={locationsState.saved || false}
                    onStartEditing={() => setLocationsState({ editing: true })}
                    onCancel={() => setLocationsState({ editing: false })}
                    onSave={(value) => {
                      setLocationsState({ saved: true });
                      setCurrentLocations(value);
                    }}
                  />
                </div>
                <div className="ml-4">
                  <AutosaveTagListSuggestions
                    suggestions={allAccessControls ? getArrayOfNames(allAccessControls) : []}
                    requireSuggestionMatch
                    className="AutoCompleteTags"
                    fieldId="accessControlsField"
                    label="Access Controls"
                    value={currentAccessControls}
                    editing={accessState.editing || false}
                    saving={accessState.saving || false}
                    saved={accessState.saved || false}
                    onStartEditing={() => setAccessState({ editing: true })}
                    onCancel={() => setAccessState({ editing: false })}
                    onSave={(value) => {
                      setAccessState({ saved: true });
                      setCurrentAccessControls(value);
                    }}
                  />
                </div>
                {isContentPage && (
                  <div className="ml-4">
                    <Checkbox className="mt-4" label="NTV" checked={ntv} onChange={() => setNtv(!ntv)} />
                  </div>
                )}
              </Flex>
            </>
          ) : (
            <Flex>
              <div>
                <div className="ml-4">
                  <p className="title">Start date</p>
                  <p className="description">
                    {currentStartDateString?.length ? formatDate(currentStartDateString) : '-'}
                  </p>
                </div>

                <div className="ml-4 mt-6">
                  <p className="title">Locations</p>
                  <p className="description">
                    {currentLocations?.length ? currentLocations.join(', ') : '-'}
                  </p>
                </div>
                <div className="ml-4 mt-6">
                  <p className="title">Access Controls</p>
                  <p className="description">
                    {currentAccessControls?.length ? currentAccessControls.join(', ') : '-'}
                  </p>
                </div>
                {isContentPage && (
                  <div className="ml-4 mt-6 ">
                    <Checkbox
                      checked={ntv}
                      disabled
                      onChange={() => {
                        // eslint-disable-next-line no-console
                        console.log('checkbox onChange');
                      }}
                      label="NTV"
                    />
                  </div>
                )}
              </div>
              <Flex flexDirection="column" justifyContent="space-between" className="ml-12">
                <div className="ml-5">
                  <p className="title ">End date</p>
                  <p className="description">
                    {currentEndDateString?.length ? formatDate(currentEndDateString) : '-'}
                  </p>
                </div>
              </Flex>
            </Flex>
          )}
        </>
      </Panel>
    </div>
  );
};

export default Availability;
