import React, { useState, useEffect } from 'react';
import { Flex } from '@brightcove/studio-components';

import { getUrlWithOptions } from '../../utils';
import { useApi } from '../../hooks/useApi';
import SubHeader from '../../components/SubHeader/SubHeader';
import PublicationStateButtons from '../../components/PublicationStateButtons/PublicationStateButtons';
import TemplatePanel from '../../components/Panel/TemplatePanel';
import Loading from '../../components/Loading/Loading';

import MenuPanel from './Panels/MenuPanel';
import './SiteConfiguration.scss';

const siteConfigUrl = getUrlWithOptions('/site_configuration');

const SiteConfiguration = () => {
  const [siteConfig, setSiteConfig] = useState<any>({});
  const [disableEditing, setDisableEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const { apiGet, apiPut, apiPatch } = useApi();

  useEffect(() => {
    (async () => {
      const { data } = await apiGet(siteConfigUrl);

      setSiteConfig(data);
      setLoading(false);
    })();
  }, [refetch]);

  const toggleDisableEditing = () => setDisableEditing(!disableEditing);

  const onSave = async (propertyName: string, value: any): Promise<any> => {
    const body = {
      [propertyName]: value,
    };
    const { data, error } = await apiPatch(siteConfigUrl, {
      body,
    });

    if (error) {
      return { error };
    }

    if (data) {
      setSiteConfig(data);
    }

    toggleDisableEditing();

    return {};
  };

  const updatePublicationStatus = async (updateType) => {
    const url = `${siteConfigUrl}/${updateType}`;
    const { data } = await apiPut(url);

    if (data) {
      setSiteConfig({ ...siteConfig, ...data });

      if (updateType === 'revert') {
        setRefetch((prevRefetch) => !prevRefetch);
      }
    }
  };

  const publishChanges = async (publishDate) => {
    const { data } = await apiPut(`${siteConfigUrl}/publish`, {
      body: {
        scheduledPublishAt: publishDate?.length > 0 ? new Date(publishDate) : undefined,
      },
    });

    if (data) {
      setSiteConfig({ ...siteConfig, ...data });
    }
  };

  const defaultPanelProps = {
    onCancel: toggleDisableEditing,
    onSave,
    disableEditing,
    onEnableEditing: toggleDisableEditing,
  };
  const panelProps = [
    {
      title: 'Header Menu Template',
      data: siteConfig?.headerTemplate,
      propertyName: 'headerTemplate',
    },
    {
      title: 'Header Menu',
      data: siteConfig?.headerItems,
      propertyName: 'headerItems',
    },
    {
      title: 'Footer Template',
      data: siteConfig?.footerTemplate,
      propertyName: 'footerTemplate',
    },
    {
      title: 'Footer',
      data: siteConfig?.footerItems,
      propertyName: 'footerItems',
    },
    {
      title: 'Pages',
      data: siteConfig?.pages,
      propertyName: 'pages',
    },
    {
      title: 'Settings',
      data: siteConfig?.settingsItems,
      propertyName: 'settingsItems',
    },
    {
      title: 'Additional Script - Header',
      data: siteConfig?.headerHtml,
      propertyName: 'headerHtml',
    },
    {
      title: 'Additional Script - Footer',
      data: siteConfig?.footerHtml,
      propertyName: 'footerHtml',
    },
  ];

  const propertiesWithTemplate = ['headerTemplate', 'footerTemplate', 'headerHtml', 'footerHtml'];

  return (
    <div className="Site-configuration">
      <SubHeader
        title={'Site Configuration'}
        subtitle={'NetApp TV'}
        actions={
          <PublicationStateButtons
            disabled={disableEditing}
            publication={siteConfig?.publication}
            allowRevert={true}
            onRevert={() => updatePublicationStatus('revert')}
            onPublish={(val) => publishChanges(val)}
            onUnschedule={() => updatePublicationStatus('unschedule')}
          />
        }
      />
      <Flex className="Site-configuration-content py-8 px-11" flexDirection="column">
        {loading ? (
          <Loading />
        ) : (
          <>
            {panelProps.map((props, index) => {
              const ComponentName = propertiesWithTemplate.includes(props.propertyName)
                ? TemplatePanel
                : MenuPanel;

              return <ComponentName key={index} {...(props as any)} {...defaultPanelProps} />;
            })}
          </>
        )}
      </Flex>
    </div>
  );
};

export default SiteConfiguration;
