import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { PlainRadioGroup, RadioOption } from '@brightcove/studio-components';

import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';

import './RadioGroupDatePicker.scss';

interface RadioGroupDatePickerProps {
  placeholder?: string;
  date: string;
  minDate?: string;
  maxDate?: string;
  label: string;
  editing: boolean;
  onChangeDatePicker: (val: string) => void;
}

const IMMEDIATELY = 'immediately';
const SCHEDULED = 'scheduled';
const CUSTOM_DATE_FORMAT = 'M/D/YY, H:mm A';

export const RadioGroupDatePicker: FC<RadioGroupDatePickerProps> = ({
  placeholder,
  date,
  minDate,
  maxDate,
  onChangeDatePicker,
  editing,
  label,
}) => {
  const [type, setType] = useState(date.length ? SCHEDULED : IMMEDIATELY);
  const [selectedDate, setSelectedDate] = useState(editing && !!date.length ? new Date(date) : undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [timeClassName, setTimeClassName] = useState('');
  const datePickerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setIsOpen(false);

        if (selectedDate) {
          onChangeDatePicker(selectedDate.toString());
        }
      }
    },
    [selectedDate]
  );

  const handleEscapePress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        setIsOpen(false);

        if (selectedDate) {
          onChangeDatePicker(selectedDate.toString());
        }
      }
    },
    [selectedDate]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapePress);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [selectedDate]);

  useEffect(() => {
    if (type === IMMEDIATELY) {
      setSelectedDate(undefined);
      onChangeDatePicker('');
    } else {
      const currentValue = editing && !!date.length ? new Date(date) : new Date();
      onChangeDatePicker(currentValue.toString());
    }
  }, [type]);

  return (
    <>
      <PlainRadioGroup name="names" value={type} onChange={setType} className="Radio-group-date-picker">
        <RadioOption label={label} value={IMMEDIATELY} />

        <RadioOption label="" value={SCHEDULED} className="Radio-group-date-picker-schedule">
          {(selected) => {
            return (
              <div ref={datePickerRef} onClick={() => setIsOpen(!isOpen)}>
                <CustomDatePicker
                  timeInputProps={{ use12HourClock: true, className: timeClassName }}
                  placeholder={placeholder || 'Select Date'}
                  defaultValue={editing && !!selectedDate ? selectedDate : undefined}
                  currentDate={new Date()}
                  min={
                    minDate
                      ? moment(minDate).subtract(1, 'hours').toDate()
                      : moment().subtract(1, 'hours').toDate()
                  }
                  max={maxDate ? new Date(maxDate) : undefined}
                  disabled={!selected}
                  customFormat={CUSTOM_DATE_FORMAT}
                  onChange={(value) => {
                    const currentTime = moment();
                    const specificDate = moment(value, 'YYYY-MM-DD HH:mm:ss');

                    if (specificDate.isBefore(currentTime)) {
                      setTimeClassName('invalid-time');
                    } else {
                      setTimeClassName('');
                    }

                    value && setSelectedDate(value);
                  }}
                />
              </div>
            );
          }}
        </RadioOption>
      </PlainRadioGroup>
    </>
  );
};
