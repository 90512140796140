import { connect } from 'react-redux';

import { setUser } from './actions';

const storePropsMapper = (state) => {
  return { store: state };
};

const storeActionsMapper = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
  };
};

const withStore = connect(storePropsMapper, storeActionsMapper);

export default withStore;
