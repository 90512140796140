import { CSSProperties } from 'react';
import { pick } from 'lodash';
import { Toaster } from '@brightcove/studio-components';

import { CTA, CTAPlacement, CTATemplate, CTAType } from '../../../types/cta';

export const placementOptions: { value: CTAPlacement; style: CSSProperties }[] = [
  { value: 'TOP_LEFT', style: { justifyContent: 'start' } },
  { value: 'BOTTOM_LEFT', style: { alignItems: 'end' } },
  { value: 'TOP_RIGHT', style: { justifyContent: 'end' } },
  { value: 'BOTTOM_RIGHT', style: { justifyContent: 'end', alignItems: 'end' } },
];

export const templateOptions: { label: string; value: CTAType }[] = [
  { label: 'Link CTA', value: 'LINK' },
  { label: 'Poll', value: 'POLL' },
  { label: 'Survey', value: 'SURVEY' },
  { label: 'Quiz', value: 'QUIZ' },
];

export const defaultCTA: CTA = {
  placement: placementOptions[0].value,
  template: {
    type: templateOptions[0].value,
  },
};

const completeCtaTemplate: Omit<CTATemplate, 'type'> = {
  name: '',
  description: '',
  clickUrl: '',
  clickLabel: '',
  imageUrl: '',
  question: '',
  answers: [''],
  questionAnswers: [
    {
      question: '',
    },
  ],
};

export const requiredCtaTemplateFields: Record<CTAType, (keyof CTATemplate)[]> = {
  LINK: ['type', 'name', 'description', 'clickLabel', 'clickUrl', 'imageUrl'],
  POLL: ['type', 'name', 'question'],
  SURVEY: ['type', 'name', 'questionAnswers'],
  QUIZ: ['type', 'name', 'questionAnswers'],
};

export const getCtaJsonByType = (type: CTAType): CTA => {
  const placement = placementOptions[0].value;

  return {
    placement,
    template: {
      type,
      ...pick(completeCtaTemplate, requiredCtaTemplateFields[type]),
    },
  };
};

export const getMissingParametersMessage = (type: CTAType, cta: CTA): string => {
  const requiredFields: (keyof CTA)[] = ['placement', 'template'];
  const baseFields = requiredFields.filter((field) => !cta[field]);

  if (baseFields?.length) {
    return `Missing parameters: ${baseFields.join(', ')}`;
  }

  const requiredTemplateFields = requiredCtaTemplateFields[type];

  if (!requiredTemplateFields?.length) {
    return `Wrong template type, please select one of LINK, POLL, SURVEY or QUIZ.`;
  }

  const templateFields = requiredTemplateFields.filter((field) => !cta.template[field]);

  if (templateFields?.length) {
    return `Missing template parameters: ${templateFields.join(', ')}`;
  }

  return '';
};

export const isScheduleAvailable = (ctasList: CTA[], body: Partial<CTA>, ctaId?: string): boolean => {
  const { startTimecode, endTimecode } = body;

  if (!startTimecode || !endTimecode) return true;

  const hasSomeOverlap = ctasList?.some((item) => {
    if (item?.startTimecode && item?.endTimecode) {
      const isBetweenStart = startTimecode >= item.startTimecode && item.endTimecode >= startTimecode;
      const isBetweenEnd = endTimecode >= item.startTimecode && item.endTimecode >= endTimecode;
      const contains = startTimecode <= item.startTimecode && endTimecode >= item.endTimecode;

      return (isBetweenStart || isBetweenEnd || contains) && ctaId !== item.id;
    }

    return false;
  });

  if (hasSomeOverlap) {
    Toaster.error({ message: 'The CTA is overlapping other ones, verify it and try again.' });
  }

  return !hasSomeOverlap;
};
