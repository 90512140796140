import React, { FC, useEffect, useState } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import { getUrlWithOptions } from '../../../utils';
import { SpeakersData } from '../../../interfaces/Content';
import { useApi } from '../../../hooks/useApi';
import Panel from '../../../components/Panel/Panel';
import TextInput from '../../../components/ControlledComponents/TextInput';
import TextArea from '../../../components/ControlledComponents/TextArea';
import Button from '../../../components/Button/Button';
import './Speakers.scss';

interface SpeakersProps {
  idPanel?: string;
  data: {
    id: string;
    currentLanguage: string;
    speakers: SpeakersData[]
  };
  disabledEdit?: boolean;
  onEditChange?: (val: boolean, isSaving?: boolean) => void;
}
const initialData: SpeakersData = {
  name: '',
  bio: '',
  imageUrl: '',
  position: 99,
  title: '',
};
const fillSpeakers = (speakers: SpeakersData[]) => {
  const length = speakers.length;
  const QUANTITY = 5;

  if (length < QUANTITY) {
    const emptySpeakers = Array.from({ length: QUANTITY - length }, () => Object.assign({}, initialData));

    return [...speakers, ...emptySpeakers];
  } else {
    return [...speakers];
  }
};
const orderSpeakers = (speakers: SpeakersData[]) => {
  const sortedSpeakers = [...speakers];

  return sortedSpeakers.sort((a, b) => a.position - b.position);
};
const Speakers: FC<SpeakersProps> = ({ idPanel, data, disabledEdit, onEditChange }) => {
  const { apiPut } = useApi();
  const { id, currentLanguage, speakers } = data;
  const [editing, setEditing] = useState<boolean>(false);
  const [currentSpeakers, setCurrentSpeakers] = useState<SpeakersData[]>([]);
  const [canBeSubmitted, setCanBeSubmitted] = useState<boolean>(true);

  useEffect(() => {
    if (data && speakers) {
      setCurrentSpeakers(orderSpeakers(fillSpeakers([...speakers])));
    }
  }, [speakers]);

  const handleSpeakers = (value: string, index: number, key: string) => {
    const editedSpeakers = [...currentSpeakers];

    editedSpeakers[index][key] = value;
    editedSpeakers[index].position = index;

    const canBeSubmitted = editedSpeakers.every((speaker) => {
      const hasOtherData = Object.keys(speaker).some(
        (key) => key !== 'position' && key !== 'name' && speaker[key].length > 0
      );

      return !hasOtherData || (hasOtherData && speaker.name.length > 0);
    });

    setCanBeSubmitted(canBeSubmitted);
    setCurrentSpeakers(editedSpeakers);
  };

  const onSaveInformation = async () => {
    const filteredSpeakers = currentSpeakers.filter((speaker: SpeakersData) => speaker.name);
    const editedPosition = filteredSpeakers.map((speaker, index) => {
      speaker.position = index;

      return speaker;
    });
    const body = {
      speakers: editedPosition,
    };
    const response = await apiPut(getUrlWithOptions(`/content/${id}/locales/${currentLanguage}`), {
      body,
    });

    if (response?.data) {
      setCurrentSpeakers(orderSpeakers(fillSpeakers([...response.data.speakers])));
      setEditing(false);

      if (onEditChange) {
        onEditChange(!editing, true);
      }
    }
  };
  const onClearSpeaker = (index) => {
    const allSpeakers = [...currentSpeakers];

    allSpeakers[index] = initialData;
    setCurrentSpeakers(allSpeakers);
  };

  return (
    <div id={idPanel} className="Speakers w-100">
      <Panel
        className="Speakers-button-disabled"
        title="Speakers"
        actions={
          <>
            {editing ? (
              <>
                <Button
                  variant="secondary"
                  text="Cancel"
                  onClick={() => {
                    setEditing(false);

                    if (onEditChange) {
                      onEditChange(!editing);
                    }
                  }}
                />
                <Button
                  variant="primary"
                  text="Save"
                  onClick={onSaveInformation}
                  disabled={!canBeSubmitted}
                />
              </>
            ) : (
              <Button
                variant="primary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);

                  if (onEditChange) {
                    onEditChange(!editing);
                  }
                }}
                disabled={disabledEdit}
              />
            )}
          </>
        }
      >
        <>
          {editing ? (
            <>
              {currentSpeakers?.length
                ? currentSpeakers.map((speaker: SpeakersData, index: number) => {
                    return (
                      <div key={index}>
                        <Flex alignItems="center">
                          <b className="ml-4 mt-1">{`Speaker ${index + 1}`}</b>
                          <Flex alignItems="center">
                            <Icon name={ICONS.REMOVE} color={'red'} className="ml-3" />
                            <Button
                              className="delete-rule-btn ml-1 mt-1"
                              variant="link"
                              onClick={() => onClearSpeaker(index)}
                            >
                              Clear Data
                            </Button>
                          </Flex>
                        </Flex>
                        <Flex className="pt-0">
                          <div className="ml-4">
                            <p className="title">Name *</p>
                            <TextInput
                              value={speaker.name}
                              onChange={(val: string) => {
                                handleSpeakers(val, index, 'name');
                              }}
                            />
                          </div>
                          <div className="ml-4">
                            <p className="title">Title</p>
                            <TextInput
                              value={speaker.title?.length ? speaker.title : ''}
                              onChange={(val: string) => {
                                handleSpeakers(val, index, 'title');
                              }}
                            />
                          </div>
                          <div className="ml-4">
                            <p className="title">Image URL</p>
                            <TextInput
                              value={speaker.imageUrl}
                              onChange={(val: string) => {
                                handleSpeakers(val, index, 'imageUrl');
                              }}
                            />
                          </div>
                        </Flex>
                        <div className="ml-4 mt-8">
                          <p className="title">Description</p>
                          <TextArea
                            value={speaker.bio}
                            onChange={(val: string) => {
                              handleSpeakers(val, index, 'bio');
                            }}
                          />
                        </div>
                        {currentSpeakers.length > 1 ? <div className="divider-line mt-4 mb-4"></div> : null}
                      </div>
                    );
                  })
                : null}
            </>
          ) : (
            <div className="ml-4">
              {!!currentSpeakers.length && currentSpeakers[0]?.name
                ? currentSpeakers.map((speaker: SpeakersData, index: number) => {
                    if (speaker.name?.length) {
                      return (
                        <div key={index}>
                          <b>
                            {`Speaker
                              ${speaker.position + 1}`}
                          </b>
                          <Flex>
                            <div className="w-25">
                              <Flex>
                                <div className="mt-6 w-50">
                                  <p className="title">Name</p>
                                  <p className="description">{speaker.name?.length ? speaker.name : '-'}</p>
                                </div>
                                <div className="ml-6 mt-6">
                                  <p className="title">Title</p>
                                  <p className="description">{speaker.title?.length ? speaker.title : '-'}</p>
                                </div>
                              </Flex>
                              <div className="mt-6">
                                <p className="title">Description</p>
                                <p className="description">{speaker.bio?.length ? speaker.bio : '-'}</p>
                              </div>
                            </div>
                            <div className="ml-6 mt-6 ">
                              <p className="title">Image</p>
                              {speaker.imageUrl?.length ? (
                                <div className="image-container">
                                  <img src={speaker.imageUrl} alt={speaker.name} />
                                </div>
                              ) : (
                                <div className="image-container"></div>
                              )}
                            </div>
                          </Flex>
                          {currentSpeakers.length > 1 ? <div className="divider-line mt-4 mb-4"></div> : null}
                        </div>
                      );
                    }
                  })
                : '-'}
            </div>
          )}
        </>
      </Panel>
    </div>
  );
};

export default Speakers;
