import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Flex, ICONS, Icon } from '@brightcove/studio-components';

import EditPanel from '../../../components/Panel/EditPanel';
import TextInput from '../../../components/ControlledComponents/TextInput';
import Checkbox from '../../../components/ControlledComponents/Checkbox';
import './EventPanel.scss';

const EventPanel = ({ event: eventProp, onSave, disableEditing, toggleDisableEditing, id: panelId = '' }) => {
  const [event, setEvent] = useState(eventProp || {});

  useEffect(() => setEvent(eventProp), [eventProp]);

  const onUpdateEventField = (field, value) => {
    setEvent({ ...event, [field]: value });
  };

  return (
    <EditPanel
      id={panelId}
      className="event-panel"
      title="Event"
      save={() =>
        onSave(_.pick(event, ['brightcovePlayerId', 'simulive', 'enableChat', 'enableQnA', 'enableInteractivity']))
      }
      disableEditing={disableEditing}
      onEnableEditing={toggleDisableEditing}
      onCancel={() => {
        setEvent(eventProp);
        toggleDisableEditing();
      }}
    >
      {(isEditing) => (
        <div className="px-4 pt-5 pb-6">
          <div className="mb-4">
            <div className="panel-label mb-1">ID</div>
            <div>{event.id}</div>
          </div>
          <div>
            <div className="panel-label mb-1">Player ID</div>
            {isEditing ? (
              <TextInput
                value={event.brightcovePlayerId || ''}
                onChange={(value) => onUpdateEventField('brightcovePlayerId', value)}
              />
            ) : (
              <div>{event.brightcovePlayerId}</div>
            )}
          </div>
          {[
            { label: 'Simulive', value: 'simulive' },
            { label: 'Enable chat', value: 'enableChat' },
            { label: 'Enable Q&A', value: 'enableQnA' },
            { label: 'Enable interactivity', value: 'enableInteractivity' },
          ].map(({ label, value }, index) => {
            if (isEditing) {
              return (
                <Checkbox
                  key={index}
                  className="mt-4"
                  label={label}
                  checked={event[value]}
                  onChange={(e) => onUpdateEventField(value, e.target.checked)}
                />
              );
            } else {
              return (
                <Flex key={index} className="mt-5">
                  {event[value] ? (
                    <Icon className="mr-3" name={ICONS.CHECK2} />
                  ) : (
                    <div style={{ marginRight: '0.8rem', padding: '0 0.3rem' }}>-</div>
                  )}
                  <div>{label}</div>
                </Flex>
              );
            }
          })}
        </div>
      )}
    </EditPanel>
  );
};

export default EventPanel;
