import { useLocation, BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import React, { useEffect } from 'react';
import { flowRight as compose } from 'lodash';
import { Toaster, initialize } from '@brightcove/studio-components';

import { Permission } from './utils/types';
import createStore, { withStore } from './store';
import * as ROUTES from './routes/routes';
import UserManagementDetail from './routes/UserManagement/UserManagementDetail';
import UserManagement from './routes/UserManagement/UserManagement';
import SiteConfiguration from './routes/SiteConfiguration/SiteConfiguration';
import ServiceMessages from './routes/ServiceMessages/ServiceMessages';
import RolesDetail from './routes/Roles/RolesDetail';
import Roles from './routes/Roles/Roles';
import Pages from './routes/Pages/Pages';
import PageDetail from './routes/Pages/PageDetail';
import CreatePage from './routes/Pages/CreatePage';
import PageSection from './routes/PageSections/PageSection';
import MyProfile from './routes/MyProfile/MyProfile';
import MaintenanceMode from './routes/MaintenanceMode/MaintenanceMode';
import LocationsDetail from './routes/Locations/LocationsDetail';
import Locations from './routes/Locations/Locations';
import LocalizedLabels from './routes/LocalizedLabels/LocalizedLabels';
import LocalizedLabelsDetail from './routes/LocalizedLabels/LocalizedLabelDetail';
import EventsDetail from './routes/Events/EventsDetail';
import EventsCreate from './routes/Events/EventsCreate';
import Events from './routes/Events/Events';
import DraftChanges from './routes/DraftChanges/DraftChanges';
import ContentManagementDetail from './routes/ContentManagement/ContentManagementDetail';
import ContentManagementCreate from './routes/ContentManagement/ContentManagementCreate';
import ContentManagement from './routes/ContentManagement/ContentManagement';
import CollectionsDetail from './routes/Collections/CollectionsDetail';
import CollectionsCreate from './routes/Collections/CollectionsCreate';
import Collections from './routes/Collections/Collections';
import ActivityLogDetail from './routes/ActivityLog/ActivityLogDetail';
import ActivityLog from './routes/ActivityLog/ActivityLog';
import AccessControlsDetail from './routes/AccessControls/AccessControlsDetail';
import AccessControls from './routes/AccessControls/AccessControls';
import { usePermissions } from './hooks/usePermissions';
import ScrollToTop from './helpers/ScrollToTop';
import config from './config';
import { withRouter } from './components/withRouter/withRouter';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ErrorScreen from './components/ErrorScreen/ErrorScreen';
import AuthenticationCheck from './auth/AuthenticationCheck';

initialize();

const store = createStore({});

const AuthenticatedUserRoutes = () => {
  const { hasPermission } = usePermissions();

  const renderRoute = (element: JSX.Element, permission?: Permission) => {
    return hasPermission(permission) ? element : <ErrorScreen />;
  };

  return (
    <Routes>
      <Route path="/" element={renderRoute(<ContentManagement />, 'READ_CONTENT')} />
      <Route path={ROUTES.MY_PROFILE} element={<MyProfile />} />
      <Route
        path={ROUTES.SERVICE_MESSAGES}
        element={renderRoute(<ServiceMessages />, 'READ_SERVICE_MESSAGE')}
      />
      <Route
        path={ROUTES.MAINTENANCE_MODE}
        element={renderRoute(<MaintenanceMode />, 'READ_MAINTENANCE_MODE')}
      />
      <Route
        path={ROUTES.SITE_CONFIGURATION}
        element={renderRoute(<SiteConfiguration />, 'READ_SITE_CONFIGURATION')}
      />
      <Route path={ROUTES.DRAFT_CHANGES} element={renderRoute(<DraftChanges />, 'READ_DRAFT')} />
      <Route path={ROUTES.ACCESS_CONTROLS} element={renderRoute(<AccessControls />, 'READ_ACCESS_CONTROL')} />
      <Route
        path={`${ROUTES.ACCESS_CONTROLS}/create`}
        element={renderRoute(<AccessControlsDetail />, 'CREATE_ACCESS_CONTROL')}
      />
      <Route
        path={`${ROUTES.ACCESS_CONTROLS}/edit/:id`}
        element={renderRoute(<AccessControlsDetail />, 'READ_ACCESS_CONTROL')}
      />
      <Route path={`${ROUTES.CONTENT_MANAGEMENT}`} element={<Navigate to="/" replace />} />
      <Route
        path={`${ROUTES.CONTENT_MANAGEMENT}/edit/:id`}
        element={renderRoute(<ContentManagementDetail />, 'READ_CONTENT')}
      />
      <Route
        path={`${ROUTES.CONTENT_MANAGEMENT}/create`}
        element={renderRoute(<ContentManagementCreate />, 'CREATE_CONTENT')}
      />
      <Route path={ROUTES.USER_MANAGEMENT} element={renderRoute(<UserManagement />, 'READ_USER')} />
      <Route
        path={`${ROUTES.USER_MANAGEMENT}/edit/:id`}
        element={renderRoute(<UserManagementDetail />, 'READ_USER')}
      />
      <Route path={ROUTES.ROLES} element={renderRoute(<Roles />, 'READ_ROLE')} />
      <Route path={`${ROUTES.ROLES}/create`} element={renderRoute(<RolesDetail />, 'CREATE_ROLE')} />
      <Route path={`${ROUTES.ROLES}/edit/:id`} element={renderRoute(<RolesDetail />, 'UPDATE_ROLE')} />
      <Route path={ROUTES.ACTIVITY_LOG} element={renderRoute(<ActivityLog />, 'READ_ACTIVITY_LOG')} />
      <Route
        path={`${ROUTES.ACTIVITY_LOG}/:id`}
        element={renderRoute(<ActivityLogDetail />, 'READ_ACTIVITY_LOG')}
      />
      <Route
        path={ROUTES.LOCALIZED_LABELS}
        element={renderRoute(<LocalizedLabels />, 'READ_LOCALIZED_LABEL')}
      />
      <Route
        path={`${ROUTES.LOCALIZED_LABELS}/create`}
        element={renderRoute(<LocalizedLabelsDetail />, 'CREATE_LOCALIZED_LABEL')}
      />
      <Route
        path={`${ROUTES.LOCALIZED_LABELS}/edit/:id`}
        element={renderRoute(<LocalizedLabelsDetail />, 'UPDATE_LOCALIZED_LABEL')}
      />
      <Route path={ROUTES.COLLECTIONS} element={renderRoute(<Collections />, 'READ_COLLECTION')} />
      <Route
        path={`${ROUTES.COLLECTIONS}/create`}
        element={renderRoute(<CollectionsCreate />, 'CREATE_COLLECTION')}
      />
      <Route
        path={`${ROUTES.COLLECTIONS}/edit/:id`}
        element={renderRoute(<CollectionsDetail />, 'READ_COLLECTION')}
      />
      <Route path={`${ROUTES.PAGES}`} element={renderRoute(<Pages />, 'READ_PAGE')} />
      <Route path={`${ROUTES.PAGES}/create`} element={renderRoute(<CreatePage />, 'CREATE_PAGE')} />
      <Route path={`${ROUTES.PAGES}/edit/:id`} element={renderRoute(<PageDetail />, 'READ_PAGE')} />
      <Route
        path={`${ROUTES.PAGES}/edit/:pageId/sections/:id`}
        element={renderRoute(<PageSection />, 'READ_PAGE')}
      />
      <Route path={ROUTES.LOCATIONS} element={renderRoute(<Locations />, 'READ_LOCATION')} />
      <Route
        path={`${ROUTES.LOCATIONS}/create`}
        element={renderRoute(<LocationsDetail />, 'CREATE_LOCATION')}
      />
      <Route
        path={`${ROUTES.LOCATIONS}/edit/:id`}
        element={renderRoute(<LocationsDetail />, 'READ_LOCATION')}
      />
      <Route path={ROUTES.EVENTS} element={renderRoute(<Events />, 'READ_EVENT')} />
      <Route path={`${ROUTES.EVENTS}/create`} element={renderRoute(<EventsCreate />, 'CREATE_EVENT')} />
      <Route path={`${ROUTES.EVENTS}/edit/:id`} element={renderRoute(<EventsDetail />, 'READ_EVENT')} />
      <Route path={ROUTES.ERROR} element={<ErrorScreen />} />
    </Routes>
  );
};

const AppWrapper = ({ store: { user } }) => {
  const location = useLocation();
  const isErrorPage = location.pathname === ROUTES.ERROR;

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`NetApp Admin - environment: ${config.environment} | version ${config.version}`);
  }, []);

  return (
    <div className="app-layout">
      {(user || isErrorPage) && <Header />}
      <div className="app-body">
        <Toaster />
        {user && <AuthenticatedUserRoutes />}
        {!user && (
          <Routes>
            <Route path={ROUTES.ERROR} element={<ErrorScreen />} />
          </Routes>
        )}
      </div>
      <Footer />
    </div>
  );
};

const AppBody = compose(withRouter, withStore)(AppWrapper);

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <ReduxProvider store={store}>
        <AuthenticationCheck>
          <AppBody />
        </AuthenticationCheck>
      </ReduxProvider>
    </Router>
  );
};

export default App;
