import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Flex } from '@brightcove/studio-components';

import EditPanel from '../../../components/Panel/EditPanel';
import TextInput from '../../../components/ControlledComponents/TextInput';

const PageInformation = ({ panelId, data, disableEditing, onEnableEditing, onCancel, onSave }) => {
  const [pageInformation, setPageInformation] = useState(data);
  const pageType = pageInformation?.type.toLowerCase();

  useEffect(() => {
    setPageInformation(data);
  }, [data]);

  const cancelChanges = () => {
    setPageInformation(data);
    onCancel();
  };

  const onChange = (value, property) => {
    setPageInformation({ ...pageInformation, [property]: value });
  };

  const getFieldsToSave = () => {
    const fieldsToSave = ['name'];

    if (pageType === 'custompage' || pageType === 'standardpage') {
      fieldsToSave.push('slug');
    }

    return fieldsToSave;
  };

  return (
    <EditPanel
      id={panelId}
      title="Page Information"
      disableEditing={disableEditing}
      onEnableEditing={onEnableEditing}
      onCancel={cancelChanges}
      save={() => onSave(_.pick(pageInformation, getFieldsToSave()))}
    >
      {(isEditing) => (
        <Flex className="Page-detail-information px-4 pt-4 pb-6" flexDirection="column">
          <Flex alignItems="center" justifyContent="space-between">
            <div className="panel-group mr-7">
              <p className="panel-label">Page Name</p>
              {isEditing ? (
                <TextInput
                  className="panel-text-input"
                  property="name"
                  value={pageInformation?.name || ''}
                  onChange={onChange}
                />
              ) : (
                <p className="panel-text">{pageInformation?.name}</p>
              )}
            </div>
            <div className="panel-group">
              <p className="panel-label">ID</p>
              <p className="panel-text">{pageInformation?.id}</p>
            </div>
          </Flex>
          {(pageType === 'custompage' || pageType === 'standardpage') && (
            <div className="panel-group">
              <p className="panel-label">Page Slug</p>
              {isEditing ? (
                <TextInput
                  className="panel-text-input"
                  property="slug"
                  value={pageInformation?.slug || ''}
                  onChange={onChange}
                />
              ) : (
                <p className="panel-text">{pageInformation?.slug || '-'}</p>
              )}
            </div>
          )}
        </Flex>
      )}
    </EditPanel>
  );
};

export default PageInformation;
