import React, { FC, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Flex } from '@brightcove/studio-components';

import TitleDescription from '../TitleDescription/TitleDescription';
import TextInput from '../ControlledComponents/TextInput';
import TextArea from '../ControlledComponents/TextArea';
import { RadioGroup } from '../ControlledComponents/RadioGroup';
import Button from '../Button/Button';
import './CustomizeModal.scss';

import StandardModal, { StandardModalProps } from './StandardModal';

interface CustomizeModalProps extends StandardModalProps {
  data?: any;
  onCustomize?: (val: any) => void;
  includesContentDetails?: boolean;
}

const imageTypeOptions = ['Poster', 'Thumbnail', 'Portrait', 'Landscape'].map((type) => ({
  label: <div className="label">{type}</div>,
  value: type,
}));

const CustomizeModal: FC<CustomizeModalProps> = ({
  className,
  data: dataProp,
  show,
  includesContentDetails,
  onClose,
  onCustomize,
}) => {
  const [data, setData] = useState(dataProp || {});
  const [customization, setCustomization] = useState({});

  useEffect(() => {
    if (dataProp) {
      setData(dataProp);
    }
  }, [dataProp]);

  useEffect(() => {
    if (data?.customizations && includesContentDetails) {
      setCustomization(data?.customizations?.find((c) => c.itemId === data?.content?.id) || {});
    } else {
      setCustomization(data);
    }
  }, [data]);

  const fields = [
    { property: 'eyebrow', component: TextInput },
    { property: 'title', component: TextInput },
    { property: 'description', component: TextArea },
    { property: 'cta1', component: TextInput, label: 'CTA 1' },
    { property: 'cta2', component: TextInput, label: 'CTA 2' },
    { property: 'image', component: TextInput },
  ];

  const onUpdateDataField = (field, value) => {
    if (field.includes('.')) {
      const [property, subProperty] = field.split('.');
      setCustomization((prevState: any) => ({
        ...prevState,
        [property]: {
          ...prevState[property],
          [subProperty]: value,
        },
        itemId: prevState.itemId || data?.content?.id,
      }));
    } else {
      setCustomization((prevState: any) => {
        return {
          ...prevState,
          [field]: value,
          itemId: prevState.itemId || data?.content?.id,
        };
      });
    }
  };

  const handleSetButton = () => {
    const { type, url } = customization['image'] || {};

    if (url && !type) {
      customization['image'].type = imageTypeOptions[0].value;
    }

    onCustomize?.(customization);
    onClose?.();
  };

  const renderFields = useMemo(
    () =>
      fields.map(({ property, component: Component, label }, index) => {
        const field = customization ? customization[property] : undefined;

        return (
          <div key={index} className={index === fields.length - 1 ? '' : 'mb-5'}>
            {property.toLowerCase().includes('cta') ? (
              <Flex>
                <div style={{ flex: 1 }} className="mr-6">
                  <div className="label mb-2">{`${label} text`}</div>
                  <Component
                    value={field?.label || ''}
                    onChange={(value) => onUpdateDataField(`${property}.label`, value)}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <div className="label mb-2">{`${label} url`}</div>
                  <Component
                    value={field?.url || ''}
                    onChange={(value) => onUpdateDataField(`${property}.url`, value)}
                  />
                </div>
              </Flex>
            ) : property === 'image' ? (
              <div className="image-container">
                <div className="mb-5">
                  <div className="label mb-2">Image URL</div>
                  <Component
                    value={field?.url || ''}
                    onChange={(value) => onUpdateDataField(`${property}.url`, value)}
                  />
                </div>
                <div>
                  <div className="label mb-2">Image</div>
                  <RadioGroup
                    value={field?.type || imageTypeOptions[0].value}
                    options={imageTypeOptions}
                    onChange={(value) => onUpdateDataField(`${property}.type`, value)}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="label mb-2">{_.capitalize(property)}</div>
                <Component value={field || ''} onChange={(value) => onUpdateDataField(property, value)} />
              </>
            )}
          </div>
        );
      }),
    [customization]
  );

  return (
    <StandardModal
      className={classNames('Customize-modal', className)}
      show={show}
      title={'Customize'}
      onClose={onClose}
      actions={
        <>
          <Button
            variant="secondary"
            text="Cancel"
            onClick={() => {
              setData(dataProp || {});
              onClose?.();
            }}
          />
          <Button className="ml-3" variant="primary" text={'Set'} onClick={handleSetButton} />
        </>
      }
    >
      <div className="Customize-modal-content">
        {includesContentDetails && (
          <Flex className="mb-12">
            <div className="mr-8">
              <div className="label mb-1">Name</div>
              <TitleDescription title={data?.content?.name || ''} description={data?.content?.id || ''} />
            </div>
            <div>
              <div className="label mb-1">Type</div>
              <div>{data?.content?.type || ''}</div>
            </div>
          </Flex>
        )}
        {renderFields}
      </div>
    </StandardModal>
  );
};

export default CustomizeModal;
