import React, { FC } from 'react';
import classnames from 'classnames';
import './Checkbox.scss';

interface CheckboxProps {
  onChange: (val: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  checked: boolean;
  id?: string;
  className?: string;
  disabled?: boolean;
}
const Checkbox: FC<CheckboxProps> = ({ onChange, className, checked, label, id, disabled }) => {
  return (
    <div className={classnames('Checkbox', className)}>
      <input type="checkbox" checked={checked} onChange={onChange} name={label} id={id} disabled={disabled} />
      <p>{label}</p>
    </div>
  );
};

export default Checkbox;
