import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { formatDate } from '../../utils/format';
import { DEFAULT_PAGE } from '../../utils/constants';
import { getUrlWithOptions } from '../../utils';
import { useApi } from '../../hooks/useApi';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import { DeleteConfirmationModal, withModal } from '../../components/Modals';
import InputSearch from '../../components/ControlledComponents/InputSearch';
import Button from '../../components/Button/Button';

import './Locations.scss';

const DeleteButton = withModal(Button, DeleteConfirmationModal);

const Locations = () => {
  const navigate = useNavigate();
  const { apiGet, apiDelete, refetchGet, isLoading } = useApi();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [currentPageSize, setCurrentPageSize] = useState(50);
  const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_PAGE);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [fetchUrl, setFetchUrl] = useState<string>(
    getUrlWithOptions('/locations', undefined, undefined, undefined, currentPageIndex, currentPageSize)
  );

  const columns = [
    {
      Header: 'Name',
      id: 'name',
      className: 'name',
      accessor: (row) => ({ name: row.name, id: row.id }),
      Cell: ({ value: { name, id: rowId } }) => (
        <TitleDescription
          title={name}
          description={rowId}
          id={rowId}
          onClick={(id) => navigate(`../${ROUTES.LOCATIONS}/edit/${id}`)}
        />
      ),
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      className: 'date',
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
          <div className="subtitle">{row.original.createdBy}</div>
        </>
      ),
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      className: 'date',
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
          <div className="subtitle">{row.original.updatedBy}</div>
        </>
      ),
      width: 200,
    },
  ];

  useEffect(() => {
    setFetchUrl(
      getUrlWithOptions('/locations', searchQuery, undefined, undefined, currentPageIndex, currentPageSize)
    );
  }, [searchQuery, currentPageIndex, currentPageSize]);

  useEffect(() => {
    (async () => {
      setLocations([]);
      const { data: locationsData } = await apiGet(fetchUrl);

      if (locationsData) {
        setLocations(locationsData.items);
        setTotalCount(locationsData.totalCount);
      }
    })();
  }, [fetchUrl]);

  const onSearch = (query) => setSearchQuery(query);

  const onDelete = async () => {
    const idsToDelete = selectedRows.map((item) => item.id).toString();
    const { error } = await apiDelete(getUrlWithOptions(`/locations?ids=${encodeURIComponent(idsToDelete)}`));

    if (!error) {
      const { data } = await refetchGet();

      setLocations(data.items);
    }
  };

  const onChangeSelection = (items) => setSelectedRows(items);

  const onPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
  }, []);

  return (
    <div className="Locations">
      <SubHeader
        title="Locations"
        subtitle="NetApp TV"
        icon={<Icon name={ICONS.MAP_PIN} />}
        actions={<InputSearch placeholder="Search" onSearch={onSearch} />}
        quantitySubtitle={`${currentTotalCount || 0} Location${currentTotalCount === 1 ? '' : 's'}`}
      />

      <Flex className="mt-4 ml-11">
        <Button
          className="mr-2"
          variant="primary"
          text="Create"
          onClick={() => navigate(`../${ROUTES.LOCATIONS}/create`)}
        />
        <DeleteButton
          className="delete-btn"
          variant="secondary"
          disabled={selectedRows.length === 0}
          modalProps={{
            title: 'Delete Location',
            children: (
              <>
                You will be deleting {selectedRows.length} location{selectedRows.length !== 1 && 's'} from the
                system. This action cannot be undone.
              </>
            ),
          }}
          onModalConfirm={() => onDelete()}
        >
          Delete
        </DeleteButton>
      </Flex>

      <Table
        className="mt-4"
        data={locations || []}
        columns={columns}
        onChangeSelection={onChangeSelection}
        hasSelection
        hasPagination
        pageCount={Math.ceil(currentTotalCount / currentPageSize)}
        pageIndex={currentPageIndex}
        pageSize={currentPageSize}
        onPaginationChange={onPaginationChange}
        width={750}
        loading={isLoading}
      />
    </div>
  );
};

export default Locations;
