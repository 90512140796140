import React, { FC, useEffect, useState } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import { getUrlWithOptions } from '../../../utils';
import { RelatedResourcesData } from '../../../interfaces/Content';
import { useApi } from '../../../hooks/useApi';
import Panel from '../../../components/Panel/Panel';
import TextInput from '../../../components/ControlledComponents/TextInput';
import SimpleSelect from '../../../components/ControlledComponents/SimpleSelect';
import Checkbox from '../../../components/ControlledComponents/Checkbox';
import Button from '../../../components/Button/Button';
import './RelatedResources.scss';

interface RelatedResourcesProps {
  idPanel?: string;
  data: {
    id: string;
    currentLanguage: string;
    relatedResources: RelatedResourcesData[]
  };
  disabledEdit?: boolean;
  onEditChange?: (val: boolean, isSaving?: boolean) => void;
}
const types = {
  download: 'PDF',
  'external-link-square': 'Other',
};
const initialData: RelatedResourcesData = {
  title: '',
  type: Object.entries(types)[0][0],
  url: '',
  needsAuth: false,
  position: 99,
};
const fillRelatedResources = (relatedResources: RelatedResourcesData[]) => {
  const length = relatedResources.length;
  const QUANTITY = 10;

  if (length < QUANTITY) {
    const emptyRelatedResources = Array.from({ length: QUANTITY - length }, () =>
      Object.assign({}, initialData)
    );

    return [...relatedResources, ...emptyRelatedResources];
  } else {
    return [...relatedResources];
  }
};
const orderRelatedResources = (RelatedResources: RelatedResourcesData[]) => {
  const sortedRelatedResources = [...RelatedResources];

  return sortedRelatedResources.sort(
    (a, b) => parseInt(a.position.toString(), 10) - parseInt(b.position.toString(), 10)
  );
};
const RelatedResources: FC<RelatedResourcesProps> = ({ idPanel, data, disabledEdit, onEditChange }) => {
  const { apiPut } = useApi();
  const { id, currentLanguage, relatedResources } = data;
  const [editing, setEditing] = useState<boolean>(false);
  const [currentRelatedResources, setCurrentRelatedResources] = useState<RelatedResourcesData[]>([]);
  const [canBeSubmitted, setCanBeSubmitted] = useState<boolean>(true);

  useEffect(() => {
    if (data && relatedResources) {
      setCurrentRelatedResources(orderRelatedResources(fillRelatedResources([...relatedResources])));
    }
  }, [relatedResources]);

  const handleRelatedResources = (value: string | boolean, index: number, key: string) => {
    const editedRelatedResources = [...currentRelatedResources];

    editedRelatedResources[index][key] = value;
    editedRelatedResources[index].position = index;

    const fields = Object.keys(editedRelatedResources[index]).filter(
      (key: string) => key !== 'position' && key !== 'needsAuth'
    );
    const allPropsHaveData = fields.every((key: string) => editedRelatedResources[index][key].length);
    const allPropsHaveNoData = fields.every((key: string) => editedRelatedResources[index][key].length === 0);

    if (allPropsHaveData) {
      setCanBeSubmitted(true);
    } else if (allPropsHaveNoData) {
      setCanBeSubmitted(true);
    } else {
      setCanBeSubmitted(false);
    }

    setCurrentRelatedResources(editedRelatedResources);
  };

  const onSaveInformation = async () => {
    const filteredRelatedResources = currentRelatedResources.filter(
      (resource: RelatedResourcesData) => resource.title
    );
    const editedPosition = filteredRelatedResources.map((resource, index) => {
      resource.position = index;

      return resource;
    });
    const body = {
      resources: editedPosition,
    };
    const response = await apiPut(getUrlWithOptions(`/content/${id}/locales/${currentLanguage}`), {
      body,
    });

    if (response?.data) {
      setCurrentRelatedResources(orderRelatedResources(fillRelatedResources([...response.data.resources])));
      setEditing(false);

      if (onEditChange) {
        onEditChange(!editing, true);
      }
    }
  };
  const onClearSpeaker = (index) => {
    const allRelatedResources = [...currentRelatedResources];

    allRelatedResources[index] = initialData;
    setCurrentRelatedResources(allRelatedResources);
  };

  return (
    <div id={idPanel} className="RelatedResources w-100">
      <Panel
        title="Related Resources"
        actions={
          <>
            {editing ? (
              <>
                <Button
                  variant="secondary"
                  text="Cancel"
                  onClick={() => {
                    setEditing(false);

                    if (onEditChange) {
                      onEditChange(!editing);
                    }
                  }}
                />
                <Button
                  variant="primary"
                  text="Save"
                  onClick={onSaveInformation}
                  disabled={!canBeSubmitted}
                />
              </>
            ) : (
              <Button
                variant="primary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);

                  if (onEditChange) {
                    onEditChange(!editing);
                  }
                }}
                disabled={disabledEdit}
              />
            )}
          </>
        }
      >
        <>
          {editing ? (
            <>
              {currentRelatedResources?.length
                ? currentRelatedResources.map((resource: RelatedResourcesData, index: number) => {
                    return (
                      <div key={index}>
                        <Flex alignItems="center">
                          <b className="ml-4 mt-1">Resource {index + 1}</b>
                          <Flex alignItems="center">
                            <Icon name={ICONS.REMOVE} color={'red'} className="ml-3" />
                            <Button
                              className="delete-rule-btn ml-1 mt-1"
                              variant="link"
                              onClick={() => onClearSpeaker(index)}
                            >
                              Clear Data
                            </Button>
                          </Flex>
                        </Flex>
                        <Flex className="pt-0">
                          <div className="ml-4">
                            <p className="title">Title *</p>
                            <TextInput
                              value={resource.title}
                              onChange={(val: string) => {
                                handleRelatedResources(val, index, 'title');
                              }}
                            />
                          </div>
                          <div className="ml-4">
                            <p className="title">Type *</p>
                            <SimpleSelect
                              className="select-input-sm "
                              value={resource.type?.length ? resource.type : 'img'}
                              options={types as any}
                              onChange={(val: string) => handleRelatedResources(val, index, 'type')}
                            />
                          </div>
                          <div className="ml-4">
                            <p className="title">URL *</p>
                            <TextInput
                              value={resource.url}
                              onChange={(val: string) => {
                                handleRelatedResources(val, index, 'url');
                              }}
                            />
                          </div>
                        </Flex>
                        <Checkbox
                          className="mt-4 ml-3"
                          label="Needs Auth"
                          checked={resource.needsAuth}
                          onChange={(e) => {
                            handleRelatedResources(e.target.checked, index, 'needsAuth');
                          }}
                        />
                        {currentRelatedResources.length > 1 ? (
                          <div className="divider-line mt-4 mb-4"></div>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </>
          ) : (
            <div className="ml-4">
              {!!currentRelatedResources.length && !!currentRelatedResources[0]?.title
                ? currentRelatedResources.map((resource: RelatedResourcesData, index: number) => {
                    if (resource.title?.length) {
                      return (
                        <div key={index}>
                          <b>{`Resource ${resource.position + 1}`}</b>
                          <div>
                            <div className="mt-6">
                              <p className="title">Title</p>
                              <p className="description">{resource.title?.length ? resource.title : '-'}</p>
                            </div>
                            <div className="mt-6 ">
                              <p className="title">Type</p>
                              <p className="description">{resource.type?.length ? resource.type : '-'}</p>
                            </div>
                            <div className="mt-6 ">
                              <p className="title">URL</p>
                              <p className="link">
                                {resource.url?.length ? (
                                  <a href={resource.url} target="blank">
                                    {resource.url}
                                  </a>
                                ) : (
                                  '-'
                                )}
                              </p>
                            </div>
                            <div className="mt-6 ">
                              <p className="title">Auth</p>
                              <Checkbox
                                checked={resource.needsAuth}
                                disabled
                                onChange={() => {
                                  // eslint-disable-next-line no-console
                                  console.log('checkbox onChange');
                                }}
                                label="Needs Auth"
                              />
                            </div>
                          </div>
                          {currentRelatedResources.length > 1 ? (
                            <div className="divider-line mt-4 mb-4"></div>
                          ) : null}
                        </div>
                      );
                    }
                  })
                : '-'}
            </div>
          )}
        </>
      </Panel>
    </div>
  );
};

export default RelatedResources;
