import Trashcan from './icons/trashcan.svg';
import ScheduleIcon from './icons/schedule-icon.svg';
import PagesHeaderIcon from './icons/pages-header-icon.svg';
import NetAppLogo from './icons/netapp-logo.svg';

const SVGImage = {
  NetAppLogo,
  Trashcan,
  PagesHeaderIcon,
  ScheduleIcon,
};

export { SVGImage };
