import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Flex, Icon, ICONS, Thumbnail } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { formatDate } from '../../utils/format';
import { DEFAULT_PAGE } from '../../utils/constants';
import { getUrlWithOptions } from '../../utils';
import COLORS from '../../styles/_colors.export.scss';
import { selectContentFilter, selectContentSearch } from '../../store/selectors';
import { setContentFilter, setContentSearch } from '../../store/actions';
import { useApi } from '../../hooks/useApi';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import SideNavigationItem from '../../components/SideNavigation/SideNavigationItem';
import SideNavigation from '../../components/SideNavigation/SideNavigation';
import InputSearch from '../../components/ControlledComponents/InputSearch';
import Button from '../../components/Button/Button';

import './ContentManagement.scss';

const ALL_CONTENT = 'allContent';
const contentFilters = [
  {
    iconName: 'PLAY_OUTLINE',
    name: 'All Content',
    id: ALL_CONTENT,
  },
  {
    iconName: 'FOLDER',
    name: 'Series',
    id: 'ItemSubType:SeriesContent',
  },
  {
    iconName: 'FOLDER',
    name: 'Seasons',
    id: 'ItemSubType:SeasonContent',
  },
  {
    iconName: 'FOLDER',
    name: 'Episodes',
    id: 'ItemSubType:EpisodeContent',
  },
  {
    iconName: 'FOLDER',
    name: 'Movies',
    id: 'ItemSubType:MovieContent',
  },
  {
    iconName: 'FOLDER',
    name: 'Trailers',
    id: 'ItemSubType:TrailerContent',
  },
  {
    iconName: 'FOLDER',
    name: 'Ads',
    id: 'ItemSubType:AdContent',
  },
];

const resource = '/content';
const SORT_DEFAULT = 'updatedAt:desc';
const FILTER_DEFAULT = '';
const VALID_SORT_FIELDS = {
  Name: 'name',
  extId: 'extId',
  Created: 'createdAt',
  Updated: 'updatedAt',
  published: 'published',
};

interface ContentManagementProps {
  embedded?: boolean;
  excludeFilters?: any[];
  includeFilters?: any[];
  singleSelection?: boolean;
  onRowSelect?: (param: any[]) => void;
}

const ContentManagement: FC<ContentManagementProps> = ({
  embedded,
  excludeFilters,
  includeFilters,
  singleSelection,
  onRowSelect,
}) => {
  const dispatch = useDispatch();
  const filter = useSelector(selectContentFilter);
  const search = useSelector(selectContentSearch);
  const [sectionTitle, setSectionTitle] = useState('');
  const [currentFilter, setCurrentFilter] = useState(embedded ? FILTER_DEFAULT : filter || FILTER_DEFAULT);
  const [currentSearch, setCurrentSearch] = useState<string>(search || '');
  const [items, setItems] = useState<any[]>([]);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_PAGE);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [currentSort, setCurrentSort] = useState<string>(SORT_DEFAULT);
  const [loading, setLoading] = useState(true);
  const { apiGet } = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    let text = 'All Content';

    if (currentFilter) {
      const selected = contentFilters.find((item) => item.id === currentFilter)?.name;
      text = selected?.length ? selected : 'All Content';
    }

    setSectionTitle(text);
  });

  const getFilters = useCallback(() => {
    return contentFilters.filter((filter) => {
      return includeFilters
        ? includeFilters.includes(filter.id)
        : excludeFilters
        ? !excludeFilters.includes(filter.id)
        : filter;
    });
  }, [includeFilters, excludeFilters]);

  const getFirstFilterId = () => {
    const firstFilterId = getFilters()[0].id;

    return firstFilterId.search(ALL_CONTENT) >= 0 ? getAllContentFilter() : firstFilterId;
  };

  const getData = async () => {
    setLoading(true);
    setItems([]);
    const { data } = await apiGet(
      getUrlWithOptions(
        resource,
        currentSearch,
        currentSort,
        currentFilter || getFirstFilterId(),
        currentPageIndex,
        currentPageSize
      )
    );

    setItems(data?.items?.length ? data.items : []);
    setTotalCount(data.totalCount);
    setLoading(false);
  };

  const getAllContentFilter = () => {
    let allContentFilter = '';

    if (excludeFilters) {
      allContentFilter = contentFilters
        .filter((filter) => !excludeFilters.includes(filter.id) && filter.id !== ALL_CONTENT)
        .map((contentFilter) => contentFilter.id)
        .join(',');
    }

    return allContentFilter;
  };

  useEffect(() => {
    getData();
  }, [currentPageIndex, currentPageSize, currentSearch, currentFilter, currentSort]);

  const onChangeFilter = (id: string) => {
    const filter = id.search(ALL_CONTENT) >= 0 ? getAllContentFilter() : id;

    setCurrentPageIndex(DEFAULT_PAGE);
    setCurrentFilter(filter);
    !embedded && dispatch(setContentFilter(filter));
  };

  const onSearch = (val: string) => {
    setCurrentPageIndex(DEFAULT_PAGE);
    setCurrentSearch(val);
    !embedded && dispatch(setContentSearch(val));
  };

  const onClickSort = (field, order) => {
    if (field && order) {
      setCurrentSort(`${VALID_SORT_FIELDS[field]}:${order.toLocaleLowerCase()}`);
    }
  };

  const onPaginationChange = ({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
  };

  const columns = [
    {
      Header: '',
      accessor: 'image',
      Cell: ({ value }) => <Thumbnail className="Content-management-thumbnail" src={value} />,
    },
    {
      Header: 'Name',
      accessor: (row) => [row.name, row.id], // accessor is the "key" in the data
      Cell: ({ value }) => {
        const [name, id] = value;

        return (
          <TitleDescription
            title={name}
            description={id}
            id={id}
            onClick={(itemId) => navigate(`../${ROUTES.CONTENT_MANAGEMENT}/edit/${itemId}`)}
          />
        );
      },
      sortable: true,
    },
    {
      Header: 'Reference ID',
      accessor: 'extId',
      sortable: true,
      className: 'Content-management-reference-id',
    },
    {
      Header: 'Type',
      className: 'Content-management-type',
      accessor: (row) => row?.type?.replace('Content', ''),
    },
    {
      Header: 'Published',
      accessor: 'published', // accessor is the "key" in the data
      Cell: ({ value }) => {
        return (
          <Flex justifyContent="center">
            {value ? (
              <Icon name={ICONS.CHECK2} color={COLORS.cyan} />
            ) : (
              <Icon name={ICONS.WARNING} color={COLORS.burgundy} />
            )}
          </Flex>
        );
      },
      sortable: true,
      className: 'Content-management-published',
    },
    {
      Header: 'Created',
      sortable: true,
      className: 'Content-management-created',
      accessor: (row) => [row.createdAt, row.createdBy], // accessor is the "key" in the data
      Cell: ({ value }) => {
        const [createdAt, email] = value;

        return (
          <TitleDescription
            classNameTitle="Content-management-column-updated-item-title"
            classNameDescription="Content-management-column-updated-item-description"
            title={formatDate(createdAt)}
            description={email}
          />
        );
      },
    },
    {
      Header: 'Updated',
      sortable: true,
      className: 'Content-management-updated',
      accessor: (row) => [row.updatedAt, row.updatedBy], // accessor is the "key" in the data
      Cell: ({ value }) => {
        const [updated, email] = value;

        return (
          <TitleDescription
            classNameTitle="Content-management-column-updated-item-title"
            classNameDescription="Content-management-column-updated-item-description"
            title={formatDate(updated)}
            description={email}
          />
        );
      },
    },
  ];
  const embeddedModalColumns = columns.filter((column) => column.accessor !== 'extId');

  return (
    <Flex className="Content-management-container">
      {getFilters().length > 1 && (
        <SideNavigation
          onChange={onChangeFilter}
          item={SideNavigationItem}
          filters={getFilters()}
          defaultValue={currentFilter.length > 0 ? currentFilter : getFilters()[0].id}
        />
      )}
      <Flex flexDirection="column" className="Content-management-content">
        <SubHeader
          icon={<Icon name="FILM" />}
          title={sectionTitle}
          subtitle={'NetApp TV'}
          quantitySubtitle={loading ? '' : `${currentTotalCount} Items`}
          actions={
            <InputSearch value={currentSearch} placeholder="Search by name or ID" onSearch={onSearch} />
          }
        />

        <Flex justifyContent="flex-end">
          {(!currentFilter.length || currentFilter === 'ItemSubType:SeriesContent') && !embedded ? (
            <Button
              variant="primary"
              onClick={() => {
                navigate(`../${ROUTES.CONTENT_MANAGEMENT}/create`);
              }}
              className="mt-6 mr-10"
            >
              Create Series
            </Button>
          ) : null}
        </Flex>

        <Table
          className={`${
            (!currentFilter.length || currentFilter === 'ItemSubType:SeriesContent') && !embedded
              ? 'mt-4'
              : 'mt-0'
          }`}
          data={items}
          columns={embedded ? embeddedModalColumns : columns}
          hasPagination
          pageCount={Math.ceil(currentTotalCount / currentPageSize)}
          onPaginationChange={onPaginationChange}
          defaultSortingValues={[{ id: 'Updated', desc: true }]}
          pageIndex={currentPageIndex}
          pageSize={currentPageSize}
          onClickSort={onClickSort}
          hasSelection={embedded}
          onChangeSelection={(items) => onRowSelect?.(items)}
          singleSelection={singleSelection}
          width={1100}
          loading={loading}
        />
      </Flex>
    </Flex>
  );
};

export default ContentManagement;
