import React, { FC, useEffect, useState } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import { secondsToTime, getSecondFromTime, secondsToTimeString } from '../../../utils/format';
import { getUrlWithOptions } from '../../../utils';
import COLORS from '../../../styles/_colors.export.scss';
import { CuePointData } from '../../../interfaces/Content';
import { useApi } from '../../../hooks/useApi';
import SimplePager from '../../../components/SimplePager/SimplePager';
import Panel from '../../../components/Panel/Panel';
import './Cuepoints.scss';
import DeleteConfirmationModal from '../../../components/Modals/DeleteConfirmationModal';
import TextInput from '../../../components/ControlledComponents/TextInput';
import SimpleSelect from '../../../components/ControlledComponents/SimpleSelect';
import Button from '../../../components/Button/Button';

interface CuePointsProps {
  idPanel?: string;
  data: any;
  addNew: boolean;
  selectedCuePoint: any;
  duration: number;
  selectedTime: number;
  onCancel: () => void;
  onChangeTime: (val: number) => void;
  onSaveCuePoint: (val: CuePointData[]) => void;
}

const CuePoints: FC<CuePointsProps> = ({
  idPanel,
  data,
  addNew,
  duration,
  selectedCuePoint,
  selectedTime,
  onCancel,
  onChangeTime,
  onSaveCuePoint,
}) => {
  const timeSplit = secondsToTime(selectedTime);
  const currentHours = timeSplit.hours;
  const currentMinutes = timeSplit.minutes;
  const currentSeconds = timeSplit.seconds;

  const { apiPatch } = useApi();
  const { id } = data;
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [currentName, setCurrentName] = useState<string>('');
  const [currentMetadata, setCurrentMetadata] = useState<string>('');
  const [currentUrl, setCurrentUrl] = useState<string>('');
  const [currentType, setCurrentType] = useState<string>('AD');
  const [index, setIndex] = useState(0);
  const [cuePoints, setCuePoints] = useState<any[]>([]);

  useEffect(() => {
    if (cuePoints[index]?.time) {
      onChangeTime(cuePoints[index]?.time);
      setCurrentName(cuePoints[index]?.name);
      setCurrentMetadata(cuePoints[index]?.metadata);
      setCurrentUrl(cuePoints[index]?.url);
      setCurrentType(cuePoints[index]?.type ? cuePoints[index]?.type : 'AD');
    }
  }, [index, cuePoints]);

  // add new cuepoint
  useEffect(() => {
    if (addNew) {
      if (data.cuepoints?.length) {
        setCuePoints([...data.cuepoints, {}]);
        setIndex(data.cuepoints.length);
      } else {
        setCuePoints([]);
        setIndex(0);
      }
    }
  }, [addNew]);

  // show after select a cuepoint from CuePointBar
  useEffect(() => {
    if (selectedCuePoint && !!data.cuepoints?.length) {
      const currentIndex = data.cuepoints.findIndex((cuepoint) => cuepoint.time === selectedCuePoint.time);

      setCuePoints(data.cuepoints);
      setIndex(currentIndex);
    }
  }, [selectedCuePoint, data.cuepoints]);

  useEffect(() => {
    if (currentHours || currentMinutes || currentSeconds) {
      onChangeTime(getSecondFromTime(currentHours, currentMinutes, currentSeconds));
    }

    const time = getSecondFromTime(currentHours, currentMinutes, currentSeconds);

    repeatedCuepoint(time);
    requiredFields();
    isValidCuepoint(time);
  }, [currentHours, currentMinutes, currentSeconds, currentName, currentMetadata, currentUrl]);

  const requiredFields = () => {
    const currentTime = getSecondFromTime(currentHours, currentMinutes, currentSeconds);

    const shouldDisableSave = [
      cuePoints[index]?.name === currentName,
      cuePoints[index]?.metadata === currentMetadata,
      cuePoints[index]?.url === currentUrl,
      cuePoints[index]?.type === currentType,
    ];

    if (cuePoints[index]?.time > 60 * 60) {
      const [hour, minutes, seconds] = secondsToTimeString(cuePoints[index].time).split(':');

      shouldDisableSave.push(
        parseInt(hour, 10) === currentHours,
        parseInt(minutes, 10) === currentMinutes,
        parseInt(seconds, 10) === currentSeconds
      );
    } else {
      const [minutes, seconds] = secondsToTimeString(cuePoints[index]?.time).split(':');

      shouldDisableSave.push(
        parseInt(minutes, 10) === currentMinutes,
        parseInt(seconds, 10) === currentSeconds
      );
    }

    if (currentTime && !!currentName?.length && !shouldDisableSave.every((item) => item)) {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  };

  const repeatedCuepoint = (time: number) => {
    if (cuePoints.find((cp) => cp.time === time)) {
      setShowMessage(true);
      setErrorMessage('There is a cue point at this timecode already. Please select a different timecode');
      setDisabledSave(true);
      setShowDeleteButton(true);
    } else {
      setShowMessage(false);
      setErrorMessage('');
      setShowDeleteButton(false);
    }
  };

  const isValidCuepoint = (time: number) => {
    if (time > duration) {
      setShowMessage(true);
      setErrorMessage('Cue point timecode should be less than video duration');
      setDisabledSave(true);
    }
  };

  const onSaveInformation = async () => {
    // the unique property of each cuepoint is the time, so we filter out the edited cuepoint
    const filteredCuepoints = cuePoints.filter((cp) => selectedCuePoint?.time !== cp.time);

    // replace the edited cuepoint with the updated version of it
    const editedCuepoints = [
      ...filteredCuepoints,
      {
        metadata: currentMetadata,
        name: currentName,
        type: currentType,
        time: getSecondFromTime(currentHours, currentMinutes, currentSeconds),
        url: currentUrl,
      },
    ];

    const body = {
      cuepoints: editedCuepoints,
    };
    const response = await apiPatch(getUrlWithOptions(`/content/${id}`), {
      body,
    });

    if (response?.data) {
      setCuePoints(editedCuepoints);
      setIndex(editedCuepoints.length - 1);
      onSaveCuePoint(editedCuepoints);
    }

    onCancel();
  };

  const onDeleteCuePoint = async () => {
    const allCuePoints = [...cuePoints];
    const filteredCuepoints = allCuePoints.filter((cp) => cp.time !== cuePoints[index].time);

    const body = {
      cuepoints: filteredCuepoints,
    };
    const response = await apiPatch(getUrlWithOptions(`/content/${id}`), {
      body,
    });

    if (response?.data) {
      setCuePoints(filteredCuepoints);
      setIndex(filteredCuepoints.length - 1);
      onSaveCuePoint(filteredCuepoints);
    }

    onCancel();
    setShowDeleteModal(false);
  };

  return (
    <>
      <DeleteConfirmationModal
        title="Delete Cue Point"
        show={showDeleteModal}
        onDelete={onDeleteCuePoint}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
      >
        <>Are you sure you want to delete this cue point? This action cannot be undone.</>
      </DeleteConfirmationModal>

      <div id={idPanel} className="CuePoints w-100">
        <Panel
          title="Cue Point"
          actions={
            <>
              <Button
                variant="secondary"
                text="Cancel"
                onClick={() => {
                  const tmpCuepoint = cuePoints.slice(0, cuePoints.length);

                  setIndex(cuePoints.length - 1);
                  setCuePoints(tmpCuepoint);
                  onCancel();
                }}
              />
              <Button variant="primary" text="Save" onClick={onSaveInformation} disabled={disabledSave} />
            </>
          }
        >
          <>
            <>
              <Flex>
                <div className="ml-4">
                  <p className="title">Timecode (hh:mm:ss:) *</p>
                  <Flex flexDirection="row" alignContent="center">
                    <TextInput
                      value={currentHours}
                      onChange={(val) => {
                        const value = parseInt(val || '0');

                        onChangeTime(getSecondFromTime(value, currentMinutes, currentSeconds));
                      }}
                      className="timecode"
                      type="text"
                    />

                    <TextInput
                      value={currentMinutes}
                      onChange={(val) => {
                        const value = parseInt(val || '0');

                        if (value <= 60) onChangeTime(getSecondFromTime(currentHours, value, currentSeconds));
                      }}
                      className="ml-2 timecode"
                      type="text"
                    />
                    <TextInput
                      value={currentSeconds}
                      onChange={(val) => {
                        const value = parseInt(val || '0');

                        if (value <= 60) onChangeTime(getSecondFromTime(currentHours, currentMinutes, value));
                      }}
                      className="ml-2 timecode"
                      type="text"
                    />
                  </Flex>
                </div>
                <div className="ml-6 ">
                  <p className="title">Type *</p>
                  <SimpleSelect
                    className="select-input-sm"
                    value={currentType}
                    onChange={setCurrentType}
                    options={[
                      { label: 'Ad', value: 'AD' },
                      { label: 'Code', value: 'DATA' },
                    ]}
                  />
                </div>
                <div className="ml-8">
                  <p className="title">Name *</p>
                  <TextInput value={currentName?.length ? currentName : ''} onChange={setCurrentName} />
                </div>
                {showDeleteButton ? (
                  <div className="ml-8 mt-8" onClick={() => setShowDeleteModal(true)}>
                    <Icon name={ICONS.TRASHCAN} color={COLORS.cyan} className="button-trash" />
                  </div>
                ) : null}
              </Flex>
              <Flex>
                <div className="ml-4 mt-4">
                  <p className="title">Metadata</p>
                  <TextInput
                    value={currentMetadata?.length ? currentMetadata : ''}
                    onChange={setCurrentMetadata}
                    className="metadata"
                  />
                </div>
                <div className="ml-8 mt-4">
                  <p className="title">URL</p>
                  <TextInput value={currentUrl?.length ? currentUrl : ''} onChange={setCurrentUrl} />
                </div>
              </Flex>
            </>
            {showMessage ? <div className="mt-4 ml-4 error-message">{errorMessage}</div> : null}

            <SimplePager
              length={cuePoints?.length ? cuePoints.length : 0}
              onChange={setIndex}
              index={index}
              nextText="Next >"
              previousText="< Previous"
            />
          </>
        </Panel>
      </div>
    </>
  );
};

export default CuePoints;
