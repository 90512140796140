import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { formatDate } from '../../utils/format';
import { DEFAULT_PAGE } from '../../utils/constants';
import { getUrlWithOptions } from '../../utils';
import { useApi } from '../../hooks/useApi';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import DeleteConfirmationModal from '../../components/Modals/DeleteConfirmationModal';
import InputSearch from '../../components/ControlledComponents/InputSearch';
import Button from '../../components/Button/Button';
import './AccessControls.scss';

const resource = '/access_controls';
const SORT_DEFAULT = 'updatedAt:desc';
const VALID_SORT_FIELDS = {
  Name: 'name',
  Created: 'createdAt',
  Updated: 'updatedAt',
};
const AccessControls = () => {
  const { apiGet, apiDelete, isLoading, refetchGet } = useApi();
  const [selectedItems, setSelectedItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const [items, setItems] = useState<any[]>([]);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_PAGE);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [currentSort, setCurrentSort] = useState<string>(SORT_DEFAULT);
  const navigate = useNavigate();

  const getData = async () => {
    setItems([]);
    const { data } = await apiGet(
      getUrlWithOptions(resource, currentSearch, currentSort, undefined, currentPageIndex, currentPageSize)
    );

    setItems(data.items?.length ? data.items : []);
    setTotalCount(data.totalCount);
  };

  useEffect(() => {
    getData();
  }, [currentPageIndex, currentPageSize, currentSearch, currentSort]);

  const onPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
  }, []);

  const onDeleteItems = () => {
    const ids = selectedItems.map((item: any) => item.id).join(',');
    const deleteData = async () => {
      const { error } = await apiDelete(getUrlWithOptions(`${resource}?ids=${encodeURIComponent(ids)}`));

      setShowDeleteModal(false);

      if (!error) {
        const { data } = await refetchGet();

        setItems(data?.items || []);
        setTotalCount(data.totalCount);
      }
    };

    deleteData();
  };

  const onSearch = (val: string) => setCurrentSearch(val);

  const onClickSort = (field, order) => {
    if (field && order) {
      setCurrentSort(`${VALID_SORT_FIELDS[field]}:${order.toLocaleLowerCase()}`);
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row) => [row.name, row.id], // The return must be an array if you need that the search (global filter) apply
        Cell: ({ value }) => {
          const [name, id] = value;

          return (
            <TitleDescription
              title={name}
              description={id}
              id={id}
              onClick={(selectedId) => navigate(`../${ROUTES.ACCESS_CONTROLS}/edit/${selectedId}`)}
            />
          );
        },
        sortable: true,
      },
      {
        Header: 'External ID',
        accessor: (row) => {
          return [row.externalIds[0]];
        },
      },
      {
        Header: 'Created',

        accessor: (row) => ({
          created: row.createdAt,
          createdBy: row.updatedBy,
        }), // accessor is the "key" in the data
        Cell: ({ value }) => (
          <TitleDescription
            classNameTitle="AccessControls-column-update-item-title"
            classNameDescription="AccessControls-column-update-item-description"
            title={formatDate(value.created)}
            description={value.createdBy}
          />
        ),
        sortable: true,
      },
      {
        Header: 'Updated',
        accessor: (row) => ({
          updated: row.createdAt,
          updatedBy: row.updatedBy,
        }), // accessor is the "key" in the data
        Cell: ({ value }) => (
          <TitleDescription
            classNameTitle="AccessControls-column-update-item-title"
            classNameDescription="AccessControls-column-update-item-description"
            title={formatDate(value.updated)}
            description={value.updatedBy}
          />
        ),
        sortable: true,
      },
    ],
    []
  );

  return (
    <>
      <SubHeader
        icon={<Icon name={ICONS.WRENCH} className="mt-5" />}
        title={'Access Controls'}
        subtitle={'NetApp TV'}
        quantitySubtitle={`${currentTotalCount} Access Control`}
        className="pl-4"
        actions={<InputSearch placeholder="Search by name or ID" onSearch={onSearch} />}
      />

      <div className="ml-11 mr-12 flex-no-grow ">
        <DeleteConfirmationModal
          title="Delete Message"
          show={showDeleteModal}
          onDelete={onDeleteItems}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          actionDisabled={isLoading}
        >
          <>
            You will be deleting {selectedItems.length} Access control permanently from the system. This
            action cannot be undone.
          </>
        </DeleteConfirmationModal>
        <Flex className="mt-6">
          <Button
            variant="primary"
            onClick={() => {
              navigate(`../${ROUTES.ACCESS_CONTROLS}/create`);
            }}
          >
            Create
          </Button>
          <Button
            className="ml-3"
            variant="danger"
            disabled={!selectedItems.length}
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            Delete
          </Button>
        </Flex>
      </div>

      <Table
        className="AccessControls-table mt-4"
        data={items}
        columns={columns}
        onChangeSelection={setSelectedItems}
        hasSelection
        defaultSortingValues={[{ id: 'Updated', desc: true }]}
        hasPagination
        pageCount={Math.ceil(currentTotalCount / currentPageSize)}
        globalFilter={currentSearch}
        onPaginationChange={onPaginationChange}
        pageIndex={currentPageIndex}
        pageSize={currentPageSize}
        onClickSort={onClickSort}
        width={820}
        loading={isLoading}
      />
    </>
  );
};

export default AccessControls;
