import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Flex } from '@brightcove/studio-components';

import { hasScriptInHtml, Toaster } from '../../../utils';
import EditPanel from '../../../components/Panel/EditPanel';
import TextArea from '../../../components/ControlledComponents/TextArea';

const Html = ({
  panelId,
  code,
  title,
  panelGroupTitle = '',
  disableEditing,
  onEnableEditing,
  onCancel,
  onSave,
}) => {
  const [html, setHtml] = useState(code);
  const [toastId, setToastId] = useState<any>(null);

  useEffect(() => {
    setHtml(code);
  }, [code]);

  const onHtmlChange = (value) => {
    const hasScriptTags = hasScriptInHtml(value);

    if (hasScriptTags) {
      if (toastId === null) {
        setToastId(
          Toaster.error({
            message: 'Script tags are not allowed',
            hideAfter: 0,
          })
        );
      }
    } else {
      Toaster.dismiss(toastId);
      setToastId(null);
    }

    setHtml(value);
  };

  const cancelChanges = () => {
    setHtml(code);
    onCancel();
  };

  return (
    <EditPanel
      id={panelId}
      title={title}
      disableEditing={disableEditing}
      onEnableEditing={onEnableEditing}
      onCancel={cancelChanges}
      disableSave={toastId}
      save={() => onSave({ code: html })}
    >
      {(isEditing) => (
        <Flex className="Page-detail-html px-4 pt-4 pb-6" alignItems="center" justifyContent="center">
          <div className="panel-group">
            {panelGroupTitle && <p className="panel-label">{panelGroupTitle}</p>}
            <TextArea
              className={classNames('panel-text-area-input', { editing: isEditing })}
              value={html || ''}
              readOnly={!isEditing}
              autoFocus={isEditing}
              onChange={onHtmlChange}
            />
          </div>
        </Flex>
      )}
    </EditPanel>
  );
};

export default Html;
