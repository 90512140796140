import React, { FC, useEffect, useState } from 'react';
import { Flex, ImageUpload, PlainImageUpload, Thumbnail } from '@brightcove/studio-components';

import Panel from '../Panel/Panel';
import Button from '../Button/Button';
import './Images.scss';
import { getUrlWithOptions, Toaster } from '../../utils';
import { ImageData } from '../../interfaces';
import { useApi } from '../../hooks/useApi';

interface ImagesProps {
  idPanel?: string;
  data: ImageData;
  endpointRootPath: string;
  onEditModeChange?: (val: boolean, isSavedData?: boolean) => void;
  disableEditing?: boolean;
}

const DEFAULT_IMAGE = 'https://img.brightcove.com/UIKitLogo-Cube.svg';
const getImageByType = (images, type) => {
  const image = images.find((currentImage) => currentImage.type === type);

  return image ? image.url : DEFAULT_IMAGE;
};

const Images: FC<ImagesProps> = ({ idPanel, data, endpointRootPath, onEditModeChange, disableEditing }) => {
  const { id, language } = data;
  const [images, setImages] = useState<any[]>(data?.images || []);
  const { apiPut } = useApi();
  const [editing, setEditing] = useState<boolean>(false);
  const [imagePoster, setImagePoster] = useState<any>({
    image: getImageByType(images, 'Poster'),
    file: null,
  });
  const [imageThumb, setImageThumb] = useState<any>({
    image: getImageByType(images, 'Thumbnail'),
    file: null,
  });
  const [savedData, setSavedData] = useState(false);
  const [saving, setSaving] = useState(false);
  const [originalImages, setOriginalImages] = useState<any[]>([]);

  useEffect(() => {
    setImages(data?.images || []);
    setOriginalImages(data?.images || []);
  }, [data]);

  useEffect(() => {
    setImagePoster({
      image: getImageByType(images, 'Poster'),
      file: null,
    });
    setImageThumb({
      image: getImageByType(images, 'Thumbnail'),
      file: null,
    });
  }, [images]);

  useEffect(() => {
    onEditModeChange && onEditModeChange(editing, savedData);
  }, [editing]);

  const onSaveImages = async () => {
    setSaving(true);

    const [imagePosterUrl, imageThumbUrl] = await Promise.all([
      await getUploadUrl(imagePoster.file, 'Poster'),
      await getUploadUrl(imageThumb.file, 'Thumbnail'),
    ]);

    setSaving(false);

    if (imagePosterUrl || imageThumbUrl) {
      setSavedData(true);
      setEditing(false);
    }
  };

  const getUploadUrl = async (file: File, type) => {
    if (!file) {
      return;
    }

    const body = {
      type,
      fileName: file.name,
    };
    const responseImage = await apiPut(
      getUrlWithOptions(`${endpointRootPath}/${id}/locales/${language}/images`),
      { body }
    );

    if (responseImage?.data?.uploadUrl?.length) {
      const fileBinaryData = await file.arrayBuffer();

      if (fileBinaryData) {
        const response = await fetch(responseImage.data.uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
          },
          body: fileBinaryData,
        });

        if (!response.ok) {
          Toaster.error({ message: 'Upload failed' });
        } else {
          return responseImage?.data?.url;
        }
      }
    }
  };

  const onCancel = () => {
    setImagePoster({
      image: getImageByType(originalImages, 'Poster'),
      file: null,
    });
    setImageThumb({
      image: getImageByType(originalImages, 'Thumbnail'),
      file: null,
    });
    setEditing(false);
  };

  return (
    <div id={idPanel} className="Images">
      <Panel
        title="Images"
        actions={
          <>
            {editing ? (
              <>
                <Button variant="secondary" text="Cancel" disabled={saving} onClick={onCancel} />
                <Button
                  variant="primary"
                  text={saving ? 'Saving...' : 'Save'}
                  disabled={saving}
                  onClick={onSaveImages}
                />
              </>
            ) : (
              <Button
                variant="primary"
                text="Edit"
                onClick={() => {
                  setEditing(!editing);
                }}
                disabled={disableEditing}
              />
            )}
          </>
        }
      >
        <>
          {editing ? (
            <Flex className="ml-4">
              <div>
                <Flex className="container-poster">
                  <p className="title">Poster</p>
                </Flex>
                <ImageUpload
                  className="image-upload"
                  label="Upload image"
                  actionText="Upload image"
                  onChange={(file) => {
                    setImagePoster({
                      image: URL.createObjectURL(file),
                      file,
                    });
                  }}
                  onRemove={() =>
                    setImagePoster({
                      image: '',
                      file: null,
                    })
                  }
                  aspectRatio={PlainImageUpload.SIXTEEN_NINE}
                  value={imagePoster.image}
                />
                <p className="title">(16:9, Min. 1920 width, Example: 1920x1080)</p>
              </div>
              <div className="ml-9">
                <Flex className="container-thumbnail">
                  <p className="title">Thumbnail</p>
                </Flex>
                <ImageUpload
                  className="image-upload image-upload-thumbnail"
                  label="Upload image"
                  actionText="Upload image"
                  onChange={(file) => {
                    setImageThumb({
                      image: URL.createObjectURL(file),
                      file,
                    });
                  }}
                  onRemove={() =>
                    setImageThumb({
                      image: '',
                      file: null,
                    })
                  }
                  aspectRatio={PlainImageUpload.SIXTEEN_NINE}
                  value={imageThumb.image}
                />
                <p className="title">(16:9, Min. 800 width, Example: 800x450)</p>
              </div>
            </Flex>
          ) : (
            <Flex className="ml-4">
              <div>
                <p className="title">Poster</p>
                <div className="">
                  <Thumbnail src={imagePoster.image} style={{ width: 265 }} />
                </div>
              </div>
              <div className="ml-9">
                <p className="title">Thumbnail</p>
                <div className="">
                  {imageThumb.image ? <Thumbnail src={imageThumb.image} style={{ width: 121 }} /> : null}
                </div>
              </div>
            </Flex>
          )}
        </>
      </Panel>
    </div>
  );
};

export default Images;
