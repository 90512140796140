import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { PlainTextArea } from '@brightcove/studio-components';
import './TextArea.scss';

interface TextAreaProps {
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
  property?: string;
  value: string;
  onChange?: (val: string, property?: string) => void;
  disableResizing?: boolean;
}

const TextArea: FC<TextAreaProps> = (props) => {
  const { className, property, value, onChange, disableResizing } = props;
  const [currentValue, setCurrentValue] = useState<string>(value || '');

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <PlainTextArea
      {...props}
      className={classNames('Text-area', className)}
      value={currentValue}
      onChange={(newValue) => {
        setCurrentValue(newValue);
        onChange && onChange(newValue, property);
      }}
      disableResizing={disableResizing || true}
    />
  );
};

export default TextArea;
