import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import './ActivityLog.scss';
import { formatDate } from '../../utils/format';
import { DEFAULT_PAGE } from '../../utils/constants';
import { getUrlWithOptions } from '../../utils';
import COLORS from '../../styles/_colors.export.scss';
import { useApi } from '../../hooks/useApi';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import SimpleSelect from '../../components/ControlledComponents/SimpleSelect';
import InputSearch from '../../components/ControlledComponents/InputSearch';

const ActivityLog = () => {
  const navigate = useNavigate();
  const { apiGet } = useApi();
  const [activityFilters, setActivityFilters] = useState<any[]>([{ label: 'All', value: '' }]);
  const [activityFilter, setActivityFilter] = useState<string>('');
  const [resourceIdFilter, setResourceIdFilter] = useState<string>('');
  const [userFilter, setUserFilter] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [currentPageSize, setCurrentPageSize] = useState(50);
  const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_PAGE);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [activities, setActivities] = useState([]);
  const [fetchUrl, setFetchUrl] = useState<string>(
    getUrlWithOptions('/activity_logs', undefined, undefined, undefined, currentPageIndex, currentPageSize)
  );
  const statusFilters = [
    { label: 'All', value: '' },
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];

  useEffect(() => {
    const filtersStr = [activityFilter, statusFilter].filter((filter) => filter).join();

    const queriesStr = [resourceIdFilter, userFilter].filter((filter) => filter).join();

    setFetchUrl(
      getUrlWithOptions(
        '/activity_logs',
        queriesStr,
        undefined,
        filtersStr,
        currentPageIndex,
        currentPageSize
      )
    );
  }, [activityFilter, resourceIdFilter, userFilter, statusFilter, currentPageIndex, currentPageSize]);
  useEffect(() => {
    (async () => {
      const [{ data: activityTypes }, { data: activitiesData }] = await Promise.all([
        apiGet(getUrlWithOptions('/activity_types')),
        apiGet(fetchUrl),
      ]);

      if (activityTypes) {
        const updatedActivityFilters = [{ label: 'All', value: '' }];

        activityTypes.forEach((activity) =>
          updatedActivityFilters.push({
            label: activity,
            value: activity,
          })
        );
        setActivityFilters(updatedActivityFilters);
      }

      setTotalCount(activitiesData.totalCount);
      setActivities(activitiesData.items);
    })();
  }, [fetchUrl]);

  const columns = [
    {
      Header: 'Activity type',
      accessor: 'activityType',
      Cell: ({ value, row: { original } }) => (
        <TitleDescription
          title={value}
          id={original.id}
          onClick={(id) => navigate(`../${ROUTES.ACTIVITY_LOG}/${id}`)}
        />
      ),
      filter: 'exactText',
      className: 'activity-type',
    },
    {
      Header: 'Resource ID',
      accessor: 'resourceIds',
      Cell: ({ value }) => <>{value.join(', ')}</>,
    },
    {
      Header: 'Admin user',
      id: 'email',
      accessor: (row) => row.changedBy.email,
      Cell: ({ value, row }) => (
        <>
          <div>{value}</div>
          <div className="subtitle">{row.original.changedBy.id}</div>
        </>
      ),
    },
    {
      Header: 'Status',
      accessor: 'success',
      Cell: ({ value }) => (
        <Icon name={value ? ICONS.CHECK2 : ICONS.WARNING} color={value ? COLORS.cyan : COLORS.burgundy} />
      ),
      filter: 'equals',
      className: 'status',
    },
    {
      Header: 'Created date and time',
      accessor: 'changedAt',
      Cell: ({ value }) => <>{formatDate(value)}</>,
      className: 'date',
    },
  ];

  const onPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
  }, []);

  return (
    <div className="Activity-log">
      <SubHeader
        title="Activity Log"
        subtitle="NetApp TV"
        icon={<Icon name={ICONS.CLOCK} />}
        quantitySubtitle={`${currentTotalCount || 0} Activity Log${currentTotalCount === 1 ? '' : 's'}`}
      />
      <Flex alignItems="flex-end" className="my-4 ml-10">
        <div className="filters-header">Filters</div>
        <div className="ml-8">
          <div className="filter-label">Activities</div>
          <SimpleSelect
            className="select-input-lg mt-1"
            value={activityFilters[0]?.value}
            onChange={(filter) => setActivityFilter(filter ? `operation:${filter}` : '')}
            options={activityFilters}
          />
        </div>
        <div className="ml-3">
          <div className="filter-label mb-1">Resource ID</div>
          <InputSearch
            placeholder="Filter by Resource ID"
            onSearch={(filter) => setResourceIdFilter(filter ? `resourceId:${filter}` : '')}
          />
        </div>
        <div className="ml-3">
          <div className="filter-label mb-1">User</div>
          <InputSearch
            placeholder="Filter by User"
            onSearch={(filter) => setUserFilter(filter ? `email:${filter}` : '')}
          />
        </div>
        <div className="ml-3">
          <div className="filter-label">Status</div>
          <SimpleSelect
            className="select-input-lg mt-1"
            value={statusFilters[0]?.value}
            onChange={(filter) =>
              setStatusFilter(filter !== undefined && filter !== '' ? `success:${filter}` : '')
            }
            options={statusFilters}
          />
        </div>
      </Flex>
      {activities.length ? (
        <Table
          data={activities}
          columns={columns}
          hasPagination
          pageCount={Math.ceil(currentTotalCount / currentPageSize)}
          pageIndex={currentPageIndex}
          pageSize={currentPageSize}
          onPaginationChange={onPaginationChange}
          width={1100}
        />
      ) : (
        <p className="Activity-log-no-results">No results found</p>
      )}
    </div>
  );
};

export default ActivityLog;
