import React, { ChangeEventHandler, FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Flex } from '@brightcove/studio-components';
import './Counter.scss';

interface CounterProps {
  className?: string;
  initialCount?: number;
  onChange?: (val: number) => void;
}

const Counter: FC<CounterProps> = ({ initialCount, onChange, className }) => {
  const [count, setCount] = useState(initialCount || 0);

  useEffect(() => {
    initialCount && setCount(initialCount);
  }, [initialCount]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCount(e.target.valueAsNumber);
    onChange?.(e.target.valueAsNumber);
  };

  return (
    <Flex className={classNames('Counter', className)} alignItems="center">
      <div
        className="Counter-icon"
        onClick={() => {
          const newCount = Math.max(count - 1, 0);

          setCount(newCount);
          onChange?.(newCount);
        }}
      >
        -
      </div>

      <input className="Counter-input mx-2" type="number" value={count} min={0} onChange={handleChange} />

      <div
        className="Counter-icon"
        onClick={() => {
          const newCount = count + 1;

          setCount(newCount);
          onChange?.(newCount);
        }}
      >
        +
      </div>
    </Flex>
  );
};

export default Counter;
