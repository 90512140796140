import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { Toaster } from '../utils';
import * as ROUTES from '../routes/routes';

interface Options {
  body?: any;
  headers?: Record<string, string>;
}

interface Response {
  data?: any;
  status: number;
  error?: Error;
}

const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const useApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentMethod, setCurrentMethod] = useState('');
  const [error, setError] = useState<Error | undefined>(undefined);
  const [status, setStatus] = useState<number>(0);
  const [currentFetchGet, setCurrentFetchGet] = useState({
    resource: '',
    method: METHODS.GET,
    options: {},
  });
  const navigate = useNavigate();

  const callApi = async (
    resource: string,
    method: string,
    options?: Options,
    showErrorToaster = true
  ): Promise<Response> => {
    setIsLoading(true);
    setError(undefined);

    const opts = {
      headers: {
        'Content-Type': 'application/json',
      },
      ...options,
      body: options?.body ? JSON.stringify(options?.body) : undefined,
    };

    setCurrentMethod(method);

    if (method === METHODS.GET) {
      setCurrentFetchGet({ resource, method: METHODS.GET, options: opts });
    }

    try {
      const response = await fetch(`${resource}`, {
        method,
        ...opts,
        credentials: 'include',
      });

      let text;
      let data;
      try {
        text = await response.text();

        try {
          data = JSON.parse(text);
        } catch (e) {
          data = response;
        }
      } catch (e) {
        data = null;
      }

      setIsLoading(false);
      setStatus(response.status);

      if (!response.ok) {
        throw data;
      }

      return {
        data,
        status: response.status,
      };
    } catch (error: any) {
      const errorObj = new Error(error?.message || 'Something went wrong');
      const errorStatus = error?.code ? parseInt(error.code, 10) : error?.status || 500;

      if (method === METHODS.GET && errorStatus === 403) {
        navigate(`../${ROUTES.ERROR}`);
      } else if (showErrorToaster) {
        Toaster.error({ message: errorObj.message });
      }

      setIsLoading(false);
      setError(errorObj as Error);
      setStatus(errorStatus);

      return {
        status: errorStatus,
        error: errorObj as Error,
      };
    }
  };

  const apiGet = (resource: string, options?: Options, showErrorToaster?: boolean): Promise<Response> =>
    callApi(resource, METHODS.GET, options, showErrorToaster);
  const apiPost = (resource: string, options?: Options): Promise<Response> =>
    callApi(resource, METHODS.POST, options);
  const apiPut = (resource: string, options?: Options): Promise<Response> =>
    callApi(resource, METHODS.PUT, options);
  const apiPatch = (resource: string, options?: Options): Promise<Response> =>
    callApi(resource, METHODS.PATCH, options);
  const apiDelete = (resource: string, options?: Options): Promise<Response> =>
    callApi(resource, METHODS.DELETE, options);
  const refetchGet = (): Promise<Response> =>
    callApi(currentFetchGet.resource, METHODS.GET, currentFetchGet.options);

  return {
    apiGet,
    apiPost,
    apiPut,
    apiPatch,
    apiDelete,
    isLoading,
    currentMethod,
    error,
    status,
    refetchGet,
  };
};
