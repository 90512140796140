import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Flex } from '@brightcove/studio-components';

import SimpleSelect from '../ControlledComponents/SimpleSelect';
import Button from '../Button/Button';
import { getUrlWithOptions } from '../../utils';
import { selectIsLanguageDisabled } from '../../store/selectors';
import { toggleLanguageDisabled } from '../../store/actions';
import { useApi } from '../../hooks/useApi';
import './LanguageDropdown.scss';

interface LanguageDropdownProps {
  title: string;
  currentLanguage: string;
  nativeLanguage: string;
  allowNativeLanguageUpdate?: boolean;
  onLanguageChange: (val: string) => void;
  onNativeLanguageChange?: (val: any) => void;
  endpointRootPath: string;
}

const LanguageDropdown: FC<LanguageDropdownProps> = ({
  title,
  currentLanguage,
  nativeLanguage,
  allowNativeLanguageUpdate = true,
  onLanguageChange,
  onNativeLanguageChange,
  endpointRootPath,
}) => {
  const { id } = useParams();
  const { apiGet, apiPut } = useApi();
  const dispatch = useDispatch();
  const isLanguageDisabled = useSelector(selectIsLanguageDisabled);
  const [languages, setAllLanguages] = useState<any[]>([]);

  const languagesList = useMemo(() => {
    return languages.map(({ label, value }) => ({
      label: `${nativeLanguage === value ? 'Native Language: ' : ''}${label}`,
      value,
    }));
  }, [languages, nativeLanguage]);

  useEffect(() => {
    (async () => {
      const { data } = await apiGet(getUrlWithOptions(`/languages`));

      setAllLanguages(data);
    })();
  }, []);

  useEffect(() => {
    dispatch(toggleLanguageDisabled(false));
  }, []);

  const setAsNativeLanguage = async () => {
    const body = { isNativeLanguage: true };

    const response = await apiPut(getUrlWithOptions(`${endpointRootPath}/${id}/locales/${currentLanguage}`), {
      body,
    });

    if (response?.data) {
      onNativeLanguageChange?.({ ...response.data, language: currentLanguage });
    }
  };

  return (
    <Flex className="Language-dropdown" flexDirection="column">
      <p className="title">{title}</p>

      <SimpleSelect
        className={`select-input-lg mr-2 mt-2 ${isLanguageDisabled ? 'disabled' : ''}`}
        value={currentLanguage}
        options={languagesList}
        disabled={isLanguageDisabled}
        onChange={onLanguageChange}
      />

      {allowNativeLanguageUpdate && (
        <Button
          className="mt-3"
          variant="primary"
          text="Set as Native Language"
          onClick={setAsNativeLanguage}
          disabled={nativeLanguage === currentLanguage}
        />
      )}
    </Flex>
  );
};

export default LanguageDropdown;
