exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".base-countryTagList-1dOVy:not(:focus-within) .base-reset-3Wcie{display:none}.base-reset-3Wcie.base-reset-3Wcie{padding:0}", ""]);

// exports
exports.locals = {
	"countryTagList": "base-countryTagList-1dOVy",
	"reset": "base-reset-3Wcie"
};