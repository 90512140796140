import React, { FC } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import SimpleSelect from '../ControlledComponents/SimpleSelect';
import Button from '../Button/Button';
import { DEFAULT_PAGE } from '../../utils/constants';
import './Pagination.scss';

interface PaginationProps {
  gotoPage: (val: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  setPageSize: (val: number) => void;
  canNextPage: any;
  pageCount: any;
  pageOptions: any;
  pageIndex: any;
  pageSize: any;
}

const Pagination: FC<PaginationProps> = ({
  gotoPage,
  previousPage,
  nextPage,
  setPageSize,
  canNextPage,
  pageCount,
  pageOptions,
  pageIndex,
  pageSize,
}) => {
  const canPreviousPage = pageIndex > DEFAULT_PAGE;

  return (
    <div className="Pagination">
      <Flex {...{ alignItems: 'baseline' }}>
        <p className="ml-4">Show: </p>
        <SimpleSelect
          className="ml-2 select-input-sm"
          value={pageSize}
          onChange={(value) => {
            setPageSize(Number(value));
          }}
          options={[10, 20, 30, 40, 50].map((optionPageSize) => ({
            label: `${optionPageSize} Rows`,
            value: optionPageSize,
          }))}
        />
      </Flex>
      <Flex {...{ alignItems: 'center' }}>
        <Button
          variant="primary"
          onClick={() => gotoPage(DEFAULT_PAGE)}
          disabled={!canPreviousPage}
          className="Pagination-button"
        >
          <Icon color="white" name={ICONS.BEGINNING_FILLED} />
        </Button>

        <Button
          className="Pagination-button"
          variant="primary"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <Icon color="white" name={ICONS.TRIANGLE_RIGHT_FILLED} rotate="180" />
        </Button>
        {!!pageCount && (
          <span className="Pagination-info">
            <strong>{`${pageIndex} `}</strong>
            of
            <strong>{` ${pageOptions.length - 1}`}</strong>
          </span>
        )}
        <Button
          className="Pagination-button"
          variant="primary"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <Icon color="white" name={ICONS.TRIANGLE_RIGHT_FILLED} />
        </Button>
        <Button
          className="Pagination-button"
          variant="primary"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <Icon color="white" name={ICONS.END_FILLED} />
        </Button>
      </Flex>
    </div>
  );
};

export default Pagination;
