export const PERMISSIONS = {
  READ_USER: 'READ_USER',
  UPDATE_USER: 'UPDATE_USER',
  DELETE_USER: 'DELETE_USER',
  READ_MAINTENANCE_MODE: 'READ_MAINTENANCE_MODE',
  CREATE_MAINTENANCE_MODE: 'CREATE_MAINTENANCE_MODE',
  UPDATE_MAINTENANCE_MODE: 'UPDATE_MAINTENANCE_MODE',
  DELETE_MAINTENANCE_MODE: 'DELETE_MAINTENANCE_MODE',
  READ_ROLE: 'READ_ROLE',
  DELETE_ROLE: 'DELETE_ROLE',
  UPDATE_ROLE: 'UPDATE_ROLE',
  CREATE_ROLE: 'CREATE_ROLE',
  READ_ACCESS_CONTROL: 'READ_ACCESS_CONTROL',
  UPDATE_ACCESS_CONTROL: 'UPDATE_ACCESS_CONTROL',
  CREATE_ACCESS_CONTROL: 'CREATE_ACCESS_CONTROL',
  DELETE_ACCESS_CONTROL: 'DELETE_ACCESS_CONTROL',
  CREATE_SERVICE_MESSAGE: 'CREATE_SERVICE_MESSAGE',
  READ_SERVICE_MESSAGE: 'READ_SERVICE_MESSAGE',
  UPDATE_SERVICE_MESSAGE: 'UPDATE_SERVICE_MESSAGE',
  DELETE_SERVICE_MESSAGE: 'DELETE_SERVICE_MESSAGE',
  READ_LOCATION: 'READ_LOCATION',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  DELETE_LOCATION: 'DELETE_LOCATION',
  CREATE_LOCATION: 'CREATE_LOCATION',
  READ_COLLECTION: 'READ_COLLECTION',
  UPDATE_COLLECTION: 'UPDATE_COLLECTION',
  CREATE_COLLECTION: 'CREATE_COLLECTION',
  DELETE_COLLECTION: 'DELETE_COLLECTION',
  EDIT_COLLECTION: 'EDIT_COLLECTION',
  PUBLISH_COLLECTION: 'PUBLISH_COLLECTION',
  READ_LOCALIZED_LABEL: 'READ_LOCALIZED_LABEL',
  CREATE_LOCALIZED_LABEL: 'CREATE_LOCALIZED_LABEL',
  UPDATE_LOCALIZED_LABEL: 'UPDATE_LOCALIZED_LABEL',
  DELETE_LOCALIZED_LABEL: 'DELETE_LOCALIZED_LABEL',
  READ_ACTIVITY_LOG: 'READ_ACTIVITY_LOG',
  READ_CONTENT: 'READ_CONTENT',
  DELETE_CONTENT: 'DELETE_CONTENT',
  UPDATE_CONTENT: 'UPDATE_CONTENT',
  PUBLISH_CONTENT: 'PUBLISH_CONTENT',
  CREATE_CONTENT: 'CREATE_CONTENT',
  READ_SITE_CONFIGURATION: 'READ_SITE_CONFIGURATION',
  UPDATE_SITE_CONFIGURATION: 'UPDATE_SITE_CONFIGURATION',
  PUBLISH_SITE_CONFIGURATION: 'PUBLISH_SITE_CONFIGURATION',
  CREATE_PAGE: 'CREATE_PAGE',
  READ_PAGE: 'READ_PAGE',
  UPDATE_PAGE: 'UPDATE_PAGE',
  DELETE_PAGE: 'DELETE_PAGE',
  PUBLISH_PAGE: 'PUBLISH_PAGE',
  CREATE_EVENT: 'CREATE_EVENT',
  READ_EVENT: 'READ_EVENT',
  UPDATE_EVENT: 'UPDATE_EVENT',
  DELETE_EVENT: 'DELETE_EVENT',
  PUBLISH_EVENT: 'PUBLISH_EVENT',
  MANAGE_EVENT: 'MANAGE_EVENT',
  READ_DRAFT: 'READ_DRAFT',
  PUBLISH_DRAFT: 'PUBLISH_DRAFT',
};

export const DEFAULT_PAGE = 1;

export const DATE_FORMAT_SECONDS = 'M/D/YYYY, H:mm:ss A';

export const DATE_FORMAT = 'M/D/YYYY, H:mm A';
