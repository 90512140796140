import React, { FC, useState } from 'react';
import classNames from 'classnames';

import Button from '../Button/Button';

import Panel, { PanelProps } from './Panel';
import './EditPanel.scss';

interface Response {
  error?: any;
}

interface EditPanelProps extends Omit<PanelProps, 'children'> {
  disableEditing?: boolean;
  disableSave?: boolean;
  children: (isEditing?: boolean) => JSX.Element;
  onCancel?: () => void;
  save: () => Promise<Response>;
  onEnableEditing?: () => void;
  id?: string;
  editActions?: JSX.Element | string;
}

const EditPanel: FC<EditPanelProps> = ({
  className,
  title,
  disableEditing,
  disableSave,
  children,
  onCancel,
  save,
  onEnableEditing,
  id,
  editActions,
}) => {
  const [editing, setEditing] = useState(false);

  return (
    <div id={id} style={{ flex: 1 }}>
      <Panel
        className={classNames('Edit-panel', className)}
        title={title}
        actions={
          editing ? (
            <>
              {editActions}
              <Button
                className="mr-1"
                variant="secondary"
                text="Cancel"
                onClick={() => {
                  onCancel && onCancel();
                  setEditing(false);
                }}
              />
              <Button
                variant="primary"
                text="Save"
                onClick={async () => {
                  const { error } = await save();

                  if (!error) {
                    setEditing(false);
                  }
                }}
                disabled={disableSave}
              />
            </>
          ) : (
            <Button
              variant="primary"
              text="Edit"
              onClick={() => {
                onEnableEditing && onEnableEditing();
                setEditing(true);
              }}
              disabled={disableEditing}
            />
          )
        }
      >
        <div className="Edit-panel-content">{children(editing)}</div>
      </Panel>
    </div>
  );
};

export default EditPanel;
