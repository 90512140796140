import moment from 'moment';

type HoursMinutesSeconds = {
  hours: number;
  minutes: number;
  seconds: number;
};

export const showDates = (startTime: string, endTime: string) => {
  let dates = '';

  dates += startTime.length ? `From ${startTime} ` : '';
  dates += endTime.length ? ` Until ${endTime}` : '';

  if (!!startTime.length && !!endTime.length) {
    dates = `${startTime} to ${endTime}`;
  }

  return dates;
};

export const formatDate = (date, withTime = true, customFormat = '', includeSeconds = false) => {
  const format = withTime ? `YYYY-MM-DD h:mm${includeSeconds ? ':ss' : ''} A` : 'YYYY-MM-DD';

  return date ? moment(date).format(customFormat || format) : '-';
};

export const formatCountdownTimer = (time) => {
  const days = time.days > 0 ? time.days + 'd' : '';
  const hours = time.hours > 0 ? time.hours + 'h' : '';
  const minutes = time.minutes > 0 ? time.minutes + 'm' : '';
  const seconds = time.seconds + 's';

  if (time.days === 0 && time.hours === 0 && time.minutes === 0) {
    return seconds;
  }

  return days + ' ' + hours + ' ' + minutes + ' ' + seconds;
};

export const secondsToTimeString = (seconds) => {
  const format = seconds < 60 * 60 ? 'mm:ss' : 'HH:mm:ss';

  return moment.utc(moment.duration(seconds, 'seconds').as('milliseconds')).format(format);
};

export const getSecondFromTime = (hh, mm, ss) => {
  return hh * 3600 + mm * 60 + ss;
};

export const secondsToTime = (value?: number): HoursMinutesSeconds => {
  if (!value) return { hours: 0, minutes: 0, seconds: 0 };

  const duration = moment.duration(value, 'seconds');

  return {
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  };
};

export const renderArrayOfStrings = (value?: string | string[]): string => {
  if (!value?.length) return '-';

  if (Array.isArray(value)) return value.join(', ');

  return value;
};
