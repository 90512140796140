import React, { FC, useState } from 'react';
import { Flex } from '@brightcove/studio-components';

import { formatDate, showDates } from '../../utils/format';
import { getAvailableLanguages, getMessageByLanguage } from '../../utils';
import { ScheduledTextPanelData, LanguageData } from '../../interfaces/ScheduledTextPanel';
import Panel from '../../components/Panel/Panel';
import SimpleSelect from '../../components/ControlledComponents/SimpleSelect';
import Button from '../../components/Button/Button';

interface MaintenanceModeViewProps extends ScheduledTextPanelData {
  showDeleteModal: (val: boolean, id: string) => void;
  setEditingId: (val: string) => void;
  disabled?: boolean;
  languages: LanguageData[];
}

const MaintenanceModeView: FC<MaintenanceModeViewProps> = ({
  id,
  status,
  startTime,
  endTime,
  messages,
  setEditingId,
  showDeleteModal,
  disabled,
  languages,
}) => {
  const [currentLanguage, setCurrentLanguage] = useState(messages[0]?.language);

  return (
    <>
      <Panel
        title={<p>Maintenance Mode</p>}
        actions={
          <>
            <SimpleSelect
              className="select-input-lg mr-2"
              value={currentLanguage}
              options={getAvailableLanguages(languages, messages) as any}
              onChange={(value) => setCurrentLanguage(value)}
              disabled={disabled}
            />
            <Button
              variant="primary"
              text="Edit"
              onClick={() => {
                setEditingId(id?.length ? id : '');
              }}
              disabled={disabled}
            />
            <Button
              variant="secondary"
              text="Delete"
              onClick={() => {
                showDeleteModal(true, id?.length ? id : '');
              }}
              disabled={disabled}
            />
          </>
        }
      >
        <>
          <Flex>
            <div className="Maintenance-mode-view">
              <p className="Maintenance-mode-view-title">Status</p>
              <p className="Maintenance-mode-view-content">{status === 'Inactive' ? 'OFF' : 'ON'}</p>
            </div>
            {startTime && endTime && (
              <div className="Service-messages-view">
                <p className="Service-messages-view-title">Time Frame</p>
                <p className="Service-messages-view-content">
                  {showDates(startTime ? formatDate(startTime) : '', endTime ? formatDate(endTime) : '')}
                </p>
              </div>
            )}
          </Flex>
          <Flex>
            <div className="Maintenance-mode-view">
              <p className="Maintenance-mode-view-title">Message</p>
              <p className="Maintenance-mode-view-content">
                {getMessageByLanguage(currentLanguage, messages)?.value}
              </p>
            </div>
          </Flex>
        </>
      </Panel>
    </>
  );
};
export default MaintenanceModeView;
