import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import './SimpleSelect.scss';
import { PlainSelect } from '@brightcove/studio-components';

interface SimpleSelectProps {
  property?: string;
  value: string | number | boolean;
  onChange: (val: string, property?: string) => void;
  options: unknown[];
  className?: string;
  disabled?: boolean;
}
const SimpleSelect: FC<SimpleSelectProps> = ({
  property,
  value: initialValue,
  onChange,
  options,
  className,
  disabled = false,
}) => {
  const [value, setValue] = useState(initialValue);
  const onSelectionChange = (selectedValue) => {
    setValue(selectedValue);
    onChange?.(selectedValue, property);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <PlainSelect
      className={classnames(`SimpleSelect`, className)}
      value={value}
      options={options}
      onChange={onSelectionChange}
      disabled={disabled}
    />
  );
};

export default SimpleSelect;
