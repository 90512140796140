import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Flex } from '@brightcove/studio-components';

import './ContentManagementDetail.scss';

import {
  getLocaleByLanguage,
  DEFAULT_LANGUAGE,
  CONTENT_EPISODE,
  getUrlWithOptions,
  getNativeLanguage,
} from '../../utils';
import { ContentDetailData, ContentLocalesData, CuePointData } from '../../interfaces/Content';
import { useApi } from '../../hooks/useApi';
import config from '../../config';
import TableOfContents from '../../components/TableOfContents/TableOfContents';
import ReactPlayer from '../../components/ReactPlayer/ReactPlayer';
import Images from '../../components/Panel/Images';
import Availability from '../../components/Panel/Availability';
import LanguageDropdown from '../../components/LanguageDropdown/LanguageDropdown';
import CuePointsBar from '../../components/CuePointsBar/CuePointsBar';
import Button from '../../components/Button/Button';

import Speakers from './Panels/Speakers';
import RelatedResources from './Panels/RelatedResources';
import LocaleInformationEpisodes from './Panels/LocaleInformationEpisodes';
import Information from './Panels/Information';
import CuePoints from './Panels/CuePoints';
import ContentMovie from './Panels/ContentMovie';
import ContentEpisode from './Panels/ContentEpisode';
import CollectionsContained from './Panels/CollectionsContained';

const initialContent = [
  { title: 'Content', id: 'sectionContent', offset: -100 },
  { title: 'Availability', id: 'sectionAvailability', offset: -100 },
  { title: 'Images', id: 'sectionImages', offset: -100 },
  {
    title: 'Locale Information',
    id: 'sectionLocaleInformation',
    offset: -100,
  },
  {
    title: 'Collections Contained In',
    id: 'sectionCollections',
    offset: -150,
  },
  { title: 'Information', id: 'sectionInformation', offset: -100 },
  { title: 'Speakers', id: 'sectionSpeakers', offset: -100 },
  { title: 'Related Resources', id: 'sectionRelatedResources', offset: -100 },
];
const CONTENT = '/content';

let player;

const ContentManagementDetailSeries = ({ data, localesData, id, onSavedData, onLanguageChange }) => {
  const { apiPatch, isLoading } = useApi();
  const [currentData, setCurrentData] = useState<ContentDetailData>();
  const [currentLocalesData, setCurrentLocalesData] = useState<ContentLocalesData[]>([]);
  const [canShowPlayer, setCanShowPayer] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);
  const [currentId, setCurrentId] = useState(id ? id : '');
  const [currentLocaleInformation, setCurrentLocaleInformation] = useState<any>();
  const [currentImageData, setCurrentImages] = useState<any>();
  const [currentInformation, setCurrentInformation] = useState<any>();
  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  // Cue points:
  const [duration, setDuration] = useState(0);
  // Set the time in CuePointsBar and Player
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [addNewCuePoint, setAddNewCuePoint] = useState<boolean>(false);
  // Open the Cuepoints Panel with the selected cuepoint
  const [selectedCuePoint, setSelectedCuePoint] = useState<CuePointData | null>(null);
  // There are the cuepoints after the creation of one of them.
  const [editedCuePoints, setEditedCuePoints] = useState<CuePointData[]>();

  const [isEditingPanel, setIsEditingPanel] = useState(false);
  const [reloadPlayer, setReloadPlayer] = useState(false);

  const onSuccess = (success) => {
    player = success.ref;
    player.on('durationchange', () => {
      setDuration(player.duration());
    });
  };

  const clearCurrentLanguage = () => {
    const hasLocaleDataForCurrentLanguage = localesData.some((item) => item.language === currentLanguage);

    if (!hasLocaleDataForCurrentLanguage) {
      setCurrentLanguage(DEFAULT_LANGUAGE);
    }
  };

  const processData = () => {
    setCurrentData(data);
    setCurrentLocalesData(localesData);
    setCurrentId(id);

    if (isInitializing) {
      const nativeLanguage = getNativeLanguage(localesData) || DEFAULT_LANGUAGE;
      setCurrentLanguage(nativeLanguage);
      setIsInitializing(false);
    }

    if (!config.account_id?.length) {
      // eslint-disable-next-line no-console
      console.error('Content Management - The player needs a REACT_APP_BC_ACCOUNT_ID');
    } else {
      setCanShowPayer(true);
    }

    setCurrentInformation(getDataInformation(data, getLocaleByLanguage(localesData, currentLanguage), id));
    setCurrentLocaleInformation(
      getDataLocaleInformation(getLocaleByLanguage(localesData, currentLanguage), currentLanguage)
    );
    setCurrentImages(
      getImageData(getLocaleByLanguage(localesData, currentLanguage)?.images, id, currentLanguage)
    );
  };

  useEffect(() => {
    if (player) {
      player.currentTime(currentTime);
    }
  }, [currentTime]);

  useEffect(() => {
    if (!!currentLanguage && !!currentLocalesData?.length && !!currentData) {
      setCurrentLocaleInformation(
        getDataLocaleInformation(getLocaleByLanguage(currentLocalesData, currentLanguage), currentLanguage)
      );
      setCurrentImages(
        getImageData(
          getLocaleByLanguage(currentLocalesData, currentLanguage)?.images,
          currentId,
          currentLanguage
        )
      );
      setCurrentInformation(
        getDataInformation(currentData, getLocaleByLanguage(currentLocalesData, currentLanguage), currentId)
      );
    }
  }, [currentLanguage]);

  useEffect(() => {
    clearCurrentLanguage();
  }, [localesData]);

  useEffect(() => {
    if (!!data && !!id.length) {
      processData();
    }
  }, [data, localesData, id]);

  const getDataAvailability = (contentData: ContentDetailData) => {
    const { startDate, endDate, locations, accessControls, ntv } = contentData;

    return {
      startDate,
      endDate,
      locations,
      accessControls,
      ntv,
    };
  };

  const getDataCuepoints = (ContentData: ContentDetailData, selectedId: string) => {
    const { cuepoints } = ContentData;
    const tempCuepoints = editedCuePoints?.length ? editedCuePoints : cuepoints;

    return {
      id: selectedId,
      cuepoints: tempCuepoints.sort((a, b) => a.time - b.time),
    };
  };

  const getDataContent = (ContentData: ContentDetailData, selectedId: string) => {
    const { extId, source, publicUrl, series, season, brightcoveVideoId } = ContentData;

    return {
      id: selectedId,
      extId,
      source,
      publicUrl,
      series,
      season,
      brightcoveVideoId,
    };
  };

  const getImageData = (imagesData: any[], selectedId: string, language: string) => {
    return {
      images: imagesData,
      id: selectedId,
      language,
    };
  };

  const getDataInformation = (
    contentData: ContentDetailData,
    dataLocales: ContentLocalesData,
    selectedId: string
  ) => {
    const {
      syndicationDescription,
      campaignCode,
      translationStatus,
      genre,
      extId,
      industrySector,
      youtubePrivacy,
      tags,
      ticket,
      episodes,
      type,
      sessionId,
      sessionJourney,
      sessionTimeId,
      sessionCode,
      productionYear,
    } = contentData || {};

    const { product, industry, topic } = dataLocales || {};

    return {
      product,
      topic,
      industry,
      translationStatus,
      genre,
      extId,
      industrySector,
      syndicationDescription,
      campaignCode,
      youtubePrivacy,
      tags,
      ticket,
      episodes,
      type,
      id: selectedId,
      sessionId,
      sessionJourney,
      sessionTimeId,
      sessionCode,
      productionYear,
    };
  };

  const getDataLocaleInformation = (dataLocales: ContentLocalesData, language: string) => {
    const {
      name,
      description,
      cta,
      keywords,
      industry,
      product,
      service,
      sessionTechLevel,
      sessionTheme,
      sessionType,
      topic,
      speakers,
      resources,
    } = dataLocales || {};

    return {
      name,
      description,
      cta,
      language,
      keywords,
      industry,
      product,
      service,
      sessionTechLevel,
      sessionTheme,
      sessionType,
      topic,
      speakers,
      resources,
    };
  };

  const handleLanguageChange = (value) => {
    setCurrentLanguage(value);
    onLanguageChange?.(value);
  };

  const updateLocales = (updatedLocale, updatedLocales) => {
    const index = _.findIndex(updatedLocales, (locale: any) => locale.language === updatedLocale.language);

    if (index > -1) {
      updatedLocales[index] = updatedLocale;
    } else {
      updatedLocales.push(updatedLocale);
    }

    setCurrentLocalesData(updatedLocales);
  };

  const onNativeLanguageChange = (updatedLocale) => {
    const updatedLocales = currentLocalesData.map((locale) => {
      if (locale.language === updatedLocale.language) {
        return { ...locale, isNativeLanguage: true };
      } else {
        return { ...locale, isNativeLanguage: false };
      }
    });

    updateLocales(updatedLocale, updatedLocales);
  };

  const onCovertToAd = async () => {
    const response = await apiPatch(getUrlWithOptions(`/content/${id}`), {
      body: {
        subType: 'AdContent',
      },
    });

    if (response?.data) {
      onSavedData();
    }
  };

  return (
    <>
      {currentData && !!currentLocalesData?.length ? (
        <>
          <Flex justifyContent="center" className="ReactVideoPlayer-container">
            {canShowPlayer && (
              <ReactPlayer
                accountId={config.account_id}
                contentId={currentId}
                videoId={currentData?.brightcoveVideoId || ''}
                reloadPlayer={reloadPlayer}
                onSuccess={onSuccess}
                startDate={currentData.startDate}
              />
            )}
          </Flex>
          {currentData ? (
            <CuePointsBar
              cuePoints={editedCuePoints?.length ? editedCuePoints : currentData?.cuepoints}
              duration={duration}
              selectedTime={currentTime}
              className="mt-6"
              onCuePointSelected={setSelectedCuePoint}
              onChangeTime={setCurrentTime}
            />
          ) : null}
          <span className="ml-11 mb-8">
            <Flex className="mt-4">
              <Flex flexWrap="wrap" style={{ flex: 'auto' }}>
                <Button
                  variant="primary"
                  text="Add a cue point"
                  onClick={() => setAddNewCuePoint(true)}
                  className="mb-4"
                />
                <Button
                  variant="primary"
                  text={isLoading ? 'Loading...' : 'Convert to Ad'}
                  onClick={onCovertToAd}
                  className="mb-4"
                />

                {addNewCuePoint || selectedCuePoint ? (
                  <CuePoints
                    idPanel="sectionCuepoints"
                    data={getDataCuepoints(currentData, currentId)}
                    addNew={addNewCuePoint}
                    duration={duration}
                    onCancel={() => {
                      setAddNewCuePoint(false);
                      setSelectedCuePoint(null);
                    }}
                    selectedCuePoint={selectedCuePoint}
                    selectedTime={currentTime}
                    onChangeTime={setCurrentTime}
                    onSaveCuePoint={(cuePoints) => {
                      setEditedCuePoints(cuePoints);
                      onSavedData();
                      setReloadPlayer((prevReloadPlayer) => !prevReloadPlayer);
                    }}
                  />
                ) : null}
                {currentData.type === CONTENT_EPISODE ? (
                  <ContentEpisode idPanel="sectionContent" data={getDataContent(currentData, currentId)} />
                ) : (
                  <ContentMovie idPanel="sectionContent" data={getDataContent(currentData, currentId)} />
                )}
                <Availability
                  idPanel="sectionAvailability"
                  data={getDataAvailability(currentData)}
                  endpointRootPath={CONTENT}
                  onEditModeChange={(editing, isSavedData) => {
                    setIsEditingPanel(editing);

                    if (isSavedData) {
                      onSavedData();
                    }
                  }}
                  disableEditing={isEditingPanel}
                />
                <Images
                  idPanel="sectionImages"
                  data={currentImageData}
                  endpointRootPath={CONTENT}
                  onEditModeChange={(editing, isSavedData) => {
                    setIsEditingPanel(editing);

                    if (isSavedData) {
                      onSavedData();
                    }
                  }}
                  disableEditing={isEditingPanel}
                />
                <LocaleInformationEpisodes
                  idPanel="sectionLocaleInformation"
                  data={currentLocaleInformation}
                  onEditChange={(editing, isSaving = false) => {
                    setIsEditingPanel(editing);

                    if (isSaving) {
                      onSavedData();
                    }
                  }}
                  disabledEdit={isEditingPanel}
                />
                <CollectionsContained
                  idPanel="sectionCollections"
                  data={currentData?.containedInCollections}
                />
                <Information
                  idPanel="sectionInformation"
                  data={currentInformation ? currentInformation : {}}
                  onEditModeChange={(editing) => {
                    setIsEditingPanel(editing);
                    onSavedData();
                  }}
                  disabledEditing={isEditingPanel}
                />
                <Speakers
                  idPanel="sectionSpeakers"
                  data={{
                    id: currentId,
                    currentLanguage,
                    speakers: currentLocaleInformation?.speakers,
                  }}
                  onEditChange={(editing, isSaving = false) => {
                    setIsEditingPanel(editing);

                    if (isSaving) {
                      onSavedData();
                    }
                  }}
                  disabledEdit={isEditingPanel}
                />
                <RelatedResources
                  idPanel="sectionRelatedResources"
                  data={{
                    id: currentId,
                    currentLanguage,
                    relatedResources: currentLocaleInformation?.resources,
                  }}
                  onEditChange={(editing, isSaving = false) => {
                    setIsEditingPanel(editing);

                    if (isSaving) {
                      onSavedData();
                    }
                  }}
                  disabledEdit={isEditingPanel}
                />
              </Flex>
              <div className="ml-8 mr-8">
                <LanguageDropdown
                  title="Language"
                  currentLanguage={currentLanguage}
                  endpointRootPath={CONTENT}
                  nativeLanguage={getNativeLanguage(currentLocalesData)}
                  onNativeLanguageChange={onNativeLanguageChange}
                  onLanguageChange={handleLanguageChange}
                />
                <TableOfContents data={initialContent} isFixed={false} />
              </div>
            </Flex>
          </span>
        </>
      ) : null}
    </>
  );
};

export default ContentManagementDetailSeries;
