import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../../routes';
import { pageFilters } from '../../../utils';
import COLORS from '../../../styles/_colors.export.scss';
import TitleDescription from '../../../components/TitleDescription/TitleDescription';
import Table from '../../../components/Table/Table';
import EditPanel from '../../../components/Panel/EditPanel';
import AddPageModal from '../../../components/Modals/AddPageModal';
import { withModal } from '../../../components/Modals';
import Button from '../../../components/Button/Button';
import './MenuPanel.scss';

const AddMenuItemButton = withModal(Button, AddPageModal);
const ChangePageButton = withModal(Button, AddPageModal);

const MenuPanel = ({ propertyName, title, data, disableEditing, onEnableEditing, onCancel, onSave }) => {
  const navigate = useNavigate();
  const [panelItems, setPanelItems] = useState<any[]>([]);
  const isPagePanel = propertyName && propertyName === 'pages';
  const columns = [
    {
      Header: 'Page Item',
      colWidth: '35%',
      accessor: (row) => [row.name, row.id],
      Cell: ({ value }) => {
        const [name, id] = value;

        return (
          <TitleDescription
            title={name}
            description={id}
            id={id}
            onClick={() => navigate(`../${ROUTES.PAGES}/edit/${id}`)}
          />
        );
      },
    },
    {
      Header: 'Status',
      className: 'status',
      colWidth: '5%',
      accessor: 'published',
      Cell: ({ value }) => (
        <Icon name={value ? ICONS.CHECK2 : ICONS.WARNING} color={value ? COLORS.cyan : COLORS.burgundy} />
      ),
    },
  ];
  const pagesColumns = [
    {
      Header: 'Page Type',
      colWidth: '15%',
      accessor: 'key',
      Cell: ({ value }) => getPageTypeLabel(value),
    },
    {
      Header: 'Page',
      colWidth: '25%',
      accessor: (row) => [row.name, row.id],
      Cell: ({ value }) => {
        const [name, id] = value;

        return (
          name &&
          id && (
            <TitleDescription
              title={name}
              description={id}
              id={id}
              onClick={() => navigate(`../${ROUTES.PAGES}/edit/${id}`)}
            />
          )
        );
      },
    },
    {
      Header: '',
      colWidth: '10%',
      accessor: (row) => [row.key, row.type],
      id: 'change-btn',
      Cell: ({ value }) => {
        const [key, type] = value;

        return (
          <ChangePageButton
            className="mb-5 mt-2 change-page-button"
            variant="primary"
            modalProps={{
              filter: key,
              pageChangeModal: true,
              onPageUpdate: updatePage,
            }}
          >
            {type ? 'Change' : 'Add'}
          </ChangePageButton>
        );
      },
    },
  ];

  const getPageTypeLabel = (type) => {
    const pageFilter = pageFilters.filter((pageFilter) => pageFilter.id.toLowerCase() === type.toLowerCase());
    const pageFilterLabel = pageFilter.length > 0 ? pageFilter[0]?.name : '';

    if (pageFilterLabel.endsWith('s')) {
      return pageFilterLabel.substring(0, pageFilterLabel.length - 1);
    }

    return pageFilterLabel;
  };

  const onClickDeleteRow = (id: string) => {
    setPanelItems((prev) => prev.filter((item: any) => item.id !== id));
  };

  const onDragRow = (reorderedRows: any[]) => setPanelItems(reorderedRows);

  const addPage = (selections: any[]) => {
    setPanelItems((prev) => {
      return [...prev, ...selections];
    });
  };

  const updatePage = (selections: any[]) => {
    const selectedPageInfo = _.pick(selections[0], ['name', 'type', 'id']);

    setPanelItems((prev) =>
      prev.map((item) => (item.key === selectedPageInfo.type ? { ...selectedPageInfo, key: item.key } : item))
    );
  };

  const getPagePanelData = useCallback(() => {
    return Object.entries(data || {}).map(([key, value]) => {
      return { key, id: `temp_id_${_.uniqueId()}`, ...(value as Record<string, never>) };
    });
  }, [data]);

  const onSaveChanges = () => {
    let updatedPanelItems = panelItems.map((item) => item.id);

    if (isPagePanel) {
      const pages = _.keyBy(panelItems, 'key');

      updatedPanelItems = _.forOwn(pages, ({ id }, key: string) => {
        pages[key] = id.indexOf('temp_id') === -1 ? id : '';
      }) as any;
    }

    return onSave(propertyName, updatedPanelItems);
  };

  useEffect(() => {
    setPanelItems(isPagePanel ? getPagePanelData() : data);
  }, [isPagePanel, getPagePanelData, data]);

  return (
    <EditPanel
      title={<p>{title}</p>}
      disableEditing={disableEditing}
      onEnableEditing={onEnableEditing}
      onCancel={() => {
        setPanelItems(isPagePanel ? getPagePanelData() : data);
        onCancel();
      }}
      save={() => onSaveChanges()}
    >
      {(isEditing) => (
        <div className={classNames('Site-configuration-menu', { editing: isEditing })}>
          <>
            {panelItems && (
              <Table
                className={isEditing ? 'isEditing' : ''}
                data={panelItems}
                columns={isPagePanel ? pagesColumns : columns}
                onClickDeleteRow={onClickDeleteRow}
                onDragRow={onDragRow}
                hasDeletion={!isPagePanel}
                hasDragging={!isPagePanel}
              />
            )}
            {isEditing && !isPagePanel && (
              <AddMenuItemButton
                className="mt-5 mb-5 mt-2 add-menu-item-button"
                variant="primary"
                modalProps={{
                  filter: 'CustomPage,StandardPage',
                  onAddPage: addPage,
                }}
              >
                + Add Menu Item
              </AddMenuItemButton>
            )}
          </>
        </div>
      )}
    </EditPanel>
  );
};

export default MenuPanel;
