import { v4 as uuidv4 } from 'uuid';
import React, { FC, useState, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { Flex } from '@brightcove/studio-components';

import Table from '../../Table/Table';
import TextInput from '../../ControlledComponents/TextInput';
import Checkbox from '../../ControlledComponents/Checkbox';
import Button from '../../Button/Button';
import { SVGImage } from '../../../assets/images';

interface QuestionAnswerProps {
  className?: string;
  showNoAnswerBlurb?: boolean;
  question: string;
  answers: string[];
  correctAnswer?: string;
  ctaType: string;
  onAddAnswer: () => void;
  onUpdateQuestion: (val: string) => void;
  onUpdateAnswers: (val: string[]) => void;
  onDeleteAnswer: (val: string[]) => void;
  onDeleteQuestion?: () => void;
  onCorrectAnswerChange?: (val2: number) => void;
}

const QuestionAnswer: FC<QuestionAnswerProps> = ({
  className,
  showNoAnswerBlurb = true,
  question,
  answers: answersProp,
  correctAnswer,
  ctaType,
  onAddAnswer,
  onUpdateQuestion,
  onUpdateAnswers,
  onDeleteAnswer,
  onDeleteQuestion,
  onCorrectAnswerChange,
}) => {
  const getAnswersWithAnswersProp = (answersArr) => {
    return answersArr.map((item) => ({ ...item, answers: answersArr }));
  };

  const formatAnswers = (answersArr) => {
    const formattedAnswers = answersArr.map((answer, index) => ({
      answer,
      isCorrectAnswer: correctAnswer?.toString() == index,
      id: uuidv4(),
    }));

    return getAnswersWithAnswersProp(formattedAnswers);
  };

  const [answers, setAnswers] = useState<any[]>(formatAnswers(answersProp || []));

  const onAnswerChange = (newValue, rowInfo) => {
    const updatedAnswersAsStrings: any[] = [];
    const updatedAnswersAsObjects: any[] = [];

    for (const item of rowInfo.answers) {
      const updatedItem = item;

      if (item.id === rowInfo.id) {
        updatedItem.answer = newValue;
      }

      updatedAnswersAsStrings.push(updatedItem.answer);
      updatedAnswersAsObjects.push(updatedItem);
    }

    setAnswers(getAnswersWithAnswersProp(updatedAnswersAsObjects));
    onUpdateAnswers(updatedAnswersAsStrings);
  };

  const onCorrectAnswerClick = (checked, index, rowInfo) => {
    const updatedAnswersAsObjects = rowInfo.answers?.map((item, currentIndex) => {
      return {
        ...item,
        isCorrectAnswer: checked && index == currentIndex,
      };
    });

    setAnswers(getAnswersWithAnswersProp(updatedAnswersAsObjects));
    onCorrectAnswerChange?.(checked ? index : null);
  };

  // hacky solution to not lose references and avoid errors on table's input
  // see https://github.com/TanStack/table/issues/1336#issuecomment-659288615
  const onAnswerChangeRef = useRef(onAnswerChange);
  const onCorrectAnswerClickRef = useRef(onCorrectAnswerClick);
  onAnswerChangeRef.current = onAnswerChange;
  onCorrectAnswerClickRef.current = onCorrectAnswerClick;

  const answerColumns = useMemo(
    () => [
      {
        accessor: 'answer',
        Cell: ({ value, row: { original, id } }) => {
          return (
            <>
              {ctaType === 'QUIZ' && (
                <Flex className="mt-4" alignItems="center">
                  <div className="label">
                    Answers{Number(id) <= 1 ? '*' : ''} (Minimum 2, maximum 5 answers)
                  </div>
                  <Checkbox
                    className="correct-answer-checkbox"
                    label="Correct answer"
                    checked={original.isCorrectAnswer}
                    onChange={(e) => {
                      onCorrectAnswerClickRef?.current?.(e.target.checked, id, original);
                    }}
                  />
                </Flex>
              )}
              <TextInput
                value={value || ''}
                onChange={(newValue) => {
                  onAnswerChangeRef?.current?.(newValue, original);
                }}
              />
            </>
          );
        },
        className: 'answer',
      },
    ],
    [question]
  );

  const onAnswerAdd = () => {
    const updatedAnswers = answers.concat({
      answer: '',
      isCorrectAnswer: false,
      id: uuidv4(),
    });

    setAnswers(getAnswersWithAnswersProp(updatedAnswers));
    onAddAnswer();
  };

  const onClickDeleteRow = (id) => {
    const updatedAnswersAsStrings: any[] = [];
    const updatedAnswersAsObjects: any[] = [];

    for (const item of answers) {
      if (item.id !== id) {
        updatedAnswersAsStrings.push(item.answer);
        updatedAnswersAsObjects.push(item);
      }
    }

    setAnswers(getAnswersWithAnswersProp(updatedAnswersAsObjects));
    onDeleteAnswer(updatedAnswersAsStrings);
  };

  const onDragRow = (reorderedRows) => {
    setAnswers(reorderedRows);
    onUpdateAnswers(reorderedRows.map(({ answer }) => answer));
  };

  return (
    <div className={classnames('full-width-grid-item', className)}>
      <div>
        <div className="label mb-2 required">Question</div>
        <Flex>
          <TextInput property="question" value={question || ''} onChange={onUpdateQuestion} />
          {onDeleteQuestion && (
            <img
              className="delete-btn ml-4"
              src={SVGImage.Trashcan}
              alt="trashcan"
              onClick={onDeleteQuestion}
            />
          )}
        </Flex>
      </div>
      {answers.length === 0 && showNoAnswerBlurb ? (
        <b className="mt-5" style={{ display: 'block' }}>
          If no answers are added, a text box will be shown for users to enter their answers during the{' '}
          {ctaType.toLowerCase()}
        </b>
      ) : (
        <>
          {ctaType !== 'QUIZ' && <div className="label mt-5 mb-1">Answers (Maximum 5 answers)</div>}
          <Table
            className="answers-table"
            data={answers}
            columns={answerColumns}
            onClickDeleteRow={onClickDeleteRow}
            onDragRow={onDragRow}
            hasDeletion
            hasDragging
          />
        </>
      )}
      <div className="mt-5">
        <Button
          variant="primary"
          text="+ Add Answer"
          onClick={onAnswerAdd}
          disabled={!question || answers.length === 5}
        />
      </div>
    </div>
  );
};

export default QuestionAnswer;
