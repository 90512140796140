import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { DEFAULT_LANGUAGE, getLocaleByLanguage, getNativeLanguage, getUrlWithOptions } from '../../utils';
import { useApi } from '../../hooks/useApi';
import AddBodyClasses from '../../helpers/AddBodyClasses';
import TableOfContents from '../../components/TableOfContents/TableOfContents';
import SubHeader from '../../components/SubHeader/SubHeader';
import TemplatePanel from '../../components/Panel/TemplatePanel';
import Loading from '../../components/Loading/Loading';
import LanguageDropdown from '../../components/LanguageDropdown/LanguageDropdown';

import SectionInformation from './Panels/SectionInformation';
import SectionContent from './Panels/SectionContent/SectionContent';
import LocaleInformation from './Panels/LocaleInformation';
import './PageSection.scss';

const pagePanels = [
  { id: 'section', title: 'Section Information', offset: -250 },
  { id: 'locale', title: 'Locale Information', offset: -250 },
  { id: 'content', title: 'Section Content', offset: -250 },
  { id: 'template', title: 'Section Template', offset: -250 },
];

const PageSection = () => {
  const { apiGet, apiPut } = useApi();
  const [section, setSection] = useState<any>({});
  const [locales, setLocales] = useState<any[]>([]);
  const { pageId, id: sectionId } = useParams();
  const [disableEditing, setDisableEditing] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);
  const [currentLocale, setCurrentLocale] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const PAGES_ENDPOINT = '/pages';
  const sectionUrl = getUrlWithOptions(`${PAGES_ENDPOINT}/${pageId}/sections/${sectionId}`);
  const navigate = useNavigate();
  const sectionType = section?.type?.toLowerCase();

  useEffect(() => {
    (async () => {
      setLoading(true);

      const { data } = await apiGet(sectionUrl);

      if (data) {
        const localesData = data?.locales;
        const nativeLanguage = getNativeLanguage(localesData) || DEFAULT_LANGUAGE;
        const currentLocaleInfo = getLocaleByLanguage(localesData, nativeLanguage);

        setSection(data);
        setLocales(localesData);
        setCurrentLanguage(nativeLanguage);
        setCurrentLocale(currentLocaleInfo);
      }

      setLoading(false);
    })();
  }, []);

  const updateLocaleInfo = async (localeInfo, languageParam?: string): Promise<any> => {
    const { error } = await apiPut(`${sectionUrl}/locales/${languageParam || currentLanguage}`, {
      body: _.omit(localeInfo, ['language', 'isNativeLanguage', 'pageSectionId']),
    });

    if (error) {
      return { error };
    }

    const { data: sectionData } = await apiGet(sectionUrl);
    const localesData = sectionData?.locales;
    const currentLocaleInfo = getLocaleByLanguage(localesData, currentLanguage);
    setSection(sectionData);
    setLocales(localesData);
    setCurrentLocale(currentLocaleInfo);

    toggleDisableEditing();

    return {};
  };

  const updateSectionInfo = async (updatedSectionInfo): Promise<any> => {
    const { error } = await apiPut(sectionUrl, { body: updatedSectionInfo });

    if (error) {
      return { error };
    }

    const { data } = await apiGet(sectionUrl);

    data && setSection(data);
    toggleDisableEditing();

    return {};
  };

  const onUpdateSectionContent = async (updatedSection, customizations) => {
    if (!customizations.length) {
      const updatedSectionContent = _.omit(updatedSection.content, ['name', 'collectionType']);
      const { error: sectionError } = await updateSectionInfo(
        _.omit({ ...updatedSection, content: updatedSectionContent }, ['id', 'pageId', 'type', 'locales'])
      );

      if (sectionError) {
        return {
          error: {
            ...(sectionError && sectionError),
          },
        };
      }
    }

    if (!_.isEqual(customizations, currentLocale?.customizations)) {
      await updateLocaleInfo({ customizations });
    }

    toggleDisableEditing();

    return {};
  };

  // copied over from PageDetail; needs to be adapted to this page
  const filteredTableOfContents = () => {
    return pagePanels.filter((panel) => {
      switch (sectionType) {
        case 'grid':
        case 'carousel':
          return true;
        case 'hero':
          return panel.id !== 'locale';
        case 'seriesdetail':
          return panel.id !== 'content';
        case 'custom':
          return panel.id !== 'locale' && panel.id !== 'content';
        default:
          return panel.id !== 'locale' && panel.id !== 'content';
      }
    });
  };

  const onLanguageChange = (value) => {
    const localeInfo = getLocaleByLanguage(locales, value);

    setCurrentLanguage(value);
    setCurrentLocale(localeInfo);
  };

  const goBack = () => navigate(`../${ROUTES.PAGES}/edit/${pageId}`);

  const toggleDisableEditing = () => setDisableEditing(!disableEditing);

  const defaultPanelProps = {
    onCancel: toggleDisableEditing,
    disableEditing,
    toggleDisableEditing,
  };

  return (
    <>
      {loading && _.isEmpty(section) && <Loading />}
      {!_.isEmpty(section) && (
        <>
          <AddBodyClasses classes={['disabled-overflow']} />
          <div className="Page-section">
            <SubHeader
              title={section.name}
              subtitle="Back to Page"
              className="pl-5 pr-3"
              detailMode
              onBack={goBack}
              icon={<Icon name={ICONS.ARROW_RIGHT} flip="horizontal" />}
            />
            <Flex>
              <div style={{ flex: 1 }} className="page-content">
                <SectionInformation
                  {...defaultPanelProps}
                  panelId="section"
                  section={section}
                  onSave={(payload) => updateLocaleInfo(payload, 'en-us')}
                  includePlayerCustomization={sectionType === 'player' || sectionType === 'eventhero'}
                />
                {(sectionType === 'carousel' || sectionType === 'grid' || sectionType === 'seriesdetail') && (
                  <LocaleInformation
                    {...defaultPanelProps}
                    panelId="locale"
                    data={currentLocale}
                    onSave={updateLocaleInfo}
                    customSection={!(sectionType === 'carousel' || sectionType === 'grid')}
                  />
                )}
                {(sectionType === 'carousel' || sectionType === 'grid' || sectionType === 'hero') && (
                  <SectionContent
                    {...defaultPanelProps}
                    panelId="content"
                    section={section}
                    pageId={pageId}
                    currentLanguage={currentLanguage}
                    onSave={onUpdateSectionContent}
                    customizations={currentLocale?.customizations || []}
                  />
                )}
                {sectionType !== 'carousel' || sectionType === 'grid' || sectionType === 'hero'}
                <TemplatePanel
                  {...defaultPanelProps}
                  id="template"
                  title="Section Template"
                  data={section?.template || ''}
                  onSave={(property, templateText) => updateSectionInfo({ template: templateText })}
                  onEnableEditing={toggleDisableEditing}
                />
              </div>
              <div className="side-menu">
                {(sectionType === 'carousel' ||
                  sectionType === 'grid' ||
                  sectionType === 'hero' ||
                  sectionType === 'seriesdetail') && (
                  <LanguageDropdown
                    title="Language"
                    currentLanguage={currentLanguage}
                    nativeLanguage={getNativeLanguage(section.locales)}
                    endpointRootPath={`${PAGES_ENDPOINT}/${pageId}/sections`}
                    onLanguageChange={onLanguageChange}
                  />
                )}
                <TableOfContents data={filteredTableOfContents()} hasBorder />
              </div>
            </Flex>
          </div>
        </>
      )}
    </>
  );
};

export default PageSection;
