import React, { FC, useState } from 'react';

import Button from '../Button/Button';
import Pages from '../../routes/Pages/Pages';

import GenericModal, { GenericModalProps } from './GenericModal';
import './AddPageModal.scss';

interface AddPageModalProps extends GenericModalProps {
  pageChangeModal?: boolean;
  filter?: string;
  onAddPage?: (param: any[]) => void;
  onPageUpdate?: (param: any[]) => void;
}

const AddPageModal: FC<AddPageModalProps> = ({
  show,
  onClose,
  pageChangeModal,
  filter,
  onAddPage,
  onPageUpdate,
}) => {
  const [selections, setSelections] = useState<any[]>([]);

  const handleCloseModal = () => onClose?.();
  const onRowSelect = (items: any[]) => {
    setSelections(items);
  };

  return (
    <GenericModal
      className="Add-page-modal"
      show={show}
      title={pageChangeModal ? 'Change Page Template' : 'Add Menu Items'}
      onClose={handleCloseModal}
      buttons={
        <>
          <Button variant="secondary" text="Cancel" onClick={handleCloseModal} />,
          <Button
            className="ml-3"
            variant="primary"
            text={pageChangeModal ? 'Set Template' : 'Add Items'}
            disabled={selections.length === 0}
            onClick={() => {
              if (pageChangeModal) {
                onPageUpdate?.(selections);
              } else {
                onAddPage?.(selections);
              }

              handleCloseModal();
            }}
          />
        </>
      }
    >
      <Pages embedded selectedFilter={filter} pageChangeModal={pageChangeModal} onRowSelect={onRowSelect} />
    </GenericModal>
  );
};

export default AddPageModal;
