import React, { FC } from 'react';
import classnames from 'classnames';
import { Button as BrightcoveButton } from '@brightcove/studio-components';
import './Button.scss';

interface ButtonProps {
  variant: string;
  className?: string;
  text?: string;
  onClick: () => void;
  children?: JSX.Element | string;
  disabled?: boolean;
}
const Button: FC<ButtonProps> = ({ variant, text, onClick, className, children, disabled }) => {
  return (
    <>
      <BrightcoveButton
        className={classnames(
          variant === 'link'
            ? `Button Button-${disabled ? 'link-disabled' : variant}`
            : `Button Button-${disabled ? 'disabled' : variant}`,
          className
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {text ? text : children}
      </BrightcoveButton>
    </>
  );
};

export default Button;
