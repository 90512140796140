import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  provideTheme,
  IconButton,
  ICONS,
  InputGroup,
  TagList
} from '@brightcove/studio-components';
import PlainCountryTagListInput from './PlainCountryTagList';
import css from 'styles/base.sass';

/**
An input for editing an array of country tags wrapped in an `InputGroup` for use
in forms.

This component accepts all props passed to `PlainCountryTagListInput` and
`InputGroup`.

All form inputs in this library must be implemented as [controlled
components](https://reactjs.org/docs/forms.html#controlled-components).
*/
export class CountryTagListInput extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      hasError: Boolean(this.props.error)
    };

    this.handleError = this.handleError.bind(this);
  }

  handleError(err) {
    this.setState({ hasError: Boolean(err) });
  }

  render() {
    const {
      className,
      contentRight,
      description,
      error,
      helperText,
      inputClassName,
      label,
      required,
      readOnly,
      style,
      testName,
      tooltip,
      theme,
      ...inputProps
    } = this.props;

    const controlProps = {
      description,
      helperText,
      label,
      required,
      style,
      testName,
      theme,
      tooltip
    };

    if (readOnly) {
      return (
        <InputGroup theme={theme} {...controlProps}>
          <TagList theme={theme} items={inputProps.value} {...inputProps} />
        </InputGroup>
      );
    }

    return (
      <InputGroup
        className={cx(css.countryTagList, className)}
        error={error || this.state.hasError ? 'Error' : null}
        theme={theme}
        tooltip="Here is a tooltip."
        contentRight={
          inputProps.value.length ? (
            <IconButton
              className={css.reset}
              name={ICONS.REPLAY}
              onClick={() => inputProps.onChange([])}
            />
          ) : (
            contentRight
          )
        }
        {...controlProps}
      >
        <PlainCountryTagListInput
          theme={theme}
          className={inputClassName}
          testName={testName}
          onError={this.handleError}
          hasError={Boolean(error)}
          {...inputProps}
        />
      </InputGroup>
    );
  }
}

CountryTagListInput.propTypes = {
  ...PlainCountryTagListInput.propTypes,
  ...InputGroup.propTypes,
  inputClassName: PropTypes.string,
  /** A theme name to apply on this component */
  theme: PropTypes.string
};

export default provideTheme()(CountryTagListInput);
