import React, { FC, useState } from 'react';
import { Flex } from '@brightcove/studio-components';

import TextInput from '../ControlledComponents/TextInput';
import SimpleSelect from '../ControlledComponents/SimpleSelect';
import Button from '../Button/Button';
import { pageSections } from '../../utils';

import GenericModal, { GenericModalProps } from './GenericModal';
import AddContentItemModal from './AddContentItemModal';
import AddCollectionModal from './AddCollectionModal';
import './AddSectionModal.scss';

const pageSectionTypes = pageSections.custom_sections.map((type) => {
  return {
    label: type,
    value: type,
  };
});

interface AddSectionDetailsProps extends GenericModalProps {
  confirmBtnLabel?: string;
  nextStep?: (param1: any, param2?: string) => void;
  onCancel?: () => void;
}

const AddSectionDetails: FC<AddSectionDetailsProps> = ({
  confirmBtnLabel,
  show,
  onClose,
  nextStep,
  onCancel,
}) => {
  const [name, setName] = useState('');
  const [type, setType] = useState(pageSectionTypes[0].value);

  const handleCloseModal = () => {
    setName('');
    setType(pageSectionTypes[0].value);
    onCancel?.();
    onClose?.();
  };

  const onContinue = (modalType) => {
    nextStep?.({ name, type }, modalType);

    if (type === 'Custom') {
      handleCloseModal();
    }
  };

  return (
    <GenericModal
      className="Add-section-modal"
      show={show}
      title="Add a section"
      onClose={handleCloseModal}
      buttons={
        <Flex alignItems="center" justifyContent="center">
          <Button variant="secondary mr-3" text="Cancel" onClick={handleCloseModal} />
          {type === 'Hero' && (
            <Button
              className="mr-3"
              variant="primary"
              disabled={!name || !type}
              text="Add Item"
              onClick={() => onContinue('content-item')}
            />
          )}
          <Button
            variant="primary"
            disabled={!name || !type}
            text={type === 'Custom' ? confirmBtnLabel : 'Add Collection'}
            onClick={() => onContinue(type !== 'Custom' ? 'collection' : undefined)}
          />
        </Flex>
      }
    >
      <Flex alignItems="center" justifyContent="center" className="Add-section-modal-content mt-6">
        <Flex flexDirection="column" className="mr-8">
          <p className="label mb-2">Section Name*</p>
          <TextInput
            className="modal-text-input"
            property="name"
            value={name}
            onChange={(value) => setName(value)}
          />
        </Flex>
        <Flex flexDirection="column">
          <p className="label mb-2">Section Type*</p>
          <SimpleSelect
            className="modal-select-input select-input-lg mr-2 mt-2"
            property="type"
            value={type}
            options={pageSectionTypes}
            onChange={(value) => setType(value)}
          />
        </Flex>
      </Flex>
    </GenericModal>
  );
};

type AddSectionModalProps = AddSectionDetailsProps & {
  title: string;
  onAddSection: (params: any) => void;
};

const AddSectionModal: FC<AddSectionModalProps> = (props) => {
  const [step, setStep] = useState('details');
  const [sectionInfo, setSectionInfo] = useState<any>({});

  const nextStep = (stepData: any, modalType: any) => {
    const updatedSectionInfo = { ...sectionInfo, ...stepData };

    setSectionInfo(updatedSectionInfo);

    switch (updatedSectionInfo.type) {
      case 'Custom':
        props.onAddSection(updatedSectionInfo);
        resetModal();
        break;
      case 'Hero':
      case 'Grid':
      case 'Carousel':
        if (step === 'collection' || step === 'content-item') {
          props.onAddSection(updatedSectionInfo);
          resetModal();
        }

        setStep((prevStep) => (prevStep === 'details' && modalType ? modalType : 'details'));
        break;
    }
  };

  const resetModal = () => {
    setStep('details');
    setSectionInfo({});
  };

  const onAddContent = (items: any[]) => {
    const content = {
      type: step === 'collection' ? 'Collection' : 'Item',
      id: items?.[0].id,
    };

    nextStep({ content }, undefined);
  };

  return (
    <>
      {step === 'details' && <AddSectionDetails {...props} nextStep={nextStep} />}
      {step === 'collection' && (
        <AddCollectionModal
          {...props}
          filter={sectionInfo?.type === 'Hero' ? 'type:manual' : ''}
          onAddCollection={onAddContent}
          onCancel={resetModal}
        />
      )}
      {step === 'content-item' && (
        <AddContentItemModal {...props} onAddItems={onAddContent} onCancel={resetModal} />
      )}
    </>
  );
};

export default AddSectionModal;
