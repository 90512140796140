import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Flex } from '@brightcove/studio-components';

import EditPanel from '../../../components/Panel/EditPanel';
import TextInput from '../../../components/ControlledComponents/TextInput';
import TextArea from '../../../components/ControlledComponents/TextArea';

const LocaleInformation = ({ panelId, data, disableEditing, onEnableEditing, onCancel, onSave }) => {
  const [localeInfo, setLocaleInfo] = useState(data || {});

  useEffect(() => {
    setLocaleInfo(data);
  }, [data]);

  const cancelChanges = () => {
    setLocaleInfo(data);
    onCancel();
  };

  const onChange = (value, property) => {
    setLocaleInfo({ ...localeInfo, [property]: value });
  };

  return (
    <EditPanel
      id={panelId}
      title="Locale Information"
      disableEditing={disableEditing}
      onEnableEditing={onEnableEditing}
      onCancel={cancelChanges}
      save={() => onSave(localeInfo)}
    >
      {(isEditing) => (
        <Flex
          className="Page-detail-locale-information px-4 pt-4 pb-6"
          flexDirection="column"
          justifyContent="space-between"
        >
          <div className="panel-group">
            <p className="panel-label">Name</p>
            {isEditing ? (
              <TextInput
                className="panel-text-input"
                property="name"
                value={localeInfo?.name || ''}
                onChange={onChange}
              />
            ) : (
              <p className="panel-text">{localeInfo?.name || '-'}</p>
            )}
          </div>
          <div className="panel-group">
            <p className="panel-label">Description</p>
            {isEditing ? (
              <TextArea
                className={classNames('panel-text-area-input', { editing: isEditing })}
                property="description"
                value={localeInfo?.description || ''}
                disableResizing={true}
                onChange={onChange}
              />
            ) : (
              <p className="panel-text">{localeInfo?.description || '-'}</p>
            )}
          </div>
        </Flex>
      )}
    </EditPanel>
  );
};

export default LocaleInformation;
