import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { getUrlWithOptions } from '../../utils';
import { useApi } from '../../hooks/useApi';
import SubHeader from '../../components/SubHeader/SubHeader';
import Panel from '../../components/Panel/Panel';

import './ActivityLogDetail.scss';

const ActivityLogDetail = () => {
  const navigate = useNavigate();
  const { id: activityId } = useParams();
  const { apiGet } = useApi();
  const [activity, setActivity] = useState<any>({});

  useEffect(() => {
    (async () => {
      const { data: activityData } = await apiGet(getUrlWithOptions(`/activity_logs/${activityId}`));

      if (activityData) {
        setActivity(activityData);
      }
    })();
  }, []);

  const formatResponse = (str) => {
    return JSON.stringify(JSON.parse(str), undefined, 4);
  };

  const { activityType, resourceIds, success, changedBy, body, response } = activity;

  return (
    <div className="Activity-log-detail">
      <SubHeader
        title="Activity Type 1"
        detailMode
        subtitle="Back to Activity Log"
        icon={<Icon name={ICONS.ARROW_RIGHT} flip="horizontal" />}
        onBack={() => navigate(`../${ROUTES.ACTIVITY_LOG}`)}
      />
      <div className="main-content">
        <Panel title="Activity Details">
          <div className="panel-content pt-2">
            <div className="grid">
              <div className="mb-5">
                <div className="panel-label mb-1">Activity type</div>
                <div className="activity-type-description">{activityType}</div>
              </div>
              <div>
                <div className="panel-label mb-1">Resource ID</div>
                <div>{resourceIds?.join(', ')}</div>
              </div>
              <div className="my-5">
                <div className="panel-label mb-1">Status</div>
                <div>{success ? 'Success' : 'Failure'}</div>
              </div>
              <div className="my-5">
                <div className="panel-label mb-1">Admin user</div>
                <div>{changedBy?.email}</div>
              </div>
            </div>
            {body && (
              <div className="mb-5">
                <div className="panel-label">Change</div>
                <pre className="mb-0 mt-1">{formatResponse(body)}</pre>
              </div>
            )}
            {response && (
              <div>
                <div className="panel-label">Response</div>
                <pre className="mb-0 mt-1">{formatResponse(response)}</pre>
              </div>
            )}
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default ActivityLogDetail;
