import React, { FC } from 'react';
import classnames from 'classnames';
import { Panel as BrightcovePanel, PanelTitleBar } from '@brightcove/studio-components';
import './Panel.scss';

export interface PanelProps {
  title: JSX.Element | string;
  actions?: JSX.Element | string;
  children?: JSX.Element | string;
  className?: string;
}
const Panel: FC<PanelProps> = ({ className, title, actions, children }) => {
  return (
    <>
      <BrightcovePanel className={classnames('Panel', className)}>
        <PanelTitleBar title={title}>{actions}</PanelTitleBar>
        {children ? children : <></>}
      </BrightcovePanel>
    </>
  );
};

export default Panel;
