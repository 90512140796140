import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import _, { capitalize } from 'lodash';

import { getSimplifiedItemType, getUrlWithOptions } from '../../../../utils';
import { toggleLanguageDisabled } from '../../../../store/actions';
import { useApi } from '../../../../hooks/useApi';
import Table from '../../../../components/Table/Table';
import EditPanel from '../../../../components/Panel/EditPanel';

import {
  CustomizeButton,
  ManageCollectionButton,
  ManageItemButton,
  commonColumns,
} from './SectionContent.constants';
import './SectionContent.scss';

const SectionContent = ({
  panelId,
  section: sectionProp,
  pageId,
  disableEditing,
  onSave,
  toggleDisableEditing,
  currentLanguage,
  customizations: customizationsProp = [],
}) => {
  const { apiGet, isLoading } = useApi();
  const [section, setSection] = useState<any>(sectionProp || {});
  const [contentData, setContentData] = useState<any>({});
  const [customizations, setCustomizations] = useState<any[]>(customizationsProp);
  const dispatch = useDispatch();
  const savedContentData = useRef();
  const sectionType = section?.type?.toLowerCase();
  const isHeroSection = sectionType === 'hero';
  const contentType = section?.content?.type?.toLowerCase();
  const isContentCollection = contentType === 'collection';

  const heroColumns = [
    {
      Header: 'Action',
      accessor: 'customization',
      Cell: ({ value }) => (
        <>
          <div className="disabled-customize-btn">Customize</div>
          <CustomizeButton
            className="customize-btn"
            variant="link"
            modalProps={{
              data: { ...value, customizations },
              onCustomize,
              includesContentDetails: true,
            }}
          >
            Customize
          </CustomizeButton>
        </>
      ),
      colWidth: '154px',
    },
  ];

  useEffect(() => {
    setCustomizations(customizationsProp);
  }, [customizationsProp]);

  useEffect(() => {
    (async () => {
      let collection = {};

      if (isHeroSection && isContentCollection) {
        collection = await getCollection();
      }

      const { data } = await apiGet(getUrlWithOptions(`/pages/${pageId}/sections/${section.id}/content`));

      data && setContentData({ ...data, ...collection });
    })();
  }, []);

  const onUpdateSectionField = (field, value) => {
    const updatedSection = JSON.parse(JSON.stringify(section)); // create deep copy

    _.set(updatedSection, field, value);
    setSection(updatedSection);
  };

  const onUpdateContent = async ([selectedItem]) => {
    const { id, name, type } = selectedItem;
    let updatedContentData;

    if (isContentCollection) {
      const { data } = await apiGet(getUrlWithOptions(`/collections/${id}/items`));

      if (!data) {
        return;
      }

      updatedContentData = data;
    } else {
      updatedContentData = selectedItem;
    }

    setCustomizations([]);
    onUpdateSectionField('content', {
      ...section.content,
      id,
      name,
      collectionType: type,
    });
    savedContentData.current = { ...contentData };
    setContentData(updatedContentData);
  };

  const getCollection = async () => {
    const { data } = await apiGet(getUrlWithOptions(`/collections/${section?.content?.id}/items`));

    return data || {};
  };

  const formatContentItems = (contentItems) => {
    return contentItems.map((item) => {
      const itemCustomization =
        customizations.find((customization: any) => item.id === customization?.content?.id) || {};

      return {
        ...item,
        language: currentLanguage,
        customization: {
          ...itemCustomization,
          content: {
            name: item.name,
            id: item.id,
            type: item.type,
          },
        },
      };
    });
  };

  const onCustomize = (updatedCustomization) => {
    const existingCustomizationIndex = customizations.findIndex(
      (item) => item.itemId === updatedCustomization.itemId
    );
    const updatedCustomizations = [...customizations];

    if (existingCustomizationIndex > -1) {
      updatedCustomizations[existingCustomizationIndex] = updatedCustomization;
    } else {
      updatedCustomizations.push(updatedCustomization);
    }

    setCustomizations(updatedCustomizations);
  };

  const getTableData = () => {
    const tableData = contentData?.items || (contentData && [contentData]) || [];

    if (isHeroSection) {
      return formatContentItems(tableData);
    }

    return tableData;
  };

  const handleEnableEditing = () => {
    dispatch(toggleLanguageDisabled());
    toggleDisableEditing();
  };

  const defaultButtonProps = {
    className: 'ml-4 mb-5 mt-2',
    variant: 'primary',
    children: (
      <>
        + {_.isEmpty(contentData) ? 'Add' : 'Edit'} {_.capitalize(contentType)}
      </>
    ),
  };

  const defaultModalProps = {
    title: `Edit a Section - Select ${_.capitalize(contentType)}`,
    filter: isHeroSection ? 'type:manual' : '',
    singleSelection: true,
  };

  return (
    <EditPanel
      id={panelId}
      className="Section-Content"
      title="Section Content"
      disableEditing={disableEditing}
      disableSave={section?.name === ''}
      onEnableEditing={handleEnableEditing}
      onCancel={() => {
        setSection(sectionProp);
        // tslint:disable-next-line
        savedContentData.current && setContentData(savedContentData.current);
        setCustomizations(customizationsProp);
        toggleDisableEditing();
        dispatch(toggleLanguageDisabled());
      }}
      save={() => {
        dispatch(toggleLanguageDisabled());

        return onSave(section, customizations);
      }}
    >
      {(isEditing) => (
        <div>
          <div className={`pt-4 pb-8 px-5 Section-Content-grid${isHeroSection ? ' isHeroSection' : ''}`}>
            <div>
              <div className="panel-label">{isHeroSection ? 'Content source name' : 'Collection name'}</div>
              <div>{contentData?.content?.name}</div>
            </div>
            {isHeroSection && (
              <div>
                <div className="panel-label">Content Type</div>
                <div>{capitalize(getSimplifiedItemType(contentData?.content?.type))}</div>
              </div>
            )}
            <div>
              <div className="panel-label">{isHeroSection ? 'Content source ID' : 'Collection ID'}</div>
              <div>{section?.content?.id}</div>
            </div>
            {!isHeroSection && (
              <>
                <div>
                  <div className="panel-label">Collection Type</div>
                  <div>{contentData?.content?.type}</div>
                </div>
                <div>
                  <div className="panel-label">Max # Items</div>
                  <div>{contentData?.content?.maxNumItems}</div>
                </div>
              </>
            )}
          </div>
          {!_.isEmpty(contentData) && (
            <Table
              className={`thead-grey${isEditing ? ' isEditing' : ''}`}
              data={getTableData()}
              columns={isHeroSection ? commonColumns.concat(heroColumns) : commonColumns}
              width={800}
              loading={isLoading}
            />
          )}
          {isEditing &&
            (isContentCollection ? (
              <ManageCollectionButton
                {...defaultButtonProps}
                modalProps={{
                  ...defaultModalProps,
                  onAddCollection: onUpdateContent,
                }}
              />
            ) : (
              <ManageItemButton
                {...defaultButtonProps}
                modalProps={{
                  ...defaultModalProps,
                  onAddItems: onUpdateContent,
                }}
              />
            ))}
        </div>
      )}
    </EditPanel>
  );
};

export default SectionContent;
