import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { formatDate } from '../../utils/format';
import { DEFAULT_PAGE } from '../../utils/constants';
import { getUrlWithOptions, isSuperAdmin } from '../../utils';
import COLORS from '../../styles/_colors.export.scss';
import { useApi } from '../../hooks/useApi';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import SideNavigationItem from '../../components/SideNavigation/SideNavigationItem';
import SideNavigation from '../../components/SideNavigation/SideNavigation';
import { DeleteConfirmationModal, withModal } from '../../components/Modals';
import InputSearch from '../../components/ControlledComponents/InputSearch';
import Button from '../../components/Button/Button';
import './UserManagement.scss';

const DeleteButton = withModal(Button, DeleteConfirmationModal);

const UserManagement = () => {
  const navigate = useNavigate();
  const { apiGet, apiDelete, refetchGet, isLoading } = useApi();
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [roleNames, setRoleNames] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [roleFilter, setRoleFilter] = useState<string>('');
  const [sort, setSort] = useState<string>('');
  const [currentPageSize, setCurrentPageSize] = useState(50);
  const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_PAGE);
  const [users, setUsers] = useState<any>([]);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [fetchUrl, setFetchUrl] = useState<string>(
    getUrlWithOptions('/users', undefined, undefined, undefined, currentPageIndex, currentPageSize)
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name and email',
        sortable: true,
        id: 'firstName',
        accessor: (row) => ({
          firstName: row.firstName,
          lastName: row.lastName,
          email: row.email,
          id: row.id,
        }),
        Cell: ({ value, row }) => (
          <TitleDescription
            title={`${value.firstName} ${value.lastName}`}
            description={value.email}
            id={value.id}
            onClick={
              !isSuperAdmin({ name: row.original.role })
                ? (id) => navigate(`../${ROUTES.USER_MANAGEMENT}/edit/${id}`)
                : undefined
            }
          />
        ),
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Status',
        accessor: 'deleted',
        sortable: true,
        className: 'status',
        Cell: ({ value }) => (
          <Icon name={value ? ICONS.WARNING : ICONS.CHECK2} color={value ? COLORS.burgundy : COLORS.cyan} />
        ),
      },
      {
        Header: 'Last Login',
        accessor: 'lastLoggedInAt',
        className: 'lastLogin',
        Cell: ({ value }) => <div>{formatDate(value)}</div>,
      },
    ],
    []
  );

  useEffect(() => {
    setFetchUrl(
      getUrlWithOptions('/users', searchQuery, sort, roleFilter, currentPageIndex, currentPageSize)
    );
  }, [searchQuery, roleFilter, sort, currentPageIndex, currentPageSize]);
  useEffect(() => {
    (async () => {
      setUsers([]);
      const [{ data: rolesData }, { data: usersData }] = await Promise.all([
        apiGet(getUrlWithOptions('/basic/roles')),
        apiGet(fetchUrl),
      ]);

      if (rolesData) {
        setRoleNames(rolesData.map((role) => ({ ...role, iconName: 'USER' })));
      }

      if (usersData) {
        setTotalCount(usersData.totalCount);
        setUsers(
          usersData.items.map((user) => ({
            ...user,
            disableSelection: isSuperAdmin({ name: user.role }),
          }))
        );
      }
    })();
  }, [fetchUrl]);

  const onChangeSelection = (items) => setSelectedItems(items);

  const onDeleteItems = async () => {
    const idsToDelete = selectedItems.map((item) => item.id).toString();
    const { error } = await apiDelete(
      `${getUrlWithOptions('/users')}?ids=${encodeURIComponent(idsToDelete)}`
    );

    if (!error) {
      const { data } = await refetchGet();

      setUsers(data.items);
    }
  };

  const onSearch = (query) => {
    setSearchQuery(query);
  };

  const onChangeFilter = (filter: string) => {
    setRoleFilter(`role:${filter}`);
    setCurrentPageIndex(DEFAULT_PAGE);
  };

  const onClickSort = (field, order) => {
    if (field && order) {
      setSort(`${field}:${order}`);
    }
  };

  const onPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
  }, []);

  return (
    <Flex className="User-management">
      <SideNavigation onChange={onChangeFilter} item={SideNavigationItem} filters={roleNames} />

      <div className="content">
        <SubHeader
          title="User Management"
          subtitle="NetApp TV"
          actions={<InputSearch placeholder="Search by name or email" onSearch={onSearch} />}
        />
        <div>
          <DeleteButton
            className="ml-5 mt-4"
            variant="danger"
            disabled={
              selectedItems.length === 0 ||
              !!selectedItems.find(({ disableSelection }) => disableSelection) ||
              selectedItems.some((item) => item.deleted)
            }
            modalProps={{
              title: 'Delete User',
              children: (
                <>
                  You will be deleting {selectedItems.length} user
                  {selectedItems.length !== 1 && 's'} from the system. This action cannot be undone.
                </>
              ),
            }}
            onModalConfirm={() => onDeleteItems()}
          >
            Delete
          </DeleteButton>
        </div>

        <Table
          className="mt-4"
          data={users}
          columns={columns}
          onChangeSelection={onChangeSelection}
          onClickSort={onClickSort}
          hasSelection
          hasPagination
          hasSelectAll={false}
          pageCount={Math.ceil(currentTotalCount / currentPageSize)}
          pageIndex={currentPageIndex}
          pageSize={currentPageSize}
          onPaginationChange={onPaginationChange}
          width={880}
          loading={isLoading}
        />
      </div>
    </Flex>
  );
};

export default UserManagement;
