import { useNavigate } from 'react-router';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { flowRight as compose } from 'lodash';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { formatDate } from '../../utils/format';
import { DEFAULT_PAGE } from '../../utils/constants';
import { getPageCount, getUrlWithOptions } from '../../utils';
import { useApi } from '../../hooks/useApi';
import { withRouter } from '../../components/withRouter/withRouter';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import { DeleteConfirmationModal, withModal } from '../../components/Modals';
import InputSearch from '../../components/ControlledComponents/InputSearch';
import Button from '../../components/Button/Button';

import './Collections.scss';

const DeleteButton = withModal(Button, DeleteConfirmationModal);

interface CollectionsProps {
  embedded?: boolean;
  filter?: string;
  singleSelection?: boolean;
  onRowSelect?: (param: any[]) => void;
}

const Collections: FC<CollectionsProps> = ({ embedded, filter, singleSelection, onRowSelect }) => {
  const navigate = useNavigate();
  const { apiGet, apiDelete, refetchGet } = useApi();
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sort, setSort] = useState<string>('updatedAt:desc');
  const [collectionsData, setCollectionsData] = useState<any>(undefined);
  const [currentPageSize, setCurrentPageSize] = useState(50);
  const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_PAGE);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      Header: 'Name',
      sortable: true,
      accessor: 'name',
      Cell: ({ value, row }) => (
        <TitleDescription
          title={value}
          description={row.original.id}
          id={row.original.id}
          onClick={(id) => navigate(`../${ROUTES.COLLECTIONS}/edit/${id}`)}
        />
      ),
    },
    {
      Header: 'Type',
      accessor: 'type',
      sortable: false,
      className: 'type',
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      sortable: true,
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
          <div className="subtitle">{row.original.createdBy}</div>
        </>
      ),
      className: 'date',
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
          <div className="subtitle">{row.original.updatedBy}</div>
        </>
      ),
      sortable: true,
      className: 'date',
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      setCollectionsData([]);

      const { data } = await apiGet(
        getUrlWithOptions(
          '/collections',
          searchQuery,
          sort,
          filter || undefined,
          currentPageIndex,
          currentPageSize
        )
      );

      setCollectionsData(data);
      setTotalCount(data.totalCount);
      setLoading(false);
    })();
  }, [searchQuery, sort, currentPageIndex, currentPageSize]);

  const onChangeSelection = (items) => {
    setSelectedItems(items);
    onRowSelect?.(items);
  };

  const onDeleteItems = async () => {
    const idsToDelete = selectedItems.map((item) => item.id).toString();
    const { error } = await apiDelete(
      getUrlWithOptions(`/collections?ids=${encodeURIComponent(idsToDelete)}`)
    );

    if (!error) {
      const { data } = await refetchGet();

      setCollectionsData(data);
      setTotalCount(data.totalCount);
    }
  };

  const onClickSearch = (query) => setSearchQuery(query);

  const onClickSort = (field, order) => {
    if (field && order) {
      setSort(`${field}:${order}`);
    }
  };

  const onPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
  }, []);

  return (
    <Flex className="Collections" flexDirection="column">
      <SubHeader
        title="Collections"
        subtitle="NetApp TV"
        actions={<InputSearch placeholder="Search" onSearch={onClickSearch} />}
        icon={<Icon name={ICONS.CARD} />}
        quantitySubtitle={`${collectionsData?.totalCount || 0} Collection${
          collectionsData?.totalCount === 1 ? '' : 's'
        }`}
      />
      {!embedded && (
        <Flex className="mt-4 mx-10" justifyContent="space-between" alignItems="center">
          <DeleteButton
            variant="danger"
            disabled={selectedItems.length === 0}
            modalProps={{
              title: 'Delete Collection',
              children: (
                <>
                  You will be deleting {selectedItems.length} collection
                  {selectedItems.length !== 1 && 's'} from the system. This action cannot be undone.
                </>
              ),
            }}
            onModalConfirm={onDeleteItems}
          >
            Delete
          </DeleteButton>
          <Button
            variant="primary"
            text="Create collection"
            onClick={() => navigate(`../${ROUTES.COLLECTIONS}/create`)}
          />
        </Flex>
      )}
      <Table
        className={!embedded ? 'mt-4' : ''}
        data={collectionsData?.items || []}
        columns={columns}
        onChangeSelection={onChangeSelection}
        onClickSort={onClickSort}
        hasSelection
        defaultSortingValues={[{ id: 'updatedAt', desc: true }]}
        hasPagination
        pageCount={getPageCount(currentTotalCount, currentPageSize)}
        onPaginationChange={onPaginationChange}
        pageIndex={currentPageIndex}
        pageSize={currentPageSize}
        singleSelection={singleSelection}
        width={840}
        loading={loading}
      />
    </Flex>
  );
};

export default compose(withRouter)(Collections);
