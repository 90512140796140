import DatePicker, { DatePickerProps } from 'react-widgets/DatePicker';
import React, { ChangeEvent, FC } from 'react';
import moment from 'moment';

import { DATE_FORMAT, DATE_FORMAT_SECONDS } from '../../utils/constants';

type Props = DatePickerProps & {
  customFormat?: string;
};

const CustomDatePicker: FC<Props> = ({ customFormat, timeInputProps, timePrecision, onChange, ...props }) => {
  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const format = timePrecision === 'seconds' ? DATE_FORMAT_SECONDS : DATE_FORMAT;
    const date = moment(event.target.value, customFormat || format, true);

    if (date.isValid()) {
      onChange?.(date.toDate(), date.toISOString());
    }
  };

  return (
    <DatePicker
      {...props}
      includeTime
      timePrecision={timePrecision}
      timeInputProps={timeInputProps || { use12HourClock: true }}
      inputProps={{
        ...props.inputProps,
        onKeyUp: handleInput as any,
      }}
      onChange={onChange}
    />
  );
};

export default CustomDatePicker;
