import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Flex, Icon, ICONS, Thumbnail } from '@brightcove/studio-components';

import { formatDate } from '../../../../utils/format';
import { getItemCollectionPath } from '../../../../utils';
import COLORS from '../../../../styles/_colors.export.scss';
import TitleDescription from '../../../../components/TitleDescription/TitleDescription';
import CustomizeModal from '../../../../components/Modals/CustomizeModal';
import AddContentItemModal from '../../../../components/Modals/AddContentItemModal';
import AddCollectionModal from '../../../../components/Modals/AddCollectionModal';
import { withModal } from '../../../../components/Modals';
import Button from '../../../../components/Button/Button';

export const ManageCollectionButton = withModal(Button, AddCollectionModal);
export const CustomizeButton = withModal(Button, CustomizeModal);
export const ManageItemButton = withModal(Button, AddContentItemModal);

export const commonColumns: any[] = [
  {
    Header: 'Collection Item',
    accessor: (row) => ({ name: row.name, id: row.id, type: row.type }),
    Cell: ({ value, row }) => {
      const navigate = useNavigate();
      const image = value.image || row.original?.image;

      return (
        <Flex>
          <Thumbnail className="Collections-detail-thumbnail" src={image} />
          <TitleDescription
            className="pb-3 ml-4"
            title={value.name}
            description={value.id}
            onClick={() => navigate(`../${getItemCollectionPath(value.type)}/edit/${value.id}`)}
          />
        </Flex>
      );
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
    className: 'type',
  },
  {
    Header: 'Published',
    accessor: 'published',
    Cell: ({ value }) => {
      return value ? (
        <Icon name={ICONS.CHECK2} color={COLORS.cyan} />
      ) : (
        <Icon name={ICONS.WARNING} color={COLORS.burgundy} />
      );
    },
    className: 'published',
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    Cell: ({ value }) => <>{formatDate(value)}</>,
    className: 'date',
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    Cell: ({ value }) => <>{formatDate(value)}</>,
    className: 'date',
  },
];
