import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tag } from '@brightcove/studio-components';
import css from 'styles/plain-taglist-input.sass';

class PlaceholderTag extends React.Component {
  render() {
    const { theme, children } = this.props;

    return (
      <Tag className={cx(css[theme], css.placeholder)} theme={theme}>
        {children}
      </Tag>
    );
  }
}

PlaceholderTag.propTypes = {
  theme: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default PlaceholderTag;
