import { useNavigate, useParams } from 'react-router';
import React, { FC, useEffect, useState } from 'react';
import { Flex, Icon } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { Toaster, fillMissingLanguages, getUrlWithOptions, updateItems, UPDATE } from '../../utils';
import { useApi } from '../../hooks/useApi';
import SubHeader from '../../components/SubHeader/SubHeader';
import Panel from '../../components/Panel/Panel';
import TextInput from '../../components/ControlledComponents/TextInput';
import SimpleSelect from '../../components/ControlledComponents/SimpleSelect';
import Button from '../../components/Button/Button';
import './LocalizedLabels.scss';

interface MessageData {
  language: string;
  value: string;
}

interface LanguageData {
  value: string;
  label: string;
}

const resource = '/localized_labels';
const DEFAULT_LANGUAGE = 'en-us';
const LocalizedLabelDetail: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { apiGet, apiPut, apiPost, isLoading } = useApi();
  const [currentKeyTitle, setCurrentKeyTitle] = useState('');
  const [currentKey, setCurrentKey] = useState('');
  const [messages, setMessages] = useState<MessageData[]>([]);
  const [languages, setLanguages] = useState<LanguageData[]>([]);
  const [selectedMessageValue, setSelectedMessageValue] = useState('');
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE);
  const [currentId, setCurrentId] = useState<string>(id ? id : '');
  const [editing, setEditing] = useState(!id);
  const [originalData, setOriginalData] = useState<MessageData[]>([]);

  useEffect(() => {
    getLanguages();
  }, [currentId]);
  useEffect(() => {
    const currentMessage = messages.find((message) => message.language === currentLanguage)?.value;

    setSelectedMessageValue(currentMessage?.length ? currentMessage : '');
  }, [currentLanguage, messages]);

  const getLanguages = async () => {
    const { data } = await apiGet(getUrlWithOptions(`/languages`));

    setLanguages(data);
  };

  useEffect(() => {
    if (languages.length) {
      if (currentId.length) {
        getData();
      } else {
        setMessages(fillMissingLanguages([], languages));
      }
    }
  }, [languages]);

  const getData = async () => {
    const { data } = await apiGet(getUrlWithOptions(`${resource}/${currentId}`));

    setCurrentKeyTitle(data.key);
    setCurrentKey(data.key);
    setOriginalData(data.values);
    setMessages(fillMissingLanguages(data.values, languages));
  };

  const updateMessagesByLanguage = (value: string) => {
    return messages.map((message: MessageData) => {
      if (message.language === currentLanguage) {
        message.value = value;
      }

      return message;
    });
  };
  const onSubmit = async () => {
    let body;

    if (id?.length) {
      body = {
        values: messages.filter((message: MessageData) => message.value),
      };

      const response = await apiPut(getUrlWithOptions(`${resource}/${id}`), {
        body,
      });

      if (response?.data?.key?.length) {
        Toaster.success({
          message: 'Localized Label Save',
        });

        const updatedItems = updateItems(messages, [response?.data.values[0]], UPDATE, 'language');

        setOriginalData(fillMissingLanguages(updatedItems, languages));
        setMessages(fillMissingLanguages(updatedItems, languages));
        setCurrentLanguage(DEFAULT_LANGUAGE);
        setEditing(false);
      }
    } else {
      body = {
        key: currentKey,
        values: messages.filter((message: MessageData) => message.value),
      };

      const response = await apiPost(getUrlWithOptions(`${resource}`), {
        body,
      });
      const isSuccess = Array.isArray(response?.data?.values);

      if (isSuccess) {
        setCurrentId(currentKey);
        navigate(`../${ROUTES.LOCALIZED_LABELS}/edit/${currentKey}`);
      }
    }
  };

  return (
    <>
      <SubHeader
        icon={<Icon name="ARROW_RIGHT" rotate="180" />}
        title={!!currentKeyTitle.length && !!currentId?.length ? currentKeyTitle : 'New Label'}
        subtitle={'Back to Localized Labels'}
        detailMode
        onBack={() => navigate(`../${ROUTES.LOCALIZED_LABELS}`)}
        className="pl-5"
      />
      <div className="ml-11 mr-11">
        {languages?.length ? (
          <Panel
            className="mt-12"
            title={<p>Label Detail </p>}
            actions={
              <>
                {!editing && !!currentId?.length ? (
                  <Button variant="primary" text={'Edit'} onClick={() => setEditing(true)} />
                ) : (
                  <>
                    <SimpleSelect
                      className="select-input-lg mr-2"
                      value={currentLanguage}
                      options={languages as any}
                      onChange={(value) => {
                        setCurrentLanguage(value);
                      }}
                    />
                    <Button
                      variant="secondary"
                      text="Cancel"
                      onClick={() => {
                        if (editing && !!currentId) {
                          setCurrentLanguage(DEFAULT_LANGUAGE);
                          setMessages(fillMissingLanguages(originalData, languages));
                          setEditing(false);
                        } else {
                          navigate(`../${ROUTES.LOCALIZED_LABELS}`);
                        }
                      }}
                    />

                    <Button
                      variant="primary"
                      text={currentId ? 'Save' : 'Create'}
                      onClick={onSubmit}
                      disabled={!currentKey.length || isLoading}
                    />
                  </>
                )}
              </>
            }
          >
            <>
              <Flex>
                <Flex {...{ flexDirection: 'column' }}>
                  <Flex>
                    <div className="LocalizedLabelsDetail w-100">
                      <p className="LocalizedLabelsDetail-title">Key</p>
                      {editing ? (
                        <TextInput
                          value={currentKey}
                          onChange={setCurrentKey}
                          className="w-100"
                          disabled={currentId?.length ? true : false}
                        />
                      ) : (
                        <div>{currentKey}</div>
                      )}
                    </div>
                  </Flex>
                  <Flex>
                    <div className="LocalizedLabelsDetail mt-4">
                      <p className="LocalizedLabelsDetail-title">Value</p>
                      {editing ? (
                        <textarea
                          className="LocalizedLabelsDetail-body"
                          value={selectedMessageValue}
                          onChange={(e) => {
                            setMessages(updateMessagesByLanguage(e.target.value));
                            setSelectedMessageValue(e.target.value);
                          }}
                        ></textarea>
                      ) : (
                        <div>{selectedMessageValue}</div>
                      )}
                    </div>
                  </Flex>
                </Flex>
              </Flex>
            </>
          </Panel>
        ) : null}
      </div>
    </>
  );
};
export default LocalizedLabelDetail;
