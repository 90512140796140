import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Icon, ICONS } from '@brightcove/studio-components';

import * as ROUTES from '../routes';
import { formatDate } from '../../utils/format';
import { DEFAULT_PAGE } from '../../utils/constants';
import { getPageCount, getUrlWithOptions } from '../../utils';
import COLORS from '../../styles/_colors.export.scss';
import { selectEventsFilter, selectEventsSearch } from '../../store/selectors';
import { setEventsFilter, setEventsSearch } from '../../store/actions';
import { useApi } from '../../hooks/useApi';
import TitleDescription from '../../components/TitleDescription/TitleDescription';
import Table from '../../components/Table/Table';
import SubHeader from '../../components/SubHeader/SubHeader';
import SideNavigationItem from '../../components/SideNavigation/SideNavigationItem';
import SideNavigation from '../../components/SideNavigation/SideNavigation';
import { DeleteConfirmationModal, withModal } from '../../components/Modals';
import InputSearch from '../../components/ControlledComponents/InputSearch';
import Button from '../../components/Button/Button';
import './Events.scss';

const DeleteButton = withModal(Button, DeleteConfirmationModal);

const Events = () => {
  const filterOptions = [
    { name: 'All Events', id: '', iconName: ICONS.MAIN_MENU_EVENTS },
    { name: 'Live Events', id: 'Live', iconName: ICONS.CALENDAR },
    { name: 'Upcoming Events', id: 'Upcoming', iconName: ICONS.CALENDAR },
    { name: 'Past Events', id: 'Complete', iconName: ICONS.CALENDAR },
  ];
  const dispatch = useDispatch();
  const filter = useSelector(selectEventsFilter);
  const search = useSelector(selectEventsSearch);

  const [selectedFilter, setSelectedFilter] = useState<string>(filter || filterOptions[0].id);
  const [selectedFilterName, setSelectedFilterName] = useState<string>(
    filterOptions.find(({ id }) => id === filter)?.name || filterOptions[0].name
  );
  const [searchQuery, setSearchQuery] = useState<string>(search || '');
  const [eventsData, setEventsData] = useState<any>(undefined);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const { apiGet, apiDelete, refetchGet } = useApi();
  const [currentPageSize, setCurrentPageSize] = useState(50);
  const [currentPageIndex, setCurrentPageIndex] = useState(DEFAULT_PAGE);
  const [currentTotalCount, setTotalCount] = useState(0);
  const [sort, setSort] = useState<string>('updatedAt:desc');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const columns = [
    {
      Header: 'Name',
      id: 'name',
      className: 'name',
      accessor: (row) => ({ name: row.name, id: row.id }),
      Cell: ({ value: { name, id: rowId } }) => (
        <TitleDescription
          title={name}
          description={rowId}
          id={rowId}
          onClick={(id) => navigate(`../${ROUTES.EVENTS}/edit/${id}`)}
        />
      ),
      sortable: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      sortable: true,
      className: 'status',
    },
    {
      Header: 'Published',
      accessor: 'published',
      sortable: true,
      Cell: ({ value }) => (
        <Icon name={value ? ICONS.CHECK2 : ICONS.WARNING} color={value ? COLORS.cyan : COLORS.burgundy} />
      ),
      className: 'published',
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
          <div className="subtitle">{row.original.createdBy}</div>
        </>
      ),
      className: 'date',
      sortable: true,
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      Cell: ({ value, row }) => (
        <>
          <div>{formatDate(value)}</div>
          <div className="subtitle">{row.original.updatedBy}</div>
        </>
      ),
      className: 'date',
      sortable: true,
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      setEventsData([]);
      const { data } = await apiGet(
        getUrlWithOptions(
          '/events',
          searchQuery,
          sort,
          `status:${selectedFilter}`,
          currentPageIndex,
          currentPageSize
        )
      );

      if (data) {
        setEventsData(data);
        setTotalCount(data.totalCount);
      }

      setLoading(false);
    })();
  }, [searchQuery, sort, selectedFilter, currentPageIndex, currentPageSize]);

  const onChangeFilter = (filter: string) => {
    setSelectedFilter(filter ? filter : '');
    setCurrentPageIndex(DEFAULT_PAGE);
    setSelectedFilterName(filterOptions.filter((option) => option.id === filter)[0].name);
    dispatch(setEventsFilter(filter));
  };

  const onSearch = (query) => {
    setSearchQuery(query);
    dispatch(setEventsSearch(query));
  };

  const onChangeSelection = (items) => setSelectedItems(items);

  const onDeleteItems = async () => {
    const idsToDelete = selectedItems.map((item) => item.id).toString();
    const { error } = await apiDelete(getUrlWithOptions(`/events?ids=${encodeURIComponent(idsToDelete)}`));

    if (!error) {
      const { data } = await refetchGet();

      setEventsData(data);
      setTotalCount(data.totalCount);
    }
  };

  const onClickSort = (field, order) => {
    if (field && order) {
      setSort(`${field}:${order}`);
    }
  };

  const onPaginationChange = useCallback(({ pageSize, pageIndex }) => {
    setCurrentPageIndex(pageIndex);
    setCurrentPageSize(pageSize);
  }, []);

  return (
    <Flex className="Events">
      <SideNavigation
        onChange={onChangeFilter}
        item={SideNavigationItem}
        filters={filterOptions}
        defaultValue={selectedFilter}
      />
      <Flex className="EventsContent" flexDirection="column" style={{ flex: 1 }}>
        <SubHeader
          title={selectedFilterName}
          subtitle="NetApp TV"
          actions={<InputSearch value={searchQuery} placeholder="Search by name or ID" onSearch={onSearch} />}
          icon={<Icon name={ICONS.FILM} />}
          quantitySubtitle={`
            ${eventsData?.totalCount || 0} Item${eventsData?.totalCount === 1 ? '' : 's'}
          `}
        />

        <Flex className="py-4 pl-5 pr-8" justifyContent="space-between" alignItems="center">
          <DeleteButton
            variant="danger"
            disabled={selectedItems.length === 0}
            modalProps={{
              title: 'Delete Event',
              children: (
                <>
                  You will be deleting {selectedItems.length} event
                  {selectedItems.length !== 1 && 's'} from the system. This action cannot be undone.
                </>
              ),
            }}
            onModalConfirm={onDeleteItems}
          >
            Delete
          </DeleteButton>
          <Button
            variant="primary"
            text="Create new live event"
            onClick={() => navigate(`../${ROUTES.EVENTS}/create`)}
          />
        </Flex>

        <Table
          className="EventsTable"
          data={eventsData?.items || []}
          columns={columns}
          onChangeSelection={onChangeSelection}
          onClickSort={onClickSort}
          hasSelection
          defaultSortingValues={[{ id: 'updatedAt', desc: true }]}
          hasPagination
          pageCount={getPageCount(currentTotalCount, currentPageSize)}
          onPaginationChange={onPaginationChange}
          pageIndex={currentPageIndex}
          pageSize={currentPageSize}
          width={1000}
          loading={loading}
        />
      </Flex>
    </Flex>
  );
};

export default Events;
