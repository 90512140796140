import { Link } from 'react-scroll';
import React, { FC } from 'react';
import './TableOfContents.scss';
import classNames from 'classnames';

interface TableOfContentsProps {
  className?: string;
  data: TableOfContentsItem[];
  isFixed?: boolean;
  isSticky?: boolean;
  hasBorder?: boolean;
}
interface TableOfContentsItem {
  id: string;
  title: string;
  offset: number;
}
const TableOfContents: FC<TableOfContentsProps> = ({ data, isFixed, isSticky, className, hasBorder }) => {
  return (
    <div
      className={classNames(
        'TableOfContents-container',
        `${isSticky ? 'TableOfContents-sticky' : ''}`,
        className
      )}
    >
      <div>
        <div
          className={classNames(
            'TableOfContents-list',
            `${isFixed ? 'TableOfContents-fixed' : ''}`,
            `${hasBorder ? 'hasBorder' : ''}`
          )}
        >
          {data.map(({ id, title, offset }) => {
            return (
              <div className="TableOfContents-list-item" key={id}>
                <Link
                  to={id}
                  smooth={true}
                  duration={500}
                  activeClass="TableOfContents-list-item-active"
                  spy={true}
                  offset={offset}
                >
                  {title}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TableOfContents;
