import { compose } from 'redux';
import { useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';

import { getUrlWithOptions } from '../utils';
import { withStore } from '../store';
import { useApi } from '../hooks/useApi';
import Loading from '../components/Loading/Loading';

const authBaseUrl = getUrlWithOptions('/auth');

const AuthenticationCheck = ({ children, setUser, store: { user } }) => {
  const { apiGet } = useApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const location = window.location;

  const checkIfSessionValid = (status) => {
    switch (status) {
      case 200:
        return true;
      case 401:
        location.replace(`${authBaseUrl}/login?r=${encodeURIComponent(location.href)}`);
        break;
      default:
        setUser(null);
        navigate('/error');
    }

    return false;
  };

  useEffect(() => {
    (async () => {
      const { status } = await apiGet(`${authBaseUrl}/verify`, undefined, false);
      const isValidSession = checkIfSessionValid(status);

      if (isValidSession && !user) {
        const { data } = await apiGet(getUrlWithOptions('/users/me'));

        data && setUser(data);
      }

      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loading />;
  } else {
    return children;
  }
};

export default compose(withStore)(AuthenticationCheck);
