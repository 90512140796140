import { useTable, usePagination, useRowSelect, useGlobalFilter, useSortBy, useFilters } from 'react-table';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import React, { FC, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import update from 'immutability-helper';
import classnames from 'classnames';
import { Icon, ICONS } from '@brightcove/studio-components';

import Pagination from '../Pagination/Pagination';
import Loading from '../Loading/Loading';
import { DEFAULT_PAGE } from '../../utils/constants';
import COLORS from '../../styles/_colors.export.scss';
import { SVGImage } from '../../assets/images';

import Row from './components/Row';

import './Table.scss';

const IndeterminateCheckbox: FC<any> = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input className="Table-checkbox" type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

const IndeterminateRadioButton: FC<any> = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div className="Table-custom-radio-button">
      <label>
        <input type="radio" ref={resolvedRef} {...rest} />
        <span></span>
      </label>
    </div>
  );
});

export interface TableProps {
  data: unknown[];
  columns: unknown[];
  onChangeSelection?: (val: []) => void;
  hasPagination?: boolean;
  hasSelection?: boolean;
  hasSelectAll?: boolean;
  hasDeletion?: boolean;
  hasDragging?: boolean;
  className?: string;
  globalFilter?: any;
  columnFilters?: unknown[];
  setGlobalFilter?: (val: string) => void;
  onChangeRowsQuantity?: (val: number) => void;
  onPaginationChange?: (val: any) => void;
  onClickDeleteRow?: (val: string, val2: any) => void;
  onDragRow?: (val: any[]) => void;
  defaultSortingValues?: Array<{ id: string; desc: boolean }>;
  pageCount?: number;
  pageIndex?: number;
  pageSize?: number;
  onClickSort?: (val1: string, val2: string) => void;
  singleSelection?: boolean;
  resetSelections?: boolean;
  loading?: boolean;
  width?: number | string;
}

const Table: FC<TableProps> = ({
  data: currentData,
  columns: tableColumns,
  onChangeSelection,
  onChangeRowsQuantity,
  onPaginationChange,
  onClickSort,
  onClickDeleteRow,
  onDragRow,
  hasPagination,
  hasSelection,
  hasSelectAll = true,
  hasDeletion,
  hasDragging,
  className,
  globalFilter: currentFilter,
  columnFilters,
  defaultSortingValues,
  pageCount: controllerPageCount = 0,
  pageIndex: controlledPageIndex = DEFAULT_PAGE,
  pageSize: controlledPageSize = 10,
  singleSelection,
  resetSelections = true,
  loading = false,
  width,
}) => {
  // hacky solution to not lose references and avoid errors on table's changes
  // see https://github.com/TanStack/table/issues/1336#issuecomment-659288615
  const onDragRowRef = useRef(onDragRow);
  const onClickDeleteRowRef = useRef(onClickDeleteRow);
  onDragRowRef.current = onDragRow;
  onClickDeleteRowRef.current = onClickDeleteRow;

  if (hasDeletion) {
    tableColumns.push({
      id: 'deletion',
      className: 'deletion',
      Cell: ({ row: { original } }) => {
        if (!original.disableDeletion) {
          return (
            <img
              src={SVGImage.Trashcan}
              alt="trashcan"
              onClick={() => {
                onClickDeleteRowRef?.current?.(original.id, original);
              }}
            />
          );
        }
      },
    });
  }

  if (hasDragging) {
    tableColumns.push({
      id: 'dragging',
      className: 'dragging',
      Cell: ({ row }) => {
        if (!row.original.disableDragging) {
          return <Icon name={ICONS.MOVE} color={COLORS.green} />;
        }
      },
    });
  }

  const data: any = useMemo(() => (loading ? [] : currentData), [currentData]);
  const columns = useMemo(() => tableColumns, [hasDeletion, hasDragging, tableColumns]);
  const [storedData, setStoredData] = useState(data);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy },
    selectedFlatRows,
    setGlobalFilter,
    setAllFilters,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data: storedData,
      initialState: {
        pageIndex: controlledPageIndex,
        pageSize: controlledPageSize,
        sortBy: [...(defaultSortingValues ? defaultSortingValues : [{ id: undefined }])],
      }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      pageOptions: {},
      pageCount: controllerPageCount + 1,
      /* autoResetPage: false, */
      disableSortRemove: true,
      manualSortBy: true,
      autoResetSelectedRows: resetSelections,
      autoResetSelectedCell: false,
      autoResetSelectedColumn: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (!hasSelection) {
        return null;
      }

      hooks.visibleColumns.push((visibleColumns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          className: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({
            toggleRowSelected,
            rows: selectionRows,
            selectedFlatRows: selectedRows,
            getToggleAllRowsSelectedProps,
          }) => {
            const containsDisabledRow = !!data.find((item) => item.disableSelection);
            let modifiedToggleAllRowsProps;

            if (containsDisabledRow) {
              const style = {
                cursor: 'pointer',
              };
              // consider all rows selected if all but one are checked
              const checked: boolean =
                selectionRows.length > 1 && selectionRows.length - selectedRows.length === 1;
              const title = 'Toggle All Rows Selected';
              // header is indeterminate while it is unchecked and some rows are selected
              const indeterminate: boolean = !checked && selectedRows.length > 0;
              // header is disabled if only one row exists
              const disabled: boolean = selectionRows.length === 1;
              const overiddenOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                selectionRows.forEach(({ id, original }) => {
                  if (!original.disableSelection) {
                    toggleRowSelected(id, event.currentTarget.checked);
                  }
                });
              };

              modifiedToggleAllRowsProps = {
                onChange: overiddenOnChange,
                style,
                checked,
                title,
                indeterminate,
                disabled,
              };
            }

            return (
              <div>
                {!singleSelection && hasSelectAll && (
                  <>
                    {containsDisabledRow ? (
                      <IndeterminateCheckbox {...modifiedToggleAllRowsProps} />
                    ) : (
                      <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    )}
                  </>
                )}
              </div>
            );
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              {row.original.disableSelection ? null : (
                <>
                  {singleSelection ? (
                    <IndeterminateRadioButton
                      {...row.getToggleRowSelectedProps({
                        onChange: () => {
                          toggleAllRowsSelected(false);
                          row.toggleRowSelected(!row.isSelected);
                        },
                      })}
                    />
                  ) : (
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  )}
                </>
              )}
            </div>
          ),
        },
        ...visibleColumns,
      ]);
    }
  );

  useEffect(() => {
    setStoredData(data);
  }, [data]);

  useEffect(() => {
    if (onChangeSelection) {
      onChangeSelection(selectedFlatRows.map((d) => d.original));
    }
  }, [selectedFlatRows.length]);

  useEffect(() => {
    if (currentFilter) {
      setGlobalFilter(currentFilter);
    }
  }, [currentFilter]);

  useEffect(() => {
    if (columnFilters) {
      setAllFilters(columnFilters);
    }
  }, [columnFilters]);

  useEffect(() => {
    if (onChangeRowsQuantity) {
      onChangeRowsQuantity(rows.length);
    }
  }, [rows.length]);

  useEffect(() => {
    if (onPaginationChange) {
      onPaginationChange({ pageSize, pageIndex });
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (onClickSort) {
      const { id, desc } = sortBy[0];
      const order = desc ? 'desc' : 'asc';

      onClickSort(id, order);
    }
  }, [sortBy]);

  const moveRow = useCallback((dragIndex, hoverIndex) => {
    setStoredData((prevRows) => {
      const reorderedRows = update(prevRows, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevRows[dragIndex]],
        ],
      });

      onDragRowRef?.current?.(reorderedRows);

      return reorderedRows;
    });
  }, []);

  const loaderLength = hasSelection ? columns.length + 1 : columns.length;

  return (
    <>
      <div className={classnames('Table', className)}>
        <div className="InternalTable">
          <DndProvider backend={HTML5Backend}>
            <table {...getTableProps()} style={{ minWidth: width }}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr key={headerGroup} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => {
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.sortable
                              ? column.getSortByToggleProps({
                                  className: column.className,
                                })
                              : { className: column.className }
                          )}
                          style={{ width: column.colWidth }}
                          key={`${column.id}-${index}`}
                        >
                          {column.render('Header')}
                          {column.id !== 'selection' && (
                            <span className={`sorting-icon${!column.sortable ? ' visibility-hidden' : ''}`}>
                              <Icon
                                name={ICONS.TRIANGLE_RIGHT_FILLED}
                                rotate={column.isSorted ? (column.isSortedDesc ? '90' : '270') : undefined}
                                color={COLORS.grey50}
                              />
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {loading ? (
                  <tr role="row">
                    <td role="cell" colSpan={loaderLength}>
                      <Loading style={{ height: 100 }} />
                    </td>
                  </tr>
                ) : (
                  page.map((row, index) => {
                    prepareRow(row);

                    return (
                      <Row
                        key={`${row.original.id}-${index}`}
                        id={row.original.id}
                        index={index}
                        row={row}
                        moveRow={moveRow}
                      />
                    );
                  })
                )}
              </tbody>
            </table>
          </DndProvider>
        </div>

        {hasPagination && (
          <Pagination
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            setPageSize={setPageSize}
            canNextPage={canNextPage}
            pageCount={pageCount}
            pageOptions={pageOptions}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        )}
      </div>
    </>
  );
};

export default Table;
