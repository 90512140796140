import { XYCoord, useDrag, useDrop } from 'react-dnd';
import React, { FC, useRef } from 'react';

const DRAG_N_DROP_ITEM_TYPE = 'row';

interface RowProps {
  id: any;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  row: any;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const Row: FC<RowProps> = ({ id, index, moveRow, row }) => {
  const previewRef = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop<DragItem, void, { handlerId: any }>({
    accept: DRAG_N_DROP_ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!previewRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DRAG_N_DROP_ITEM_TYPE,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(dragRef);
  preview(drop(previewRef));

  return (
    <tr
      {...row.getRowProps()}
      className={row.isSelected && !row.original.disableSelection ? 'selected' : ''}
      ref={!row.original.disableDragging ? previewRef : undefined}
      style={{ opacity }}
    >
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps({
              className: cell.column.className,
            })}
            ref={cell.column.id === 'dragging' ? dragRef : undefined}
            style={{ width: cell.column.colWidth }}
            key={cell.column.id}
          >
            {cell.render('Cell')}
          </td>
        );
      })}
    </tr>
  );
};

export default Row;
